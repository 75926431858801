import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SdApiService } from '../../../../services/base/sd-api.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { merge, Observable, of } from 'rxjs';
import { MessageAttachmentService } from 'src/app/services/message-attachment.service';
import { SDAuthService } from 'src/app/services/sd-auth.service';
import { AppConstants } from 'src/app/resources/app-constants';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { FaqDataService } from 'src/app/services/faq-data.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ComposerMailService } from 'src/app/services/composer-mail.service';
import { Location } from '@angular/common';

declare var $: any;

const MODAL_DURATION = 3000;

@Component({
  selector: 'app-faq-manager-editor',
  templateUrl: './faq-manager-editor.component.html',
  styleUrls: ['./faq-manager-editor.component.scss']
})
export class FaqManagerEditorComponent implements OnInit, AfterViewInit {
  constructor(
    private service: SdApiService,
    private router: Router,
    private dialog: MatDialog,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    public messageAttachmentService: MessageAttachmentService,
    private faqDataService: FaqDataService,
    private authService: SDAuthService,
    private customerService: CustomerService,
    private location: Location,
    private composerMailService: ComposerMailService) { }

  loading = false;
  pageTitle = '';
  submitFaqLabel = '';
  mode = '';
  faqId = 0;
  title = '';
  userEmail = '';
  userCreated = '';
  userModified = '';
  clientEditValue;
  selectedClient: string = null;
  isSubmitting = false;

  filteredClientOptions: Observable<any[]>;

  public clientFilterControl = new FormControl();
  availableClients: string[] = [];
  allClientsObj = [];

  @ViewChild('inputFileControl', { read: ElementRef }) inputFileControl: ElementRef;

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe(loading => {
      this.loading = loading;
    });

    this.activatedRoute.queryParams.subscribe(async params => {
      this.faqId = params.id;
      this.mode = this.faqId == 0 ? 'new' : 'edit';

      this.userEmail = await this.authService.getCurrentUserEmail();

      if (this.mode === 'edit') {
        this.loadingStateService.setLoadingState(true);
        this.faqDataService.loadFaq(this.faqId).subscribe(faqData => {
          this.loadingStateService.setLoadingState(false);
          this.setLabels();

          this.userCreated = faqData.date_created;
          this.userModified = this.userEmail;
          this.title = faqData.title;

          (window as any).faqEditorHtml = faqData.faq_text;
          $('#faqEditorId').summernote('code', (window as any).faqEditorHtml);

          if (this.allClientsObj.length > 0) {
            const clientEquivalent = this.allClientsObj.filter(c => c.id === faqData.client_id)[0];
            this.selectedClient = clientEquivalent?.name || '';
          } else {
            this.clientEditValue = faqData.client_id;
          }
        });
        
        this.clientFilterControl.disable();
      } else {
        this.setLabels();
        this.userCreated = this.userEmail;
        this.userModified = '';
      }
    });


    this.loadingStateService.setLoadingState(true);
    this.customerService.getCustomers().subscribe(response => {
      this.loadingStateService.setLoadingState(false);
      const clients = response.data;
      this.availableClients = clients.map(c => c.customer_name).sort();
      this.allClientsObj = clients.map(c => {
        c.id = c.customer_id;
        c.name = c.customer_name;
        return c;
      });
      this.filteredClientOptions = this.clientFilterControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.customer_name),
          map(customerName => customerName ? this._filterClient(customerName) : this.availableClients.slice())
        );
      
      if (this.mode === 'edit') {
        if (this.clientEditValue) {
          const clientEquivalent = this.allClientsObj.find(c => c.id === this.clientEditValue);
          this.selectedClient = clientEquivalent?.name || '';
        }
      }
    });
  }

  ngAfterViewInit() {
    $('#faqEditorId').summernote({
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'italic', 'clear']],
        ['fontname', ['fontname']],
        ['fontsize', ['fontsize']],
        ['fontsizeunit', ['fontsizeunit']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
      ],
      fontNames: [
        'Arial', 'Calibri', 'Roboto', 'Comic Sans MS', 'Courier New', 'Helvetica',
        'Impact', 'sans-serif', 'Tahoma', 'Times New Roman', 'Verdana'
      ],
      fontSizes: ['8', '9', '10', '11', '12', '14', '15', '16', '18', '24', '36'],
      fontSizeUnits: ['pt'],
      fontNamesIgnoreCheck: ['Arial'],
      dialogsInBody: true,
      disableResizeEditor: true,
      callbacks: {
        onChange(contents, $editable) {
          (window as any).faqEditorHtml = contents;
        },
      },
    });

    $('.note-editable').css('font-size', `${AppConstants.GMAIL_FONT_SIZE}${AppConstants.GMAIL_FONT_UNIT}`);
    $('.note-editable').css('font-family', AppConstants.GMAIL_FONT_FAMILY);

    $('#faqEditorId').summernote('code', '');

    const htmlElementColl: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('note-editable') as HTMLCollectionOf<HTMLElement>;

    const service = this.composerMailService;
    $("#faqEditorId").on("summernote.paste", function(we, e) {
      for (let i = 0; i < e.originalEvent.clipboardData.items.length; i += 1) {
        if ((e.originalEvent.clipboardData.items[i].kind == 'string') &&
            (e.originalEvent.clipboardData.items[i].type.match('^text/html'))) {
          e.preventDefault();
          e.originalEvent.clipboardData.items[i].getAsString(function(s) {
            s =  service.fixPastedHtml(s);
            $("#faqEditorId").summernote("pasteHTML", s);
          });
        }
      }
    });

    let heightMeasure = "30vh";
    if (htmlElementColl[0]) {
      htmlElementColl[0].style.maxHeight = heightMeasure;
      htmlElementColl[0].style.minHeight = heightMeasure;
    }
    if (htmlElementColl[1]) {
      htmlElementColl[1].style.height = heightMeasure;
    }

  }

  setLabels() {
    if (this.mode === 'new') {
      this.pageTitle += 'Create FAQ';
      this.submitFaqLabel = "CREATE FAQ";
    } else {
      this.pageTitle += 'Edit FAQ';
      this.submitFaqLabel = 'SAVE';
    }
  }


  private _filterClient(client: string): string[] {
    const filterValue = client.toLowerCase();

    return this.availableClients.filter(e => e.toLowerCase().includes(filterValue));
  }

  handleOperationError(message: string) {
    this.loadingStateService.setLoadingState(false);
    const errorConfirmDialogConfig = new MatDialogConfig();
    errorConfirmDialogConfig.data = {
      title: 'Error',
      message,
    };
    this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
  }

  displayFn(input: string): string {
    return input ? input : '';
  }

  get invalidForm(): boolean {
    if (!this.title || !this.selectedClient || !this.availableClients.includes(this.selectedClient)) {
      return true;
    }
    return false;
  }

  createFaq() {
    const clientId = this.allClientsObj.filter(c => c.name === this.selectedClient)[0].id;
    const faqText = (window as any).faqEditorHtml;
    this.isSubmitting = true;
    this.faqDataService.createOrUpdateFaq(this.mode, this.faqId, this.title, clientId, faqText, this.userCreated, this.userModified).subscribe(res => {
      if (res.status === 201 || res.status === 204) {
        const operationSuccessLabel = this.mode === 'new' ? ' created ' : ' edited ';
        this.snackBar.open(`Faq ${operationSuccessLabel} successfully`, null, {
          duration: MODAL_DURATION,
        });
        this.router.navigate(['/home/management/faq/faq-manage']);
      } else if(res.status === 409 && res.message) {
        this.snackBar.open(res.message, null, {
          duration: MODAL_DURATION,
        });
        this.isSubmitting = false;
      } else {
        const operationFailureLabel = this.mode === 'new' ? ' creating ' : ' editing ';
        this.snackBar.open(`An error ocurred while ${operationFailureLabel} the faq`, null, {
          duration: MODAL_DURATION,
        });
        this.isSubmitting = false;
      }
    }, err => {
      const operationFailureLabel = this.mode === 'new' ? ' creating ' : ' editing ';
      this.snackBar.open(`An error ocurred while ${operationFailureLabel} the faq`, null, {
        duration: MODAL_DURATION,
      });
      this.isSubmitting = false;
    });

  }

  cancel() {
    this.location.back();
  }
}
