<div class="ltb-classifier-container">
  <div class="title">Classifications</div>
  <div fxLayout="column" *ngIf="statuses.length">
      <div class="category-options-wrapper">
      <button
        *ngFor="let classification of statuses"
        class="classification-btn"
        mat-stroked-button
        [disabled]="!selectedLead || !selectedEmail"
        (click)="onClassifyLTB(classification)"
      >
        <span class="classification-name">
          {{ classification.name | titlecase }}
        </span>
      </button>
    </div>
  </div>
</div>