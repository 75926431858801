import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { SdApiService } from './base/sd-api.service';
import { LoadingStateService } from './base/loading-state.service';
import { IInquiryLead, InquiryLead } from '../model/InquiryLead';
import { InquirySearch } from '../model/InquirySearch';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InquiryService {
  private inquiryLeadsSubject = new BehaviorSubject<IInquiryLead[]>(null);
  private currentInquiryLeads: IInquiryLead[];

  private inquirySearchSubject = new BehaviorSubject<InquirySearch>(null);
  private currentInquirySearch: InquirySearch;

  private isSearchLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private sdApiService: SdApiService,
    private loadingStateService: LoadingStateService,
    private httpClient: HttpClient
  ) {}

  get inquiryLeads() {
    return this.inquiryLeadsSubject.asObservable();
  }

  setInquiryLeads(value: IInquiryLead[]) {
    this.currentInquiryLeads = value;
    this.inquiryLeadsSubject.next(value);
  }

  get inquirySearch() {
    return this.inquirySearchSubject.asObservable();
  }

  setInquirySearch(value: InquirySearch) {
    this.currentInquirySearch = value;
    this.inquirySearchSubject.next(value);
  }

  get isLoading() {
    return this.isSearchLoading.asObservable();
  }

  setSearchLoading(value: boolean) {
    this.isSearchLoading.next(value);
  }

  searchInquiryLead(sdr: string, searchDate: string, email: string, firstName: string, lastName: string) {
    this.isSearchLoading.next(true);
    this.sdApiService.searchInquiryLead(sdr, searchDate, email, firstName, lastName).subscribe((results: any) => {
        this.isSearchLoading.next(false);
        if (results.length) {
          results[0].selected = true;
          results[0].filled = true;
        }
        this.setInquiryLeads(results);
      },
      error => {
        console.log(error);
        this.isSearchLoading.next(false);
      }
    );
  }

  searchInquiryDetails(email: string) {
    this.isSearchLoading.next(true);
    this.sdApiService.searchInquiryLead(this.currentInquirySearch.sdr, this.currentInquirySearch.date, email, null,
       null).subscribe((results: any) => {
        this.isSearchLoading.next(false);
        if (results.length > 0) {
          this.currentInquiryLeads.filter(il => il.selected)[0].selected = false;
          results[0].selected = true;
          results[0].filled = true;
          const searchedInquiry = this.currentInquiryLeads.findIndex(il => il.emailAddress == email);
          this.currentInquiryLeads[searchedInquiry] = results[0];
          this.setInquiryLeads(this.currentInquiryLeads);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getLeadsDataByEmail(email: string, fromSdrId: string): Observable<IInquiryLead> {
    const url = `${environment.apiBaseURL}/app/leads?email_address=${email}&sdr=${fromSdrId}`;

    return this.httpClient.get(url).pipe(map((response: any) => new InquiryLead(response.data)));
  }
}
