<div
  fxLayout="row"
  class="composer-top-bar"
  fxLayoutAlign="space-between center"
>
  <div>Message</div>
  <div fxLayout="row">
    <mat-icon
      mat-icon-button
      class="composer-top-bar__close-fullscreen"
      (click)="onMinimizeDialog()"
      >close_fullscreen</mat-icon
    >
    <mat-icon (click)="onCloseDialog()">close</mat-icon>
  </div>
</div>
