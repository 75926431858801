import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import dayjs from 'dayjs';

@Component({
  selector: 'app-contact-date-dialog',
  templateUrl: './contact-date-dialog.component.html',
  styleUrls: ['./contact-date-dialog.component.scss', '../../discrepancies.component.scss']
})
export class ContactDateDialogComponent {
  minDate = dayjs().subtract(1, 'day').format();
  constructor(
    public dialogRef: MatDialogRef<ContactDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      contactDate: FormControl
    }
  ) { }

  get isAcceptEnabled(): boolean {
    return this.data.contactDate.valid;
  }
}
