import { Component, ViewChild, ElementRef, AfterViewInit, SecurityContext, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Email } from '../../../model/Email';
import { SelectedDiscrepancyService } from 'src/app/services/selected-discrepancy.service';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';

@Component({
  selector: 'app-discrepancy-details-body',
  templateUrl: './discrepancy-details-body.component.html',
  styleUrls: ['./discrepancy-details-body.component.scss'],
})
export class DiscrepancyDetailsBodyComponent implements OnInit, AfterViewInit {
  public htmlData: any;
  currentEmail: Email;
  @ViewChild('iframe') iframe: ElementRef;
  doc: any;
  compRef: any;
  attachmentDynamicPadding = 0;
  screenHeight: number;
  screenWidth: number;
  loading = false;

  constructor(
    private sanitizer: DomSanitizer,
    private selectedDiscrepancyService: SelectedDiscrepancyService,
    private loadingStateService: LoadingStateService,
  ) {}

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  ngAfterViewInit() {
    this.selectedDiscrepancyService.selectedEmail.subscribe((email) => {
      this.currentEmail = email;
      this.htmlData = null;
      if (this.currentEmail && this.currentEmail.bodyHtml) {
        this.removeDocType();
        this.htmlData = this.sanitizer.sanitize(
          SecurityContext.HTML,
          this.sanitizer.bypassSecurityTrustHtml(this.currentEmail.bodyHtml),
        );
        this.setIframeReady(this.iframe, this.htmlData);
      } else {
        this.iframe.nativeElement.style.setProperty('display', 'none');
      }
      // Scroll to the top once the selected email changes
      const element: HTMLIFrameElement = document.getElementById('emailIframe') as HTMLIFrameElement;
      const iframe = element.contentWindow;
      iframe.document.querySelectorAll('*').forEach((n) => n.scrollTo(0, 0));
    });
  }

  removeDocType() {
    this.currentEmail.bodyHtml = this.currentEmail.bodyHtml.replace('"-//W3C//DTD HTML 4.01//EN"', '');
  }

  private setIframeReady(iframe: ElementRef, html: string): void {
    const win: Window = iframe.nativeElement.contentWindow;

    const doc: Document = win.document;
    doc.open();
    doc.write(html);
    doc.close();
    this.iframe.nativeElement.style.setProperty('display', 'inherit');
    // this.iframe.nativeElement.style.setProperty('width',this.iframe.nativeElement.contentWindow.document.body.scrollWidth + 'px');
    this.iframe.nativeElement.style.setProperty(
      'height',
      this.iframe.nativeElement.contentWindow.document.body.scrollHeight + 'px',
    );
    this.iframe.nativeElement.style.setProperty('width', '95%');
    // this.iframe.nativeElement.style.setProperty('height','calc(100vh - 148px)');

    const htmlElement: HTMLIFrameElement = document.getElementById('emailIframe') as HTMLIFrameElement;
    const htmlIframe = htmlElement.contentWindow;
    htmlIframe.document.querySelectorAll('*').forEach((n) => {
      if (n.tagName == 'A') {
        n.setAttribute('target', '_blank');
      }
    });
  }

  formatPlain(str) {
    str = this.formatPlainDouble(str);
    str = this.formatPlainSingle(str);
    return str;
  }

  formatPlainDouble(str) {
    let parsedStr = str.split('\\r\\n').join('<br>');
    parsedStr = parsedStr.split('\\r').join('<br>');
    parsedStr = parsedStr.split('\\n').join('<br>');
    parsedStr = parsedStr.trim();
    if (parsedStr.substr(0, 2).toLowerCase() == "b'" || parsedStr.substr(0, 2).toLowerCase() == 'b"') {
      parsedStr = parsedStr.substr(2);
    }
    if (parsedStr.substr(parsedStr.length - 1) == "'" || parsedStr.substr(parsedStr.length - 1) == '"') {
      parsedStr = parsedStr.substr(0, parsedStr.length - 1);
    }
    return parsedStr;
  }

  formatPlainSingle(str) {
    let parsedStr = str.split('\r\n').join('<br>');
    parsedStr = parsedStr.split('\r').join('<br>');
    parsedStr = parsedStr.split('\n').join('<br>');
    parsedStr = parsedStr.trim();
    if (parsedStr.substr(0, 2).toLowerCase() == "b'" || parsedStr.substr(0, 2).toLowerCase() == 'b"') {
      parsedStr = parsedStr.substr(2);
    }
    if (parsedStr.substr(parsedStr.length - 1) == "'" || parsedStr.substr(parsedStr.length - 1) == '"') {
      parsedStr = parsedStr.substr(0, parsedStr.length - 1);
    }
    return parsedStr;
  }
}
