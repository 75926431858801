<div fxFill class="open-inquiry-history" fxLayout="row" fxLayoutAlign="start">
    <div class="mat-elevation-z0 shrink" fxFlex="100">
        <mat-table class="lead-history-table"  [dataSource]="listData"  matSort matSortActive="leadToCampaignId" matSortDirection="desc" matSortDisableClear #historySort="matSort">
          <ng-container matColumnDef="leadToCampaignId">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Lead to campaign id</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.leadToCampaignId}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="campaignId">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Campaign Id</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.campaignId}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="campaignBulkDate">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Campaign Bulk Date</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.campaignBulkDate | date:'dd/MM/yyyy' }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="customerName">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Customer Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.customerName}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="userId">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>SDR Id</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.userId}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="userEmail">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>SDR Email</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.userEmail}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="emailStatus">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Email Status</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.emailStatus}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="dateStatus">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Date Status</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.dateStatus | date:'dd/MM/yyyy' }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="loading">
            <mat-footer-cell *matFooterCellDef colspan="9">
              Loading data...
            </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef colspan="9">
              No data.
            </mat-footer-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></mat-header-row>
          <mat-row
            (click)="onRowClicked(row)"
            [class.row-is-clicked]="selectedRow.has(row)"
            *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
          <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':!loading}"></mat-footer-row>
          <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide': loading || !(listData !== null && listData.data.length === 0)}"></mat-footer-row>
        </mat-table>
        <mat-paginator [pageSize]="4" showFirstLastButtons #historyPaginator="matPaginator"></mat-paginator>
    </div>
</div>

