<div class="table-actions">
  <div
    *ngIf="showSelectAll"
    [ngClass]="!showSelectAllAction ? 'action flat-border' : 'action'"
  >
    <div [ngClass]="!showSelectAllAction ? 'action-select-all' : 'action-select-all flat-border'">
      <mat-checkbox
        color="primary"
        [checked]="allItensSelected"
        (change)="handleSelectAllEvent($event.checked)"
        id="table-actions-select-all-mat-checkbox"
        >Select All</mat-checkbox
      >
    </div>
    <div
      class="action-button"
      *ngIf="showSelectAllAction"
    >
      <button
        mat-flat-button
        (click)="handleSelectAllActionEvent()"
        [disabled]="selectAllActionDisabled"
        id="table-actions-select-action-btn"
      >
        <mat-icon *ngIf="selectAllActionIcon">{{ selectAllActionIcon }}</mat-icon>
        <span>{{ selectAllActionLabel }}</span>
      </button>
    </div>
  </div>

  <div class="filter">
    <mat-form-field
      *ngIf="showFilterKeyDropdown"
      fxFlex="45"
      class="form-field-5"
      appearance="outline"
      subscriptSizing="dynamic"
      id="table-actions-filter-column-mat-form-field"
    >
      <mat-label>Filter Column</mat-label>
      <mat-select
        (selectionChange)="handleFilterKeyChangedEvent($event.value)"
        id="table-actions-filter-column-mat-select"
      >
        <mat-option
          *ngFor="let filter of filters"
          [value]="filter.key"
          id="table-actions-filter-column-mat-option"
        >
          {{ filter.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      *ngIf="showFilter"
      [fxFlex]="showFilterKeyDropdown ? '45' : '100'"
      appearance="outline"
      class="form-field-5"
      [subscriptSizing]="'dynamic'"
      id="table-actions-filter-mat-form-field"
    >
      <mat-label>Filter</mat-label>
      <input
        matInput
        (keyup)="handleFilterUserInputEvent(filter.value)"
        #filter
        id="table-actions-filter-input"
      />
    </mat-form-field>
  </div>
</div>
