<div class="layoutDiv">
  <div class="container">
    <div
      class="titleDiv"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon>arrow_back_ios</mat-icon>
      <span>Client Management</span>
    </div>
    <mat-divider></mat-divider>
    <div
      class="actions"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <span fxFlex="15">Select a client</span>

      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        class="form-field-5 actions__client-field"
        id="client-management-client-mat-form-field"
      >
        <mat-label>Client</mat-label>
        <mat-select
          (selectionChange)="getOperatorsList($event.value)"
          id="client-management-client-mat-select"
        >
          <mat-option
            *ngFor="let customer of customers$ | async"
            [value]="customer"
            id="client-management-client-mat-option"
          >
            {{ customer.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="action-buttons">
        <button
          *ngIf="(currentTab$ | async) === OperatorManagementTables.SDR && false"
          class="operator-button"
          mat-raised-button
          (click)="openAssignOperatorModal()"
          [disabled]="!selectedCustomer"
          id="client-management-assign-operator-btn"
        >
          <mat-icon>group_add</mat-icon>
          <span>{{ 'Assign Operator to SDR' }}</span>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <mat-tab-group (selectedTabChange)="handleTabChange($event.index)">
      <mat-tab label="Operators">
        <div class="table-padding">
          <app-table-actions
            [showSelectAll]="false"
            [showFilterKeyDropdown]="true"
            [filters]="operatorsFilters$ | async"
            (filterKeyChangedEvent)="handleOperatorFilterKeyChangedEvent($event)"
            (filterUserInputEvent)="handleFilterUserInputEvent($event)"
            #operatorTableActions
          ></app-table-actions>

          <div class="mat-elevation-z8 shrink">
            <mat-table
              [dataSource]="operatorDataSource"
              matSort
              #operatorSort="matSort"
            >
              <ng-container matColumnDef="name">
                <mat-header-cell
                  fxLayoutAlign="start center"
                  fxFlex="25"
                  *matHeaderCellDef
                  mat-sort-header
                  >Operator Name</mat-header-cell
                >
                <mat-cell
                  fxLayoutAlign="start center"
                  fxFlex="25"
                  *matCellDef="let element"
                  >{{ element.name }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="email">
                <mat-header-cell
                  fxLayoutAlign="start center"
                  fxFlex="30"
                  *matHeaderCellDef
                  mat-sort-header
                  >User Email</mat-header-cell
                >
                <mat-cell
                  fxLayoutAlign="start center"
                  fxFlex="30"
                  *matCellDef="let element"
                  >{{ element.email }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="status">
                <mat-header-cell
                  fxLayoutAlign="start center"
                  fxFlex="20"
                  *matHeaderCellDef
                  mat-sort-header
                  >Operator Status</mat-header-cell
                >
                <mat-cell
                  fxLayoutAlign="start center"
                  fxFlex="20"
                  *matCellDef="let element"
                >
                  <span class="rounded-background">
                    {{ element.status }}
                  </span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="languages">
                <mat-header-cell
                  fxLayoutAlign="center center"
                  fxFlex="15"
                  *matHeaderCellDef
                  mat-sort-header
                  >Language</mat-header-cell
                >
                <mat-cell
                  fxLayoutAlign="center center"
                  fxFlex="15"
                  *matCellDef="let element"
                >
                  <app-flag-viewer [languages]="formatLanguages(element.languages)"></app-flag-viewer>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell
                  fxLayoutAlign="center center"
                  fxFlex="10"
                  *matHeaderCellDef
                  >Actions</mat-header-cell
                >
                <mat-cell
                  fxLayoutAlign="center center"
                  fxFlex="10"
                  *matCellDef="let element"
                  class="center"
                >
                  <button
                    fxLayoutAlign="center center"
                    class="action-btn"
                    mat-icon-button
                    (click)="handleOperatorEditClick(element)"
                    id="client-management-edit-operator-btn"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="noData">
                <tr
                  class="mat-mdc-row no-data-row"
                  *matNoDataRow
                >
                  <td
                    class="mat-cell no-data"
                    colspan="2"
                    fxFlex="100"
                  >
                    {{ operatorDataSource.data || operatorHasError ? 'No data.' : 'Select a client.' }}
                  </td>
                </tr>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedOperatorColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedOperatorColumns"></mat-row>
            </mat-table>
            <mat-paginator
              [pageSizeOptions]="[10, 50, 100]"
              showFirstLastButtons
              #operatorPaginator
            ></mat-paginator>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="SDRS">
        <div class="table-padding">
          <app-table-actions
            [showSelectAll]="false"
            (filterUserInputEvent)="handleFilterUserInputEvent($event)"
            #sdrTableActions
          ></app-table-actions>

          <div class="mat-elevation-z8 shrink">
            <mat-table
              [dataSource]="sdrDataSource"
              matSort
              #sdrSort="matSort"
            >
              <ng-container matColumnDef="user_name">
                <mat-header-cell
                  fxLayoutAlign="start center"
                  fxFlex="90"
                  *matHeaderCellDef
                  mat-sort-header
                  >SDR Name</mat-header-cell
                >
                <mat-cell
                  fxLayoutAlign="start center"
                  fxFlex="90"
                  *matCellDef="let element"
                  >{{ element.user_name }}</mat-cell
                >
              </ng-container>
              <!-- <ng-container matColumnDef="operators">
                <mat-header-cell fxLayoutAlign="start center" fxFlex="45" *matHeaderCellDef>Operators Associated</mat-header-cell>
                <mat-cell fxLayout="column"  fxLayoutAlign="space-evenly start" fxFlex="45" *matCellDef="let element">
                  <div *ngFor="let operator of element.operators" class="rounded-background">
                    <span
                      [matMenuTriggerFor]="sdrDetails" 
                      #sdrDetailsTrigger="matMenuTrigger"
                      (mouseenter)="sdrDetailsTrigger.openMenu()"
                      (mouseleave)="sdrDetailsTrigger.closeMenu()"
                    >
                      {{ operator.name }} - SDR Level: {{ operator.sdr_level }}
                    </span>
                    <mat-menu 
                      #sdrDetails="matMenu"
                      yPosition="above"
                      [hasBackdrop]="false"
                      class="sdr-details"
                    >
                      <div fxLayout="row" fxLayoutGap="10px" fxFlex="100">
                        <div fxFlexAlign="center" fxFlex="10">
                          <mat-icon class="material-symbols-outlined" style="color: #ffffff;">account_circle</mat-icon>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="space-around" fxFlex="90">
                          <span>{{operator.name}}</span>
                          <span>{{operator.email}}</span>
                        </div>
                      </div>       
                      <div fxLayout="row" fxFlex="100">
                        <div fxLayout="column" fxLayoutAlign="space-around" fxFlex="85" style="margin-left: auto;">
                          <span>Folders: {{ operator.classification_folders }}</span>
                          <span>Discrepancies: {{ operator.discrepancy_types }}</span>
                        </div>
                      </div>
                    </mat-menu>
                  </div>
                </mat-cell>
              </ng-container> -->
              <ng-container matColumnDef="actions">
                <mat-header-cell
                  fxLayoutAlign="center center"
                  fxFlex="10"
                  *matHeaderCellDef
                  >Actions</mat-header-cell
                >
                <mat-cell
                  fxLayoutAlign="center center"
                  fxFlex="10"
                  *matCellDef="let element"
                  class="center"
                >
                  <button
                    fxLayoutAlign="center center"
                    class="action-btn"
                    mat-icon-button
                    (click)="handleSDREditClick(element)"
                    id="client-management-edit-sdr-btn"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="noData">
                <tr
                  class="mat-mdc-row no-data-row"
                  *matNoDataRow
                >
                  <td
                    class="mat-cell no-data"
                    colspan="2"
                    fxFlex="100"
                  >
                    {{ sdrDataSource.data || sdrHasError ? 'No data.' : 'Select a client.' }}
                  </td>
                </tr>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedSdrColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedSdrColumns"></mat-row>
            </mat-table>
            <mat-paginator
              [pageSizeOptions]="[10, 50, 100]"
              showFirstLastButtons
              #sdrPaginator
            ></mat-paginator>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
