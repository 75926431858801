<div class="modal-header">
  <h2>Unset Operator</h2>
</div>
<div class="modal-content">
  <div class="unset-container">
    <div class="unset-content">
      <h4>Are you sure you want to unset this operator from this client?</h4>
      <span>The level permissions of this operator will be revoked for this client. The operator will not be able to select this client or make classifications for this client.</span>
      <div class="operator-info">
        <div class="operator-client">
          <div class="operator-info-header">
            <span>CLIENT</span>
          </div>
          <div>
            <span><strong>{{data.client.name}}</strong></span>
          </div>
        </div>
        <div class="operator-user">
          <div class="operator-info-header">
            <span>USER SELECTED</span>
          </div>
          <div class="user-data">
            <span ><strong>{{data.operator.name}}</strong></span>
            <span>{{data.operator.email}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-actions">
    <button mat-button class="dismiss-button" (click)="onDismiss();">Cancel</button>
    <button class="confirm-button" mat-button (click)="onConfirm();" cdkFocusInitial>Confirm</button>
</div>