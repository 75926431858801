import { FormGroup } from '@angular/forms';

export interface ILeadToBulk {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  status: IStatus;
  date_status: Date;
  date_received: Date;
  contact_date: Date;
  comments: string;
  client_contact: IClientContact;
  id_lead_forward: number;
  source: ISource;
  matrix: IMatrix;
  country: ICountry;
  delay: IDelay;
  industry: IIndustry;
  job_linkedin_id: number;
  message_id_received: string;
  lead_to_campaign_id: number;
  campaign_bulk_date: Date;
  country_lead: string;
  industry_lead: string;
  score: number;
  id_lead: number;
  campaign_bulk_id: number;
  template_id: number;
  campaign_id: number;
}

interface IClientContact {
  id: number;
  name: string;
}

interface IStatus {
  id: number;
  name: string;
}

interface IMatrix {
  id: number;
  name: string;
  value: number;
}

interface ICountry {
  id: number;
  name: string;
  value: number;
}

interface IDelay {
  id: number;
  name: string;
  value: number;
}

interface IIndustry {
  id: number;
  name: string;
  value: number;
}

export interface ISource {
  id: number;
  name: string;
  factor: number;
}

export interface ISelectsData {
  matrix: IMatrix[];
  country: ICountry[];
  delay: IDelay[];
  industry: IIndustry[];
  qualifications: ISource[];
}

export interface IEmailStatus {
  id: number;
  name: string;
}

interface IPattern {
  id: number;
  name: string;
}

interface ISelectItem {
  name: string;
}

export interface INewLeadSelectsData {
  countries: ISelectItem[];
  states: ISelectItem[];
  sizes: ISelectItem[];
  patterns: ISelectItem[];
  industries: ISelectItem[];
}

export interface INewLeadDialogData {
  leadForm?: FormGroup;
  selectsData: INewLeadSelectsData;
}

export interface ILead {
  id_lead: number;
  email_address: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  kw_industry: string;
  kw_size: string;
  country: string;
  state: string;
  city: string;
  title: string;
  job_responsibility: string;
  job_department: string;
  seniority: string;
  department: string;
  linked_in_url_gen: string;
  phone: string;
  phone_extension: string;
  phone_mobile: string;
  phone_office: string;
  orig_company_full_name: string;
  count_leads_same_domain: 0;
  company: ILeadCompany;
  email_status: IStatus;
  linked_in_urls: ILeadLinkedInData;
  pattern_id: number;
  dominio: string;
  comments?: string;
}

interface ILeadCompany {
  id_company: number;
  source: string;
  company_name: string;
  domain: string;
  size: string;
  city: string;
  linkedin_company_id: string;
  industry: string;
  employee_count: number;
}

interface ILeadLinkedInData {
  linked_in_url_gen: string;
  linked_in_company_url: string;
}

export interface IInteraction {
  interactionId: number;
  campaignId: number;
  sdrId: number;
  sdrEmailAddress: string;
  utcDateTimeCreated: string;
  utcDateTimeInteraction: string;
  prospectStatus: string;
  prospectStatusId: number;
  messageId: string;
  prospectRating: number;
  templateId: number;
  customerId: number;
  customerName: string;
  toContact: string;
  salesRepId: number;
  idLeadForward?: number;
}

export interface IInteractionContact {
  contactId: number;
  emailAddress: string;
  contactStatus: string;
  contactType: string;
  linkedInUrl: string;
  personId: number | null;
  fullName: string;
  country: string;
  state: string;
  companyId: number;
  companyName: string;
  title?: string;
  companyEmployeeCount?: number;
  companySize?: number;
  companySource?: string;
  city?: string;
  phone?: string;
}

export interface ILeadInteractionsData {
  contact: IInteractionContact;
  interactions: IInteraction[];
}

export class DiscrepancyLeadToBulk implements ILeadToBulk {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  status: IStatus;
  date_status: Date;
  date_received: Date;
  contact_date: Date;
  comments: string;
  client_contact: IClientContact;
  id_lead_forward: number;
  source: ISource;
  matrix: IMatrix;
  country: ICountry;
  delay: IDelay;
  industry: IIndustry;
  message_id_received: string;
  job_linkedin_id: number;
  lead_to_campaign_id: number;
  campaign_bulk_date: Date;
  country_lead: string;
  industry_lead: string;
  score: number;
  id_lead: number;
  campaign_bulk_id: number;
  campaign_id: number;
  template_id: number;
  company: ILeadCompany;

  constructor(lead) {
    this.id = lead.lead_to_campaign_id || lead.id_lead;
    this.first_name = lead.first_name;
    this.last_name = lead.last_name;
    this.email = lead.email_address;
    this.status = lead.status;
    this.date_status = lead.date_status;
    this.contact_date = lead.contact_date ? new Date(lead.contact_date) : null;
    this.comments = lead.comments;
    this.client_contact = lead.client_contact;
    this.id_lead_forward = lead.id_lead_forward;
    this.source = lead.source;
    this.job_linkedin_id = lead.job_linkedin_id;
    this.lead_to_campaign_id = lead.lead_to_campaign_id;
    this.message_id_received = lead.message_id_received;
    this.matrix = lead.matrix;
    this.delay = lead.delay;
    this.country = lead.country;
    this.industry = lead.industry;
    this.campaign_bulk_date = this.formatCampaignBulkDate(lead.campaign_bulk_date);
    this.country_lead = lead.country_lead;
    this.industry_lead = lead.industry_lead;
    this.score = lead.score;
    this.date_received = lead.date_received ? new Date(lead.date_received) : null;
    this.id_lead = lead.id_lead;
    this.campaign_bulk_id = lead.campaign_bulk_id;
    this.campaign_id = lead.campaign_id;
    this.template_id = lead.template_id;
    this.company = lead.company;
  }

  formatCampaignBulkDate(ogDate: string): Date {
    if (!ogDate) return;
    const splitDate = ogDate.split('-');
    return new Date(`${splitDate[1]}-${splitDate[2]}-${splitDate[0]}`);
  }
}

export class DiscrepancyLead implements ILead {
  id_lead: number;
  email_address: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  kw_industry: string;
  kw_size: string;
  country: string;
  state: string;
  city: string;
  title: string;
  job_responsibility: string;
  job_department: string;
  seniority: string;
  department: string;
  linked_in_url_gen: string;
  phone: string;
  phone_extension: string;
  phone_mobile: string;
  phone_office: string;
  orig_company_full_name: string;
  count_leads_same_domain: 0;
  company: ILeadCompany;
  email_status: IStatus;
  linked_in_urls: ILeadLinkedInData;
  pattern_id: number;
  dominio: string;
  comments?: string;

  constructor(lead) {
    this.id_lead = lead.id_lead;
    this.email_address = lead.email_address;
    this.first_name = lead.first_name;
    this.middle_name = lead.middle_name;
    this.last_name = lead.last_name;
    this.kw_industry = lead.kw_industry;
    this.kw_size = lead.kw_size;
    this.country = lead.country;
    this.state = lead.state;
    this.city = lead.city;
    this.title = lead.title;
    this.seniority = lead.seniority;
    this.department = lead.department;
    this.linked_in_url_gen = lead.linked_in_url_gen;
    this.phone = lead.phone;
    this.phone_extension = lead.phone_extension;
    this.phone_mobile = lead.phone_mobile;
    this.phone_office = lead.phone_office;
    this.orig_company_full_name = lead.orig_company_full_name;
    this.count_leads_same_domain = lead.count_leads_same_domain;
    this.company = lead.company;
    this.email_status = lead.email_status;
    this.linked_in_urls = lead.linked_in_urls;
    this.pattern_id = lead.pattern_id || null;
    this.dominio = lead.dominio || null;
    this.comments = lead.comments;
    this.job_responsibility = lead.job_responsibility;
    this.job_department = lead.job_department;
  }
}

export class Contact implements IInteractionContact {
  contactId: number;
  emailAddress: string;
  contactStatus: string;
  contactType: string;
  linkedInUrl: string;
  personId: number | null;
  fullName: string;
  country: string;
  state: string;
  companyId: number;
  companyName: string;
  title?: string;
  companyEmployeeCount?: number;
  companySize?: number;
  companySource?: string;
  city?: string;
  phone?: string;

  constructor(contact) {
    this.contactId = contact.contactId;
    this.emailAddress = contact.emailAddress;
    this.contactStatus = contact.contactStatus;
    this.contactType = contact.contactType;
    this.linkedInUrl = contact.linkedInUrl;
    this.personId = contact?.personId;
    this.fullName = contact.fullName;
    this.country = contact.country;
    this.state = contact.state;
    this.companyId = contact.companyId;
    this.companyName = contact.companyName;
    this.title = contact.title || null;
    this.companyEmployeeCount = contact.companyEmployeeCount || null;
    this.companySize = contact.companySize || null;
    this.companySource = contact.companySource || null;
    this.city = contact.city || null;
    this.phone = contact.phone || null;
  }
}

export class Interaction implements IInteraction {
  interactionId: number;
  campaignId: number;
  sdrId: number;
  sdrEmailAddress: string;
  utcDateTimeCreated: string;
  utcDateTimeInteraction: string;
  prospectStatus: string;
  prospectStatusId: number;
  messageId: string;
  prospectRating: number;
  templateId: number;
  customerId: number;
  customerName: string;
  toContact: string;
  salesRepId: number;
  idLeadForward?: number;

  constructor(interaction) {
    this.interactionId = interaction.interactionId;
    this.campaignId = interaction.campaignId;
    this.sdrId = interaction.sdrId;
    this.sdrEmailAddress = interaction.sdrEmailAddress;
    this.utcDateTimeCreated = interaction.utcDateTimeCreated;
    this.utcDateTimeInteraction = interaction.utcDateTimeInteraction;
    this.prospectStatus = interaction.prospectStatus;
    this.prospectStatusId = interaction.prospectStatusId;
    this.messageId = interaction.messageId;
    this.prospectRating = interaction.prospectRating;
    this.templateId = interaction.templateId;
    this.customerId = interaction.customerId;
    this.customerName = interaction.customerName;
    this.toContact = interaction.toContact;
    this.salesRepId = interaction.salesRepId;
    this.idLeadForward = interaction.idLeadForward || null;
  }
}

export class ContactInteraction implements ILeadInteractionsData {
  contact: IInteractionContact;
  interactions: IInteraction[];

  constructor(data) {
    this.contact = new Contact(data.contact);
    this.interactions = data?.interactions?.map((interaction) => new Interaction(interaction)) || [];
  }
}
