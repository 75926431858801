import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-blocked-sdrs-dialog',
  templateUrl: './blocked-sdrs-dialog.component.html',
  styleUrls: ['./blocked-sdrs-dialog.component.scss']
})
export class BlockedSdrsDialogComponent implements OnInit {

  blockedSdrs: string[] = [];


  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.blockedSdrs = data;
  }

  ngOnInit() {
  }

}
