<div
  id="layoutDiv"
  class="bot-setup-container"
>
  <div
    id="container"
    [ngClass.lt-md]="'mobile'"
  >
    <div id="titleDiv"><span class="pageTitle">Bot Setup</span></div>
    <div class="info-text">
      <span>Download Report</span>
    </div>
    <div
      class="date-picker-container"
      fxLayout.gt-md="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-between center"
    >
      <mat-form-field
        class="date-form-field"
        fxFill
        appearance="outline"
        id="bot-setup-date-from-mat-form-field"
      >
        <mat-label>Date From</mat-label>
        <input
          matInput
          [matDatepicker]="pickerFrom"
          [(ngModel)]="fromDate"
          #fromControl="ngModel"
          id="bot-setup-date-from-input"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerFrom"
          id="bot-setup-date-from-mat-datepicker-toggle"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>

      <mat-form-field
        class="date-form-field"
        fxFill
        appearance="outline"
        id="bot-setup-date-to-mat-form-field"
      >
        <mat-label>Date To</mat-label>
        <input
          matInput
          [matDatepicker]="pickerTo"
          [(ngModel)]="toDate"
          #toControl="ngModel"
          id="bot-setup-date-to-input"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerTo"
          id="bot-setup-date-to-mat-datepicker-toggle"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="button-container"
    >
      <button
        mat-raised-button
        color="primary"
        (click)="downloadSimultionReport()"
        [disabled]="!fromDate || !toDate || fromControl.invalid || toControl.invalid || fromDate > toDate"
        id="bot-setup-simulation-btn"
      >
        SIMULATION
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="downloadClassificationReport()"
        [disabled]="!fromDate || !toDate || fromControl.invalid || toControl.invalid || fromDate > toDate"
        id="bot-setup-classification-btn"
      >
        CLASSIFICATION
      </button>
    </div>
  </div>
</div>
