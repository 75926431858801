<div
  class="faq-editor-container"
  id="layoutDiv"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <div id="container">
    <div
      id="titleDiv"
      fxLayoutAlign="space-between center"
    >
      <span
        class="pageTitle"
        [ngClass.lt-md]="'mobile'"
        >{{ pageTitle }}</span
      >
      <button
        mat-raised-button
        class="cancel-btn"
        (click)="cancel()"
        id="faq-manager-editor-cancel-btn"
      >
        Cancel
      </button>
    </div>

    <div
      fxLayout="row wrap"
      class="create-faq-row-form"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        fxFlex.gt-md="50"
        fxFlex.lt-md="100"
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
        id="faq-manager-editor-client-mat-form-field"
      >
        <mat-label>Client</mat-label>
        <input
          type="text"
          aria-label="Number"
          matInput
          required
          [formControl]="clientFilterControl"
          [matAutocomplete]="clientsAuto"
          [(ngModel)]="selectedClient"
          id="faq-manager-editor-client-input"
        />
        <mat-autocomplete
          #clientsAuto="matAutocomplete"
          [displayWith]="displayFn"
          id="faq-manager-editor-client-mat-autocomplete"
        >
          <mat-option
            *ngFor="let client of filteredClientOptions | async"
            [value]="client"
            id="faq-manager-editor-client-mat-option"
          >
            {{ client }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div
      fxLayout="row wrap"
      class="create-faq-row-form"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        fxFlex="100"
        class="form-field-5"
        appearance="outline"
        subscriptSizing="dynamic"
        id="faq-manager-editor-title-mat-form-field"
      >
        <mat-label>Title</mat-label>
        <input
          matInput
          [(ngModel)]="title"
          required
          maxlength="50"
          id="faq-manager-editor-title-input"
        />
      </mat-form-field>
    </div>

    <div
      fxLayout="row wrap"
      class="create-faq-row-form"
      fxLayoutAlign="start center"
    >
      <div fxFlex="100">
        <div>
          <div id="faqEditorId"></div>
        </div>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      class="create-faq-row-form btnCreateFaq-row"
    >
      <button
        (click)="createFaq()"
        class="btnCreateFaq"
        mat-raised-button
        color="primary"
        [disabled]="invalidForm || isSubmitting"
        id="faq-manager-editor-create-faq-btn"
      >
        {{ submitFaqLabel }}
      </button>
    </div>
  </div>
</div>
