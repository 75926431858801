import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IInteractionContact, INewLeadDialogData, INewLeadSelectsData } from 'src/app/model/DiscrepancyLeadToBulk';
import { NewLeadPreviewDialogComponent } from '../new-lead-preview-dialog/new-lead-preview-dialog.component'
import { switchMap } from 'rxjs/operators';
import { DiscrepanciesDataService } from 'src/app/services/discrepancies-data.service';
import { combineLatest, of } from 'rxjs';

@Component({
  selector: 'app-new-lead-dialog',
  templateUrl: './new-lead-dialog.component.html',
  styleUrls: ['./new-lead-dialog.component.scss', '../discrepancies.component.scss']
})
export class NewLeadDialogComponent implements OnInit {
  selectedLead: IInteractionContact;
  newLeadForm: FormGroup;
  selectsData: INewLeadSelectsData = {
    countries: [],
    states: [],
    sizes: [],
    industries: [],
    patterns: []
  };

  constructor(
    private discrepanciesService: DiscrepanciesDataService,
    public dialogRef: MatDialogRef<NewLeadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INewLeadDialogData,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if(this.data?.leadForm) {
      this.newLeadForm = this.data.leadForm;
    } else {
      this.newLeadForm = new FormGroup({
        full_name: new FormControl('', [Validators.required]),
        email_address: new FormControl('', [Validators.required, Validators.email]),
        linked_in_url_gen: new FormControl('', [Validators.required]),
        country: new FormControl('', [Validators.required]),
        state: new FormControl(''),
        city: new FormControl(''),
        title: new FormControl(''),
        phone: new FormControl(''),
        email_address_source: new FormControl(''),
        id_company: new FormControl(null, [Validators.pattern(/^\d+$/)]),
      })

      this.newLeadForm.get('country').addValidators((control) => {
        if (control.value && ['United States', 'Canada'].includes(control.value )) {
          this.discrepanciesService.getStatesByCountry(control.value.toLowerCase()).subscribe(response => {
            this.selectsData.states = response.data;
          }) 
          this.newLeadForm.get('state').enable();
        } else {
          this.newLeadForm.get('state').setValue('');
          this.newLeadForm.get('state').disable();
        }
        return null;
      })
    }
    this.discrepanciesService.selectedSearchResult.pipe(
      switchMap((lead) => {
        if (lead) {
          return 
        } else {
          return of([null, null]);
        }
      })
    )
    
    combineLatest([
      this.discrepanciesService.selectedSearchResult,
      this.discrepanciesService.getNewLeadSelectsData()
    ]).subscribe(([leadResponse, selectsDataResponse]) => {
      this.selectedLead = leadResponse.contact;
      this.selectsData = selectsDataResponse;
      this.newLeadForm.get('id_company').setValue(leadResponse?.contact.companyId);
    });
  }

  get isStateDisabled(): boolean {
    return this.newLeadForm.get('country').value !== 'United States';
  }

  previewData() {
    if(!this.newLeadForm.valid) return;
    this.dialog.open(NewLeadPreviewDialogComponent, {
      width:'800px',
      panelClass: 'discrepancies-dialog',
      data: {
        leadForm: this.newLeadForm,
        selectsData: this.selectsData,
      }
    });

    this.dialogRef.close();
  }

  handleCopy(field: { property: string, value: any}) {
    if(!field || !field.property || !field.value) return;
    this.newLeadForm.get(field.property).setValue(field.value);
  }

  copyPartial() {
    if (!this.selectedLead) return;
    const form = this.newLeadForm;
    const lead = this.selectedLead;
    form.get('state').setValue(lead.state);
    // form.get('city').setValue(lead.state);
    // form.get('email_address_source').setValue(lead.company.source);
    form.get('country').setValue(lead.country);
  }

  copyAll() {
    if (!this.selectedLead) return;
    const form = this.newLeadForm;
    const lead = this.selectedLead;
    form.get('first_name').setValue(lead.fullName);
    form.get('email_address').setValue(lead.emailAddress);
    form.get('linked_in_url_gen').setValue(lead.linkedInUrl);
    form.get('country').setValue(lead.country);
    form.get('state').setValue(lead.state);
    // form.get('city').setValue(lead.state);
    // form.get('title').setValue(lead.title);
    // form.get('phone').setValue(lead.phone);
    // form.get('email_address_source').setValue(lead.company.source);
  }
}
