<div class="layoutDiv">
  <div class="container" fxLayout="column" fxFlex="100">
    <div class="titleDiv" fxFlex="100" fxLayout="column" fxLayoutAlign="space-around start" >
      <h4>Suggestions to reassign</h4>
      <span>Easy way to reassign the SDR. In one single step, check the suggestions and approve the relocation</span>
    </div>

    <div class="filters" fxLayout="row"  fxFlex="100" fxLayoutAlign="space-between center" >
      
      <div fxFlex="15">
        <mat-form-field  fxFlex="100"  floatLabel="always">
          <mat-label>Operator</mat-label>
          <mat-select placeholder="Search the name">
            <mat-option *ngFor="let option of ['none']" [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="11">    
        <mat-form-field fxFlex="100"  floatLabel="always">
          <mat-label>System permission</mat-label>
          <mat-select>
            <mat-option *ngFor="let option of ['none']" [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="11">
        <mat-form-field fxFlex="100" floatLabel="always">
          <mat-label>Amount of clients</mat-label>
          <mat-select>
            <mat-option *ngFor="let option of ['none']" [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="11">
        <mat-form-field fxFlex="100"  floatLabel="always">
          <mat-label>Amount of SDR's</mat-label>
          <mat-select>
            <mat-option *ngFor="let option of ['none']" [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="11">
        <mat-form-field fxFlex="100"  floatLabel="always">
          <mat-label>Level of folders</mat-label>
          <mat-select>
            <mat-option *ngFor="let option of ['none']" [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="11">
        <mat-form-field fxFlex="100"  floatLabel="always">
          <mat-label>Productivity by email</mat-label>
          <mat-select>
            <mat-option *ngFor="let option of ['none']" [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="11">
        <mat-form-field fxFlex="100"  floatLabel="always">
          <mat-label>Productivity by time</mat-label>
          <mat-select>
            <mat-option *ngFor="let option of ['none']" [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="19">
        <button fxFlex="100" mat-button>
          <mat-icon>delete_outlined</mat-icon>
          <span>Clear all filters</span>
        </button>
      </div>

    </div>

    <div class="content" fxFlex="100">
      <mat-table [dataSource]="operatorDetailsDataSource">  
        <ng-container matColumnDef="operator">
          <!-- <mat-header-cell fxLayoutAlign="start center" fxFlex="100" *matHeaderCellDef></mat-header-cell> -->
          <mat-cell  fxLayoutAlign="start center" fxFlex="100" *matCellDef="let element">
            <app-operator-card [operatorDetails]="element"></app-operator-card>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="noData">
          <mat-footer-cell *matNoDataRow>
            {{ operatorDetailsDataSource.data || (hasError | async) ? 'No data.' : 'Select a client.'}}
          </mat-footer-cell>
        </ng-container>
        <!-- <mat-header-row *matHeaderRowDef="displayedOperatorDetailsColumns"></mat-header-row> -->
        <mat-row *matRowDef="let row; columns: displayedOperatorDetailsColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>