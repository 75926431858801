import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { DefaultAPIResponse } from "../model/APIResponse";
import {
  IOperatorSDR,
  IOperatorUser,
  IOperatorUserCreatePayload,
  IOperatorUserDetails,
  IOperatorUserToggleActivePayload,
  IOperatorUserUpdatePayload,
  OperatorsLevels,
  UpdateOperatorLevelRequest
} from "../model/Operator";


@Injectable({
    providedIn: 'root'
  })
export class OperatorService {
	apiBackEnd = '';
	constructor(private http: HttpClient) {
		this.apiBackEnd = environment.apiBaseURL;
	}

	public getAllUnsignedOperatorForSdr(sdrId: number, selectAll = false): Observable<DefaultAPIResponse<IOperatorSDR[]>> {
		let params = selectAll ? new HttpParams() : new HttpParams().append('is_assigned', false);
		return this.http.get<DefaultAPIResponse<IOperatorSDR[]>>(`${this.apiBackEnd}/app/user/${sdrId}/operators`, { params });
	}

	public getAllOperatorLevels(): Observable<DefaultAPIResponse<OperatorsLevels[]>> {
		return this.http.get<DefaultAPIResponse<OperatorsLevels[]>>(`${this.apiBackEnd}/app/levels`);
	}

	public updateOperatorLevels(body: UpdateOperatorLevelRequest[]): Observable<DefaultAPIResponse<[]>> {
		return this.http.patch<DefaultAPIResponse<[]>>(`${this.apiBackEnd}/app/operators_customers_level/`, body);
	}

	public unsetOperator(operatorId: number ): Observable<DefaultAPIResponse<[]>> {
		return this.http.delete<DefaultAPIResponse<[]>>(`${this.apiBackEnd}/app/operators_customers_level/${operatorId}`);
	}

	public getAllUsers(isActive: boolean): Observable<DefaultAPIResponse<IOperatorUser[]>> {
		let params = new HttpParams().append('is_active', isActive);
		return this.http.get<DefaultAPIResponse<IOperatorUser[]>>(`${this.apiBackEnd}/app/operators`, { params });
	}

	public createOperatorUser(body: IOperatorUserCreatePayload): Observable<DefaultAPIResponse<number>> {
		return this.http.post<DefaultAPIResponse<number>>(`${this.apiBackEnd}/app/operators`, body);
	}

	public toggleOperatorsUsersActiveStatus(body: IOperatorUserToggleActivePayload): Observable<DefaultAPIResponse<null>> {
		return this.http.patch<DefaultAPIResponse<null>>(`${this.apiBackEnd}/app/operators`, body);
	}

	public getOperatorUserDetails(operatorUserEmail: string): Observable<DefaultAPIResponse<IOperatorUserDetails>> {
		return this.http.get<DefaultAPIResponse<IOperatorUserDetails>>(`${this.apiBackEnd}/app/operators/${operatorUserEmail}`);
	}

	public updateOperatorUser(operatorUserEmail: string, body: IOperatorUserUpdatePayload): Observable<DefaultAPIResponse<null>> {
		return this.http.patch<DefaultAPIResponse<null>>(`${this.apiBackEnd}/app/operators/${operatorUserEmail}`, body);
	}
}
