import { Component, OnInit } from '@angular/core';
import { FeedService } from 'src/app/services/feed.service';
import {LoadingStateService} from '../../../services/base/loading-state.service';
import {SDAuthService} from '../../../services/sd-auth.service';
import { SdApiService } from 'src/app/services/base/sd-api.service';
import { ClientsDataContext } from 'src/app/model/Sdr';

@Component({
  selector: 'app-mail-manager',
  templateUrl: './mail-manager.component.html',
  styleUrls: ['./mail-manager.component.scss']
})
export class MailManagerComponent implements OnInit {
  loading = false;
  showMinComposer = false;
  loadingToken = true;
  showFeed = true;

  constructor(
    private loadingStateService: LoadingStateService,
    private sdAuthService: SDAuthService,
    private feedService: FeedService,
    private sdApiService: SdApiService
  ) { }

  ngOnInit() {
    this.sdAuthService.refreshToken().then(() => {
      this.loadingToken = false;
    });
    
    this.sdApiService.setClientsContext(ClientsDataContext.HOMEPAGE);
    
    this.loadingStateService.isLoading.subscribe((loading) => {
      this.loading = loading;
    });

    this.feedService.showFeed.subscribe((showFeed) => {
      this.showFeed = showFeed;
    });
  }

}
