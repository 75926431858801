<div fxLayout="row" class="top-bar" fxLayoutAlign="space-between center">
  <div class="dialog-name">Contact Date</div>
  <div fxLayout="row">
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>
</div>
<div mat-dialog-content fxLayout="row">
  <mat-form-field appearance="fill" fxFlex="100">
    <mat-label>Select a Contact Date</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="data.contactDate" [min]="minDate">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-raised-button class="lead-save-btn" [mat-dialog-close]="data.contactDate.value" [disabled]="!isAcceptEnabled">Accept</button>
</div>