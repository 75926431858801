import { Injectable } from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import { Email } from '../model/Email';
import { SdApiService } from './base/sd-api.service';
import { LoadingStateService } from './base/loading-state.service';
import { FeedService } from './feed.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MailCategory} from '../model/MailCategory';
import {MailAction} from '../model/MailAction';
import { Notification } from '../model/Notification';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotificationType } from '../model/NotificationType';
import { NotificationCategory } from '../model/NotificationCategory';
import { NotificationDismissType } from '../model/NotificationDismissType';
import { UIGuid } from '../helpers/ui-guid';
import { ActionService } from './action.service';
import { delay } from 'rxjs/operators';
import { LogService } from './log.service';
import { IDiscrepancy } from '../model/Discrepancy';

const MODAL_DURATION = 5000;
const UNDO_STANDBY_TIME = 10 * 1000;
const CLASSIFY_MILLISECONDS_COOLDOWN = 500;

@Injectable({
  providedIn: 'root'
})
export class SelectedDiscrepancyService {
  private selectedEmailFeed = new BehaviorSubject<Email>(null);
  private selectedDiscrepancy = new BehaviorSubject<IDiscrepancy>(null);
  private bulkSelectionFeed = new BehaviorSubject<Email[]>([]);
  private clickedEmail = new BehaviorSubject<string>('');
  private activeActions = new Map<string, MailAction>();
  private currentMail: Email;
  public bulkMailSelection: Email[] = [];
  private lastClassificationTime = 0;
  private lastClassificationDate;
  selectedFolder = '';


  constructor(
    private sdApiService: SdApiService,
    private loadingStateService: LoadingStateService,
    private feedService: FeedService,
    private snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    private actionsService: ActionService,
    private logService: LogService
  ) {
    this.feedService.selectedFolder.subscribe(folderValue => {
      this.selectedFolder = folderValue;
    });
  }

  get selectedEmail() {
    return this.selectedEmailFeed.asObservable();
  }

  get selectedLog() {
    return this.selectedDiscrepancy.asObservable();
  }

  selectMail(email: Email) {
    this.currentMail = email;
    this.selectedEmailFeed.next(email);
  }

  selectDiscrepancy(discrepancy: IDiscrepancy) {
    this.selectedDiscrepancy.next(discrepancy);
  }

  setClickedEmail(email: string) {
    this.clickedEmail.next(email);
  }

  get clickedEmailToSearch() {
    return this.clickedEmail.asObservable();
  }

  trashSingleMail(email: Email) {
    if (email && this.isSafeToUpdate) {
      const currentMail = email;
      const action = {email: currentMail, folder: { name: 'Trash'} as MailCategory} as MailAction;
      this.setLoadingAndRemoveFromFeed(email);
      this.sdApiService.moveMailToTrash(currentMail).subscribe(actionId => {
          // this.lastActionId = actionId;
          action.actionId = actionId;
          // this.movingMail = false;
          this.saveAction(action);
          this.loadingStateService.setLoadingState(false);
          if (!action.actionId) {
            // this.snackBar.open('Ha ocurrido un error al eliminar el mail', null, { duration: MODAL_DURATION });
            // this.feedService.addMailToFeed({ ...currentMail, hasError: true });
            this.clearAction(action);
          } else {
            const ref = this.snackBar.open('Mail eliminado con éxito', "Undo", { duration: MODAL_DURATION });
            ref.afterDismissed().subscribe(() => this.clearAction(action));
            ref.onAction().subscribe(() => {this.undoClassifyOrTrash(action); });
          }
        }
      );
    }
  }

  moveToTrash() {
    if (this.currentMail && this.isSafeToUpdate) {
      const mailsToMove = [...this.bulkMailSelection, this.currentMail];
      if (mailsToMove.length) {
        mailsToMove.forEach((mail) => {
          this.trashSingleMail(mail);
        });
        this.bulkMailSelection = [];
        this.bulkSelectionFeed.next([]);
      }
    }
  }

  setLoadingAndRemoveFromFeed(email: Email) {
    this.loadingStateService.setLoadingState(true);
    // this.movingMail = true;
    this.feedService.removeMailFromFeed(email);
    this.selectMail(this.feedService.getFirstMail());
  }

  triggerClassifyMail(category: MailCategory) {
    const d = new Date();
    const time = d.getTime();
    const newTimeFormatted = d.getHours().toString() + ":" + d.getMinutes().toString() + ":" + d.getSeconds().toString() + ":" + d.getMilliseconds().toString();
    // Block classification attempt before cooldown
    if (this.lastClassificationTime !== 0 && time - this.lastClassificationTime <= CLASSIFY_MILLISECONDS_COOLDOWN) {
      const previousTimeFormatted = this.lastClassificationDate.getHours().toString() + ":" + this.lastClassificationDate.getMinutes().toString() + ":" + this.lastClassificationDate.getSeconds().toString() + ":" + this.lastClassificationDate.getMilliseconds().toString();
      console.log("Blocked quick succession of classify actions, previous time " + previousTimeFormatted + " new time " + newTimeFormatted);
      return;
    }
    this.lastClassificationTime = time;
    this.lastClassificationDate = d;
    // console.log("Classify request accepted, classify time " + newTimeFormatted);

    this.classifyMail(category, this.selectedFolder);
  }

  classifyMail(folder: MailCategory, currentFolder: string) {
    if (this.currentMail && this.isSafeToUpdate) {
      const mailsToMove = [...this.bulkMailSelection, this.currentMail];
      if (mailsToMove.length) {
        this.feedService.registerLastClassified(mailsToMove.map(m => m.storageId));
        mailsToMove.forEach((mail) => {
          this.classifySingleMail(mail, folder, currentFolder);
        });
        this.bulkMailSelection = [];
        this.bulkSelectionFeed.next([]);
        const element: HTMLIFrameElement = document.getElementById('emailIframe') as HTMLIFrameElement;
        const iframe = element.contentWindow;
        iframe.document.querySelectorAll("*").forEach(n => n.scrollTo(0, 0));
      }
    }
  }

  classifySingleMail(email: Email, folder: MailCategory, currentFolder: string) {
    if (email && folder) {
      // console.log(email);
      const action = {email, folder} as MailAction;
      const actionIdentifier = email.storageId + "_classify_" + UIGuid.newGuid() + "_" + new Date().getTime().toString();

      // Create classify notification in advance
      let successClassifyClipboardInfo = `
        type:Success \n
        time:` + this.formatAMPM(new Date()) + ` \n
        message:Mail classified as ` + folder.name + ` \n
        subject:` + email.subject + ` \n
        sdr:` + email.sdr + ` \n
        targetFolder:` + folder.name + ` \n
        storageId:` + email.storageId + ` \n
        messageId:` + email.messageId + ` \n
      `;
      let successClassifyNotification = new Notification(
        {
          id: "successClassify_" + folder.id + "_" + email.storageId,
          type: NotificationType.Success,
          category: NotificationCategory.Classify,
          created_dt: new Date().toString(),
          message: "Mail classified as <span style='font-weight: bold;color:" + this.classificationColor(folder.name) + "'>" + folder.name + "</span>",
          placeholderInfo1: email.subject,
          placeholderInfo2: email.sdr ,
          undoSendAction: false,
          undoActionReady: false,
          undoColor: this.classificationColor(folder.name),
          undoClassifyAction: true,
          debugAction: true,
          debugClipboardInfo: successClassifyClipboardInfo,
          undoClassifySnackbarMessage: "Classify action on " + folder.name + " reverted successfully",
          undoSendSnackbarMessage: "",
          undoClassifyActionObject: action,
          dismissType: NotificationDismissType.AutoDismiss,
          actionIdentifier
        }
      );
      this.notificationsService.addNotificationToFeed(successClassifyNotification);

      this.actionsService.addActionItem(actionIdentifier, {});

      this.logService.submitLog({
        tags: ['ActionStarted','Classify',successClassifyNotification.id,successClassifyNotification.placeholderInfo1,successClassifyNotification.placeholderInfo2],
        body: "ActionStarted - " + successClassifyNotification.message
      });

      this.feedService.removeMailFromFeed(email);
      this.selectMail(this.feedService.getFirstMail());


      // Queue delayed call
      of(null)
        .pipe(
          delay(UNDO_STANDBY_TIME)
        )
      .subscribe(x => {
        if (!this.actionsService.isUndone(actionIdentifier)) {

          // this.setLoadingAndRemoveFromFeed(email);
          this.loadingStateService.setLoadingState(true);
          this.sdApiService.moveMailToFolder(action.email, folder.id, currentFolder).subscribe(response => {
            // this.movingMail = false;
            this.loadingStateService.setLoadingState(false);
            if (!response.action_id) {
              // this.snackBar.open('Ha ocurrido un error al clasificar el mail', null, { duration: MODAL_DURATION });
              // this.feedService.addMailToFeed({ ...action.email, hasError: true });
              const errorMsg = response.error ? '. ' + response.error : '';
              let errorClassifyClipboardInfo = `
                type:Error \n
                time:` + this.formatAMPM(new Date()) + ` \n
                message:Error classifying message as ` + folder.name + errorMsg + `  \n
                subject:` + email.subject + ` \n
                sdr:` + email.sdr + ` \n
                targetFolder:` + folder.name + ` \n
                storageId:` + email.storageId + ` \n
                messageId:` + email.messageId + ` \n
                exceptionDetail:` + JSON.stringify(response) + ` \n
              `;
              let errorClassifyNotification = new Notification(
                {
                  id: "errorClassify_" + folder.id + "_" + email.storageId,
                  type: NotificationType.Error,
                  category: NotificationCategory.Classify,
                  created_dt: new Date().toString(),
                  message: "Error classifying message as <span style='font-weight: bold;color:" + this.classificationColor(folder.name) + "'>" + folder.name + "</span>" + errorMsg,
                  placeholderInfo1: email.subject,
                  placeholderInfo2: email.sdr,
                  undoSendAction: false,
                  undoActionReady: true,
              undoSendReady: false,
                  undoColor: this.classificationColor(folder.name),
                  undoClassifyAction: false,
                  debugAction: true,
                  debugClipboardInfo: errorClassifyClipboardInfo,
                  undoClassifySnackbarMessage: "",
                  undoSendSnackbarMessage: "",
                  undoClassifyActionObject: null,
                  dismissType: NotificationDismissType.NoDismissable
                }
              );
              this.notificationsService.addNotificationToFeed(errorClassifyNotification);
            }
            setTimeout(() => {
              this.clearAction(action);
              this.feedService.deleteFromLastClassified(email.storageId);
            }, 50000)
          });

          this.logService.submitLog({
            tags: ['ActionCompleted','Classify',successClassifyNotification.id,successClassifyNotification.placeholderInfo1,successClassifyNotification.placeholderInfo2],
            body: "ActionCompleted - " + successClassifyNotification.message
          });

        }

        this.actionsService.removeActionItem(actionIdentifier);

      });
    }

  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  saveAction(action: MailAction) {
    if (!this.activeActions.get(action.actionId)) {
      this.activeActions.set(action.actionId, action);
    } else
      console.log("ERROR: Duplicated action ID!");
  }

  get isSafeToUpdate() {
    return true; // !this.movingMail && !this.undoingAction;
  }

  undoClassifyOrTrash(action: MailAction) {
    if (this.activeActions.get(action.actionId)) {
      this.activeActions.get(action.actionId).isUndoing = true;
      this.loadingStateService.setLoadingState(true);
      this.sdApiService.undoAction(action.actionId).subscribe(success => {
        this.loadingStateService.setLoadingState(false);
        if (!success) {
          const ref = this.snackBar.open(
            'Ha ocurrido un error al revertir la acción',
            "Try again",
            { duration: MODAL_DURATION }
            );
          ref.onAction().subscribe(() => {this.undoClassifyOrTrash(action); });
        } else {
          this.snackBar.open(
            `Mail revertido desde ${action.folder.name}`,
            null,
            { duration: MODAL_DURATION }
            );
          this.clearAction(action);
          this.feedService.deleteFromLastClassified(action.email.storageId);
          // Verify with user.
          // this.selectMail(null);
          // this.feedService.refresh();
        }
      });
    }
  }

  clearAction(action: MailAction) {
    if (this.activeActions.has(action.actionId))
      this.activeActions.delete(action.actionId);
  }

  selectAll(mails: Email[]) {
    this.bulkMailSelection = [];
    this.bulkMailSelection.push(...mails);
    this.bulkSelectionFeed.next(this.bulkMailSelection);
  }

  clearAll() {
    this.bulkMailSelection = [];
    this.bulkSelectionFeed.next(this.bulkMailSelection);
  }

  toggleMailInBulkSelection(mail: Email, forceAdd?: boolean) {
    const index = this.bulkMailSelection.indexOf(mail);
    // console.log(index);
    if (index > -1) {
      if (!forceAdd)
        this.bulkMailSelection.splice(index, 1);
    } else {
      this.bulkMailSelection.push(mail);
    }
    this.bulkSelectionFeed.next(this.bulkMailSelection);
  }

  isMailInBulkSelection(email: Email) {
    return this.bulkMailSelection.indexOf(email) > -1;
  }

  get emailBulkSelection() {
    return this.bulkSelectionFeed.asObservable();
  }

  refresh() {
    this.selectMail(null);
    this.feedService.refresh();
  }

  classificationColor(classification: string) {
    let color = "black";
    switch (classification.toLowerCase()) {
      case "21 - unsubscribe company":
        color = "#9E2C21";
        break;
      case "20 - bad fit company":
        color = "#9E2C21";
        break;
      case "25 - competitor":
        color = "#9E2C21";
        break;
      case "10 - client":
        color = "#9E2C21";
        break;
      case "converted":
        color = "#19709E";
        break;
      case "review":
        color = "#729E2F";
        break;
    }
    return color;
  }

}
