export class AppConstants {
    static readonly REPLY_TEMPLATE_TYPE = 1;
    static readonly INTRODUCTION_TEMPLATE_TYPE = 2;
    static readonly GMAIL_FONT_FAMILY = 'Arial';
    static readonly GMAIL_FONT_SIZE = '11';
    static readonly GMAIL_FONT_UNIT = 'pt';
    static readonly GMAIL_LINE_HEIGHT = '19.5px';
    static readonly OUTLOOK_FONT_FAMILY = 'Arial';
    static readonly OUTLOOK_FONT_SIZE = '11';
    static readonly OUTLOOK_FONT_UNIT = 'pt';
    static readonly OUTLOOK_LINE_HEIGHT = '19.5px';
    static readonly CATEGORY_CALL_U_BACK_ID = '17';
    static readonly CATEGORY_TO_CONTACT_ID = '26';
    static readonly CATEGORY_REVIEW_ID = '0';
    static readonly CATEGORY_UNSUBSCRIBE_MANUAL_ID = '3';
    static readonly CATEGORY_NOT_INTERESTED_ID = '6';
    static readonly CATEGORY_BAD_FIT_ID = '21';
    static readonly CATEGORY_OUT_OF_COMPANY_ID = '16';
    static readonly CATEGORY_OUT_OF_OFFICE_LONG_TERM_ID = '7';
    static readonly CATEGORY_OUT_OF_OFFICE_ID = '11';
    static readonly CATEGORY_NOT_NOW_ID = '9';
    static readonly DISCREPANCIES_LIMIT = 20;
    static readonly CONTACTS_LIMIT = 10;
}
