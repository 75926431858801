import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
  })
export class ParameterService {

    apiBackEnd = '';
    constructor(private http: HttpClient) {
        this.apiBackEnd = environment.apiBaseURL;
      }

    public getAttachmentParameters(): Observable<any> {
        return this.http.get(`${this.apiBackEnd}/app/mails/parameters`);
    }
}
