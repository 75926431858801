<div
  fxLayout="column"
  class="search-fields-container"
>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="24"
  >
    <div
      style="width: 240px"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        class="search-form-field"
        appearance="fill"
        id="inquiry-clients-mat-form-field"
      >
        <mat-label>Clients</mat-label>
        <mat-select
          style="font-size: 12px"
          [(ngModel)]="selectedClient"
          (ngModelChange)="updateClients()"
          id="inquiry-clients-mat-select"
        >
          <mat-option
            *ngFor="let client of clients; let i = index"
            [value]="client"
            style="font-size: 12px"
            [id]="'inquiry-clients-mat-option-' + i"
          >
            {{ client }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      style="width: 240px"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        class="search-form-field"
        appearance="fill"
        id="inquiry-sdr-mat-form-field"
      >
        <mat-label>SDR</mat-label>
        <mat-select
          style="font-size: 12px"
          [(ngModel)]="selectedSdr"
          id="inquiry-sdr-mat-select"
        >
          <mat-option
            *ngFor="let sdr of clientSdrs"
            [value]="sdr"
            style="font-size: 12px"
            id="inquiry-sdr-mat-option"
          >
            {{ sdr.email }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      style="width: 240px"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        class="search-form-field"
        appearance="fill"
        id="inquiry-date-mat-form-field"
      >
        <mat-label>Date</mat-label>
        <input
          style="font-size: 12px"
          matInput
          [matDatepicker]="pickerSearchDate"
          [(ngModel)]="searchDate"
          id="inquiry-date-input"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="pickerSearchDate"
          id="inquiry-date-mat-datepicker-toggle"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #pickerSearchDate
          id="inquiry-date-mat-datepicker"
        ></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div
    fxFlex="50"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="24"
  >
    <div
      style="width: 240px"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        class="search-form-field"
        appearance="fill"
        id="inquiry-email-mat-form-field"
      >
        <mat-label>Email</mat-label>
        <input
          matInput
          [(ngModel)]="email"
          #usrEmail="ngModel"
          maxlength="80"
          style="font-size: 12px"
          (ngModelChange)="validateEmail()"
          id="inquiry-email-input"
        />
        <mat-icon
          matSuffix
          aria-label="Clear"
          *ngIf="email"
          (click)="onEmailClear()"
          class="search-form-field__icon"
          id="inquiry-email-mat-icon-clear"
        >
          close
        </mat-icon>
      </mat-form-field>
    </div>

    <div
      style="width: 240px"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        class="search-form-field"
        appearance="fill"
        id="inquiry-firstName-mat-form-field"
      >
        <mat-label>First Name</mat-label>
        <input
          matInput
          [(ngModel)]="firstName"
          #usrFirstName="ngModel"
          maxlength="80"
          style="font-size: 12px"
          id="inquiry-firstName-input"
        />
        <mat-icon
          matSuffix
          aria-label="Clear"
          *ngIf="firstName"
          (click)="onFirstNameClear()"
          class="search-form-field__icon"
          id="inquiry-firstName-mat-icon-clear"
        >
          close
        </mat-icon>
      </mat-form-field>
    </div>

    <div
      style="width: 240px"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        class="search-form-field"
        appearance="fill"
        id="inquiry-lastName-mat-form-field"
      >
        <mat-label>Last Name</mat-label>
        <input
          matInput
          [(ngModel)]="lastName"
          #usrLastName="ngModel"
          maxlength="80"
          style="font-size: 12px"
          id="inquiry-lastName-input"
        />
        <mat-icon
          matSuffix
          aria-label="Clear"
          *ngIf="lastName"
          (click)="onLastNameClear()"
          class="search-form-field__icon"
          id="inquiry-lastName-mat-icon-clear"
        >
          close
        </mat-icon>
      </mat-form-field>
    </div>
    <div
      style="width: 125px"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        mat-stroked-button
        (click)="search()"
        class="search-button"
        [disabled]="!isValidSearch || isSearchLoading"
        id="inquiry-search-btn"
      >
        <span *ngIf="!isSearchLoading">Search</span>
        <mat-spinner
          class="search-btn-spinner"
          *ngIf="isSearchLoading"
          diameter="20"
        ></mat-spinner>
      </button>
    </div>
  </div>
  <div fxLayout="row">
    <small
      class="error-message"
      *ngIf="error"
      >{{ error }}</small
    >
  </div>
</div>
