import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewLeadDialogComponent } from '../new-lead-dialog/new-lead-dialog.component';
import { DiscrepanciesDataService } from 'src/app/services/discrepancies-data.service';
import { ILead, INewLeadDialogData } from 'src/app/model/DiscrepancyLeadToBulk';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-new-lead-preview-dialog',
  templateUrl: './new-lead-preview-dialog.component.html',
  styleUrls: ['./new-lead-preview-dialog.component.scss', '../discrepancies.component.scss']
})
export class NewLeadPreviewDialogComponent {
  isLoading = false;
  isSaved = false;
  creationError = ''
  addedToBulk = false;
  newLead: ILead;
  addToBulkError = '';
  constructor(
    private discrepanciesService: DiscrepanciesDataService,
    public dialogRef: MatDialogRef<NewLeadPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INewLeadDialogData,
    private dialog: MatDialog
  ) { }

  createNewLead() {
    if(this.data?.leadForm && this.data.leadForm.valid) {
      this.isLoading = true;
      this.discrepanciesService.createLead(this.data.leadForm.getRawValue()).subscribe(response => {
        if(response.code === 200 && response.data) {
          this.isSaved = true;
          this.newLead = response.data;
        } else if(response.message){
          this.creationError =  `An error occurred while creating new lead: ${JSON.parse(response.message)?.errorMessage}`
        }
      }, error => {
        this.creationError = `An error occurred while creating new lead: ${error.error.detail}`;
      }, () => {
        this.isLoading = false;
      })
    }
  }

  get displaySuccessMessage() {
    return !this.creationError && !this.addToBulkError && (this.isSaved || this.addedToBulk);
  }

  backToEdit() {
    this.dialog.open(NewLeadDialogComponent, {
      width:'800px',
      panelClass: 'discrepancies-dialog',
      data: {
        leadForm: this.data.leadForm
      }
    })

    this.dialogRef.close();
  }

  addToBulk() {
  //  if(this.newLead && this.newLead.id_lead) {
  //     this.discrepanciesService.selectedLeadToBulk.pipe(
  //       switchMap((selectedLeadToBulk) => {
  //         if(selectedLeadToBulk && selectedLeadToBulk.campaign_bulk_id) {
  //           return this.discrepanciesService.addLeadToBulk({
  //             id_lead: this.newLead.id_lead,
  //             campaign_bulk_id: selectedLeadToBulk.campaign_bulk_id
  //           })
  //         } else {
  //           return of({
  //             error: 'Invalid Campaign Bulk ID'
  //           })
  //         }
  //       })
  //     ).subscribe(response => {
  //       if(!response.error) {
  //         this.addedToBulk = true;
  //       } else {
  //         this.addToBulkError = response.error;
  //       }
  //     })
  //   }
  }

  compareFunction(option, value): boolean {
    return option === value
  }
}
