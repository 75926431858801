<div class="layoutDiv">
  <div class="container">
    <div
      class="titleDiv"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon>arrow_back_ios</mat-icon>
      <span>SDR's Management</span>

      <div class="action-buttons">
        <button
          class="save-button"
          mat-raised-button
          (click)="handleSave()"
          [disabled]="!selectedSdrIds.length"
          id="sdrs-management-save-btn"
        >
          <span>SAVE</span>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div
        class="filters"
        fxLayout="row"
        fxFlex="100"
        fxLayoutGap="10"
      >
        <mat-form-field
          fxFlex="25"
          class="form-field-5"
          appearance="outline"
          id="sdrs-management-company-mat-form-field"
        >
          <mat-label>Select Company</mat-label>
          <input
            matInput
            [matAutocomplete]="companyFilter"
            (keyup)="handleUserInput(SdrFiltersEnum.COMPANY, companyFilterInput.value)"
            #companyFilterInput
            id="sdrs-management-company-input"
          />
          <mat-autocomplete
            #companyFilter="matAutocomplete"
            [displayWith]="formatFilterPlaceholderName"
            id="sdrs-management-company-mat-autocomplete"
          >
            <mat-option
              *ngFor="let option of avaliableCompanies$ | async"
              [value]="option"
              (onSelectionChange)="handleFilterSelectionChange(SdrFiltersEnum.COMPANY, $event.source?.value.id)"
              id="sdrs-management-company-mat-option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field
          fxFlex="25"
          class="form-field-5"
          appearance="outline"
          id="sdrs-management-sdr-mat-form-field"
        >
          <mat-label>Select SDR</mat-label>
          <input
            matInput
            [matAutocomplete]="sdrFilter"
            (keyup)="handleUserInput(SdrFiltersEnum.SDR, sdrFilterInput.value)"
            #sdrFilterInput
            id="sdrs-management-sdr-input"
          />
          <mat-autocomplete
            #sdrFilter="matAutocomplete"
            [displayWith]="formatFilterPlaceholderName"
            id="sdrs-management-sdr-mat-autocomplete"
          >
            <mat-option
              *ngFor="let option of avaliableSdrs$ | async"
              [value]="option"
              (onSelectionChange)="handleFilterSelectionChange(SdrFiltersEnum.SDR, $event.source?.value.id)"
              id="sdrs-management-sdr-mat-option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field
          fxFlex="25"
          class="form-field-5"
          appearance="outline"
          id="sdrs-management-permission-mat-form-field"
        >
          <mat-label>Select Permission</mat-label>
          <input
            matInput
            [matAutocomplete]="permissionFilter"
            (keyup)="handleUserInput(SdrFiltersEnum.PERMISSION, permissionFilterInput.value)"
            #permissionFilterInput
            id="sdrs-management-permission-input"
          />
          <mat-autocomplete
            #permissionFilter="matAutocomplete"
            [displayWith]="formatFilterPlaceholderName"
            id="sdrs-management-persmission-mat-autocomplete"
          >
            <mat-option
              *ngFor="let option of avaliablePermissions$ | async"
              [value]="option"
              (onSelectionChange)="handleFilterSelectionChange(SdrFiltersEnum.PERMISSION, $event.source?.value.id)"
              id="sdrs-management-permission-mat-option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field
          fxFlex="25"
          class="form-field-5"
          appearance="outline"
          id="sdrs-management-operator-mat-form-field"
        >
          <mat-label>Select Operator</mat-label>
          <input
            matInput
            [matAutocomplete]="operatorFilter"
            (keyup)="handleUserInput(SdrFiltersEnum.OPERATOR, operatorFilterInput.value)"
            #operatorFilterInput
            id="sdrs-management-operator-input"
          />
          <mat-autocomplete
            #operatorFilter="matAutocomplete"
            [displayWith]="formatFilterPlaceholderEmail"
            id="sdrs-management-operator-mat-autocomplete"
          >
            <mat-option
              *ngFor="let option of avaliableOperators$ | async"
              [value]="option"
              (onSelectionChange)="handleFilterSelectionChange(SdrFiltersEnum.OPERATOR, $event.source?.value.id)"
              id="sdrs-management-operator-mat-option"
            >
              {{ option.email }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="table-padding">
      <app-table-actions
        [showSelectAllAction]="true"
        [selectAllActionLabel]="'Reassign in bulk'"
        [selectAllActionDisabled]="!selectedSdrIds.length || (disableSelectAllAction$ | async)"
        [allItensSelected]="selectedAllSdrs$ | async"
        [showFilter]="false"
        (selectAllEvent)="handleSelectAllEvent($event)"
        (selectAllActionEvent)="openReassignModal()"
      ></app-table-actions>
      <div
        class="mat-elevation-z8 shrink"
        [formGroup]="sdrFormArray"
      >
        <mat-table
          [dataSource]="sdrDataSource"
          matSort
        >
          <ng-container matColumnDef="company">
            <mat-header-cell
              fxLayoutAlign="start center"
              fxFlex="15"
              *matHeaderCellDef
              mat-sort-header
              >Company</mat-header-cell
            >
            <mat-cell
              fxLayoutAlign="start center"
              fxFlex="15"
              *matCellDef="let element"
              >{{ element.customer_name }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="sdr">
            <mat-header-cell
              fxLayoutAlign="start center"
              fxFlex="35"
              *matHeaderCellDef
              >SDR</mat-header-cell
            >
            <mat-cell
              fxLayoutAlign="start center"
              fxFlex="35"
              *matCellDef="let element"
            >
              <mat-checkbox
                color="primary"
                [checked]="checkIfRowIsSelected(element.user_operator_id)"
                (change)="handleSelect($event.checked, element.user_operator_id)"
                id="sdrs-management-select-mat-checkbox"
              >
                {{ element?.user_email }}
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="sdr_permission">
            <mat-header-cell
              fxLayoutAlign="start center"
              fxFlex="25"
              *matHeaderCellDef
              >SDR Permission</mat-header-cell
            >
            <mat-cell
              fxLayoutAlign="start center"
              fxFlex="25"
              *matCellDef="let element; let i = index"
            >
              <mat-form-field
                appearance="outline"
                class="form-field-5"
                subscriptSizing="dynamic"
                [id]="'sdrs-management-select-permission-' + i + '-mat-form-field'"
              >
                <input
                  matInput
                  placeholder="Select Permission"
                  [matAutocomplete]="permissionFilter"
                  [formControl]="sdrFormArray.controls[i].get('level')"
                  (keyup)="
                    handleUserInput(SdrFiltersEnum.PERMISSION, permissionFilterInput.value, element.user_operator_id)
                  "
                  #permissionFilterInput
                  [id]="'sdrs-management-select-permission-' + i + '-input'"
                />
                <mat-autocomplete
                  #permissionFilter="matAutocomplete"
                  [displayWith]="formatFilterPlaceholderName"
                  [id]="'sdrs-management-select-permission-' + i + '-mat-autocomplete'"
                >
                  <mat-option
                    *ngFor="
                      let option of (avaliablePermissionsForTable$ | async)[element.user_operator_id];
                      let i = index
                    "
                    [value]="option"
                    (onSelectionChange)="setFormValue(sdrFormArray.controls[i]?.get('level'), $event.source?.value)"
                    [id]="'sdrs-management-select-permission-' + i + '-mat-option'"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="operator">
            <mat-header-cell
              fxLayoutAlign="start center"
              fxFlex="25"
              *matHeaderCellDef
              >Operator Name</mat-header-cell
            >
            <mat-cell
              fxLayoutAlign="start center"
              fxFlex="25"
              *matCellDef="let element; let i = index"
            >
              <mat-form-field
                appearance="outline"
                class="form-field-5"
                subscriptSizing="dynamic"
                [id]="'sdrs-management-select-operator-' + i + '-mat-form-field'"
              >
                <input
                  matInput
                  placeholder="Select Operator"
                  [matAutocomplete]="operatorFilter"
                  [formControl]="sdrFormArray.controls[i]?.get('operator')"
                  (keyup)="
                    handleUserInput(SdrFiltersEnum.OPERATOR, operatorFilterInput.value, element.user_operator_id)
                  "
                  #operatorFilterInput
                  [id]="'sdrs-management-select-operator-' + i + '-input'"
                />
                <mat-autocomplete
                  #operatorFilter="matAutocomplete"
                  [displayWith]="formatFilterPlaceholderEmail"
                  [id]="'sdrs-management-select-operator-' + i + '-mat-autocomplete'"
                >
                  <mat-option
                    *ngFor="
                      let option of (avaliableOperatorsForTable$ | async)[element.user_operator_id];
                      let i = index
                    "
                    [value]="option"
                    (onSelectionChange)="setFormValue(sdrFormArray.controls[i]?.get('operator'), $event.source?.value)"
                    [id]="'sdrs-management-select-operator-' + i + '-mat-option'"
                  >
                    {{ option.email }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="noData">
            <tr
              class="mat-row"
              *matNoDataRow
            >
              <td
                class="mat-cell no-data"
                colspan="2"
                fxFlex="100"
              >
                {{ sdrDataSource.data || sdrHasError ? 'No data.' : 'Loading users.' }}
              </td>
            </tr>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[10, 50, 100]"
          (page)="handlePageEvent($event)"
          [length]="sdrCount"
          [pageSize]="pageSize"
          [pageIndex]="currentPage - 1"
          [showFirstLastButtons]="true"
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
