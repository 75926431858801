
import { Component, OnInit, ViewChild } from '@angular/core';
import { OperatorService } from '../../../../services/operator.service.';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import {
  IOperatorUserCreatePayload,
  IOperatorUserDetails,
  IOperatorUserDetailsSdr,
  OperatorUserManagementEditTabs,
  ToggleOperatorUserActions
} from '../../../../model/Operator';
import { HttpStatusCode } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MODAL_DURATION } from '../../../../model/Utils';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { TableActionsComponent } from '../../../../components/table-actions/table-actions.component';

@Component({
  selector: 'app-operator-user-edit',
  templateUrl: './operator-user-edit.component.html',
  styleUrls: ['./operator-user-edit.component.scss']
})
export class OperatorUserEditComponent implements OnInit{
  @ViewChild('sdrSort', { static: false  }) public sdrSort: MatSort;
  @ViewChild('sdrPaginator', { static: false  }) public sdrPaginator: MatPaginator;
  @ViewChild(TableActionsComponent, {static: false}) public tableActions: TableActionsComponent;

  public operatorUserForm: FormGroup = new FormGroup({});
  public curentOperator: BehaviorSubject<IOperatorUserDetails | null> = new BehaviorSubject(null);
  public currentTab$: BehaviorSubject<OperatorUserManagementEditTabs> = new BehaviorSubject<OperatorUserManagementEditTabs>(OperatorUserManagementEditTabs.DETAILS);
  public OperatorUserManagementEditTabs = OperatorUserManagementEditTabs;
  public sdrDataSource: MatTableDataSource<IOperatorUserDetailsSdr> = new MatTableDataSource(null);
  public displayedSdrColumns: string[] = ['email', 'customer_name', 'level_name', 'actions'];
  public sdrHasError = false;

  constructor(
    private readonly operatorService: OperatorService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
  ) { }


  public ngOnInit(): void {
    this.getOperatorUserDetails();
  }

  public toggleUser():void {
    this.toggleOperatorUserStatus();
  }

  public save(): void {
    if(this.operatorUserForm.valid){
      this.updateOperatorUser();
    }
  }

  public handleTabChange(selectedTabIndex: number): void {
    this.currentTab$.next(selectedTabIndex === 0 ? OperatorUserManagementEditTabs.DETAILS : OperatorUserManagementEditTabs.SDRS);
    this.tableActions.resetFilterField();
    this.sdrDataSource.filter = null;
  }

  public handleFilterUserInputEvent(filter: string): void {
    this.applySdrFilter(filter);
  }

  private getOperatorUserDetails(): void {
    this.operatorService.getOperatorUserDetails(this.userEmail).pipe(take(1)).subscribe(res => {
      if(res.code === HttpStatusCode.Ok){
        this.curentOperator.next({...res.data, email: this.userEmail});
        this.createSdrDataSource(res.data.default_sdrs ?? []);
      }
    })
  }

  private updateOperatorUser(): void {
    const formValue = this.operatorUserForm.value as unknown as IOperatorUserCreatePayload;
    if(formValue.password === null){
      formValue.password = '';
    }
    this.operatorService.updateOperatorUser(this.userEmail, formValue).pipe(take(1)).subscribe(res => {
      if(res.code === HttpStatusCode.Ok){
        this.showToast('operator was successfully updated');
        this.navigateToParentPage();
      } else {
        this.showToast(`failed to update operator: ${res.message}`);
      }
    }, () => {
      this.showToast('failed to update operator');
    })
  }

  private toggleOperatorUserStatus(): void {
    const action = this.curentOperator.value.enabled ? ToggleOperatorUserActions.DISABLE : ToggleOperatorUserActions.ENABLE;
    const isInactivate = this.curentOperator.value.enabled;
    this.operatorService.toggleOperatorsUsersActiveStatus({
      action,
      usernames: [this.userEmail],
    }).pipe(take(1)).subscribe(res => {
      if(res.code === HttpStatusCode.NoContent) {
        const message = isInactivate ? `The operator is inactive` : `The operator was restored`;
        this.showToast(message);
        this.navigateToParentPage();
      } else {
        this.showToast(`Failed to ${isInactivate ? 'inactivate' : 'restore'} operator`);
      }
    }, () => {
      this.showToast(`Failed to ${isInactivate ? 'inactivate' : 'restore'} operator`);
    })
  }

  private navigateToParentPage(): void {
    this.router.navigate(['home/management/operator-users']);
  }

  private get userEmail(): string {
    return this.route.snapshot.paramMap.get('email');
  }

  private showToast(message: string): void {
    this.snackBar.open(
      message,
      null,
      {
        duration: MODAL_DURATION,
      }
    );
  }

  private createSdrDataSource(sdrList: IOperatorUserDetailsSdr[]): void {
    this.sdrDataSource = new MatTableDataSource(sdrList);
    this.sdrDataSource.sort = this.sdrSort;
    this.sdrDataSource.paginator = this.sdrPaginator;

    this.sdrDataSource.filterPredicate = (data, filter) => {
      return data.email.toLowerCase().indexOf(filter) !== -1;
    }
  }

  private applySdrFilter(filterInput: string): void {
    this.sdrDataSource.filter = filterInput.trim().toLowerCase();
  }
}
