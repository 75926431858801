import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  DateRange,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDateRangeSelectionStrategy,
} from '@angular/material/datepicker';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { ILeadsToBulkSearchParams } from 'src/app/model/Discrepancy';
import { DiscrepanciesDataService } from 'src/app/services/discrepancies-data.service';

@Injectable()
export class SevenDayRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  constructor(private _dateAdapter: DateAdapter<D>) {}

  selectionFinished(date: D | null): DateRange<D> {
    return this._createSevenDayRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this._createSevenDayRange(activeDate);
  }

  private _createSevenDayRange(date: D | null): DateRange<D> {
    if (date) {
      const end = this._dateAdapter.addCalendarDays(date, 0);
      const start = this._dateAdapter.addCalendarDays(date, -6);
      return new DateRange<D>(start, end);
    }

    return new DateRange<D>(null, null);
  }
}

@Component({
  selector: 'app-advanced-search-dialog',
  templateUrl: './advanced-search-dialog.component.html',
  styleUrls: ['./advanced-search-dialog.component.scss', '../discrepancies.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: SevenDayRangeSelectionStrategy,
    },
  ],
})
export class AdvancedSearchDialogComponent implements OnInit {
  form: FormGroup = new FormGroup({});
  isLoading = false;
  selectedResult = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['id', 'first_name', 'last_name', 'email'];
  searchResultsData: MatTableDataSource<any> = new MatTableDataSource([]);
  subscriptions = new Subscription();
  noResultsMessage = 'No Data';
  selectedRow = new Set<any>();

  constructor(
    public dialogRef: MatDialogRef<AdvancedSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private discrepanciesService: DiscrepanciesDataService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.minLength(3)]),
      searchDate: new FormControl(new Date()),
      fromDate: new FormControl(dayjs().subtract(6, 'days').format(), this.validDateRange),
      toDate: new FormControl(dayjs().format(), this.validDateRange),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      bySingleDate: new FormControl(false),
      matchAnyCondition: new FormControl(false),
    });
  }

  get isValid(): boolean {
    return this.form.get('firstName').value || this.form.get('lastName').value || this.form.get('email').value;
  }

  get isValidEmail(): boolean {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.form.get('email')?.value?.trim());
  }

  private validDateRange = (control: AbstractControl): ValidationErrors | null => {
    const shoudlFilterByDateRange = !this.form.get('bySingleDate')?.value;

    if (shoudlFilterByDateRange) {
      const fromDateControl = this.form.get('fromDate');
      const toDateControl = this.form.get('toDate');
      const startDate = (dayjs(fromDateControl?.value) || dayjs()).format('MM/DD/YYYY');
      const endDate = dayjs(toDateControl?.value).format('MM/DD/YYYY');
      const diff = dayjs(endDate).diff(startDate, 'days');
      const isValidDateRange = diff >= 0 && diff < 7;

      if (isValidDateRange) {
        if (control === toDateControl) {
          fromDateControl?.setErrors(null);
        } else {
          toDateControl?.setErrors(null);
        }
        return null;
      } else {
        if (control === toDateControl) {
          fromDateControl?.setErrors({ invalidDateRange: true });
        } else {
          toDateControl?.setErrors({ invalidDateRange: true });
        }
        return { invalidDateRange: true };
      }
    }

    return null;
  };

  onRowClicked(row) {
    this.selectedRow.add(row);
    this.discrepanciesService.setSearchParams({ sdr: this.data.sdr, email: row.email, client: this.data.client });
    this.dialogRef.close({ selectedLeadEmail: row.email });
  }

  onSubmitSearch() {
    if (this.isValid && this.data.sdr) {
      this.isLoading = true;
      const form = this.form.getRawValue();
      let params: ILeadsToBulkSearchParams = {
        sdr: this.data.sdr,
        email: form.email?.trim(),
        firstName: form.firstName,
        lastName: form.lastName,
        isPartialEmail: !this.isValidEmail,
      };

      if (!form.bySingleDate) {
        params = {
          ...params,
          fromDate: form.fromDate,
          toDate: form.toDate,
        };
      } else {
        params = {
          ...params,
          searchDate: form.searchDate,
        };
      }
      this.subscriptions.add(
        this.discrepanciesService.searchLeadsToBulk(params).subscribe(
          (searchResponse) => {
            this.searchResultsData = new MatTableDataSource(searchResponse);
            if (searchResponse.length) {
              this.noResultsMessage = '';
            } else {
              this.noResultsMessage = "We couldn't find any results matching the search criteria";
            }
            this.isLoading = false;
          },
          (error) => {
            this.noResultsMessage = 'An Error occurred while executing search';
            this.isLoading = false;
          },
        ),
      );
    }
  }
}
