import { Injectable } from "@angular/core";
import * as Rx from "rxjs/Rx";

@Injectable({
    providedIn: 'root'
  })
export class WebsocketService {
  constructor() {}

  public ws: WebSocket;

  public subject: Rx.Subject<MessageEvent>;

  public connect(url) {
    if (this.ws === undefined || this.ws.CLOSED) {
      // console.log("Connecting...");
      this.ws = new WebSocket(url);
      const observable = Rx.Observable.create((obs: Rx.Observer<MessageEvent>) => {
        this.ws.onmessage = obs.next.bind(obs);
        this.ws.onerror = obs.error.bind(obs);
        this.ws.onclose = obs.complete.bind(obs);
        // return ws.close.bind(ws);
        return obs;
      });
      const observer = {
        next: (data: Object) => {
          if (this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(data));
          }
        }
      };
      this.subject = Rx.Subject.create(observer, observable);
    }
  }

  public disconnect() {
    this.ws.close();
  }


}
