<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="topbar-dialog"
>
  <div>
    <mat-icon
      class="icon-button"
      mat-dialog-close
      >close</mat-icon
    >
  </div>
</div>
<table
  mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="date"
  matSortDirection="desc"
  matSortDisableClear
>
  <ng-container matColumnDef="date">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
    >
      Date
    </th>
    <td
      mat-cell
      *matCellDef="let row"
    >
      {{ row.date | date: 'dd/MM/yyyy' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="fullName">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
    >
      Full Name
    </th>
    <td
      mat-cell
      *matCellDef="let row"
    >
      {{ row.fullName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="additionalInfo">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
    >
      Additional Info
    </th>
    <td
      mat-cell
      *matCellDef="let row"
    >
      {{ row.additionalInfo | titlecase }}
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
  ></tr>
</table>

<div
  *ngIf="!dataSource.data.length"
  class="empty-table-mssg"
>
  No data to display
</div>
