
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IOperatorDetails } from '../../../model/Operator';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-reassign-operator',
  templateUrl: './reassign-operator-container.component.html',
  styleUrls: ['./reassign-operator-container.component.scss']
})
export class ReassignOperatorContainerComponent implements AfterViewInit{
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public displayedOperatorDetailsColumns: string[] = ['operator'];
  public operatorDetailsDataSource: MatTableDataSource<IOperatorDetails> = new MatTableDataSource(null);
  public hasError:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  ngAfterViewInit(): void {
    this.getOperatorsDetailsList();
  }

  private operatorDetailsMock$: Observable<IOperatorDetails[]> = of([
    // {
    //   id: 1,
    //   operator_name: 'test operator 1',
    //   user_email: 'test1@test.com',
    //   client_level: 'Basic',
    //   operator_status: 'active',
    //   languages: 'pt',
    //   clientsCount: 1,
    //   sdrCount: 10,
    //   folders: ['All'],
    //   discrepancies: ['All'],
    //   emailCount: 1,
    //   timeToReply: 25,
    //   companies: [
    //     {
    //       companyName: 'company 1',
    //       sdrCount: 2,
    //       sdrs: [
    //         {
    //           id: 1,
    //           sdrName: 'test 1',
    //           folders: ['All'],
    //           discrepancies: ['All'],
    //           emailCount: 1,
    //           timeToReply: 25,
    //         },
    //         {
    //           id: 5,
    //           sdrName: 'test 5',
    //           folders: ['All'],
    //           discrepancies: ['All'],
    //           emailCount: 1,
    //           timeToReply: 50,
    //         }
    //       ]
    //     },
    //     {
    //       companyName: 'company 2',
    //       sdrCount: 8,
    //       sdrs: [
    //         {
    //           id: 2,
    //           sdrName: 'test 2',
    //           folders: ['All'],
    //           discrepancies: ['All'],
    //           emailCount: 1,
    //           timeToReply: 25,
    //         }
    //       ]
    //     }
    //   ],
    // },
    // {
    //   id: 2,
    //   operator_name: 'test operator 2',
    //   user_email: 'test2@test.com',
    //   client_level: 'Advanced',
    //   operator_status: 'active',
    //   languages: 'pt',
    //   clientsCount: 2,
    //   sdrCount: 20,
    //   folders: ['Inbox', 'Sent', 'Call Back'],
    //   discrepancies: ['Manual', 'Review'],
    //   emailCount: 2,
    //   timeToReply: 50,
    //   companies: [
    //     {
    //       companyName: 'company 3',
    //       sdrCount: 15,
    //       sdrs: [
    //         {
    //           id: 3,
    //           sdrName: 'test 3',
    //           folders: ['All'],
    //           discrepancies: ['All'],
    //           emailCount: 1,
    //           timeToReply: 25,
    //         }
    //       ]
    //     },
    //     {
    //       companyName: 'company 4',
    //       sdrCount: 5,
    //       sdrs: [
    //         {
    //           id: 4,
    //           sdrName: 'test 4',
    //           folders: ['All'],
    //           discrepancies: ['All'],
    //           emailCount: 1,
    //           timeToReply: 25,
    //         }
    //       ]
    //     }
    //   ],
    // }
  ])

  private getOperatorsDetailsList(): void {
    this.operatorDetailsMock$.pipe(take(1)).subscribe(res => {
      this.createOperatorsDetailsDataSource(res);
    })
  }

  private createOperatorsDetailsDataSource(sdrList: IOperatorDetails[]): void {
    this.operatorDetailsDataSource = new MatTableDataSource(sdrList);
    this.operatorDetailsDataSource.paginator = this.paginator;

    this.operatorDetailsDataSource.filterPredicate = (data, filter) => {
      return this.displayedOperatorDetailsColumns.some(ele => {
        return ele != 'actions' && data[ele].toLowerCase().indexOf(filter) != -1;
      });
    };
  }
}
