<div
  id="layoutDiv"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <div id="container">
    <div
      id="titleDiv"
      fxLayout.gt-md="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-between center"
    >
      <span class="pageTitle">Manage Templates</span>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        style="cursor: pointer"
        [matMenuTriggerFor]="templateMenu"
      >
        <button
          class="btnCreateTemplate"
          mat-raised-button
          color="primary"
          id="email-template-management-create-template-btn"
        >
          CREATE TEMPLATE
        </button>

        <mat-menu
          #templateMenu="matMenu"
          class="menu-create-template"
        >
          <button
            (click)="createReplyTemplate()"
            mat-menu-item
            id="email-template-management-create-reply-template-btn"
          >
            Reply
          </button>
          <button
            (click)="createIntroductionTemplate()"
            mat-menu-item
            id="email-template-management-create-introduction-template-btn"
          >
            Introduction
          </button>
        </mat-menu>
      </div>
    </div>

    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="REPLY TEMPLATES">
        <div class="manage-templates-table">
          <div id="searchDiv">
            <mat-form-field
              class="search-form-field form-field-5"
              appearance="outline"
              subscriptSizing="dynamic"
              id="email-template-management-search-reply-mat-form-field"
            >
              <mat-label>Search</mat-label>
              <input
                matInput
                [(ngModel)]="replySearchKey"
                autocomplete="off"
                (keyup)="applyReplyFilter()"
                id="email-template-management-search-reply-input"
              />
              <mat-icon
                matSuffix
                *ngIf="replySearchKey"
                (click)="onReplySearchClear()"
                id="email-template-management-search-reply-mat-clear-icon"
                >close</mat-icon
              >
            </mat-form-field>
          </div>
          <div class="mat-elevation-z0 shrink">
            <mat-table
              [dataSource]="replyListData"
              matSort
              matSortActive="sortDate"
              matSortDirection="desc"
              matSortDisableClear
              #replySort="matSort"
            >
              <ng-container matColumnDef="sortDate">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="28"
                  *matHeaderCellDef
                  mat-sort-header
                  >Date</mat-header-cell
                >
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="28"
                  *matCellDef="let element"
                  >{{ element.sortDate | date: 'MM/dd/yyyy' }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="title">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="24"
                  *matHeaderCellDef
                  mat-sort-header
                  >Title</mat-header-cell
                >
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="24"
                  *matCellDef="let element"
                  >{{ element.title }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="hasAttachments">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="10"
                  *matHeaderCellDef
                  mat-sort-header
                ></mat-header-cell>
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="10"
                  *matCellDef="let element"
                  ><mat-icon
                    class="attachment-icon"
                    *ngIf="element.hasAttachments"
                    >attachment</mat-icon
                  ></mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="client">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="25"
                  *matHeaderCellDef
                  mat-sort-header
                  >Client</mat-header-cell
                >
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="25"
                  *matCellDef="let element"
                  >{{ element.client }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="25"
                  *matHeaderCellDef
                  >Actions</mat-header-cell
                >
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="25"
                  *matCellDef="let element"
                  class="center actionsAddress"
                >
                  <button
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="edit-btn"
                    mat-icon-button
                    (click)="editTemplate(element.id)"
                    id="email-template-management-edit-reply-template-btn"
                  >
                    <mat-icon class="edit-icon">edit</mat-icon>
                  </button>
                  <button
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="delete-btn"
                    mat-icon-button
                    (click)="removeReplyTemplate(element.id)"
                    id="email-template-management-remove-reply-template-btn"
                  >
                    <mat-icon class="delete-icon">delete</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="loading">
                <mat-footer-cell
                  *matFooterCellDef
                  colspan="2"
                >
                  Loading data...
                </mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="noData">
                <mat-footer-cell
                  *matFooterCellDef
                  colspan="2"
                >
                  No data.
                </mat-footer-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="replyDisplayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: replyDisplayedColumns"></mat-row>
              <mat-footer-row
                *matFooterRowDef="['loading']"
                [ngClass]="{ hide: replyListData !== null }"
              ></mat-footer-row>
              <mat-footer-row
                *matFooterRowDef="['noData']"
                [ngClass]="{ hide: !(replyListData !== null && replyListData.data.length === 0) }"
              ></mat-footer-row>
            </mat-table>
            <mat-paginator
              class="paginator-table"
              [pageSize]="10"
              showFirstLastButtons
              #replyPaginator="matPaginator"
            ></mat-paginator>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="INTRODUCTION TEMPLATES">
        <div class="manage-templates-table">
          <div id="searchDiv">
            <mat-form-field
              class="search-form-field form-field-5"
              appearance="outline"
              subscriptSizing="dynamic"
              id="email-template-management-search-introduction-mat-form-field"
            >
              <mat-label>Search</mat-label>
              <input
                matInput
                [(ngModel)]="introductionSearchKey"
                autocomplete="off"
                (keyup)="applyIntroductionFilter()"
                id="email-template-management-search-introduction-input"
              />
              <mat-icon
                matSuffix
                *ngIf="introductionSearchKey"
                (click)="onIntroductionSearchClear()"
                id="email-template-management-search-introduction-mat-icon-clear"
                >close</mat-icon
              >
            </mat-form-field>
          </div>
          <div class="mat-elevation-z0 shrink">
            <mat-table
              [dataSource]="introductionListData"
              matSort
              matSortActive="sortDate"
              matSortDirection="desc"
              matSortDisableClear
              #introductionSort="matSort"
            >
              <ng-container matColumnDef="sortDate">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="13"
                  *matHeaderCellDef
                  mat-sort-header
                  >Date</mat-header-cell
                >
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="13"
                  *matCellDef="let element"
                  >{{ element.sortDate | date: 'MM/dd/yyyy' }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="title">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="15"
                  *matHeaderCellDef
                  mat-sort-header
                  >Title</mat-header-cell
                >
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="15"
                  *matCellDef="let element"
                  ><div class="multi-line-text">{{ element.title }}</div></mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="hasAttachments">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="7"
                  *matHeaderCellDef
                  mat-sort-header
                ></mat-header-cell>
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="7"
                  *matCellDef="let element"
                  ><mat-icon
                    class="attachment-icon"
                    *ngIf="element.hasAttachments"
                    >attachment</mat-icon
                  ></mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="client">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="15"
                  *matHeaderCellDef
                  mat-sort-header
                  >Client</mat-header-cell
                >
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="15"
                  *matCellDef="let element"
                  >{{ element.client }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="sdr">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="21"
                  *matHeaderCellDef
                  mat-sort-header
                  >SDR</mat-header-cell
                >
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="21"
                  *matCellDef="let element"
                  ><div class="multi-line-text">{{ element.sdr }}</div></mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="clientContact">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="16"
                  *matHeaderCellDef
                  mat-sort-header
                  >Client Contact</mat-header-cell
                >
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="16"
                  *matCellDef="let element"
                >
                  {{ element.clientContact }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="13"
                  *matHeaderCellDef
                  >Actions</mat-header-cell
                >
                <mat-cell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="13"
                  *matCellDef="let element"
                  class="center actionsAddress"
                >
                  <button
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="edit-btn"
                    mat-icon-button
                    (click)="editTemplate(element.id)"
                    id="email-template-management-edit-introduction-template-btn"
                  >
                    <mat-icon class="edit-icon">edit</mat-icon>
                  </button>
                  <button
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="delete-btn"
                    mat-icon-button
                    (click)="removeIntroductionTemplate(element.id)"
                    id="email-template-management-remove-introduction-template-btn"
                  >
                    <mat-icon class="delete-icon">delete</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="loading">
                <mat-footer-cell
                  *matFooterCellDef
                  colspan="2"
                >
                  Loading data...
                </mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="noData">
                <mat-footer-cell
                  *matFooterCellDef
                  colspan="2"
                >
                  No data.
                </mat-footer-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="introductionDisplayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: introductionDisplayedColumns"></mat-row>
              <mat-footer-row
                *matFooterRowDef="['loading']"
                [ngClass]="{ hide: introductionListData !== null }"
              ></mat-footer-row>
              <mat-footer-row
                *matFooterRowDef="['noData']"
                [ngClass]="{ hide: !(introductionListData !== null && introductionListData.data.length === 0) }"
              ></mat-footer-row>
            </mat-table>
            <mat-paginator
              class="paginator-table"
              [length]="resultsLength"
              [pageSize]="10"
              showFirstLastButtons
              #introductionPaginator="matPaginator"
            ></mat-paginator>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
