<div class="user-create">
  <div class="user-create__title">Create User</div>
  <mat-form-field
    class="user-email-form-field form-field-5"
    appearance="outline"
    subscriptSizing="dynamic"
    id="user-create-user-email-mat-form-field"
  >
    <mat-label>User Email</mat-label>
    <input
      matInput
      [(ngModel)]="userEmail"
      [pattern]="emailPattern"
      #usrEmail="ngModel"
      id="user-create-user-email-input"
    />
    <mat-icon
      matSuffix
      *ngIf="userEmail"
      (click)="onUserClear()"
      id="user-create-user-email-mat-icon-clear"
      >close</mat-icon
    >
    <mat-error *ngIf="usrEmail.errors?.pattern"> Email is not valid</mat-error>
  </mat-form-field>
  <mat-form-field
    class="pass-set-form-field form-field-5"
    appearance="outline"
    subscriptSizing="dynamic"
    id="user-create-password-mat-form-field"
  >
    <mat-label>Password</mat-label>
    <input
      matInput
      [(ngModel)]="userPassword"
      type="password"
      [pattern]="passwordPattern"
      #usrPassword="ngModel"
      maxlength="15"
      id="user-create-password-input"
    />
    <mat-icon
      matSuffix
      *ngIf="userPassword"
      (click)="onPasswordClear()"
      id="user-create-password-mat-icon-clear"
      >close</mat-icon
    >
    <mat-error *ngIf="usrPassword.errors?.pattern">
      Password does not meet strength conditions (Minimum eight and maximum 15 characters, at least one uppercase
      letter, one lowercase letter, one number and one special character).</mat-error
    >
  </mat-form-field>
  <button
    mat-raised-button
    color="primary"
    class="add-user-btn"
    (click)="createUser()"
    [disabled]="userEmail === '' || usrEmail.errors?.pattern || userPassword === '' || usrPassword.errors?.pattern"
    id="user-create-add-user-btn"
  >
    <mat-icon>person_add</mat-icon>ADD USER
  </button>
</div>
