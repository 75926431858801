import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectedMailService } from '../../../../../services/selected-mail.service';
import { Email, FOLDERS } from '../../../../../model/Email';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MailReferredDialogComponent } from './mail-referred-dialog/mail-referred-dialog.component';
import { MailComposerComponent } from '../../../mail-composer/mail-composer.component';
import { ComposerMailService } from 'src/app/services/composer-mail.service';
import { SDAuthService } from 'src/app/services/sd-auth.service';
import { UserService } from 'src/app/services/user.service';
import { SdApiService } from 'src/app/services/base/sd-api.service';
import { InquiryComponent } from '../../../inquiry/inquiry.component';
import { Sdr } from 'src/app/model/Sdr';
import { FeedService } from 'src/app/services/feed.service';
import { MessageAttachmentService } from 'src/app/services/message-attachment.service';
import { Subject, of } from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';
import { MailCategory } from 'src/app/model/MailCategory';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FaqOperatorComponent } from '../../../faq-operator/faq-operator.component';
import { FaqDataService } from 'src/app/services/faq-data.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { ComposeEmail } from 'src/app/model/ComposeEmail';
declare var $: any;

const MODAL_DURATION = 3000;
const paddingPerRow = 50;
const attachmentLineHeight = 48;

@Component({
  selector: 'app-mail-details-tool-bar',
  templateUrl: './mail-details-tool-bar.component.html',
  styleUrls: ['./mail-details-tool-bar.component.scss'],
})
export class MailDetailsToolBarComponent implements OnInit, OnDestroy {
  currentEmail: Email;
  bulkLength = 0;
  mailTo: string[] = [];
  isSender = false;
  hasInquiryPermission = false;
  userEmail = '';
  sdrs: Sdr[];
  selectedFolder = '';
  isComposerOpen = false;
  trashOption: MailCategory = { id: '25', name: 'TRASH' };
  currentComposedEmail: ComposeEmail;
  destroy$ = new Subject<boolean>();

  constructor(
    public selectedMailService: SelectedMailService,
    private dialog: MatDialog,
    public composerMailService: ComposerMailService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private authService: SDAuthService,
    private sdApiService: SdApiService,
    private feedService: FeedService,
    public messageAttachmentService: MessageAttachmentService,
    private faqDataService: FaqDataService,
  ) {}

  ngOnInit() {
    this.selectedMailService.selectedEmail.subscribe((email) => {
      this.currentEmail = email;
      if (this.currentEmail && this.currentEmail.bodyHtml) {
        this.populateMailto();
      }
    });

    this.selectedMailService.emailBulkSelection.subscribe((bulk) => {
      this.bulkLength = bulk.length;
    });

    this.userService.isUserSender().subscribe((isSender) => {
      this.isSender = isSender;
    });

    this.userService.hasPermission('lead_inquiry').subscribe((hasPermission) => {
      this.hasInquiryPermission = hasPermission;
    });
    this.authService.getCurrentUserEmail().then((email) => {
      this.userEmail = email;
    });

    this.feedService.selectedFolder.subscribe((folderValue) => {
      this.selectedFolder = folderValue;
      this.loadSdrsInitialData(folderValue);
    });

    this.composerMailService.isComposerOpen.subscribe((isComposerOpen: boolean) => {
      this.isComposerOpen = isComposerOpen;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get isTrashEnabled(): boolean {
    return this.currentEmail && this.selectedFolder !== 'Sent';
  }

  loadSdrsInitialData(current_folder: string = FOLDERS.INBOX) {
    if (current_folder === FOLDERS.REVIEW) {
      this.sdApiService.userSdrsReview.pipe(take(1)).subscribe((sdrs) => {
        this.sdrs = sdrs;
      });
    } else if (current_folder === FOLDERS.SENT) {
      this.sdApiService.userSdrsSent.pipe(take(1)).subscribe((sdrs) => {
        this.sdrs = sdrs;
      });
    } else {
      this.sdApiService.userSDRs.pipe(take(1)).subscribe((sdrs) => {
        this.sdrs = sdrs;
      });
    }
  }

  updateDynamicPadding() {
    of(null)
      .pipe(delay(10))
      .subscribe((x) => {
        const attachmentLines = Math.ceil($('#readAttachmentsDiv').height() / attachmentLineHeight);

        if (attachmentLines > 1) {
          const pad_top_val = (attachmentLines - 1) * paddingPerRow;
          $('#emailColumn').css('padding-top', pad_top_val + 'px');
        } else {
          $('#emailColumn').css('padding-top', '0px');
        }
      });
  }

  moveToTrash() {
    this.selectedMailService.moveToTrash();
  }

  openMailTo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.mailTo;
    const dialogRef = this.dialog.open(MailReferredDialogComponent, dialogConfig);
  }

  populateMailto() {
    let startIndex = 0;
    const startTarget1 = 'Unsubscribe+-+';
    const startTarget2 = 'Unsubscribe%20-%20';
    const startTarget3 = 'Unsubscribe - ';
    const endTarget = '"';
    this.mailTo = [];

    while (startIndex < this.currentEmail.bodyHtml.length - 1) {
      const startIndexInfo = this.lesserIndex(
        this.currentEmail.bodyHtml,
        [startTarget1, startTarget2, startTarget3],
        startIndex,
      );
      startIndex = startIndexInfo.foundIndex;
      if (startIndex != -1) {
        startIndex += startIndexInfo.targetLength;
        const endIndex = this.currentEmail.bodyHtml.indexOf(endTarget, startIndex);
        if (endIndex != -1) {
          const identifiedEmailStr = this.currentEmail.bodyHtml.substring(startIndex, endIndex).trim();
          const identifiedEmailArr = identifiedEmailStr.split(',');
          startIndex = endIndex;
          identifiedEmailArr.forEach((ie) => this.mailTo.push(ie));
        } else {
          break;
        }
      } else {
        break;
      }
    }
  }

  copyDebug() {
    const el = document.createElement('textarea');
    el.value =
      'CurrentUser: ' +
      this.userEmail +
      '\nSdr: ' +
      this.currentEmail.sdr +
      '\nMessageId: ' +
      this.currentEmail.messageId +
      '\nStorageId: ' +
      this.currentEmail.storageId;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.sdApiService.postSlackMessage(el.value).subscribe(
      (response) => {
        // console.log(response);
      },
      (error) => {
        console.log(error);
      },
    );

    this.snackBar.open('Debug information was copied to clipboard', null, { duration: MODAL_DURATION });
  }

  private compareIndex(a, b) {
    const indexA = a.foundIndex;
    const indexB = b.foundIndex;

    let comparison = 0;
    if (indexA > indexB) {
      comparison = 1;
    } else if (indexA < indexB) {
      comparison = -1;
    }
    return comparison;
  }

  lesserIndex(str: string, targets: string[], startIndex: number) {
    let indexArr = [];
    for (let i = 0; i < targets.length; i++) {
      indexArr[i] = {
        foundIndex: str.indexOf(targets[i], startIndex),
        targetLength: targets[i].length,
      };
    }
    indexArr = indexArr.filter((e) => e.foundIndex != -1);
    indexArr = indexArr.sort(this.compareIndex);

    if (indexArr.length == 0) {
      return {
        foundIndex: -1,
        targetLength: -1,
      };
    } else {
      return indexArr[0];
    }
  }

  compose(action: string) {
    (window as any).reType = action;
    const fromSdrId = this.sdrs.find((sdr) => sdr.email === this.currentEmail.sdr).id;
    this.composerMailService.composeFromExisting(
      action,
      fromSdrId,
      this.currentEmail.from_.email,
      this.currentEmail.from_.name,
      this.currentEmail.to_,
      this.currentEmail.cc_,
      this.currentEmail.imap,
      this.currentEmail.subject,
      new Date(this.currentEmail.date),
      this.currentEmail.bodyHtml,
      this.currentEmail.sdrName,
      this.currentEmail.sdr,
      this.currentEmail.attachments,
      this.currentEmail.storageId,
      this.currentEmail.references,
      this.currentEmail.in_reply_to,
      this.currentEmail.messageId,
    );
    const dialogData = {
      currentFolder: this.selectedFolder.toLowerCase(),
      lead: this.currentEmail.from_.email,
    };

    window.localStorage.setItem('fwdEmailData', JSON.stringify(dialogData));
    const matDialogRef = this.dialog.open(MailComposerComponent, {
      minWidth: '98vw',
      minHeight: '98vh',
      maxHeight: '98vh',
      panelClass: 'mail-composer-container',
      data: dialogData,
      autoFocus: false,
    });
    matDialogRef
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.composerMailService.setIsMailComposerMinimized(true);
      });

    matDialogRef
      .keydownEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.composerMailService.setIsMailComposerMinimized(true);
        }
      });
  }

  openInquiry() {
    const dialogConfig = new MatDialogConfig();
    if (this.currentEmail) {
      const currentSdr = this.sdrs.filter((sdr) => sdr.email == this.currentEmail.sdr)[0];
      dialogConfig.data = {
        client: currentSdr.client,
        sdr: currentSdr,
        email: this.currentEmail.from_.email,
      };
    } else {
      dialogConfig.data = {
        client: '',
        sdr: null,
        email: '',
      };
    }

    dialogConfig.height = '960px';
    dialogConfig.width = '1200px';
    dialogConfig.maxWidth = 'initial';
    dialogConfig.panelClass = 'inquiry-dialog';
    this.dialog.open(InquiryComponent, dialogConfig);
  }

  openFaq() {
    const dialogConfig = new MatDialogConfig();
    if (this.currentEmail) {
      const currentSdr = this.sdrs.filter((sdr) => sdr.email == this.currentEmail.sdr)[0];
      this.faqDataService.loadFaqByClient(currentSdr.clientId).subscribe(
        (response) => {
          if (response.code !== 200) {
            const errorConfirmDialogConfig = new MatDialogConfig();
            errorConfirmDialogConfig.data = {
              title: 'Error',
              message: 'There is no FAQ for this client',
            };
            this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
            return;
          }
          dialogConfig.data = {
            faqTitle: response.data.title,
            faqText: response.data.faq_text,
          };
          dialogConfig.height = '100%';
          if (window.innerWidth > 960) {
            dialogConfig.width = '40%';
            dialogConfig.position = { top: '0%', left: '60%' };
          } else {
            dialogConfig.width = '70%';
            dialogConfig.position = { top: '0%', left: '30%' };
          }

          dialogConfig.maxWidth = 'initial';
          dialogConfig.maxWidth = 'initial';
          dialogConfig.panelClass = 'faq-dialog';

          this.dialog.open(FaqOperatorComponent, dialogConfig);
        },
        (error) => {
          const errorConfirmDialogConfig = new MatDialogConfig();
          errorConfirmDialogConfig.data = {
            title: 'Error',
            message: 'An error ocurred while retrieving the FAQ',
          };
          this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
        },
      );
    }
  }
}
