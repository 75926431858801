<div style="height: 4px">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loading"
  ></mat-progress-bar>
</div>
<div
  id="management-container"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <div id="backLinkDiv">
    <ol
      class="breadcrumb"
      fxLayout="row"
    >
      <li
        class="breadcrumb-item"
        [class.active]="breadcrumbItem.active"
        *ngFor="let breadcrumbItem of breadcrumbItems"
      >
        <a
          *ngIf="!breadcrumbItem.active"
          (click)="navigate(breadcrumbItem.route)"
          class="fixed-width"
          >{{ breadcrumbItem.label }}</a
        >
        <span *ngIf="breadcrumbItem.active">{{ breadcrumbItem.label }}</span>
      </li>
    </ol>
  </div>

  <div
    fxLayout.gt-md="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between start"
    class="content-wrapper"
  >
    <div
      class="menu"
      fxHide.lt-md
    >
      <mat-nav-list style="padding: 0">
        <a
          mat-list-item
          [routerLink]="['/home/management/operator-users']"
          class="menu-item"
          [class.menu-selected]="menuSelected === 'operator-users'"
          id="management-operator-users-anchor"
          >Operator Users</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/users']"
          class="menu-item"
          [class.menu-selected]="menuSelected === 'users'"
          id="management-users-anchor"
          >Users</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/client']"
          class="menu-item"
          [class.menu-selected]="menuSelected === 'client'"
          id="management-client-management-anchor"
          >Client Management</a
        >
        <!-- <a mat-list-item [routerLink]="['/home/management/reassign-operator']" class="menu-item" [class.menu-selected]="menuSelected==='reassign-operator'" >Reassign Operator</a> -->
        <!-- <a mat-list-item [routerLink]="['/home/management/sdr-manage']" class="menu-item" [class.menu-selected]="menuSelected==='sdrs'">Sdrs</a> -->
        <a
          mat-list-item
          [routerLink]="['/home/management/addresses']"
          class="menu-item"
          [class.menu-selected]="menuSelected === 'addresses'"
          id="management-address-suggestions-anchor"
          >Address Suggestions</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/templates/manage']"
          class="menu-item"
          [class.menu-selected]="menuSelected === 'templates'"
          id="management-templates-anchor"
          >Templates</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/contacts/contact-manage']"
          class="menu-item"
          [class.menu-selected]="menuSelected === 'contacts'"
          id="management-sales-rep-anchor"
          >Sales Representatives</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/botsetup']"
          class="menu-item"
          [class.menu-selected]="menuSelected === 'botsetup'"
          id="management-bot-setup-anchor"
          >Bot Setup</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/faq/faq-manage']"
          class="menu-item"
          [class.menu-selected]="menuSelected === 'faq'"
          id="management-faq-anchor"
          >FAQ</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/management/sdrs']"
          class="menu-item"
          [class.menu-selected]="menuSelected === 'sdrs'"
          id="management-sdrs-management-anchor"
          >SDR's Management</a
        >
      </mat-nav-list>
    </div>

    <div
      class="mobile-menu-wrapper"
      fxHide.gt-sm
    >
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a
          mat-menu-item
          [routerLink]="['/home/management/operator-users']"
          class="menu-item"
          id="management-operator-users-anchor"
          >Operator Users</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/users']"
          class="menu-item"
          id="management-users-anchor"
          >Users</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/client']"
          class="menu-item"
          id="management-client-management-anchor"
          >Client Management</a
        >
        <!-- <a mat-menu-item [routerLink]="['/home/management/reassign-operator']" class="menu-item">Reassign Operator</a> -->
        <!-- <a mat-list-item [routerLink]="['/home/management/sdr-manage']" class="menu-item" [class.menu-selected]="menuSelected==='sdrs'">Sdrs</a> -->
        <a
          mat-menu-item
          [routerLink]="['/home/management/addresses']"
          class="menu-item"
          id="management-address-suggestions-anchor"
          >Address Suggestions</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/templates/manage']"
          class="menu-item"
          id="management-templates-anchor"
          >Templates</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/contacts/contact-manage']"
          class="menu-item"
          id="management-sales-rep-anchor"
          >Sales Representatives</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/botsetup']"
          class="menu-item"
          id="management-bot-setup-anchor"
          >Bot Setup</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/faq/faq-manage']"
          class="menu-item"
          id="management-faq-anchor"
          >FAQ</a
        >
        <a
          mat-menu-item
          [routerLink]="['/home/management/sdrs']"
          class="menu-item"
          id="management-sdrs-management-anchor"
          >SDR's Management</a
        >
      </mat-menu>
    </div>

    <div class="list-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
