<div
  id="layoutDiv"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <div id="container">
    <div
      id="titleDiv"
      fxLayout.gt-md="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-between center"
    >
      <span class="pageTitle">Manage Frequently Asked Questions</span>
      <button
        (click)="createFaq()"
        class="btnFaq"
        mat-raised-button
        color="primary"
        id="faq-manager-create-faq-btn"
      >
        CREATE FAQ
      </button>
    </div>

    <div class="faq-table">
      <div class="mat-elevation-z8 shrink">
        <mat-form-field
          class="search-form-field form-field-5"
          appearance="outline"
          subscriptSizing="dynamic"
          id="faq-manager-search-mat-form-field"
        >
          <mat-label>Search</mat-label>
          <input
            matInput
            [(ngModel)]="faqSearchKey"
            autocomplete="off"
            (keyup)="applyFaqFilter()"
            id="faq-manager-search-input"
          />
          <mat-icon
            matSuffix
            *ngIf="faqSearchKey"
            (click)="onFaqSearchClear()"
            id="faq-manager-search-mat-icon-clear"
            >close</mat-icon
          >
        </mat-form-field>
        <mat-table
          [dataSource]="faqListData"
          matSort
          matSortDisableClear
          #faqSort="matSort"
        >
          <ng-container matColumnDef="date">
            <mat-header-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="28"
              *matHeaderCellDef
              mat-sort-header
              >Date</mat-header-cell
            >
            <mat-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="28"
              *matCellDef="let element"
              >{{ element.date | date: 'MM/dd/yyyy' }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="title">
            <mat-header-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="24"
              *matHeaderCellDef
              mat-sort-header
              >Title</mat-header-cell
            >
            <mat-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="24"
              *matCellDef="let element"
              >{{ element.title }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="client">
            <mat-header-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="25"
              *matHeaderCellDef
              mat-sort-header
              >Client</mat-header-cell
            >
            <mat-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="25"
              *matCellDef="let element"
              >{{ element.client }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="25"
              *matHeaderCellDef
              >Actions</mat-header-cell
            >
            <mat-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="25"
              *matCellDef="let element"
              class="center actionsAddress"
            >
              <button
                fxLayout="row"
                fxLayoutAlign="center center"
                class="edit-btn"
                mat-icon-button
                (click)="editFaq(element.id)"
                id="faq-manager-edit-faq-btn"
              >
                <mat-icon class="edit-icon">edit</mat-icon>
              </button>
              <button
                fxLayout="row"
                fxLayoutAlign="center center"
                class="delete-btn"
                mat-icon-button
                (click)="removeFaq(element.id)"
                id="faq-manager-remove-faq-btn"
              >
                <mat-icon class="delete-icon">delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="loading">
            <mat-footer-cell
              *matFooterCellDef
              colspan="2"
            >
              Loading data...
            </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="noData">
            <mat-footer-cell
              *matFooterCellDef
              colspan="2"
            >
              No data.
            </mat-footer-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="faqDisplayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: faqDisplayedColumns"></mat-row>
          <mat-footer-row
            *matFooterRowDef="['loading']"
            [ngClass]="{ hide: faqListData !== null }"
          ></mat-footer-row>
          <mat-footer-row
            *matFooterRowDef="['noData']"
            [ngClass]="{ hide: !(faqListData !== null && faqListData.data.length === 0) }"
          ></mat-footer-row>
        </mat-table>
        <mat-paginator
          class="paginator-table"
          [length]="resultsLength"
          [pageSize]="10"
          showFirstLastButtons
          #faqPaginator="matPaginator"
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
