<div
  fxLayout="column"
  class="mails-feed-comp"
>
  <div
    fxLayout.gt-md="row"
    fxLayout.lt-md="column"
    fxLayoutAlign.gt-md="space-between center"
    fxLayoutAlign.lt-md="start start"
    class="new-email-wrapper"
  >
    <div>
      <button
        class="new-email-btn"
        mat-raised-button
        color="primary"
        (click)="compose()"
        *ngIf="isSender"
        [disabled]="isComposerOpen"
        id="new-mail-btn"
      >
        <mat-icon>add</mat-icon> New Mail
      </button>
      <button
        mat-raised-button
        id="reload-btn"
        (click)="reload()"
      >
        Refresh
      </button>
    </div>
    <div ngClass.lt-lg="bottom-actions">
      <button
        mat-stroked-button
        (click)="openBlockedSdrs()"
        [matBadge]="blockedSdrsCount"
        [matBadgeHidden]="!blockedSdrsCount"
        id="blocked-sdsr-btn"
      >
        <mat-icon>do_not_disturb_on</mat-icon>
      </button>
      <button
        mat-stroked-button
        (click)="clearNotifications()"
        id="clear-notifications-btn"
      >
        <mat-icon>notification_important</mat-icon>
      </button>
    </div>
  </div>

  <div class="filters-wrapper">
    <div class="multiselect-wrapper">
      <app-multiselect-autocomplete
        #clientsMulti
        class="multi-autocomplete form-field-4"
        appearance="outline"
        placeholder="Type or select clients"
        key="options"
        [data]="clients"
        [selectedValues]="selectedClientsAutocomplete"
        (resultEvt)="selectClientChange($event)"
        [id]="'clients'"
      >
      </app-multiselect-autocomplete>
    </div>
    <div class="actions-wrapper">
      <a
        (click)="selectAllClients()"
        id="select-all-clients-anchor"
      >
        All
      </a>
      <a
        (click)="clearAllClients()"
        id="clear-all-clients-anchor"
      >
        Clear
      </a>
    </div>
  </div>

  <div class="filters-wrapper">
    <div class="multiselect-wrapper">
      <app-multiselect-autocomplete
        #sdrsMulti
        class="multi-autocomplete form-field-4"
        appearance="outline"
        placeholder="Type or select SDRs"
        key="options"
        [data]="clientSdrsStr"
        [selectedValues]="selectedSdrsAutocomplete"
        (resultEvt)="selectSdrChange($event)"
        [id]="'sdrs'"
      >
      </app-multiselect-autocomplete>
    </div>
    <div class="actions-wrapper">
      <a
        (click)="selectAllSDRs()"
        id="select-all-sdrs-anchor"
      >
        All
      </a>
      <a
        (click)="clearAllSDRs()"
        id="clear-all-sdrs-anchor"
      >
        Clear
      </a>
    </div>
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="space-between start"
    class="sort-filter-wrapper"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxFlex="20%">
        <button
          mat-stroked-button
          [matMenuTriggerFor]="menu"
          id="sort-mails-btn"
        >
          <mat-icon style="color: #686868">sort</mat-icon>
        </button>
        <mat-menu
          #menu="matMenu"
          class="feed-sort-menu"
          id="sort-mail-menu"
        >
          <button
            mat-menu-item
            (click)="toggleOrder('asc')"
            [ngStyle]="{ 'font-weight': sortByOldest ? 'bold' : 'normal' }"
          >
            <span>Oldest first</span>
          </button>
          <button
            mat-menu-item
            (click)="toggleOrder('desc')"
            [ngStyle]="{ 'font-weight': !sortByOldest ? 'bold' : 'normal' }"
          >
            <span>Newest first</span>
          </button>
        </mat-menu>
      </div>
      <div fxFlex="60%">
        <mat-form-field
          class="folder-picker form-field-4"
          appearance="outline"
          id="folder-mat-form-field"
        >
          <mat-select
            (selectionChange)="updateFolder()"
            [(value)]="this.selectedFolder"
            id="folder-mat-select"
          >
            <mat-option
              *ngFor="let folder of filteredFolderOptions | async; let i = index"
              [value]="folder"
              [id]="'folder-mat-option-' + i"
            >
              {{ folder | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="20%">
        <span
          class="mails-count"
          [ngPlural]="feed.length"
        >
          <ng-template ngPluralCase="=1">{{ feed.length }} mail</ng-template>
          <ng-template ngPluralCase="other">{{ feed.length }} mails</ng-template>
        </span>
      </div>
    </div>
    <div
      class="pagination-wrapper"
      fxLayoutAlign="center center"
      *ngIf="selectedFolder === 'Sent'"
    >
      <button
        mat-icon-button
        [disabled]="currentPage === 0 || this.isLoading"
        (click)="previous()"
        id="previous-page-btn"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span style="margin-left: 5px">{{ currentPage + 1 }}</span>
      <button
        mat-icon-button
        [disabled]="this.isLastPage || this.isLoading"
        style="margin-left: 5px"
        (click)="next()"
        id="next-page-btn"
      >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>

  <div style="overflow-y: scroll; height: calc(100vh - 280px)">
    <ng-container *ngIf="isFeedLoading; else EmailList">
      <app-mail-feed-skeleton-loader *ngFor="let item of [1, 2, 3, 4, 5, 6, 7]"></app-mail-feed-skeleton-loader>
    </ng-container>
    <ng-template #EmailList>
      <app-mail-feed-item
        *ngFor="let email of feed"
        [email]="email"
        [selectedMailId]="selectedMailId"
      ></app-mail-feed-item>
    </ng-template>
  </div>
</div>
