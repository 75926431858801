<div id="layoutDiv">
  <div></div>
  <div id="container">
    <div id="titleDiv"><span class="pageTitle">Sdr Management</span></div>
    
    <div id="AvailableProvidersDiv" fxLayout="column" fxLayoutAlign="center start" >
      <div fxLayout="row" fxLayoutAlign="start center" class="vertical-margin" >
        <span style="margin-right:10px;">Provider:</span>
        <mat-form-field appearance="fill" class="providersDropdown" fxFill>
          <mat-label>Choose a provider</mat-label>
          <mat-select  name="providersDropdown" [(ngModel)]="selectedProvider" #providerSelect panelClass="providersDropdown">
              <mat-option [value]="provider" *ngFor="let provider of providers">
                  {{provider}}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      
      <div fxLayout="row" fxLayoutAlign="start center" class="vertical-margin">
        <span style="margin-right:30px;">Email:</span>
        <mat-form-field class="domain-set-form-field" floatLabel="never" style="width:292px">
          <input matInput [(ngModel)]="domain" placeholder="Email" #domainElement="ngModel">
          <button mat-button matSuffix mat-icon-button aria-label="Clear"*ngIf="domain" (click)="onDomainClear()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      
      

     
      <div id="fileDiv" fxLayout="row" fxLayoutAlign="start center" style="margin-top:5px">
        <div class="attachment">
          <label for="inputFile">
            <div class="actionPointer" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>attachment</mat-icon>
              <span class="attachment-text">Configuration File:</span>
            </div>
          </label>
          <input type="file" id="inputFile" (change)="attach($event.target.files)" #inputFileControl>
        </div>
      
        <div *ngIf="attachedFile" class="attachment" style="padding-top:5.6px" fxFlex="row" fxFlexFill fxLayoutAlign="start start">
          <div *ngIf="attachedFile.isUploading" fxLayout="row wrap" fxLayoutAlign="start center">
            {{attachedFile.filename}}
            <mat-spinner [diameter]="20" style="margin-left: 10px; margin-right: 5px;"></mat-spinner>
          </div>
          
          <a class="actionPointer" (click)="fileUploadService.downloadLocalFile(attachedFile.physicalFile,attachedFile.filename)" *ngIf="!attachedFile.isUploading">
            <span>{{attachedFile.filename}}</span>
            
          </a>
          <a class="actionPointer" (click)="deleteAttachment()"
          *ngIf="!attachedFile.isUploading" style="margin-right: 5px;">
            <mat-icon style="font-size: 18.5px;">delete</mat-icon>
          </a>
        </div>
      </div>
      

      <button mat-raised-button (click)="uploadConfiguration();" [disabled]="!providerSelect.selected || !domain || !attachedFile">
        <mat-icon style="margin-right:5px;">cloud_upload</mat-icon>Upload Configuration
      </button>

    </div>

    
  </div>
</div>
