import { Component, OnInit } from '@angular/core';
import { SdApiService } from '../../../../services/base/sd-api.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from '../../../../components/alert-dialog/alert-dialog.component';
import { LoadingStateService } from '../../../../services/base/loading-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

const MODAL_DURATION = 3000;

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent implements OnInit {
  constructor(
    private service: SdApiService,
    private dialog: MatDialog,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar,
    private location: Location,
  ) {}

  loading = false;
  userEmail = '';
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  passwordPattern =
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#=+$@$!%*?&§\\[\\]\\{\\}"\'\\(\\)\\^_\\-\\~\\|\\`\\<\\>\\,\\.\\;¨])[A-Za-zd#=+$@$!%*?&§\\[\\]\\{\\}"\'\\(\\)\\^_\\-\\~\\|\\`\\<\\>\\,\\.\\;¨].{8,15}$';
  userPassword = '';

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe((loading) => {
      this.loading = loading;
    });
    this.loadingStateService.clearLoadingState();
  }

  onUserClear() {
    this.userEmail = '';
  }

  createUser() {
    if (this.userEmail != '') {
      const confirmDialogConfig = new MatDialogConfig();
      confirmDialogConfig.data = {
        title: 'Confirm',
        message: 'Are you sure you want to create this user?',
      };
      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
      confirmDialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.loadingStateService.setLoadingState(true);
          this.service.createUser(this.userEmail, this.userPassword).subscribe(
            (response) => {
              this.loadingStateService.setLoadingState(false);
              if (response.code === 200) {
                this.snackBar.open('New user ' + this.userEmail + ' was created successfully', 'success', {
                  duration: MODAL_DURATION,
                });
                this.location.back();
              } else {
                this.handleOperationError(response.message || 'An error occurred while creating user.');
              }
              this.userEmail = '';
            },
            (error) => {
              console.log(error);
              this.handleOperationError('An error ocurred while creating the user');
              this.userEmail = '';
            },
          );
        } else {
          this.userEmail = '';
        }
      });
    }
  }

  handleOperationError(message: string) {
    this.loadingStateService.setLoadingState(false);
    const errorConfirmDialogConfig = new MatDialogConfig();
    errorConfirmDialogConfig.data = {
      title: 'Error',
      message,
    };
    this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
  }

  onPasswordClear() {
    this.userPassword = '';
  }
}
