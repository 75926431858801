export class SalesRepresentative {
    name: string;
    profileCC: string;
    geographicZone: string;

    constructor(data?: any) {
      if (!data)
        return;
      this.name = data.name;
      this.profileCC = data.profileCC;
      this.geographicZone = data.geographicZone;
    }
}
