<div class="modal-header">
  <h2>{{'Bulk'}} - Set Level</h2>
</div>
<div class="modal-content">
  <div class="mat-elevation-z1">
    <mat-table [dataSource]="operatorLevelsDataSource">
      <ng-container matColumnDef="level">
        <mat-header-cell fxLayoutAlign="start center" fxFlex="90" *matHeaderCellDef>Level</mat-header-cell>
        <mat-cell  fxLayoutAlign="start center" fxFlex="90" *matCellDef="let element">
          <div class="levelRow">
            <span class="label">{{element.name}}</span>
            <span>{{element.description}}</span>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="select">
        <mat-header-cell fxLayoutAlign="start center" fxFlex="10" *matHeaderCellDef>Select</mat-header-cell>
        <mat-cell  fxLayoutAlign="start center" fxFlex="10" *matCellDef="let element">
          <mat-radio-button [checked]="selectedLevel === element.id" (change)="handleLevelChangeEvent(element.id)"></mat-radio-button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedOperatorLevelsColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedOperatorLevelsColumns"></mat-row>
    </mat-table>
  </div>
</div>
<div class="modal-actions">
    <button mat-button class="dismiss-button" (click)="onDismiss();">Cancel</button>
    <button class="confirm-button" mat-button (click)="onConfirm();" cdkFocusInitial [disabled]="!selectedLevel">Save</button>
</div>