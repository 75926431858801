import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { SdApiService } from './base/sd-api.service';
import { HttpClient } from '@angular/common/http';

export const TOKEN_KEY =  'idtoken';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiBackEnd!: string;

  constructor(
    private sdApiService: SdApiService,
    private http: HttpClient
  ) {
		this.apiBackEnd = environment.apiBaseURL;
	}

  isUserAdmin() {
    return this.sdApiService.loadUser()
      .map(u => u.permissions.filter(p => p.name == "manager").length > 0);
    // return of(false);
  }

  isUserSender() {
    return this.sdApiService.loadUser()
      .map(u => u.permissions.filter(p => p.name == "sender").length > 0);
    // return of(false);
  }

  hasPermission(permission: string) {
    return this.sdApiService.loadUser()
      .map(u => u.permissions.filter(p => p.name == permission).length > 0);
  }

}
