<div style="padding: 5px; border-radius: 100px; border: 1px solid #c8c8c8" [matMenuTriggerFor]="colorMenu">
  <div style="width: 20px; height: 20px; border-radius: 100px; cursor: pointer"
       [ngStyle]="{ background: selectedColor }"
  ></div>
</div>

<mat-menu #colorMenu="matMenu">
  <button mat-menu-item>
    <span style="width: 30px; height: 30px; border-radius: 100px; background: #15fa86; display: block"></span>
  </button>
  <button mat-menu-item>
    <span style="width: 30px; height: 30px; border-radius: 100px; background: red; display: block"></span>
  </button>
  <button mat-menu-item>
    <span style="width: 30px; height: 30px; border-radius: 100px; background: yellow; display: block"></span>
  </button>
  <button mat-menu-item>
    <span style="width: 30px; height: 30px; border-radius: 100px; background: gray; display: block"></span>
  </button>
</mat-menu>
