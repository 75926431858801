
import { Component, OnInit } from '@angular/core';
import { OperatorService } from './../../../../services/operator.service.';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { IOperatorUserCreatePayload } from '../../../../model/Operator';

import { HttpStatusCode } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MODAL_DURATION } from '../../../../model/Utils';

@Component({
  selector: 'app-operator-user-add',
  templateUrl: './operator-user-add.component.html',
  styleUrls: ['./operator-user-add.component.scss']
})
export class OperatorUserAddComponent implements OnInit{

  public operatorUserForm: FormGroup = new FormGroup({});

  constructor(
    private readonly operatorService: OperatorService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
  ) { }

  public ngOnInit(): void {}

  public cancel():void {
    this.navigateToParentPage();
  }

  public save(): void {
    if(this.operatorUserForm.valid){
      this.createOperatorUser();
    }
  }

  private createOperatorUser(): void {
    const formValue = this.operatorUserForm.value as unknown as IOperatorUserCreatePayload;
    this.operatorService.createOperatorUser(formValue).pipe(take(1)).subscribe(res => {
      if(res.code === HttpStatusCode.Ok){
        this.showToast('operator was successfully created');
        this.navigateToParentPage();
      } else {
        this.showToast(`Failed to create operator: ${res.message}`);
      }
    }, () => {
      this.showToast(`Failed to create operator`);
    })
  }

  private navigateToParentPage(): void {
    this.router.navigate(['home/management/operator-users']);
  }

  private showToast(message: string): void {
    this.snackBar.open(
      message,
      null,
      { 
        duration: MODAL_DURATION,
      }
    );
  }
}
