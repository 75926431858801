
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { IOperatorUser, IOperatorUserToggleActivePayload, OperatorUserManagementTabs, ToggleOperatorUserActions } from '../../../model/Operator';
import { OperatorService } from './../../../services/operator.service.';
import { take } from 'rxjs/operators';
import { HttpStatusCode } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { OperatorUserActiveToggleComponent } from './operator-user-active-toggle/operator-user-active-toggle.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MODAL_DURATION } from '../../../model/Utils';
import { TableActionsComponent } from '../../../components/table-actions/table-actions.component';

@Component({
  selector: 'app-operator-user-management',
  templateUrl: './operator-user-container.component.html',
  styleUrls: ['./operator-user-container.component.scss']
})
export class OperatorUserManagementContainerComponent implements OnInit{
  @ViewChild(MatSort, {static: false}) public userSort: MatSort;
  @ViewChild('activePaginator', {static: false}) public activeUserPaginator: MatPaginator;
  @ViewChild('inactivePaginator', {static: false}) public inactiveUserPaginator: MatPaginator;
  @ViewChild('activeTableActions', {static: false}) public activeTableActions: TableActionsComponent;
  @ViewChild('inactiveTableActions', {static: false}) public inactiveTableActions: TableActionsComponent;

  public OperatorUserManagementTabs = OperatorUserManagementTabs
  public currentTab$: BehaviorSubject<OperatorUserManagementTabs> = new BehaviorSubject<OperatorUserManagementTabs>(OperatorUserManagementTabs.ACTIVE);
  public disableSelectAllAction$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public userDataSource: MatTableDataSource<IOperatorUser> = new MatTableDataSource(null);
  public displayedUserColumns: string[] = ['select', 'name','email','last_activity', 'actions'];
  public selectedUserIds: number[] = [];
  public userHasError = false;
  public selectedAllUsers$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly operatorService: OperatorService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.getUsers(true);
  }

  public handleSelect(
    selected: boolean,
    entityId: number
  ): void {
    if(selected){
      this.selectedUserIds.push(entityId);
    } else {
      this.selectedUserIds = this.selectedUserIds.filter(userId => userId !== entityId);
    }

    if(!this.selectedUserIds.length){
      this.selectedAllUsers$.next(false);
      return
    }

    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const avaliableUsers = this.userDataSource.filteredData.slice(startIndex, startIndex + this.paginator.pageSize);
    this.selectedAllUsers$.next(avaliableUsers.every(user => this.selectedUserIds.includes(user.id)));
  }

  public handleSelectAllEvent(selectAll: boolean): void{
    if(selectAll){
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const avaliableUsers = this.userDataSource.filteredData.slice(startIndex, startIndex + this.paginator.pageSize);
      const usersToAdd = avaliableUsers.filter(user => !this.selectedUserIds.includes(user.id)).map(user => user.id);

      this.selectedUserIds.push(...usersToAdd);
    } else {
      this.selectedUserIds.splice(0);
    }

    this.selectedAllUsers$.next(selectAll);
  }

  public checkIfRowIsSelected(userId: number): boolean {
    return this.selectedUserIds.includes(userId);
  }

  public handleFilterUserInputEvent(filter: string): void {
    this.applyUserFilter(filter);
    this.handleSelectAllEvent(false);
  }

  public handleTabChange(selectedTabIndex: number): void {
    this.currentTab$.next(selectedTabIndex === 0 ? OperatorUserManagementTabs.ACTIVE : OperatorUserManagementTabs.INACTIVE);
    this.getUsers(selectedTabIndex === 0);
    this.resetTablesSelectedState();
    this.activeTableActions.resetFilterField();
    this.inactiveTableActions.resetFilterField();
  }

  public handleCreateUser(): void {
    this.router.navigate(['home/management/operator-users/add']);
  }

  public handleEditClick(operatorUser: IOperatorUser): void {
    this.router.navigate([`home/management/operator-users/edit/${operatorUser.email}`]);
  }

  public openOperatorUserActiveTogleModal(selectedOperatorUser?: IOperatorUser): void {
    const selectedOperatorsUsers = selectedOperatorUser ? [selectedOperatorUser] : this.getSelectedOperatorUsers();
    const dialogRef = this.dialog.open(OperatorUserActiveToggleComponent, {
      width: '50%',
      data: {
        operatorsUsers: selectedOperatorsUsers,
        action: this.currentTab$.value === OperatorUserManagementTabs.ACTIVE ? ToggleOperatorUserActions.DISABLE : ToggleOperatorUserActions.ENABLE,
      },
      panelClass: 'wd-modal'
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe((res: IOperatorUserToggleActivePayload) => {
      if(!!res){
        this.toggleOperatorUsersActiveStatus(res)
      }
    })
  }

  private getSelectedOperatorUsers(): IOperatorUser[] {
    return this.userDataSource.data.filter(user => this.selectedUserIds.includes(user.id));
  }

  private toggleOperatorUsersActiveStatus(payload: IOperatorUserToggleActivePayload): void {
    const isInactivate = this.currentTab$.value === OperatorUserManagementTabs.ACTIVE;
    this.disableSelectAllAction$.next(true);
    this.operatorService.toggleOperatorsUsersActiveStatus(payload).pipe(take(1)).subscribe(res => {
      if(res.code === HttpStatusCode.NoContent) {
        const message = isInactivate ? 
          `The ${payload.usernames.length === 1 ? 'operator is' : 'operators are'} inactive` : 
          `The ${payload.usernames.length === 1 ? 'operator was' : 'operators were'} restored`;
        this.showToast(message);
        this.getUsers(this.currentTab$.value === OperatorUserManagementTabs.ACTIVE);
      } else {
        this.showToast(`Failed to ${isInactivate ? 'inactivate' : 'restore'} operator`);
      }
    }, () => {
      this.showToast(`Failed to ${isInactivate ? 'inactivate' : 'restore'} operator`);
    })
  }

  private showToast(message: string): void {
    this.snackBar.open(
      message,
      null,
      { 
        duration: MODAL_DURATION,
      }
    );
  }

  private resetTablesSelectedState(): void {
    this.selectedUserIds = [];
    this.selectedAllUsers$.next(false);
    this.userDataSource = new MatTableDataSource(null);
  }

  private applyUserFilter(filterInput: string): void {
    this.userDataSource.filter = filterInput.trim().toLowerCase();
  }

  private getUsers(isActive: boolean): void {
    this.operatorService.getAllUsers(isActive).pipe(take(1)).subscribe(res => {
      if(Number(res.code) === HttpStatusCode.Ok && res.data) {
        this.disableSelectAllAction$.next(false);
        this.userHasError = false;
        this.createUserDataSource(res.data);
      } else {
        this.userHasError = true;
      }
    }, () => {
      this.userHasError = true;
    })
  }

  private createUserDataSource(userList: IOperatorUser[]): void {
    this.resetTablesSelectedState();
    this.userDataSource = new MatTableDataSource(userList);
    this.userDataSource.sort = this.userSort;
    this.userDataSource.paginator = this.paginator;

    this.userDataSource.filterPredicate = (data, filter) => {
      return (data.name && data.name.toLowerCase().indexOf(filter) !== -1) || (data.email && data.email.toLowerCase().indexOf(filter) !== -1);
    }
  }

  private get paginator(): MatPaginator {
    return this.currentTab$.value === OperatorUserManagementTabs.ACTIVE ? this.activeUserPaginator : this.inactiveUserPaginator;
  }
}
