export interface FaqData {
    id: number;
    date: string;
    title: string;
    client: string;
    faqText: string;
  }

export class Faq implements FaqData {
  id: number;
  date: string;
  title: string;
  client: string;
  faqText: string;

  constructor(faq) {
    this.id = faq.id;
    this.date = faq.date;
    this.title = faq.title;
    this.client = faq.client;
    this.faqText = faq.faqText;
  }
}

export interface FaqApiResponse {
  items: Faq[];
  total_count: number;
}
