import { Injectable } from '@angular/core';
import {SdApiService} from './base/sd-api.service';
import {BehaviorSubject, Observable, merge, timer, of, Subject} from 'rxjs';
import {catchError, concatMap, delay, switchMap, repeatWhen} from 'rxjs/operators';
import {LoadingStateService} from './base/loading-state.service';
import {PRODUCT_TAG} from '../model/Config';
import {Notification} from '../model/Notification';
import { NotificationType } from '../model/NotificationType';
import { WebsocketService } from "./websocket.service";
// import { SelectedMailService } from "./selected-mail.service";
import { NotificationCategory } from '../model/NotificationCategory';
import {environment} from '../../environments/environment';
import { SDAuthService } from './sd-auth.service';
import { NotificationDismissType } from '../model/NotificationDismissType';

const REFRESH_FREQUENCY = 10 * 1000;

const CLASSIFY_AUTODIMISS_LIFETIME = 10 * 1000;
const BLOCKED_SDR_AUTODIMISS_LIFETIME = 30 * 1000;
const SEND_AUTODIMISS_LIFETIME = 10 * 1000;
const AUTODIMISS_LIFETIME = 10 * 1000;
// const NOTIFICATIONS_URL = "wss://00etvu2z8h.execute-api.us-west-2.amazonaws.com/dev?name=carlosnava";

// const NOTIFICATIONS_URL = "wss://echo.websocket.org";

export interface Message {
  data?: Notification;
  action?: string;
  message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private sdApiService: SdApiService,
              private loadingState: LoadingStateService,
              private wsService: WebsocketService,
              private authService: SDAuthService,
    ) {
      sdApiService.userSDRs.subscribe((sdrs) => {
        this.clientSdrs = sdrs;
      });

      sdApiService.userBlockedSdrs.subscribe((blockedSdrs) => {
        this.blockedSdrs = blockedSdrs;
      });


      // this.sdApiService.getAttachmentParameters().subscribe(
      //   data => {
      //     // this.undoSendTime=parseInt(data.parameters.SEND_UNDO_TIME);
      //     this.undoSendTime=20;
      //   });
      this.undoSendTime = 10;
  }

  get feed() {
    return this.notificationsFeed.asObservable();
  }

  public messages: Subject<Message>;

  public notificationsFeed = new BehaviorSubject<Notification[]>([]);

  private currentNotifications: Notification[] = [];

  private connected: boolean;

  private shouldReconnect: boolean;

  private firstConnection: boolean;

  private userEmail = "";

  private notificationsUrl = "";

  private clientSdrs = [];

  private blockedSdrs = [];

  public undoSendTime = SEND_AUTODIMISS_LIFETIME;

  private initialMessage =   {
    action : "sendmsg",
    message : "Notifications"
  };

  private keepAliveMessage =   {
    action : "keepalive"
  };


  private connectionCheckScheduled: boolean;

  // Ensures they are unique in current count
  private innerCurrent = 0;

    initialize(restart:boolean) {
      if(!restart) {
        this.currentNotifications = [];
        this.notificationsFeed.next([]);
      }

      // this.connect();

      this.authService.getCurrentUserEmail().then(
        email => {
          this.userEmail = email;
          this.notificationsUrl = environment.notificationsSocketURL + "?name=" + this.userEmail;

          this.connectToSocket();
          this.shouldReconnect = false;
          this.connectionCheckScheduled = false;
          of(null)
            .pipe(
              delay(5 * 1000)
            )
          .subscribe(x => {
              this.writeLogEntry("Sending initial message");
              this.messages.next(this.initialMessage);
          });

          of(null)
            .pipe(
              delay(30 * 1000)
            )
          .subscribe(x => {
            this.writeLogEntry("Initial delay for keep alive, disc setup");
            // Keep alive Message and disc state
            of(null)
            .pipe(
              repeatWhen(x => x.pipe(delay(2 * 60 * 1000)))
              // repeatWhen(x => x.pipe(delay(5 * 1000)))
            )
            .subscribe(x => {
              // Set connection to disc state, will be undone by any msg or keep alive callback
              this.writeLogEntry("Setting to reconnect");
              this.shouldReconnect = true;
              this.writeLogEntry("Sending keep alive message");
              this.messages.next(this.keepAliveMessage);
              if (!this.connectionCheckScheduled) {
                of(null)
                  .pipe(
                    delay(30 * 1000)
                  )
                .subscribe(x => {
                    // Check connection
                    of(null)
                    .pipe(
                      repeatWhen(x => x.pipe(delay(2 * 60 * 1000)))
                      // repeatWhen(x => x.pipe(delay(5 * 1000)))
                    )
                  .subscribe(x => {
                    this.writeLogEntry("Checking connection");
                    if (this.shouldReconnect) {
                      this.reconnect();
                    }
                    this.shouldReconnect = false;
                  });
                });
              }
              this.connectionCheckScheduled = true;
            });
          });

          this.firstConnection = true;

          // Force reconnect
          of(null)
            .pipe(
              repeatWhen(x => x.pipe(delay(1 * 60 * 60 * 1000)))
              // repeatWhen(x => x.pipe(delay(60 * 1000)))
            )
          .subscribe(x => {
            if (!this.firstConnection) {
              this.writeLogEntry("Force reconnect");
              this.reconnect();
            }
            this.firstConnection = false;
            this.shouldReconnect = false;
          });
      });
    }

  connectToSocket() {
    this.wsService.connect(this.notificationsUrl);

    this.messages = this.wsService.subject.map(
      (response: MessageEvent): any => {
        const data = JSON.parse(response.data);
        return data ;
      }
    ) as Subject<Message>;

    this.messages.subscribe(msg => {
      // console.log(msg);
      this.shouldReconnect = false;
      if (msg.data) {
        const socketNotification = msg.data;
        socketNotification.time = new Date(socketNotification.created_dt + '.000Z');
        this.addNotificationToFeed(socketNotification);
      }

    });
  }

  reconnect() {
    this.writeLogEntry("Disconnecting...");
    this.wsService.disconnect();

    if (this.wsService.ws.readyState === WebSocket.OPEN) {
      this.writeLogEntry("Its connected!!");
    } else {
      this.writeLogEntry("Not connected!!");
    }


    this.writeLogEntry("Reconnecting...");
    this.connectToSocket();

    if (this.wsService.ws.readyState === WebSocket.OPEN) {
      this.writeLogEntry("Its connected!!");
    } else {
      this.writeLogEntry("Not connected!!");
    }

    of(null)
      .pipe(
        delay(5 * 1000)
      )
    .subscribe(x => {
      this.writeLogEntry("Hold...");
      if (this.wsService.ws.readyState === WebSocket.OPEN) {
        this.writeLogEntry("Its connected!!");
      } else {
        this.writeLogEntry("Not connected!!");
      }

      this.writeLogEntry("Sending initial message");
      this.messages.next(this.initialMessage);
    });
  }

  addNotificationToFeed(notification: Notification) {
    if (!this.currentNotifications.some(n => n.id == notification.id)) {
      this.innerCurrent++;
      notification.uiId = notification.id + this.innerCurrent.toString();
      this.currentNotifications.unshift(notification);
      this.notificationsFeed.next(this.currentNotifications);
      let dissmissTime;
      switch (notification.category) {
        case NotificationCategory.Classify:
          dissmissTime = CLASSIFY_AUTODIMISS_LIFETIME;
          break;
        case NotificationCategory.Send:
          dissmissTime = this.undoSendTime  * 1000;
          break;
        case NotificationCategory.BlockedSDR:
          dissmissTime = BLOCKED_SDR_AUTODIMISS_LIFETIME;
          // // Update sdrs to flag blocked sdr.
          // let blocked = !notification.message.includes("unblocked");
          // let isReported = this.blockedSdrs.some(blockedSdr => blockedSdr === notification.placeholderInfo2);
          // if(blocked && !isReported) {
          //   this.blockedSdrs.push(notification.placeholderInfo2);
          //   this.sdApiService.blockedSdrs.next(this.blockedSdrs);
          // }
          // if(!blocked && isReported) {
          //   this.blockedSdrs = this.blockedSdrs.filter(blockedSdr => blockedSdr !== notification.placeholderInfo2);
          //   this.sdApiService.blockedSdrs.next(this.blockedSdrs);
          // }
          break;
        default:
          dissmissTime = AUTODIMISS_LIFETIME;
          break;
      }
      // console.log('Adding notification ' + notification.message + ' ' + new Date().toString);
      // console.log(this.innerCurrent);
      if (notification.dismissType == NotificationDismissType.AutoDismiss) {
        of(null)
        .pipe(
          delay(dissmissTime)
        )
      .subscribe(x => {

        if (this.currentNotifications.some(n => n.uiId === notification.uiId)) {
          // console.log('Removing notification ' + notification.message + ' ' + new Date().toString);
          this.removeNotificationFromFeed(notification);
        }

        });
      }
    }
  }

  setActionToClassifyNotification(notificationId, actionId) {
    if (this.currentNotifications.some(notification => notification.id == notificationId)) {
      const notificationToUpdate = this.currentNotifications.find(notification => notification.id == notificationId);
      notificationToUpdate.undoClassifyActionObject.actionId = actionId;
      notificationToUpdate.undoActionReady = true;
      this.notificationsFeed.next(this.currentNotifications);
    }
  }

  removeNotification(notificationId) {
    if (this.currentNotifications.some(notification => notification.id == notificationId)) {
      const notificationToRemove = this.currentNotifications.find(notification => notification.id == notificationId);
      this.removeNotificationFromFeed(notificationToRemove);
    }
  }

  setActionToSendNotification(notificationId, actionId) {
    if (this.currentNotifications.some(notification => notification.id == notificationId)) {
      const notificationToUpdate = this.currentNotifications.find(notification => notification.id == notificationId);
      notificationToUpdate.undoSendActionObject.actionId = actionId;
      notificationToUpdate.undoSendReady = true;
      this.notificationsFeed.next(this.currentNotifications);
    }
  }

  notificationExists(notificationId) {
    return this.currentNotifications.some(notification => notification.id == notificationId);
  }

  removeNotificationFromFeed(notification: Notification) {
    this.cleanNotification(notification);
    const prevNotif = this.currentNotifications.length;

    const newList = [];
    this.currentNotifications.forEach((item) => {
      if (item.uiId !== notification.uiId)
        newList.push(item);
    });
    const newNotif = newList.length;
    if (prevNotif - newNotif > 1) {
      // console.log('Removed more than 1!!');
    }
    this.currentNotifications = newList;
    this.notificationsFeed.next(newList);
  }

  removeAllNotifications() {
    this.currentNotifications.forEach(n => {
      this.cleanNotification(n);
    });
    this.currentNotifications = [];
    this.notificationsFeed.next([]);
  }

  private cleanNotification(notification: Notification) {
    if (notification.dismissType != NotificationDismissType.NoDismissable) {
      const dismissMessage =  {action : "dismiss", message: notification.id};
      this.writeLogEntry("Notify dismiss");
      this.messages.next(dismissMessage);
    }
    if (notification.category == NotificationCategory.Classify && notification.type == NotificationType.Success) {
      // this.selectedMailService.clearAction(notification.undoClassifyActionObject);
    }
  }

  connect() {
    this.connected = true;
    const successSendClipboardInfoSample = `
      type:Success \n
      time:"12:20 PM" \n
      message:"Message sent successfully" \n
      subject:"Not Interested" \n
      sdr:"sdr@bairesdev.org" \n
      to:"destination@gmail.com" \n
      cc:"someone1@gmail.com, someone2@gmail.com" \n
    `;
    const successSendSample = new Notification(
      {
        id: "successSendSample",
        type: NotificationType.Success,
        category: NotificationCategory.Send,
        time: "12:20 PM",
        message: "Message sent successfully",
        placeholderInfo1: "Not Interested",
        placeholderInfo2: "sdr@bairesdev.org",
        undoSendAction: true,
        undoClassifyAction: false,
        undoActionReady: false,
        undoSendReady: false,
        debugAction: true,
        debugClipboardInfo: successSendClipboardInfoSample,
        undoClassifySnackbarMessage: "",
        undoSendSnackbarMessage: "Send message action reverted successfully",
        undoClassifyActionObject: null,
        dismissType: NotificationDismissType.AutoDismiss,
        created_dt: new Date().toString()
      }
    );
    const errorClassifyClipboardInfoSample = `
      type:Error \n
      time:"12:30 PM" \n
      message:"Error sending message" \n
      subject:"Not Interested" \n
      sdr:"sdr@bairesdev.org" \n
      to:"destination@gmail.com" \n
      cc:"someone1@gmail.com, someone2@gmail.com" \n
    `;
    const errorSendSample = new Notification(
      {
        id: "errorSendSample",
        type: NotificationType.Error,
        category: NotificationCategory.Send,
        time: "12:30 PM", // Change field, get time...
        message: "Error sending message",
        placeholderInfo1: "Not Interested",
        placeholderInfo2: "sdr@bairesdev.org",
        undoSendAction: false,
        undoActionReady: false,
        undoSendReady: false,
        undoClassifyAction: false,
        debugAction: true,
        debugClipboardInfo: errorClassifyClipboardInfoSample,
        undoClassifySnackbarMessage: "",
        undoSendSnackbarMessage: "",
        undoClassifyActionObject: null,
        dismissType: NotificationDismissType.NoDismissable,
        created_dt: new Date().toString()
      }
    );
    const accountBlockClipboardInfoSample = `
      type:Warning \n
      time:"12:40 PM" \n
      message:"Can not access account" \n
      sdr:"sdr@bairesdev.org" \n
      imap:"gmail" \n
    `;
    const accountBlockSample = new Notification(
      {
        id: "accountBlockSample",
        type: NotificationType.Warning,
        category: NotificationCategory.BlockedSDR, //
        time: "12:40 PM",
        message: "Can not access account",
        placeholderInfo1: "gmail",
        placeholderInfo2: "sdr@bairesdev.org",
        undoSendAction: false,
        undoSendReady: false,
        undoActionReady: false,
        undoClassifyAction: false,
        debugAction: true,
        debugClipboardInfo: accountBlockClipboardInfoSample,
        undoClassifySnackbarMessage: "",
        undoSendSnackbarMessage: "",
        undoClassifyActionObject: null,
        dismissType: NotificationDismissType.Dismissable,
        created_dt: new Date().toString()
      }
    );
    const successSendClipboardInfoSample2 = `
      type:Success2 \n
      time:"12:20 PM" \n
      message:"Message sent successfully" \n
      subject:"Not Interested" \n
      sdr:"sdr@bairesdev.org" \n
      to:"destination@gmail.com" \n
      cc:"someone1@gmail.com, someone2@gmail.com" \n
    `;
    const successSendSample2 = new Notification(
      {
        id: "successSendSample2",
        type: NotificationType.Success,
        category: NotificationCategory.Send,
        time: "12:20 PM",
        message: "Message sent successfully",
        placeholderInfo1: "Not Interested",
        placeholderInfo2: "sdr@bairesdev.org",
        undoSendAction: true,
        undoActionReady: false,
        undoSendReady: false,
        undoClassifyAction: false,
        debugAction: true,
        debugClipboardInfo: successSendClipboardInfoSample2,
        undoClassifySnackbarMessage: "",
        undoSendSnackbarMessage: "Send message action reverted successfully",
        undoClassifyActionObject: null,
        dismissType: NotificationDismissType.AutoDismiss,
        created_dt: new Date().toString()
      }
    );
    const successSendClipboardInfoSample3 = `
      type:Success3 \n
      time:"12:20 PM" \n
      message:"Message sent successfully" \n
      subject:"Not Interested" \n
      sdr:"sdr@bairesdev.org" \n
      to:"destination@gmail.com" \n
      cc:"someone1@gmail.com, someone2@gmail.com" \n
    `;
    const successSendSample3 = new Notification(
      {
        id: "successSendSample3",
        type: NotificationType.Success,
        category: NotificationCategory.Send,
        time: "12:20 PM",
        message: "Message sent successfully",
        placeholderInfo1: "Not Interested",
        placeholderInfo2: "sdr@bairesdev.org",
        undoSendAction: true,
        undoActionReady: false,
        undoSendReady: false,
        undoClassifyAction: false,
        debugAction: true,
        debugClipboardInfo: successSendClipboardInfoSample3,
        undoClassifySnackbarMessage: "",
        undoSendSnackbarMessage: "Send message action reverted successfully",
        undoClassifyActionObject: null,
        dismissType: NotificationDismissType.AutoDismiss,
        created_dt: new Date().toString()
      }
    );
    // var sampleNotifications = [successSendSample,errorSendSample,accountBlockSample];
    const sampleNotifications = [successSendSample, errorSendSample, successSendSample2, accountBlockSample, successSendSample3];
    let notifNum = 0;
    while (this.connected && notifNum < 5) {
      const currentNotif = notifNum % 5;
      setTimeout(() => {
        const newNotif = sampleNotifications[currentNotif];
        newNotif.id = newNotif.id;
        this.addNotificationToFeed(newNotif);
      }, REFRESH_FREQUENCY * (notifNum + 1));
      notifNum++;
    }

  }

  disconnect() {
    this.writeLogEntry("Dispose disconnect");
    this.connected = false;
    this.wsService.disconnect();
  }

  getTimeLog() {
    const date = new Date;
    const seconds = date.getSeconds();
    const minutes = date.getMinutes();
    const hour = date.getHours();
    return hour.toString() + ":" + minutes.toString() + ":" + seconds.toString() + " => ";
  }

  writeLogEntry(entry: string) {
    // console.log(this.getTimeLog() + entry);
  }
}
