import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';
import {SDAuthService} from '../../services/sd-auth.service';


@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {

  breadcrumbItems = [];
  loading = false;
  menuSelected='users';
  constructor(private authService: SDAuthService,
    private loadingStateService: LoadingStateService,
    private router: Router) { }

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe(loading => {
      this.loading = loading;
    });

    this.populateBreadcrumb(this.router.url);

    this.router.events
    .filter(event => event instanceof NavigationStart)
    .subscribe((event:NavigationStart) => {
      this.populateBreadcrumb(event.url);
    });



  }

  navigate(url:string) {
    if(url) {
      this.router.navigate([url]);
    }
  }

  populateBreadcrumb(url: string) {
    this.breadcrumbItems = [{
      label: 'Management',
      route: '/home/management/users',
      active: false
    }];

    const routeSegments = url.split('/');
    routeSegments.forEach((segment, index) => {
      var breadcrumbItem = {
        label: '',
        route: '',
        active: false
      };

      if(segment.indexOf('?') > -1) {
        segment = segment.substring(0,segment.indexOf('?'));
      }

      switch(segment) {
        case 'users':
          breadcrumbItem.label='Users';
          breadcrumbItem.route='/home/management/users';
          this.menuSelected = 'users';
          break;
        case 'operator-users':
          breadcrumbItem.label='Operator Users';
          breadcrumbItem.route='/home/management/operator-users';
          this.menuSelected = 'operator-users';
          break;
        case 'new':
          breadcrumbItem.label='New User';
          breadcrumbItem.route='/home/management/users/new';
          break;
        case 'addresses':
          breadcrumbItem.label='Address Suggestions';
          breadcrumbItem.route='/home/management/addresses';
          this.menuSelected = 'addresses';
          break;
        case 'sdr-manage':
          breadcrumbItem.label='Sdrs';
          breadcrumbItem.route='/home/management/sdr-manage';
          this.menuSelected = 'sdrs';
          break;
        case 'templates':
          breadcrumbItem.label='Templates';
          breadcrumbItem.route='/home/management/templates/manage';
          this.menuSelected = 'templates';
          break;
        case 'manage':
          breadcrumbItem.label='Manage';
          breadcrumbItem.route='/home/management/templates/manage';
          break;
        case 'edit':
          breadcrumbItem.label='Create or Edit';
          breadcrumbItem.route='/home/management/templates/edit';
          break;
        case 'contacts':
          breadcrumbItem.label='Contacts';
          breadcrumbItem.route='/home/management/contacts/contact-manage';
          this.menuSelected = 'contacts';
          break;
        case 'botsetup':
          breadcrumbItem.label='Bot Setup';
          breadcrumbItem.route='/home/management/botsetup';
          this.menuSelected = 'botsetup';
          break;
        case 'contact-manage':
          breadcrumbItem.label='Manage';
          breadcrumbItem.route='/home/management/contacts/contact-manage';
          break;
        case 'contact-edit':
          breadcrumbItem.label='Create or Edit';
          breadcrumbItem.route='/home/management/contacts/contact-edit';
          break;
        case 'faq':
          breadcrumbItem.label='Faq';
          breadcrumbItem.route='/home/management/faq/faq-manage';
          this.menuSelected = 'faq';
          break;
        case 'faq-manage':
          breadcrumbItem.label='Manage';
          breadcrumbItem.route='/home/management/faq/faq-manage';
          break;
        case 'faq-edit':
          breadcrumbItem.label='Create or Edit';
          breadcrumbItem.route='/home/management/faq/faq-edit';
          break;
        case 'client':
          breadcrumbItem.label='Client Management';
          breadcrumbItem.route='/home/management/client';
          this.menuSelected = 'client';
          break;
        case 'reassign-operator':
          breadcrumbItem.label='Reassign Operator';
          breadcrumbItem.route='/home/management/reassign-operator';
          this.menuSelected = 'reassign-operator';
          break;
        case 'sdrs':
          breadcrumbItem.label="SDR's Management";
          breadcrumbItem.route='/home/management/sdrs';
          this.menuSelected = 'sdrs-management';
          break;
      }

      if(segment.includes('@')) {
        breadcrumbItem.label='User Details';
      }

      if(index === routeSegments.length - 1) {
        breadcrumbItem.active = true;
        breadcrumbItem.route='';
      }

      if(breadcrumbItem.label) {
        this.breadcrumbItems.push(breadcrumbItem);
      }

    });
  }

}
