<div
  class="form"
  [formGroup]="operatorUserForm"
  fxLayout="column"
  fxLayoutGap="12px"
>
  <div
    fxLayout="row"
    fxFlex="100"
    fxLayoutGap="12px"
  >
    <div
      fxLayout="column"
      fxFlex="55"
    >
      <mat-form-field
        class="form-field-5"
        appearance="outline"
        subscriptSizing="dynamic"
        id="operator-user-form-name-mat-form-field"
      >
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name"
          id="operator-user-form-name-input"
        />
      </mat-form-field>
    </div>
    <div
      fxLayout="column"
      fxFlex="45"
    >
      <mat-form-field
        class="form-field-5"
        appearance="outline"
        subscriptSizing="dynamic"
        id="operator-user-form-last-name-mat-form-field"
      >
        <mat-label>Last Name</mat-label>
        <input
          matInput
          formControlName="last_name"
          id="operator-user-form-last-name-input"
        />
      </mat-form-field>
    </div>
  </div>
  <div
    fxLayout="row"
    fxFlex="100"
  >
    <div
      fxLayout="column"
      fxFlex="100"
    >
      <mat-form-field
        class="form-field-5"
        appearance="outline"
        subscriptSizing="dynamic"
        id="operator-user-form-email-mat-form-field"
      >
        <mat-label>Email</mat-label>
        <input
          matInput
          formControlName="email"
          id="operator-user-form-email-input"
        />
      </mat-form-field>
    </div>
  </div>
  <div
    fxLayout="row"
    fxFlex="100"
  >
    <div
      fxLayout="column"
      fxFlex="100"
    >
      <mat-form-field
        class="form-field-5"
        appearance="outline"
        subscriptSizing="dynamic"
        id="operator-user-form-password-mat-form-field"
      >
        <mat-label>Password</mat-label>
        <input
          matInput
          type="password"
          formControlName="password"
          [pattern]="passwordValidator"
          id="operator-user-form-password-input"
        />
        <mat-error *ngIf="operatorUserForm.get('password')?.invalid">
          Password does not meet strength conditions (Minimum eight and maximum 10 characters, at least one uppercase
          letter, one lowercase letter, one number and one special character).
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <label>Operator Language(s)</label>
  <div fxLayout="row">
    <ng-container>
      <mat-checkbox
        class="language-checkbox"
        *ngFor="let language of suportedLanguages$ | async"
        color="primary"
        [checked]="operatorUserForm?.get('language')?.value?.includes(language.shortName)"
        (change)="handleLanguageCheck($event.checked, language.shortName)"
        id="operator-user-form-language-mat-checkbox"
      >
        <app-flag-viewer
          [languages]="[language]"
          [showFullName]="true"
        ></app-flag-viewer>
      </mat-checkbox>
    </ng-container>
  </div>
</div>
