import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ItemData } from '../../model/ItemData';

@Component({
  selector: 'app-multiselect-autocomplete',
  templateUrl: './multiselect-autocomplete.component.html',
  styleUrls: ['./multiselect-autocomplete.component.scss'],
})
export class MultiselectAutocompleteComponent implements OnChanges {
  @Output() resultEvt = new EventEmitter<{ key: string; data: Array<string>; changedItem?: any }>();

  @Input() placeholder = 'Select Data';
  @Input() appearance: 'standard' | 'fill' | 'outline' = 'standard';
  @Input() id: '';
  @Input() selectedValues: any[] = [];
  @Input() key = '';

  selectControl = new UntypedFormControl();
  filteredData: Observable<Array<ItemData>>;
  filterString = '';
  rawData: Array<ItemData> = [];
  selectData: Array<ItemData> = [];
  private _data: Array<string> = [];
  get data(): Array<string> {
    return this._data;
  }

  @Input() set data(value: Array<string>) {
    this.rawData = [];
    this.selectData = [];
    value.forEach((item: string) => {
      this.rawData.push({ item, selected: true });
      this.selectData.push({ item, selected: true });
    });
    this.rawData = this.rawData.sort((a, b) => {
      if (a.item > b.item) {
        return 1;
      }
      if (a.item < b.item) {
        return -1;
      }
      return 0;
    });
    this.selectControl.setValue('');
  }

  constructor() {
    this.selectCtrlValueChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedValues) {
      this.rawData = this.selectedValues;
      this.selectCtrlValueChanges();
    }
  }

  selectCtrlValueChanges() {
    this.filteredData = this.selectControl.valueChanges.pipe(
      startWith<string>(''),
      map((value) => (typeof value === 'string' ? value : this.filterString)),
      map((filter) => this.filter(filter)),
    );
  }

  filter = (filter: string = ''): Array<ItemData> => {
    this.filterString = filter;

    if (filter.length > 0) {
      return this.rawData.filter((option) => {
        return option.item.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.rawData.slice();
    }
  };

  displayFn = (): string => '';

  optionClicked = (event: Event, data: ItemData): void => {
    event.stopPropagation();
    this.toggleSelection(data);
  };

  onCheckboxClick(event: MouseEvent, data: any): void {
    event.stopPropagation();
    this.toggleSelection(data);
  }

  toggleSelection = (data: ItemData): void => {
    data.selected = !data.selected;

    this.selectData = [];
    this.rawData.forEach((data: ItemData) => {
      if (data.selected) {
        this.selectData.push(data);
      }
    });

    this.emitAdjustedData(data);
  };

  emitAdjustedData = (changedItem): void => {
    const results: Array<string> = [];
    this.selectData.forEach((data: ItemData) => {
      results.push(data.item);
    });
    this.resultEvt.emit({ key: this.key, data: results, changedItem });
  };

  removeChip = (data: ItemData): void => {
    this.toggleSelection(data);
  };

  selectAll() {
    this.rawData.forEach((data: ItemData) => {
      data.selected = true;
      this.selectData.push(data);
    });
    this.selectControl.setValue('');
  }

  clearAll() {
    this.rawData.forEach((data: ItemData) => {
      data.selected = false;
    });
    this.selectData = [];
    this.selectControl.setValue('');
  }

  clearSearch() {
    this.selectControl.setValue('');
  }
}
