import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../services/user.service';
import { SDAuthService } from '../services/sd-auth.service';


@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor(private adminService: UserService,
              private authService: SDAuthService,
              private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return new Observable(subscriber => {
        this.adminService.isUserAdmin().subscribe(isAdmin => {
          if (!isAdmin) {
            this.router.navigate(['/home']);
          }
          subscriber.next(isAdmin);
        });
    });

  }
}
