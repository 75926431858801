import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-folders-sdrs-list',
  templateUrl: './folders-sdrs-list.component.html',
  styleUrls: ['./folders-sdrs-list.component.scss'],
})
export class FoldersSdrsListComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() clientsInfo;
  @Input() sdrsInfo;
  @Input() assignedSdrsList: MatTableDataSource<any>;
  @Output() assignSdr: EventEmitter<string> = new EventEmitter(null);
  @Output() removeSdr: EventEmitter<string> = new EventEmitter(null);

  @ViewChild('sdrsSort') sort: MatSort;
  @ViewChild('sdrsPaginator') paginator: MatPaginator;

  searchKey: string;
  clientCtrl = new FormControl('');
  sdrCtrl = new FormControl('');
  filteredClients: Observable<string[]>;
  filteredSdrs: Observable<string[]>;
  availableClients = [];
  availableSdrs = [];
  searchText = '';
  displayedColumns: string[] = ['sdr', 'actions'];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.availableClients = this.clientsInfo?.map((client) => client.name)?.sort();
    this.availableSdrs = this.sdrsInfo;
    this.updateAvailableSdrs();

    this.filteredClients = this.clientCtrl.valueChanges.pipe(
      startWith(''),
      map((val) => this._filterClients(val || '')),
    );

    this.filteredSdrs = this.sdrCtrl.valueChanges.pipe(
      startWith(''),
      map((val) => this._filterSdrs(val || '')),
    );
  }

  ngAfterViewInit(): void {
    this.updateDataSourceAccessors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assignedSdrsList) {
      this.updateDataSourceAccessors();
      this.updateAvailableSdrs();
    }
  }

  updateDataSourceAccessors() {
    if (!this.assignedSdrsList) return;
    this.assignedSdrsList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'sdr':
          return item.sdr.email;
        default:
          return item[property];
      }
    };
    this.assignedSdrsList.sort = this.sort;
    this.assignedSdrsList.paginator = this.paginator;
    this.assignedSdrsList.filterPredicate = (data, filter) => {
      return this.displayedColumns.some((ele) => {
        return ele === 'sdr' && data[ele].email.toLowerCase().indexOf(filter) != -1;
      });
    };
  }

  updateAvailableSdrs() {
    this.availableSdrs = this.availableSdrs
      .filter((sdr) => !this.assignedSdrsList.data.find((item) => item.sdr.email === sdr.email))
      .sort();
    this.sdrCtrl.setValue('');
  }

  isSdrValid() {
    return this.sdrCtrl.value && this.sdrsInfo.find((sdr) => sdr.email === this.sdrCtrl.value);
  }

  applySearch() {
    this.assignedSdrsList.filter = this.searchText.trim().toLowerCase();
  }

  onClearSearch() {
    this.searchText = '';
    this.applySearch();
  }

  onAssignSdr() {
    if (!this.sdrCtrl.value) return;
    this.assignSdr.emit(this.sdrCtrl.value);
  }

  onChangeClient(client: string) {
    const clientInfo = this.clientsInfo.find((c) => c.name === client);
    if (clientInfo) {
      this.availableSdrs = clientInfo.sdrs
        .filter((sdr) => !this.assignedSdrsList.data.find((s) => s.sdr.email === sdr.email))
        .sort();
      this.sdrCtrl.setValue('');
    }
  }

  onRemoveSdr(sdr) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Are you sure you want to unassign this sdr?',
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.removeSdr.emit(sdr);
      }
    });
  }

  displayFn(inputStr: string): string {
    return inputStr ? inputStr : '';
  }

  private _filterClients(val: string): string[] {
    if (val) {
      val = val.toLowerCase();
      return this.availableClients.filter((option) => option.toLowerCase().indexOf(val) !== -1);
    }

    return this.availableClients;
  }

  private _filterSdrs(val: string): string[] {
    if (val) {
      val = val.toLowerCase();
      return this.availableSdrs.filter((option) => option.email.toLowerCase().indexOf(val) !== -1);
    }

    return this.availableSdrs;
  }
}
