import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Conversation, SdrConversation } from '../../../model/sdr-conversations/sdrs-conversations.model';
import { SdrConversationsService } from '../../../services/sdr-conversations.service';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
})
export class ConversationComponent implements OnInit, OnDestroy {
  @Input() sdrConversation: SdrConversation;
  @Input() conversations: Conversation[];
  selectedConversation: Conversation;
  private destroy$: Subject<boolean> = new Subject();

  constructor(private sdrConversationsService: SdrConversationsService) {}

  ngOnInit(): void {
    this.sdrConversationsService.selectedConversation.pipe(takeUntil(this.destroy$)).subscribe({
      next: (selectedConversation) => {
        this.selectedConversation = selectedConversation || null;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.sdrConversationsService.setConversation(null);
  }

  selectConversation(conversation: Conversation) {
    this.sdrConversationsService.setConversation(conversation);
  }
}
