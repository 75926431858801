<div mat-dialog-title>
  <div fxLayout="row" class="top-bar" fxLayoutAlign="space-between center">
    <div class="dialog-name">Confirm Information</div>
    <div fxLayout="row">
      <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
  </div>
</div>
<div class="description-wrapper">
  <ng-container *ngIf="!isSaved && !creationError">
    <p>Here’s the preview of the information you are trying to add.</p>
    <p>Are you sure about this information?</p>
  </ng-container>
  <ng-container *ngIf="displaySuccessMessage">
    <div class="success-icon-wrapper"><span><mat-icon>done</mat-icon></span></div>
    <p *ngIf="isSaved && !addedToBulk">A new lead was created!</p>
    <p *ngIf="addedToBulk">Lead added to bulk successfully.</p>
  </ng-container>
  <ng-container *ngIf="creationError || addToBulkError">
    <div class="error-icon-wrapper"><span><mat-icon>close</mat-icon></span></div>
    <p *ngIf="creationError">{{creationError}}</p>
    <p *ngIf="addToBulkError">An error occurred while adding lead to bulk.</p>
  </ng-container>
</div>
<mat-dialog-content class="new-lead-preview-container">
  <div class="subtitle-wrapper"><span>Lead Preview</span></div>
  <form [formGroup]="data.leadForm">
    <mat-accordion multi>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>sentiment_satisfied</mat-icon><span>Lead</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="lead-details-wrapper">
          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="33">
              <mat-label>First Name</mat-label>
              <input matInput formControlName="first_name" readonly>
            </mat-form-field>
            <mat-form-field fxFlex="33">
              <mat-label>Middle Name</mat-label>
              <input matInput formControlName="middle_name" readonly>
            </mat-form-field>
            <mat-form-field fxFlex="33">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="last_name" readonly>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="50">
              <mat-label>Title</mat-label>
              <input matInput formControlName="title" readonly>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <mat-label>Email Address</mat-label>
              <input matInput formControlName="email_address" readonly>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="50">
              <mat-label>Country</mat-label>
              <mat-select formControlName="country" [compareWith]="compareFunction" disabled>
                <mat-option *ngFor="let item of data.selectsData.countries" [value]="item.name">
                  {{ item.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <mat-label>State</mat-label>
              <mat-select formControlName="state" [compareWith]="compareFunction">
                <mat-option *ngFor="let item of data.selectsData.states" [value]="item.name">
                  {{ item.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="50">
              <mat-label>City</mat-label>
              <input matInput formControlName="city" readonly>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <mat-label>Phone</mat-label>
              <input matInput formControlName="phone" readonly>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="50">
              <mat-label>LinkedInURLGen</mat-label>
              <input matInput formControlName="linked_in_url_gen" readonly>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>corporate_fare</mat-icon><span>Company</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="lead-details-wrapper">
          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="50">
              <mat-label>Email Address Source</mat-label>
              <input matInput formControlName="email_address_source" readonly>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="footer-actions-wrapper">
    <ng-container *ngIf="!isSaved; else savedLeadActions">
      <button mat-stroked-button class="cancel-btn" (click)="backToEdit()" [disabled]="isLoading">Back to Edit</button>
      <button mat-stroked-button class="confirm-btn" cdkFocusInitial (click)="createNewLead()" [disabled]="isLoading">
        <span *ngIf="!isLoading">Confirm</span>
        <span *ngIf="isLoading"><mat-icon>published_with_changes</mat-icon></span>
      </button>
    </ng-container>

    <ng-template #savedLeadActions>
      <button mat-stroked-button class="cancel-btn" mat-dialog-close>Close</button>
      <!-- <button mat-stroked-button class="add-bulk-btn" cdkFocusInitial (click)="addToBulk()" *ngIf="isSaved && !addedToBulk">
        <span *ngIf="!isLoading">Add to Bulk</span>
      </button> -->
    </ng-template>
</mat-dialog-actions>