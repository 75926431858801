import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SDAuthService } from 'src/app/services/sd-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loading = false;
  loginError = '';
  form: UntypedFormGroup;

  constructor(private router: Router, private authService: SDAuthService) {}

  ngOnInit() {
    this.authService.autoLogin();

    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
    });
  }

  async login() {
    const email = this.form.get('email').value;
    const password = this.form.get('password').value;
    if (!email || !password) {
      return;
    }

    this.loading = true;
    this.loginError = '';
    await this.authService.login(email, password).then(async value => {
      this.loading = false;
      if (value.username) {
        this.router.navigate(['/home']);
      } else {
        if (value.code === 'NotAuthorizedException') {
          this.loginError = value.message;
        }
      }
    });
  }
}
