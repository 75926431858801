<div class="classifier-container">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading || isEmailLoading"></mat-progress-bar>
  <app-classifier-toolbar
    [totalCount]="totalCount"
    [pendingCount]="pendingCount - completedCases"
    (navigateNext)="navigateNextLog()"
    (navigatePrev)="navigatePreviousLog()"
    (refreshDiscrepancies)="onRefreshDiscrepancies()"
    (inReviewDiscrepancies)="loadDiscrepanciesByStatus($event)"
    [isNextDisabled]="isNextBtnDisabled"
    [isPrevDisabled]="isPrevBtnDisabled"
  ></app-classifier-toolbar>
  <ng-container *ngIf="discrepancies.length; else NoLogs">
    <app-classifier-log 
      [log]="currentLog" 
      (completeDiscrepancy)="onCompleteDiscrepancy()"
      (emailClicked)="onClickEmail($event)"
    >
    </app-classifier-log>
    <app-discrepancy-details-body></app-discrepancy-details-body>
  </ng-container>
  <ng-template #NoLogs>
    <div class="empty-data-message">No data to display.</div>
  </ng-template>
</div>
