import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { Notification } from '../../../../model/Notification';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationType } from 'src/app/model/NotificationType';
import { SelectedMailService } from 'src/app/services/selected-mail.service';
import { NotificationCategory } from 'src/app/model/NotificationCategory';
import { Subscription, Observable, of } from 'rxjs';
import { delay, repeatWhen, take, takeWhile } from 'rxjs/operators';
import { NotificationDismissType } from 'src/app/model/NotificationDismissType';
import { SdApiService } from 'src/app/services/base/sd-api.service';
import { ComposerMailService } from 'src/app/services/composer-mail.service';
import { ActionService } from 'src/app/services/action.service';
import { FeedService } from 'src/app/services/feed.service';
import { MailComposerComponent } from '../../mail-composer/mail-composer.component';
import { MailComposerMinComponent } from '../../mail-composer-min/mail-composer-min.component';
import { ComposeEmailCategory } from 'src/app/model/ComposeEmailCategory';
import { LogService } from 'src/app/services/log.service';

const MODAL_DURATION = 3000;
const CLASSIFY_AUTODIMISS_LIFETIME = 10;
const BLOCKED_SDR_AUTODIMISS_LIFETIME = 30;
const SEND_AUTODIMISS_LIFETIME = 10;
const AUTODIMISS_LIFETIME = 10;

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: Notification;
  notificationType = NotificationType;
  lifetime = -1;

  private undoSendTime = SEND_AUTODIMISS_LIFETIME;

  constructor(
    private notificationsService: NotificationsService,
    private snackBar: MatSnackBar,
    private selectedMailService: SelectedMailService,
    private composerMailService: ComposerMailService,
    private sdApiService: SdApiService,
    private actionService: ActionService,
    public feedService: FeedService,
    private dialog: MatDialog,
    private logService: LogService,
  ) {}

  ngOnInit() {
    this.undoSendTime = this.notificationsService.undoSendTime;

    if (this.notification.dismissType == NotificationDismissType.AutoDismiss) {
      switch (this.notification.category) {
        case NotificationCategory.Classify:
          this.lifetime = CLASSIFY_AUTODIMISS_LIFETIME + 1;
          break;
        case NotificationCategory.Send:
          this.lifetime = this.undoSendTime + 1;
          break;
        case NotificationCategory.BlockedSDR:
          this.lifetime = BLOCKED_SDR_AUTODIMISS_LIFETIME + 1;
          break;
        default:
          this.lifetime = AUTODIMISS_LIFETIME + 1;
          break;
      }

      of(null)
        .pipe(repeatWhen((x) => x.pipe(delay(1 * 1000))))
        .subscribe((x) => {
          this.lifetime--;
        });
    }
  }

  clear() {
    this.notificationsService.removeNotificationFromFeed(this.notification);
  }

  undoClassifyAction() {
    this.actionService.undoActionItem(this.notification.actionIdentifier);
    this.notificationsService.removeNotificationFromFeed(this.notification);
    this.snackBar.open(this.notification.undoClassifySnackbarMessage, null, { duration: MODAL_DURATION });

    const storageEndIndex = this.notification.actionIdentifier.indexOf('_classify_');

    const storageID = this.notification.actionIdentifier.substring(0, storageEndIndex);
    this.feedService.deleteFromLastClassified(storageID);

    this.logService.submitLog({
      tags: [
        'ActionUndone',
        'Classify',
        this.notification.id,
        this.notification.placeholderInfo1,
        this.notification.placeholderInfo2,
      ],
      body: 'ActionUndone - ' + this.notification.message,
    });
  }

  getNotificationRecipients() {
    return Array.isArray(this.notification.placeholderInfo2)
      ? this.notification.placeholderInfo2.join(', ')
      : this.notification.placeholderInfo2;
  }

  undoSendAction() {
    this.actionService.undoActionItem(this.notification.actionIdentifier);
    this.notificationsService.removeNotificationFromFeed(this.notification);

    this.logService.submitLog({
      tags: ['ActionUndone', 'Send', this.notification.placeholderInfo1, this.notification.placeholderInfo2],
      body: 'ActionUndone - ' + this.notification.message,
    });

    const lastEmailCategory = this.composerMailService.restorePreviousComposedEmail();
    if (lastEmailCategory === ComposeEmailCategory.New) {
      let dialModalRef = this.dialog.open(MailComposerMinComponent, {
        panelClass: 'mail-composer-container',
        autoFocus: false,
        disableClose: true,
        hasBackdrop: false,
      });
      dialModalRef.updatePosition({ bottom: '0', right: '20px' });
    } else {
      const dialogData = JSON.parse(window.localStorage.getItem('fwdEmailData') || '{}');
      this.dialog.open(MailComposerComponent, {
        minWidth: '98vw',
        minHeight: '98vh',
        maxHeight: '98vh',
        panelClass: 'mail-composer-container',
        data: dialogData,
        autoFocus: false,
      });
    }

    this.snackBar.open(this.notification.undoSendSnackbarMessage, null, { duration: MODAL_DURATION });
  }

  debug() {
    const el = document.createElement('textarea');
    el.value = this.notification.debugClipboardInfo;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.sdApiService.postSlackMessage(el.value).subscribe(
      (response) => {},
      (error) => {},
    );

    this.snackBar.open('Debug information was copied to clipboard', null, { duration: MODAL_DURATION });
  }
}
