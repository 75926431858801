<div
  class="container"
  [ngClass.lt-md]="'mobile'"
>
  <div class="progress-bar-container">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoading"
    ></mat-progress-bar>
  </div>
  <div>
    <span class="title">Select Sales Representative</span>
  </div>
  <div class="dropdownContainer">
    <mat-form-field
      appearance="fill"
      class="field-container"
      [ngClass.lt-md]="'mobile'"
      id="sales-representative-select-mat-form-field"
    >
      <input
        type="text"
        aria-label="Number"
        matInput
        required
        [formControl]="salesRepFilterControl"
        [matAutocomplete]="salesRepAuto"
        id="sales-representative-select-input"
      />
      <mat-autocomplete
        #salesRepAuto="matAutocomplete"
        [displayWith]="displayFn"
        id="sales-representative-select-mat-autocomplete"
      >
        <mat-option
          *ngFor="let salesRep of filteredSalesRepOptions | async; let i = index"
          [value]="salesRep.name"
          [id]="'sales-representative-select-' + i + '-mat-option'"
        >
          <span class="sales-rep-name">{{ salesRep.name }}</span>
          <span class="sales-rep-geographic">{{ ' | ' + salesRep.geographicZone }}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="salesRepFilterControl.hasError('invalidProfileCC')"
        >Sales Representative does not have a Profile CC configured</mat-error
      >
    </mat-form-field>
  </div>

  <div>
    <button
      mat-raised-button
      color="primary"
      style="box-shadow: none"
      class="button-ok"
      [disabled]="!isValidSaleRep"
      (click)="useSalesRep()"
      id="sales-representative-ok-btn"
    >
      OK
    </button>
    <button
      mat-raised-button
      color="primary"
      style="box-shadow: none"
      mat-dialog-close
      id="sales-representative-cancel-btn"
    >
      Cancel
    </button>
  </div>
</div>
