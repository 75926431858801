import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnsetOperatorModalData } from '../../../../model/Operator';

@Component({
  selector: 'app-unset-operator',
  templateUrl: './unset-operator.component.html',
  styleUrls: ['./unset-operator.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class UnsetOperatorComponent {
  constructor(
    public dialog: MatDialogRef<UnsetOperatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UnsetOperatorModalData
  ) {}

  public onDismiss(): void {
    this.dialog.close(null);
  }

  public onConfirm(): void {
    this.dialog.close(this.data.operator.operator_id);
  }
}