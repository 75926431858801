import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Conversation, SdrConversation } from '../model/sdr-conversations/sdrs-conversations.model';
import { map } from 'rxjs/operators';
import { IGenericResponse } from '../model/generic-response';

@Injectable({
  providedIn: 'root',
})
export class SdrConversationsService {
  private selectedConversation$ = new BehaviorSubject<Conversation>(null);

  constructor(private http: HttpClient) {}

  get selectedConversation() {
    return this.selectedConversation$.asObservable();
  }

  setConversation(conversation: Conversation) {
    this.selectedConversation$.next(conversation);
  }

  getSdrConversations(): Observable<SdrConversation[]> {
    const url = `${environment.newApiBaseUrl}/messages/messages-feed`;
    const mockData = {
      status: 'success',
      data: [
        {
          sdrId: 7,
          sdrName: 'Enrique Fernandez',
          conversations: [
            {
              conversationId: '55458581-5758-42eb-bd45-23493d45d7de',
              messages: [
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'Hi Enrique',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'how is it going?',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'good!',
                  isInbound: true,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: "Hi Ariyah, \n\nHope you're doing well. When can we speak?\n\nChris",
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'how is it going?',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'good!',
                  isInbound: true,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'Hi Enrique',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'how is it going?',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'good!',
                  isInbound: true,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'Hi Enrique',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'how is it going?',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'good!',
                  isInbound: true,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'Hi Enrique',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'how is it going?',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'good!',
                  isInbound: true,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'Hi Enrique',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'how is it going?',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'good!',
                  isInbound: true,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'Hi Enrique',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'how is it going?',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'good!',
                  isInbound: true,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'Hi Enrique',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'how is it going?',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'good!',
                  isInbound: true,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'Hi Enrique',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'how is it going?',
                  isInbound: false,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'good!',
                  isInbound: true,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
              ],
            },
            {
              conversationId: '55458581-5758-42eb-bd45-23493d45d7da',
              messages: [
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'Hola Enrique',
                  isInbound: true,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
              ],
            },
          ],
        },
        {
          sdrId: 7,
          sdrName: 'Peppito Perez',
          conversations: [
            {
              conversationId: '55458581-5758-42eb-bd45-23493d45d7dc',
              messages: [
                {
                  messageId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messageHandlerId: '55458581-5758-42eb-bd45-23493d45d7de',
                  messagePlain: 'Hola Pepito',
                  isInbound: true,
                  createDate: '2024-08-19 17:32:02.422935 +00:00',
                },
              ],
            },
          ],
        },
      ],
      message: 'Request processed successfully.',
      errors: null,
    };

    return this.http.get(url).pipe(
      map((response: IGenericResponse<SdrConversation[]>) =>
        response.data.map(
          (sdr) =>
            ({
              sdrId: sdr.sdrId,
              sdrName: sdr.sdrName,
              conversations: sdr.conversations.map((conversation) => ({
                ...conversation,
                sdrId: sdr.sdrId,
                sdrName: sdr.sdrName,
              })),
            }) as SdrConversation,
        ),
      ),
    );
    // return of(mockData).pipe(
    //   map((response: IGenericResponse<SdrConversation[]>) =>
    //     response.data.map(
    //       (sdr) =>
    //         ({
    //           sdrId: sdr.sdrId,
    //           sdrName: sdr.sdrName,
    //           conversations: sdr.conversations.map((conversation) => ({
    //             ...conversation,
    //             sdrId: sdr.sdrId,
    //             sdrName: sdr.sdrName,
    //           })),
    //         }) as SdrConversation,
    //     ),
    //   ),
    // );
  }
}
