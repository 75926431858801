<div class="sdr-conversations">
  <div
    class="sdr-conversations__container"
    *ngIf="this.sdrConversations.length"
  >
    <div
      class="sdr-conversations__item"
      *ngFor="let sdrConversation of sdrConversations; let i = index"
    >
      <span class="sdr-conversations__sdr-name">{{ sdrConversation.sdrName }}</span>
      <app-conversation [conversations]="sdrConversation.conversations"></app-conversation>
    </div>
  </div>
  <div
    class="sdr-conversations__messages"
    *ngIf="selectedConversation"
  >
    <app-message></app-message>
  </div>
  <div
    class="sdr-conversations__extra-info"
    *ngIf="selectedConversation"
  >
    <app-extra-info></app-extra-info>
  </div>

  <div
    class="sdr-conversations__not-results"
    *ngIf="this.sdrConversations.length && !selectedConversation"
  >
    <mat-icon
      class="sdr-conversations__info-icon"
      color="primary"
      >info</mat-icon
    >
    Please select a conversation
  </div>
  <div
    class="sdr-conversations__not-results"
    *ngIf="!this.sdrConversations.length"
  >
    <mat-icon
      class="sdr-conversations__info-icon"
      color="warn"
      >info</mat-icon
    >
    No conversations found
  </div>
</div>
