import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { DefaultAPIResponse } from "../model/APIResponse";
import { ClientsAndFolders } from "../model/Customer";
import { AssignOperatorRequest, IOperator } from "../model/Operator";
import { ICustomerSdr } from "../model/Sdr";


@Injectable({
    providedIn: 'root'
  })
export class CustomerService {
    apiBackEnd = '';
    constructor(private http: HttpClient) {
      this.apiBackEnd = environment.apiBaseURL;
      }

      public getCustomers(): Observable<any> {
        return this.http.get(`${this.apiBackEnd}/app/customers/all`);
      }

      public getAllCustomers(): Observable<DefaultAPIResponse<ClientsAndFolders>> {
        return this.http.get<DefaultAPIResponse<ClientsAndFolders>>(`${this.apiBackEnd}/app/customers/?include_all=true`);
      }

      public getAllOperatorsForCustomer(
        customerId: number,
        isActive?: boolean
      ): Observable<DefaultAPIResponse<IOperator[]>> {
        let params = new HttpParams();
    
        if(isActive !== undefined){
          params = params.append('is_active', isActive);
        }
    
        return this.http.get<DefaultAPIResponse<IOperator[]>>(`${this.apiBackEnd}/app/customers/${customerId}/operators`, { params });
      }

      public getAllSdrForCustomer(customerId: number): Observable<DefaultAPIResponse<ICustomerSdr[]>> {        
        return this.http.get<DefaultAPIResponse<ICustomerSdr[]>>(`${this.apiBackEnd}/app/customers/${customerId}/users/`);
      }

      public assignOperator(body: AssignOperatorRequest[] ): Observable<DefaultAPIResponse<[]>> {
        return this.http.post<DefaultAPIResponse<[]>>(`${this.apiBackEnd}/app/users_operators_customers_levels`, body);
      }
}
