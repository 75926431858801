import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { SdApiService } from './base/sd-api.service';
import { LoadingStateService } from './base/loading-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComposeMailAction } from '../model/ComposeMailAction';
import { ComposeEmail } from '../model/ComposeEmail';
import { Attachment } from '../model/Attachment';
import { ComposeEmailCategory } from '../model/ComposeEmailCategory';
import { Sdr } from '../model/Sdr';
import { Reference } from '../model/Reference';
import { NotificationType } from '../model/NotificationType';
import { NotificationCategory } from '../model/NotificationCategory';
import { NotificationDismissType } from '../model/NotificationDismissType';
import { NotificationsService } from './notifications.service';
import { Notification } from '../model/Notification';
import { ActionService } from './action.service';
import { UIGuid } from '../helpers/ui-guid';
import { delay } from 'rxjs/operators';
import { LogService } from './log.service';
import { SDAuthService } from './sd-auth.service';

const MODAL_DURATION = 5000;
const UNDO_STANDBY_TIME = 10 * 1000;

@Injectable({
  providedIn: 'root',
})
export class ComposerMailService {
  private composeEmailSubject = new BehaviorSubject<ComposeEmail>(null);
  private lastComposeEmailSubject = new BehaviorSubject<ComposeEmail>(null);
  private activeActions = new Map<string, ComposeMailAction>();
  private sdrs: Sdr[];
  private isComposerOpenSubject = new BehaviorSubject<boolean>(false);
  private isMailComposerMediumOpenSubject = new BehaviorSubject<boolean>(false);
  private isMailComposerMinimizedSubject = new BehaviorSubject<boolean>(false);
  private userEmail = '';

  constructor(
    private sdApiService: SdApiService,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    private actionsService: ActionService,
    private logService: LogService,
    private authService: SDAuthService,
  ) {
    this.sdApiService.userSDRs.subscribe((sdrs) => {
      this.sdrs = sdrs;
    });
    this.authService.getCurrentUserEmail().then((email) => {
      this.userEmail = email;
    });
  }

  get composedEmail() {
    return this.composeEmailSubject.asObservable();
  }

  setComposedEmail(value: ComposeEmail) {
    this.composeEmailSubject.next(value);
  }

  get lastComposedEmail() {
    return this.lastComposeEmailSubject.asObservable();
  }

  setLastComposedEmail(value: ComposeEmail) {
    this.lastComposeEmailSubject.next(value);
  }

  get isComposerOpen() {
    return this.isComposerOpenSubject.asObservable();
  }

  setIsComposerOpen(value: boolean) {
    this.isComposerOpenSubject.next(value);
  }

  get isMailComposerMediumOpen() {
    return this.isMailComposerMediumOpenSubject.asObservable();
  }

  setIsMailComposerMediumOpen(value: boolean) {
    this.isMailComposerMediumOpenSubject.next(value);
  }

  get isMailComposerMinimized() {
    return this.isMailComposerMinimizedSubject;
  }

  setIsMailComposerMinimized(value: boolean) {
    this.isMailComposerMinimizedSubject.next(value);
  }

  sendEmail(composeEmail: ComposeEmail) {
    const action = { composeEmail, subject: composeEmail.subject } as ComposeMailAction;
    const actionIdentifier = 'send_' + UIGuid.newGuid() + '_' + new Date().getTime().toString();
    const successSendClipboardInfo =
      `
      type:Success \n
      time:` +
      this.formatAMPM(new Date()) +
      ` \n
      message:Message send request done successfully  \n
      from:` +
      composeEmail.from +
      ` \n
      to:` +
      composeEmail.to.join() +
      ` \n
      subject:` +
      composeEmail.subject +
      ` \n
      storageId:` +
      composeEmail.sourceStorageId +
      ` \n
      messageId:` +
      composeEmail.messageId +
      ` \n
      currentUser:` +
      this.userEmail +
      ` \n
    `;

    const successId = `successSend_${composeEmail.sourceStorageId}_${new Date().getTime()}`;

    const successSendNotification = new Notification({
      uid: successId,
      id: successId,
      type: NotificationType.Success,
      category: NotificationCategory.Send,
      created_dt: new Date().toString(),
      message: 'Message send request done successfully',
      placeholderInfo1: composeEmail.subject,
      placeholderInfo2: composeEmail.to,
      undoSendAction: true,
      undoActionReady: false,
      undoClassifyAction: false,
      debugAction: true,
      debugClipboardInfo: successSendClipboardInfo,
      undoClassifySnackbarMessage: '',
      undoSendSnackbarMessage: 'Send email ' + composeEmail.subject + ' reverted successfully',
      undoClassifyActionObject: null,
      undoSendActionObject: action,
      dismissType: NotificationDismissType.AutoDismiss,
      actionIdentifier,
    });
    this.notificationsService.addNotificationToFeed(successSendNotification);

    this.actionsService.addActionItem(actionIdentifier, {});

    this.logService.submitLog({
      tags: [
        'ActionStarted',
        'Send',
        successSendNotification.placeholderInfo1,
        successSendNotification.placeholderInfo2,
      ],
      body: 'ActionStarted - ' + successSendNotification.message,
    });

    // Queue delayed call
    of(null)
      .pipe(delay(UNDO_STANDBY_TIME))
      .subscribe((x) => {
        if (!this.actionsService.isUndone(actionIdentifier)) {
          console.log('Sending email: ', { email: composeEmail.subject });
          this.loadingStateService.setLoadingState(true);
          this.sdApiService.sendEmail(composeEmail).subscribe((response) => {
            this.loadingStateService.setLoadingState(false);
            if (response.code != 200 || response.message !== '') {
              let messageError = '';
              if (!!response?.message) {
                try {
                  const myJson = response.message.replace(/'/g, '"');
                  const parseMessageError = JSON.parse(myJson);
                  messageError = ' (' + parseMessageError.MESSAGE + ')';
                } catch (e) {
                  messageError = ' (' + response.message + ')';
                }
              }

              const errorSendClipboardInfo =
                `
              type: Error \n
              time: ` +
                this.formatAMPM(new Date()) +
                ` \n
              message: Error sending message ${messageError} \n 
              from: ` +
                composeEmail.from +
                ` \n
              to: ` +
                composeEmail.to.join() +
                ` \n
              subject: ` +
                composeEmail.subject +
                ` \n
              storageId: ` +
                composeEmail.sourceStorageId +
                ` \n
              messageId:` +
                composeEmail.messageId +
                ` \n
              currentUser:` +
                this.userEmail +
                ` \n
            `;

              const errorId = `errorSend_${composeEmail.sourceStorageId}_${new Date().getTime()}`;

              const errorSendNotification = new Notification({
                uid: errorId,
                id: errorId,
                type: NotificationType.Error,
                category: NotificationCategory.Send,
                created_dt: new Date().toString(),
                message: 'Error sending message' + messageError,
                placeholderInfo1: composeEmail.subject,
                placeholderInfo2: composeEmail.to,
                undoSendAction: false,
                undoClassifyAction: false,
                undoActionReady: false,
                undoSendReady: false,
                debugAction: true,
                debugClipboardInfo: errorSendClipboardInfo,
                undoClassifySnackbarMessage: '',
                undoSendSnackbarMessage: '',
                undoClassifyActionObject: null,
                dismissType: NotificationDismissType.NoDismissable,
              });
              this.notificationsService.addNotificationToFeed(errorSendNotification);
            }
          });

          this.logService.submitLog({
            tags: [
              'ActionCompleted',
              'Send',
              successSendNotification.placeholderInfo1,
              successSendNotification.placeholderInfo2,
            ],
            body: 'ActionCompleted - ' + successSendNotification.message,
          });
        }
        this.actionsService.removeActionItem(actionIdentifier);
      });
  }

  saveAction(action: ComposeMailAction) {
    if (!this.activeActions.get(action.actionId)) {
      this.activeActions.set(action.actionId, action);
    } else console.log('ERROR: Duplicated action ID!');
  }

  clearAction(action: ComposeMailAction) {
    if (this.activeActions.has(action.actionId)) this.activeActions.delete(action.actionId);
  }

  undoSend(action: ComposeMailAction) {
    if (this.activeActions.get(action.actionId)) {
      this.activeActions.get(action.actionId).isUndoing = true;
      this.loadingStateService.setLoadingState(true);
      this.sdApiService.undoSendAction(action.actionId).subscribe((success) => {
        this.loadingStateService.setLoadingState(false);
        if (!success) {
          const ref = this.snackBar.open('Ha ocurrido un error al revertir la acción', 'Try again', {
            duration: MODAL_DURATION,
          });
          ref.onAction().subscribe(() => {
            this.undoSend(action);
          });
        } else {
          this.snackBar.open(`Cancelado envío de email ${action.subject}`, null, { duration: MODAL_DURATION });
          this.clearAction(action);
        }
      });
    }
  }

  composeFromExisting(
    action: string,
    fromSdrId: string,
    fromEmail: string,
    fromName: string,
    lastMessageToReferences: Reference[],
    lastMessageCCReferences: Reference[],
    sender: string,
    lastMessageSubject: string,
    sentDate: Date,
    lastMessageHtml: string,
    recipientName: string,
    recipientEmail: string,
    attachments: Attachment[],
    storageId: string,
    headerReferences: any,
    headerInReplyTo: any,
    messageId: string,
  ) {
    switch (action) {
      case 'reply':
        this.reply(
          fromEmail,
          fromSdrId,
          fromName,
          lastMessageToReferences,
          lastMessageCCReferences,
          sender,
          lastMessageSubject,
          sentDate,
          lastMessageHtml,
          recipientName,
          recipientEmail,
          attachments,
          storageId,
          headerReferences,
          headerInReplyTo,
          messageId,
        );
        break;
      case 'replyAll':
        this.replyAll(
          fromEmail,
          fromSdrId,
          fromName,
          lastMessageToReferences,
          lastMessageCCReferences,
          sender,
          lastMessageSubject,
          sentDate,
          lastMessageHtml,
          recipientName,
          recipientEmail,
          attachments,
          storageId,
          headerReferences,
          headerInReplyTo,
          messageId,
        );
        break;
      case 'resend':
        this.resend(
          fromEmail,
          fromSdrId,
          fromName,
          lastMessageToReferences,
          lastMessageCCReferences,
          sender,
          lastMessageSubject,
          sentDate,
          lastMessageHtml,
          recipientName,
          recipientEmail,
          attachments,
          storageId,
          headerReferences,
          headerInReplyTo,
          messageId,
        );
        break;
      default:
        this.forward(
          fromEmail,
          fromSdrId,
          fromName,
          lastMessageToReferences,
          lastMessageCCReferences,
          sender,
          lastMessageSubject,
          sentDate,
          lastMessageHtml,
          recipientName,
          recipientEmail,
          attachments,
          storageId,
          headerReferences,
          headerInReplyTo,
          messageId,
        );
        break;
    }
  }

  private reply(
    fromEmail: string,
    fromSdrId: string,
    fromName: string,
    lastMessageToReferences: Reference[],
    lastMessageCCReferences: Reference[],
    sender: string,
    lastMessageSubject: string,
    sentDate: Date,
    lastMessageHtml: string,
    recipientName: string,
    recipientEmail: string,
    attachments: Attachment[],
    storageId: string,
    headerReferences: any,
    headerInReplyTo: any,
    messageId: string,
  ) {
    const composeEmail = this.arrangeReply(
      fromEmail,
      fromSdrId,
      fromName,
      lastMessageToReferences,
      lastMessageCCReferences,
      sender,
      lastMessageSubject,
      sentDate,
      lastMessageHtml,
      recipientName,
      recipientEmail,
      attachments,
      false,
      storageId,
      headerReferences,
      headerInReplyTo,
      messageId,
    );
    this.setComposedEmail(composeEmail);
  }

  private replyAll(
    fromEmail: string,
    fromSdrId: string,
    fromName: string,
    lastMessageToReferences: Reference[],
    lastMessageCCReferences: Reference[],
    sender: string,
    lastMessageSubject: string,
    sentDate: Date,
    lastMessageHtml: string,
    recipientName: string,
    recipientEmail: string,
    attachments: Attachment[],
    storageId: string,
    headerReferences: any,
    headerInReplyTo: any,
    messageId: string,
  ) {
    const composeEmail = this.arrangeReply(
      fromEmail,
      fromSdrId,
      fromName,
      lastMessageToReferences,
      lastMessageCCReferences,
      sender,
      lastMessageSubject,
      sentDate,
      lastMessageHtml,
      recipientName,
      recipientEmail,
      attachments,
      true,
      storageId,
      headerReferences,
      headerInReplyTo,
      messageId,
    );
    this.setComposedEmail(composeEmail);
  }

  private resend(
    fromEmail: string,
    fromSdrId: string,
    fromName: string,
    lastMessageToReferences: Reference[],
    lastMessageCCReferences: Reference[],
    sender: string,
    lastMessageSubject: string,
    sentDate: Date,
    lastMessageHtml: string,
    recipientName: string,
    recipientEmail: string,
    attachments: Attachment[],
    storageId: string,
    headerReferences: any,
    headerInReplyTo: any,
    messageId: string,
  ) {
    const composeEmail = this.arrangeResend(
      fromEmail,
      fromSdrId,
      fromName,
      lastMessageToReferences,
      lastMessageCCReferences,
      sender,
      lastMessageSubject,
      sentDate,
      lastMessageHtml,
      recipientName,
      recipientEmail,
      attachments,
      true,
      storageId,
      headerReferences,
      headerInReplyTo,
      messageId,
    );
    this.setComposedEmail(composeEmail);
  }

  private forward(
    fromEmail: string,
    fromSdrId: string,
    fromName: string,
    lastMessageToReferences: Reference[],
    lastMessageCCReferences: Reference[],
    sender: string,
    lastMessageSubject: string,
    sentDate: Date,
    lastMessageHtml: string,
    recipientName: string,
    recipientEmail: string,
    attachments: Attachment[],
    storageId: string,
    headerReferences: any,
    headerInReplyTo: any,
    messageId: string,
  ) {
    const composeEmail = this.arrangeFwd(
      fromEmail,
      fromSdrId,
      fromName,
      lastMessageToReferences,
      lastMessageCCReferences,
      sender,
      lastMessageSubject,
      sentDate,
      lastMessageHtml,
      recipientName,
      recipientEmail,
      attachments,
      storageId,
      headerReferences,
      headerInReplyTo,
      messageId,
    );
    this.setComposedEmail(composeEmail);
  }

  composeNew() {
    const composeEmail = new ComposeEmail({
      from: '',
      to: [],
      cc: [],
      bcc: [],
      subject: '',
      bodyHtml: '',
      attachments: [],
      category: ComposeEmailCategory.New,
      sourceStorageId: '',
      references: [],
      in_reply_to: null,
      messageId: '',
      sender: 'gmail',
      fromSdrId: '',
    });
    this.setComposedEmail(composeEmail);
  }

  restorePreviousComposedEmail() {
    const lastEmail = this.lastComposeEmailSubject.getValue();
    lastEmail.restored = true;
    this.setComposedEmail(lastEmail);
    return lastEmail.category;
  }

  private arrangeReply(
    fromEmail: string,
    fromSdrId: string,
    fromName: string,
    lastMessageToReferences: Reference[],
    lastMessageCCReferences: Reference[],
    sender: string,
    lastMessageSubject: string,
    sentDate: Date,
    lastMessageHtml: string,
    recipientName: string,
    recipientEmail: string,
    attachments: Attachment[],
    includeAll: boolean,
    storageId: string,
    headerReferences: any,
    headerInReplyTo: any,
    messageId: string,
  ) {
    const inReplyTo = fromEmail;
    let toReferences = [];
    let ccReferences = [];
    let subject = lastMessageSubject;
    let message = '<br>';

    if (sender == 'gmail') {
      const sentDateStr = this.getGmailFormat(sentDate);

      message += '<br><div class=3D"gmail_quote"><div dir=3D"ltr" class=3D"gmail_attr">On ' + sentDateStr + ' \n';
      message += fromName + '  &lt;<a href="mailto:' + fromEmail + '">' + fromEmail + '</a>&gt; \n';
      message += 'wrote:<br>';

      if (!lastMessageSubject.includes('Re: ')) {
        subject = 'Re: ' + lastMessageSubject;
      }

      if (lastMessageToReferences.length > 1) {
        message += '<b>To:</b> ';
        lastMessageToReferences.forEach((reference) => {
          message +=
            reference.name +
            ' &lt;<a href="mailto:' +
            reference.email +
            '" target="_blank">' +
            reference.email +
            '</a>&gt;';
          if (reference.email != lastMessageToReferences[lastMessageToReferences.length - 1].email) {
            message += '&#44;\n';
          }
        });
        message += ' \n';
      } else {
        message +=
          '<b>To:</b> ' +
          lastMessageToReferences[0].name +
          ' &lt;<a href="mailto:' +
          lastMessageToReferences[0].email +
          '" target="_blank">' +
          lastMessageToReferences[0].email +
          '</a>&gt; \n';
      }

      if (lastMessageCCReferences.length) {
        message += '<br/><b>Cc:</b> ';
        lastMessageCCReferences.forEach((reference) => {
          message +=
            reference.name +
            ' &lt;<a href="mailto:' +
            reference.email +
            '" target="_blank">' +
            reference.email +
            '</a>&gt;';
          if (reference.email != lastMessageCCReferences[lastMessageCCReferences.length - 1].email) {
            message += '&#44;\n';
          }
        });
        message += ' \n';
      }

      message += `</div><blockquote class=3D"gmail_quote" style=3D"margin:0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex"><div> \n ${lastMessageHtml} </blockquote></div> \n`;
    }

    if (sender == 'outlook') {
      const sentDateStr = this.getOutlookFormat(sentDate);

      message += '<div id="appendonsend"></div> \n';
      message += '<hr style="display:inline-block;width:98%" tabindex="-1"> \n';
      message += '<div id="divRplyFwdMsg" dir="ltr"> \n';
      message += '<font face="Calibri, sans-serif" color="#000000" style="font-size:11pt;line-height:initial;"> \n';
      message += '<b>From:</b>&nbsp;' + fromName + '&nbsp;&lt;' + fromEmail + '&gt; <br>\n';
      message += '<b>Sent:</b>&nbsp;' + sentDateStr + ' <br>\n';

      if (lastMessageToReferences.length > 1) {
        message += '<b>To:</b>';
        lastMessageToReferences.forEach((reference) => {
          message += '&nbsp;' + reference.name + '&nbsp;&lt;' + reference.email + '&gt;';
          if (reference.email != lastMessageToReferences[lastMessageToReferences.length - 1].email) {
            message += '&semi;\n';
          }
        });
        message += '<br>\n';
      } else {
        message +=
          '<b>To:</b>&nbsp;' +
          lastMessageToReferences[0].name +
          '&nbsp;&lt;' +
          lastMessageToReferences[0].email +
          '&gt; <br>\n';
      }

      if (lastMessageCCReferences.length) {
        message += '<b>CC:</b>';
        lastMessageCCReferences.forEach((reference) => {
          message += '&nbsp;' + reference.name + '&nbsp;&lt;' + reference.email + '&gt;';
          if (reference.email != lastMessageCCReferences[lastMessageCCReferences.length - 1].email) {
            message += '&semi;\n';
          }
        });
        message += '<br>\n';
      }

      message += '<b>Subject:</b>&nbsp;' + subject + ' <br><br>\n';
      message += '</font> \n';
      message += '<div>&nbsp;</div> \n';
      message += '</div>';
      message += lastMessageHtml;

      if (!lastMessageSubject.includes('Re: ')) {
        subject = 'Re: ' + lastMessageSubject;
      }
    }

    if (includeAll && lastMessageToReferences.length > 0) {
      toReferences = lastMessageToReferences.map((lmr) => lmr.email);
      toReferences = toReferences.filter((r) => r !== recipientEmail);
    }
    if (includeAll && lastMessageCCReferences.length > 0) {
      ccReferences = lastMessageCCReferences.map((lmr) => lmr.email);
      ccReferences = ccReferences.filter((cc) => cc !== recipientEmail);
    }
    return new ComposeEmail({
      from: recipientEmail,
      to: [inReplyTo].concat(toReferences),
      cc: ccReferences,
      bcc: [],
      subject,
      bodyHtml: message,
      attachments: [],
      category: ComposeEmailCategory.Reply,
      sourceStorageId: storageId,
      references: headerReferences,
      in_reply_to: headerInReplyTo,
      messageId,
      sender,
      fromSdrId,
    });
  }

  private arrangeFwd(
    fromEmail: string,
    fromSdrId: string,
    fromName: string,
    lastMessageToReferences: Reference[],
    lastMessageCCReferences: Reference[],
    sender: string,
    lastMessageSubject: string,
    sentDate: Date,
    lastMessageHtml: string,
    recipientName: string,
    recipientEmail: string,
    attachments: Attachment[],
    storageId: string,
    headerReferences: any,
    headerInReplyTo: any,
    messageId: string,
  ) {
    const inReplyTo = fromEmail;
    const references = [];
    let subject = lastMessageSubject;
    let message = '<br>';

    if (sender == 'gmail') {
      const sentDateStr = this.getGmailFormat(sentDate);

      message += '<div class="gmail_quote"><div dir="ltr" class="gmail_attr" style="line-height:initial;"> \n';
      message += '---------- Forwarded message --------- \n';
      message +=
        '<br>From: <strong class="gmail_sendername" dir="auto">' +
        fromName +
        '</strong> <span dir="auto">&lt;<a href="' +
        fromEmail +
        '" target="_blank">' +
        fromEmail +
        '</a><wbr>&gt;</span> \n';
      message += '<br>' + sentDateStr + '\n';
      message += '<br>Subject:&nbsp;' + subject + '\n';

      if (lastMessageToReferences.length > 1) {
        message += '<br>To: ';
        lastMessageToReferences.forEach((reference) => {
          message +=
            reference.name +
            ' &lt;<a href="mailto:' +
            reference.email +
            '" target="_blank">' +
            reference.email +
            '</a>&gt;';
          if (reference.email != lastMessageToReferences[lastMessageToReferences.length - 1].email) {
            message += '&#44;\n';
          }
        });
        message += ' \n';
      } else {
        message +=
          '<br>To: ' +
          lastMessageToReferences[0].name +
          ' &lt;<a href="mailto:' +
          lastMessageToReferences[0].email +
          '" target="_blank">' +
          lastMessageToReferences[0].email +
          '</a>&gt; \n';
      }

      if (lastMessageCCReferences.length) {
        message += '<br>Cc: ';
        lastMessageCCReferences.forEach((reference) => {
          message +=
            reference.name +
            ' &lt;<a href="mailto:' +
            reference.email +
            '" target="_blank">' +
            reference.email +
            '</a>&gt;';
          if (reference.email != lastMessageCCReferences[lastMessageCCReferences.length - 1].email) {
            message += '&#44;\n';
          }
        });
        message += ' \n';
      }

      message += '<br></div><br><br> \n';
      message += lastMessageHtml;
      message += '</div>';

      if (!lastMessageSubject.includes('Fwd: ')) {
        subject = 'Fwd: ' + lastMessageSubject;
      }
    }

    if (sender == 'outlook') {
      const sentDateStr = this.getOutlookFormat(sentDate);

      message += '<div id="appendonsend"></div> \n';
      message += '<hr style="display:inline-block;width:98%" tabindex="-1"> \n';
      message += '<div id="divRplyFwdMsg" dir="ltr"> \n';
      message += '<font face="Calibri, sans-serif" color="#000000" style="font-size:11pt;line-height:initial;"> \n';
      message += '<b>From:</b>&nbsp;' + fromName + '&nbsp;&lt;' + fromEmail + '&gt; <br>\n';
      message += '<b>Sent:</b>&nbsp;' + sentDateStr + ' <br>\n';

      if (lastMessageToReferences.length > 1) {
        message += '<b>To:</b>';
        lastMessageToReferences.forEach((reference) => {
          message += '&nbsp;' + reference.name + '&nbsp;&lt;' + reference.email + '&gt;';
          if (reference.email != lastMessageToReferences[lastMessageToReferences.length - 1].email) {
            message += '&semi;\n';
          }
        });
        message += '<br>\n';
      } else {
        message +=
          '<b>To:</b>&nbsp;' +
          lastMessageToReferences[0].name +
          '&nbsp;&lt;' +
          lastMessageToReferences[0].email +
          '&gt; <br>\n';
      }

      if (lastMessageCCReferences.length) {
        message += '<b>CC:</b>';
        lastMessageCCReferences.forEach((reference) => {
          message += '&nbsp;' + reference.name + '&nbsp;&lt;' + reference.email + '&gt;';
          if (reference.email != lastMessageCCReferences[lastMessageCCReferences.length - 1].email) {
            message += '&semi;\n';
          }
        });
        message += '<br>\n';
      }

      message += '<b>Subject:</b>&nbsp;' + subject + ' <br><br>\n';
      message += '</font> \n';
      message += '<div>&nbsp;</div> \n';
      message += '</div>';
      message += lastMessageHtml;

      if (!lastMessageSubject.includes('Fw: ')) {
        subject = 'Fw: ' + lastMessageSubject;
      }
    }
    return new ComposeEmail({
      from: recipientEmail,
      to: [],
      cc: references,
      bcc: [],
      subject,
      bodyHtml: message,
      attachments: JSON.parse(JSON.stringify(attachments.filter((a) => a.isSafe))),
      category: ComposeEmailCategory.Forward,
      sourceStorageId: storageId,
      references: headerReferences,
      in_reply_to: headerInReplyTo,
      messageId,
      sender,
      fromSdrId,
    });
  }

  private arrangeResend(
    fromEmail: string,
    fromSdrId: string,
    fromName: string,
    lastMessageToReferences: Reference[],
    lastMessageCCReferences: Reference[],
    sender: string,
    lastMessageSubject: string,
    sentDate: Date,
    lastMessageHtml: string,
    recipientName: string,
    recipientEmail: string,
    attachments: Attachment[],
    includeAll: boolean,
    storageId: string,
    headerReferences: any,
    headerInReplyTo: any,
    messageId: string,
  ) {
    let toReferences = [];
    let ccReferences = [];
    const subject = lastMessageSubject;
    let message = '';

    if (sender == 'gmail') {
      message += lastMessageHtml;
    }

    if (sender == 'outlook') {
      const sentDateStr = this.getOutlookFormat(sentDate);

      message += lastMessageHtml;
    }
    if (includeAll && lastMessageToReferences.length > 0) {
      toReferences = lastMessageToReferences.map((lmr) => lmr.email);
      const recipientEmailReferenceIdx = toReferences.findIndex((r) => r.email == recipientEmail);
      if (recipientEmailReferenceIdx != -1) {
        toReferences = toReferences.splice(recipientEmailReferenceIdx, 1);
      }
    }
    if (includeAll && lastMessageCCReferences.length > 0) {
      ccReferences = lastMessageCCReferences.map((lmr) => lmr.email);
    }
    return new ComposeEmail({
      from: recipientEmail,
      to: toReferences,
      cc: ccReferences,
      bcc: [],
      subject,
      bodyHtml: message,
      attachments,
      category: ComposeEmailCategory.Resend,
      sourceStorageId: storageId,
      references: headerReferences,
      in_reply_to: headerInReplyTo,
      messageId,
      sender,
      fromSdrId,
    });
  }

  private getGmailFormat(dateToFormat: Date) {
    // %a, %b %-d, %Y at %-I:%M %p
    const day = dateToFormat.toLocaleDateString('en-us', { weekday: 'short' });
    const month = dateToFormat.toLocaleString('en-us', { month: 'short' });
    const date = dateToFormat.getDate();
    const year = dateToFormat.getFullYear();
    let hours = dateToFormat.getHours();
    let hourStr = dateToFormat.getHours().toString();
    const minutes = dateToFormat.getMinutes();
    let minutesStr = dateToFormat.getMinutes().toString();
    let dayORnight = 'AM';
    if (hours > 11) {
      dayORnight = 'PM';
    }
    if (hours > 12) {
      hours = hours - 12;
    }
    if (hours == 0) {
      hours = 12;
    }
    hourStr = hours.toString();
    if (hours < 10) {
      hourStr = '0' + hours;
    }
    if (minutes < 10) {
      minutesStr = '0' + minutes;
    }

    return `${day}, ${month} ${date}, ${year} at ${hourStr}:${minutesStr} ${dayORnight}`;
  }

  private getOutlookFormat(dateToFormat: Date) {
    // Tuesday, 2 May 2020 18:45
    // %A, %-d %B %Y %H:%M
    const day = dateToFormat.toLocaleDateString('en-us', { weekday: 'long' });
    const month = dateToFormat.toLocaleString('en-us', { month: 'long' });
    const date = dateToFormat.getDate();
    const year = dateToFormat.getFullYear();
    let hours = dateToFormat.getHours();
    let hourStr = dateToFormat.getHours().toString();
    const minutes = dateToFormat.getMinutes();
    let minutesStr = dateToFormat.getMinutes().toString();
    let dayORnight = 'AM';
    if (hours > 11) {
      dayORnight = 'PM';
    }
    if (hours > 12) {
      hours = hours - 12;
    }
    if (hours == 0) {
      hours = 12;
    }
    hourStr = hours.toString();
    if (hours < 10) {
      hourStr = '0' + hours;
    }
    if (minutes < 10) {
      minutesStr = '0' + minutes;
    }

    return `${day}, ${month} ${date}, ${year} ${hourStr}:${minutesStr} ${dayORnight}`;
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  fixPastedHtml(pastedHtml) {
    // Fix Styles
    var pattern = "<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'>";
    var replacement =
      "<p class='MsoNormal' style='margin: 0in; line-height: normal; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 11pt; font-family: Calibri, sans-serif;'>";
    pastedHtml = pastedHtml.replaceAll(pattern, replacement);

    pattern = "<p class=MsoNormal style='margin-bottom:0in;line-height:normal'>";
    replacement =
      '<p class="MsoNormal" style="margin: 0in; line-height: normal; font-size: 11pt; font-family: Calibri, sans-serif;">';
    pastedHtml = pastedHtml.replaceAll(pattern, replacement);

    pattern = "<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:";
    replacement =
      "<p class='MsoNormal' style='margin: 0in; text-align: justify; font-size: 11pt; font-family: Calibri, sans-serif;line-height:";
    pastedHtml = pastedHtml.replaceAll(pattern, replacement);

    pattern = "<p class=MsoNormal style='margin-bottom:0in'>";
    replacement =
      '<p class="MsoNormal" style="margin: 0in; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;">';
    pastedHtml = pastedHtml.replaceAll(pattern, replacement);

    // Clean up word tags
    const startPattern = '<!--[if gte mso 9]><xml>';
    const startPos = pastedHtml.indexOf(startPattern);
    const endPattern = '</xml><![endif]-->';
    const endPost = pastedHtml.lastIndexOf(endPattern);
    pastedHtml = pastedHtml.substring(0, startPos) + pastedHtml.substring(endPost + endPattern.length);

    // Clean up broken fragment comments from Word
    pattern = '<!--StartFragment-->';
    pastedHtml = pastedHtml.replaceAll(pattern, '');

    pattern = '!--StartFragment-->';
    pastedHtml = pastedHtml.replaceAll(pattern, '');

    return pastedHtml;
  }
}
