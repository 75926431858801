import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IOperatorDetails, IOperatorDetailsCompanySdr } from 'src/app/model/Operator';

@Component({
  selector: 'app-operator-card',
  templateUrl: './operator-card.component.html',
  styleUrls: ['./operator-card.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class OperatorCardComponent {
  @Input() operatorDetails: IOperatorDetails;
  public sdrDataSource: MatTableDataSource<IOperatorDetailsCompanySdr> = new MatTableDataSource(null);
  public displayedSdrColumns: string[] = ['sdrName', 'folders', 'discrepancies' , 'emailCount', 'timeToReply', 'actions'];
  public selectedRows:number[] = [];
  public maxTimeToReply = 30;

  constructor() {}

  public handleCompanyDetailsOpen(): void {
    this.sdrDataSource = new MatTableDataSource(null);
    this.selectedRows = [];
  }

  public handleCompanyDetailsOpened(sdrs: IOperatorDetailsCompanySdr[]): void {
    this.sdrDataSource = new MatTableDataSource(sdrs);
  }

  public checkIfRowIsSelected(sdrId: number): boolean {
    return this.selectedRows.includes(sdrId);
  }

  public handleSelect(
    selected: boolean,
    sdrId: number
  ): void {
    if(selected){
      this.selectedRows.push(sdrId);
      return
    }

    this.selectedRows = this.selectedRows.filter(id => id !== sdrId);
  }

}