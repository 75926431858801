import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss']
})
export class FormRowComponent implements OnInit {
  @Input() label: string;
  @Input() isPartial: boolean = false;
  @Input() source: string;
  @Input() fieldControl: FormControl;
  @Input() controlType = 'text';
  @Input() selectData = [];
  @Output() handleCopy = new EventEmitter(null);

  @ViewChild('copyBtn', { static: true, read: ElementRef}) button: ElementRef<HTMLButtonElement>;
  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

  onCopy() {
    this.handleCopy.emit();
  }

  onFocusField() {
    this.renderer.setStyle(this.button.nativeElement, 'display', 'block');
  }

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.button?.nativeElement) {
      this.renderer.setStyle(this.button.nativeElement, 'display', 'none');
    } else {
      this.renderer.setStyle(this.button.nativeElement, 'display', 'block');
    }
  }

  compareFunction(option, value): boolean {
    return option === value
  }
}
