import { MailAction } from './MailAction';
import { NotificationType } from './NotificationType';
import { NotificationCategory } from './NotificationCategory';
import { NotificationDismissType } from './NotificationDismissType';
import { ComposeMailAction } from './ComposeMailAction';

export class Notification {
    id: string;
    uiId: string;
    type: NotificationType;
    category: NotificationCategory;
    time: Date;
    message: string;
    placeholderInfo1: string;
    placeholderInfo2: Array<string> | string;
    undoClassifyAction: boolean;
    undoColor: string;
    undoClassifySnackbarMessage: string;
    undoSendAction: boolean;
    undoSendSnackbarMessage: string;
    undoActionReady: boolean;
    undoSendReady: boolean;
    debugAction: boolean;
    debugClipboardInfo: string;
    undoClassifyActionObject: MailAction;
    dismissType: NotificationDismissType;
    created_dt: string;
    undoSendActionObject?: ComposeMailAction;
    undoClassifyQueued = false;
    actionIdentifier: string;
    undoSendQueued = false;

    constructor(raw?: any) {
      if (!raw)
        return;

      this.id = raw.id;
      this.uiId = raw.id;
      this.type = raw.type;
      this.category = raw.category;
      this.message = raw.message;
      this.placeholderInfo1 = raw.placeholderInfo1;
      this.placeholderInfo2 = raw.placeholderInfo2;
      this.undoClassifyAction = raw.undoClassifyAction;
      this.undoClassifySnackbarMessage = raw.undoClassifySnackbarMessage;
      this.undoSendAction = raw.undoSendAction;
      this.undoSendSnackbarMessage = raw.undoSendSnackbarMessage;
      this.undoActionReady = raw.undoActionReady;
      this.debugAction = raw.debugAction;
      this.debugClipboardInfo = raw.debugClipboardInfo;
      this.undoClassifyActionObject = raw.undoClassifyActionObject;
      this.dismissType = raw.dismissType;
      this.created_dt = raw.created_dt;
      this.time = new Date(this.created_dt);
      this.undoSendActionObject = raw.undoSendActionObject;
      this.undoColor = raw.undoColor ? raw.undoColor : 'black';
      this.actionIdentifier = raw.actionIdentifier;
    }
  }

