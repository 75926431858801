<div class="lead-summary-container" *ngIf="contact">
  <div class="title-wrapper" fxLayout="row">
    <span>Lead Information</span>
    <div>
      <!-- <a *ngIf="contact.linked_in_urls.linked_in_url_gen"
        class="icon" 
        [href]="contact.linked_in_urls.linked_in_url_gen"
        target="_blank"
      >
        <img src="/assets/images/linkedIn_icon.svg" alt="Visit Lead's LinkedIn Profile">
      </a> -->
      <!-- <a *ngIf="contact.linked_in_urls.linked_in_company_url"
        class="icon"
        [href]="contact.linked_in_urls.linked_in_company_url"
        target="_blank"
      >
        <mat-icon>domain</mat-icon>
      </a> -->
      <!-- <button (click)="copyInfo()" class="icon"><mat-icon>content_copy</mat-icon></button> -->
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="8" style="align-items: center;">
    <img fxFlex="12" src="/assets/images/lead_avatar.svg" alt="Lead's Profile picture">
    <div fxFlex="60">
      <p class="lead-name">{{ contact.fullName }}</p>
      <p class="field-data">{{ contact.emailAddress }}</p>
      <p class="field-data">{{ contact.title }}</p>
    </div>
    <button fxFlex="28" class="alias-btn" mat-stroked-button (click)="updateLeadStatus()" [disabled]="isAliasBtnDisabled">Alias</button>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div fxFlex="33">
      <p class="field-name">Country</p>
      <p class="field-data">{{ contact.country }}</p>
    </div>
    <div fxFlex="33">
      <p class="field-name" >State</p>
      <p class="field-data">{{ contact.state }}</p>
    </div>
    <div fxFlex="33">
      <p class="field-name">Company</p>
      <p class="field-data" >{{ contact.companyName }}</p>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div fxFlex="33">
      <p class="field-name">Qty. of Leads</p>
      <p class="field-data">{{ contact.companyEmployeeCount }}</p>
    </div>
    <div fxFlex="66">
      <p class="field-name">LinkedIn Employees</p>
      <p class="field-data">{{ contact.companySize }}</p>
    </div>
  </div>
</div>