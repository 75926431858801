import { Injectable } from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import { ActionDictionaryItem } from '../model/ActionDictionaryItem';


@Injectable({
  providedIn: 'root'
})
export class ActionService {
  private actionDictionarySubject = new BehaviorSubject<ActionDictionaryItem[]>([]);


  constructor() {
  }

  get actionDictionary() {
    return this.actionDictionarySubject.asObservable();
  }

  addActionItem(actionId: string, actionPayload: any) {
    const currentDictionary = this.actionDictionarySubject.getValue();
    console.log('Adding action to queue: ', { actionId, actionPayload });
    currentDictionary.push({
      id: actionId,
      actionPayload,
      undoFlag: false
    });
    this.actionDictionarySubject.next(currentDictionary);
  }

  undoActionItem(actionId: string) {
    const currentDictionary = this.actionDictionarySubject.getValue();
    const actionTarget = currentDictionary.find(a => a.id === actionId);
    console.log('Undoing action: ', {actionId, actionTarget });
    if (actionTarget) {
      actionTarget.undoFlag = true;
    }
    this.actionDictionarySubject.next(currentDictionary);
  }

  isUndone(actionId: string) {
    const currentDictionary = this.actionDictionarySubject.getValue();
    const actionTarget = currentDictionary.find(a => a.id === actionId);
    console.log('Is action undone: ', { actionId, actionTarget });
    if (actionTarget) {
      return actionTarget.undoFlag;
    }
    return false;
  }

  removeActionItem(actionId: string) {
    const currentDictionary = this.actionDictionarySubject.getValue();
    const actionTargetIndex = currentDictionary.findIndex(a => a.id === actionId);
    console.log('Removing action: ', { actionId, actionTargetIndex })
    if (actionTargetIndex !== -1) {
      currentDictionary.splice(actionTargetIndex, 1);
    }
    this.actionDictionarySubject.next(currentDictionary);
  }

}
