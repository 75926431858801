import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IEmailStatus, IInteraction, IInteractionContact } from 'src/app/model/DiscrepancyLeadToBulk';
import { DiscrepanciesDataService } from 'src/app/services/discrepancies-data.service';
import { SelectedDiscrepancyService } from 'src/app/services/selected-discrepancy.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Email } from 'src/app/model/Email';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContactDateDialogComponent } from './contact-date-dialog/contact-date-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Subscription } from 'rxjs';
import { IDiscrepancy } from 'src/app/model/Discrepancy';
dayjs.extend(utc);
dayjs.extend(timezone); 

@Component({
  selector: 'app-ltb-email-classifier',
  templateUrl: './ltb-email-classifier.component.html',
  styleUrls: ['./ltb-email-classifier.component.scss']
})
export class LtbEmailClassifierComponent implements OnInit, OnDestroy {
  statuses = []
  selectedLead: IInteractionContact = null;
  selectedEmail: Email = null;
  subscriptions = new Subscription();
  selectedInteraction: IInteraction = null;
  selectedDiscrepancy: IDiscrepancy = null;
  searchSDR: number = null;

  constructor(
    private discrepancyDataService: DiscrepanciesDataService,
    private snackbar: MatSnackBar,
    private selectedEmailService: SelectedDiscrepancyService,
    private dialog: MatDialog,
    private selectedDiscrepancyService: SelectedDiscrepancyService
  ) { }

  ngOnInit(): void {
    this.loadStatuses();
    this.subscriptions.add(this.discrepancyDataService.selectedSearchResult.subscribe(lead => { 
      this.selectedLead = lead?.contact
    }));

    this.subscriptions.add(this.selectedEmailService.selectedEmail.subscribe(email => {
      this.selectedEmail = email;
    }));

    this.subscriptions.add(this.discrepancyDataService.selectedInteraction.subscribe(interaction => { 
      this.selectedInteraction = interaction;
    }));

    this.subscriptions.add(this.selectedDiscrepancyService.selectedLog.subscribe(log => { 
      this.selectedDiscrepancy = log;
    }))

    this.subscriptions.add(this.discrepancyDataService.newSearchParams.subscribe(params => { 
      this.searchSDR = params?.sdr;
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadStatuses() {
    this.discrepancyDataService.getLTBEmailStatuses().subscribe((response: IEmailStatus[]) => {
      this.statuses = response;
    })
  }

  onClassifyLTB(newStatus: IEmailStatus) {
    if(this.selectedLead.contactStatus !== "Sent" && this.selectedLead.contactStatus !== "Out of Office") {
      this.dialog.open(ConfirmDialogComponent, {
        width: '370px',
        panelClass: 'discrepancy-confirmation-dialog',
        data: {
          title: 'Confirmation',
          message: `Are you sure you want to update the status from ${this.selectedLead.contactStatus} to ${newStatus.name}?`
        }
      }).afterClosed().subscribe(response => {
        if(response) {
          this.classifyLead(newStatus);
        }
      })
    } else {
      this.classifyLead(newStatus)
    }
  }

  private classifyLead(status: IEmailStatus) {
    if(!this.selectedInteraction) return;
    const attrs: any = {
      interactionSourceId: 2,
      reason: status.id,
      contactId: this.selectedLead.contactId,
      sdrId: this.searchSDR,
      interactionId: this.selectedInteraction.interactionId
    }

    if(status.id === 18) {
      attrs.messageId = this.selectedInteraction.messageId;
      attrs.interactionUtcDatetime = this.selectedInteraction.utcDateTimeInteraction;
      attrs.forwardedContactId = this.selectedDiscrepancy?.sender_lead?.id;
    } else {
      if(!this.selectedEmail) {
        this.snackbar.open('Unable to retrieve email information, please select the corresponding email.', null, { 
          duration: 3000,
        });
        return;
      } else {
        attrs.replyMessageId = this.selectedEmail.messageId;
        // attrs.received_datetime = dayjs(this.selectedEmail.date).tz("America/Buenos_Aires").format('YYYY-MM-DDTHH:mm:ss');
      }
  }

    if(status.id === 15) {
      const contactDate: FormControl = new FormControl('', [Validators.required]);
      this.dialog.open(ContactDateDialogComponent, {
        width: '370px',
        height: '205px',
        panelClass: 'classification-contact-date',
        data: { 
          contactDate
        }
      }).afterClosed().subscribe(result => {
        if(!result) {
          this.snackbar.open('A contact date must be defined to classify this email as To Contact.', null, { 
            duration: 3000,
          });
          return;
        } else {
          attrs.toContactDate = this.formatContactDate(result);
          this.executeClassify(attrs);
        }
      });
    } else {
      this.executeClassify(attrs);
    }
  }

  private executeClassify(attrs) {
    this.discrepancyDataService.createInteraction(attrs).subscribe((response: any) => {
      if(response.code === 200) {
        this.displaySnackbarMessage('Email status successfully updated.');
        this.discrepancyDataService.setInteractionsUpdated(true);
      } else {
        this.displaySnackbarMessage('An error occurred while updating email status.');
      }
    }, error => {
      this.displaySnackbarMessage('An error occurred while updating email status.');
    })
  }

  private displaySnackbarMessage(message: string) {
    if(!message) return;
    this.snackbar.open(message, null, { 
      duration: 3000,
    });
  }

  private formatContactDate(date: string) {
    return `${dayjs(date).format('YYYY-MM-DD')}T00:00:00.000Z`
  }

}
