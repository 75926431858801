<div
  fxLayout="column"
  class="email-feed-item-wrapper"
  [ngClass]="{
    selected: isSelected && !email.hasError,
    error: email.hasError && !isSelected,
    selectedError: isSelected && email.hasError
  }"
>
  <div
    fxLayout="row"
    fxLayoutAlign="start"
    class="mail-notification"
  >
    <mat-checkbox
      style="margin-right: 16px"
      color="primary"
      [checked]="isSelected"
      [disabled]="email.storageId === selectedMailId"
      (change)="toggleSelect()"
    >
    </mat-checkbox>

    <div
      fxLayout="column"
      fxFlex="95"
      style="cursor: pointer"
      (click)="select()"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <span class="email-subject">{{ email.subject }}</span>
        <div
          fxLayout="row"
          [ngSwitch]="email.sendStatus"
          *ngIf="selectedFolder === 'Sent'"
          class="mail-status"
        >
          <mat-icon
            *ngSwitchCase="SendStatusType.InProgress"
            class="material-icons-outlined"
            >pending</mat-icon
          >
          <mat-icon
            *ngSwitchCase="SendStatusType.Error"
            class="material-icons-outlined sent-status-error"
            >error</mat-icon
          >
        </div>
      </div>
      <div class="sdrs-info">
        <mat-chip-listbox
          class="sdrs-list"
          #chipList
        >
          <mat-chip-option
            [selectable]="false"
            [removable]="false"
            [disableRipple]="true"
            class="sdrs-list__chip-item"
          >
            <mat-icon>person</mat-icon>
            {{ email.sdr }}
          </mat-chip-option>
        </mat-chip-listbox>
        <mat-icon *ngIf="email.hasAttachments">attachment</mat-icon>
      </div>
      <div>
        <div class="sender-recipient-details">
          <span>From </span> <span style="color: black">{{ email.from }} </span> <span>to </span>
          <span style="color: black"
            >{{ email.to.split(',', 3) }}{{ email.to.split(',').length > 3 ? '...' : '' }}</span
          >
        </div>
        <div class="date-wrapper">
          <p>Local: {{ email.date | date: 'E, dd LLL yyyy hh:mm aaa' }}</p>
          <p class="secondary-text">ART: {{ getArgentinianDate() | date: 'E, dd LLL yyyy hh:mm aaa' }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
