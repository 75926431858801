<div class="discrepancies-container">
  <div fxLayout="row" class="content-wrapper">
    <div fxFlex="51">
      <app-classifier></app-classifier>
    </div>
    <div fxFlex="28">
      <app-search-leads></app-search-leads>
    </div>
    <div fxFlex="21" class="classifications-wrapper">
      <app-lead-summary></app-lead-summary>
      <app-ltb-email-classifier></app-ltb-email-classifier>
    </div>
  </div>
</div>
