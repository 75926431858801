import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IOperatorUserToggleActivePayload, ToggleOperatorUserActions, ToggleOperatorUserModalData } from '../../../../model/Operator';

@Component({
  selector: 'app-operator-user-active-toggle',
  templateUrl: './operator-user-active-toggle.component.html',
  styleUrls: ['./operator-user-active-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OperatorUserActiveToggleComponent {

  public ToggleOperatorUserActions = ToggleOperatorUserActions;
  public actionToDisplay!: string;

  constructor(
    public dialog: MatDialogRef<OperatorUserActiveToggleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ToggleOperatorUserModalData
  ) {
    this.actionToDisplay = data.action === ToggleOperatorUserActions.ENABLE ? 'active' : 'inactive';
  }

  public onDismiss(): void {
    this.dialog.close(null);
  }

  public onConfirm(): void {
    this.dialog.close(this.generateBulkTogglePayload());
  }

  private generateBulkTogglePayload(): IOperatorUserToggleActivePayload {
    return {
      usernames: this.data.operatorsUsers.map(operatorUser => operatorUser.email),
      action: this.data.action
    }
  }
}
