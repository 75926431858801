import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Attachment } from '../model/Attachment';
import { Email } from '../model/Email';

@Injectable({
  providedIn: 'root',
})
export class DiscrepanciesService {
  private apiBaseUrl = environment.apiBaseURL;
  constructor(private httpClient: HttpClient) {}

  getMessageFromDiscrepancy(uid: string): Observable<Email> {
    return this.httpClient.get(`${this.apiBaseUrl}/app/mails/messages/${uid}`).pipe(
      map((mailData: any) => {
        if (mailData.attachments) {
          mailData.attachments = mailData.attachments.map((attachment: any) => {
            return new Attachment({
              filename: attachment.name,
              key: attachment.key,
              isSafe: attachment.is_safe,
              fileId: 'TODO discard',
              size: attachment.size,
              isLocal: false,
            });
          });
        }
        return new Email(mailData);
      }),
    );
    /*  return of({
        "storage_id": "6398b7b0261b16111e561d1d",
        "subject": "test EML",
        "from": "Juliana Zorzenon <juliana.zorzenon@bairesdev.com>",
        "from_": {
            "name": "Juliana Zorzenon",
            "email": "juliana.zorzenon@bairesdev.com"
        },
        "to": "operator1 <operator1@bairesdev.org>, Mail Operator 1 <operator1@mailoperators.com>",
        "to_": [
            {
                "name": "operator1",
                "email": "operator1@bairesdev.org"
            },
            {
                "name": "Mail Operator 1",
                "email": "operator1@mailoperators.com"
            }
        ],
        "cc": "",
        "cc_": [],
        "message_id": "<CAAKtNdi0qKbm_D3gLSx884oyDBX-YwcK5sWLnoiskWwkyOWyiA@mail.gmail.com>",
        "date": "2022-12-13T17:32:41.348000+00:00",
        "body_html": "<div dir=\"ltr\">testing</div>\r\n",
        "body_plain": "testing\r\n",
        "to_addresses": [
            "operator1@bairesdev.org",
            "operator1@mailoperators.com"
        ],
        "uid": "X-GM-MSGID:1752120962683383436",
        "sdr": "operator1@mailoperators.com",
        "sdrName": "David Russo",
        "imap": "gmail",
        "folder_id": 24,
        "references": [],
        "in_reply_to": "",
        "has_attachments": true,
        "attachments": [
            {
                "name": "Not Interested.eml",
                "size": 4415,
                "content_type": "message/rfc822",
                "content_id": "f_lbmi3q5w0",
                "key": "0c4a50bf15d4899f371f70f3662ac340",
                "is_safe": true
            },
            {
                "name": "test.eml",
                "size": 681727,
                "content_type": "message/rfc822",
                "content_id": "f_lbmi3q651",
                "key": "8d64f132aed8e748dfe0d83f4b0c1268",
                "is_safe": true
            },
            {
                "name": "image.png",
                "size": 243629,
                "content_type": "image//png",
                "content_id": null,
                "key": "d72218c2eba6a0810c74000c535069f8",
                "is_safe": false
            }
        ]
    }).delay(1000)
      .pipe(map((mailData: any) => {
      if (mailData.attachments) {
        mailData.attachments = mailData.attachments.map((attachment: any) => {
          return new Attachment({
            filename: attachment.name,
            key: attachment.key,
            isSafe: attachment.is_safe,
            fileId: 'TODO discard',
            size: attachment.size,
            isLocal: false,
          });
        });
      }
      return new Email(mailData);
    })); */
  }
}
