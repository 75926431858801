<div fxLayout="row" fxLayoutAlign="space-between start" class="email-toolbar-wrapper">
  <div fxLayout="column" fxFlex="100%" *ngIf="currentEmail">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="email-heading-wrapper">
      <div fxFlex="50" [fxFlexOrder.lt-md]="2" class="email-title">
        <span [matTooltip]="currentEmail.subject" matTooltipPosition="below">{{ currentEmail.subject }}</span>
        <mat-icon
          *ngIf="currentEmail.sendStatus === 'Error' && selectedFolder === 'Sent'"
          class="material-icons-outlined email-title-icon"
          [matTooltip]="currentEmail.errorMessage"
          matTooltipPosition="right"
        >
          announcement
        </mat-icon>
      </div>

      <div fxLayoutAlign="end end" [fxFlexOrder.lt-md]="1" [ngClass.lt-md]="'margin-bottom'">
        <div class="toolbar-actions" fxLayout="row" fxLayoutAlign="end end" style="color: #4b4b4b">
          <button mat-stroked-button *ngIf="currentEmail" (click)="copyDebug()" matTooltip="Clipboard Debug"><mat-icon>bug_report</mat-icon></button>
          <!-- <button mat-stroked-button *ngIf="currentEmail && isSender" (click)="compose('reply')" matTooltip="Reply" [disabled]="isComposerOpen"><mat-icon>reply</mat-icon></button> -->
          <button mat-stroked-button *ngIf="currentEmail && isSender" (click)="compose('replyAll')" matTooltip="Reply All" [disabled]="isComposerOpen"><mat-icon>reply_all</mat-icon></button>
          <button mat-stroked-button *ngIf="currentEmail && isSender" (click)="compose('forward')" matTooltip="Forward" [disabled]="isComposerOpen"><mat-icon>forward</mat-icon></button>
          <button mat-stroked-button *ngIf="currentEmail && mailTo.length>0" (click)="openMailTo()" matTooltip="Inspect Mailto"><mat-icon>remove_red_eye</mat-icon></button>
          <!-- <button mat-stroked-button *ngIf="currentEmail" (click)="selectedMailService.triggerClassifyMail(trashOption)" matTooltip="Move to Trash"><mat-icon>delete</mat-icon></button> -->
        </div>
      </div>
    </div>

    <div>
      <app-mail-sender-recipients-details [email]="currentEmail"></app-mail-sender-recipients-details>
    </div>

    <div *ngIf="currentEmail.hasAttachments" fxLayout="column">
      <div fxFlex="row" fxFlexFill fxLayoutAlign="start start" id="readAttachmentsDiv">
        <div *ngFor="let attachment of currentEmail.attachments" fxLayoutAlign="start center">
          <app-attachment
            [attachment]="attachment"
            (clickEvt)="
              messageAttachmentService.downloadRemoteFile(attachment.key, attachment.filename, attachment.isSafe)
            "
          ></app-attachment>
        </div>
      </div>
    </div>
  </div>
</div>
