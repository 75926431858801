import { AfterViewInit, Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IInquiryLead } from 'src/app/model/InquiryLead';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';
import { InquiryService } from 'src/app/services/inquiry.service';

@Component({
  selector: 'app-inquiry-history',
  templateUrl: './inquiry-history.component.html',
  styleUrls: ['./inquiry-history.component.scss'],
})
export class InquiryHistoryComponent implements OnInit, AfterViewInit {
  currentInquiryLead: IInquiryLead;
  listData: MatTableDataSource<any> = null;
  loading = false;
  displayedColumns: string[] = [
    'leadToCampaignId',
    'campaignId',
    'campaignBulkDate',
    'customerName',
    'userId',
    'userEmail',
    'emailStatus',
    'dateStatus',
  ];
  selectedRow = new Set<any>();
  @Output() setSelectedHistoryRow = new EventEmitter();
  @ViewChild('historySort') historySort: MatSort;
  @ViewChild('historyPaginator') historyPaginator: MatPaginator;
  constructor(
    private inquiryService: InquiryService,
    private loadingStateService: LoadingStateService,
  ) {}

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  ngAfterViewInit() {
    this.inquiryService.inquiryLeads.subscribe((inquiryLeads) => {
      if (inquiryLeads?.length) {
        this.currentInquiryLead = inquiryLeads.filter((il) => il.selected)[0];
        const array = this.currentInquiryLead?.history?.map((item) => {
          return {
            $key: item.leadToCampaignId,
            leadToCampaignId: item.leadToCampaignId,
            campaignId: item.campaignId,
            campaignBulkId: item.campaignBulkId,
            campaignBulkDate: item.campaignBulkDate,
            customerName: item.customerName,
            userId: item.userId,
            userEmail: item.userEmail,
            emailStatus: item.emailStatus,
            dateStatus: item.dateStatus,
          };
        });
        this.listData = new MatTableDataSource(array);
        this.listData.sort = this.historySort;
        this.listData.paginator = this.historyPaginator;
        this.listData.filterPredicate = (data, filter) => {
          return this.displayedColumns.some((ele) => {
            return data[ele].toLowerCase().indexOf(filter) != -1;
          });
        };
      } else {
        this.listData = new MatTableDataSource([]);
        this.listData.paginator = this.historyPaginator;
      }
    });
  }

  onRowClicked(row) {
    this.selectedRow.clear();
    this.selectedRow.add(row);
    this.setSelectedHistoryRow.emit(row);
  }
}
