<div fxLayout="row" class="top-bar" fxLayoutAlign="space-between center">
  <div class="dialog-name">Details</div>
  <div fxLayout="row">
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>
</div>
<div class="lead-details-container" mat-dialog-content>
  <mat-accordion multi>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Lead ID: {{ lead.id }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="lead-personal-info-wrapper">
        <div>
          <img src="/assets/images/lead_avatar.svg" alt="Lead Avatar">
          <div>
            <p><b>{{ lead.first_name}} {{ lead.last_name}}</b></p>
            <p>{{ lead.email }}</p>
          </div>
        </div>
        <div fxLayout="column">
          <small *ngIf="lead.campaign_id">Campaign ID: <strong>{{ lead.campaign_id }}</strong></small>
          <small *ngIf="lead.template_id">Template ID: <strong>{{ lead.template_id }}</strong></small>
        </div>
        <div class="icons-wrapper">
          <span class="status-chip">{{ lead.status.name }}</span>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Details - Lead to bulk
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="lead-details-wrapper">
        <form [formGroup]="form">
          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="33" class="lead-to-bulk-date-input">
              <mat-label>Date Received</mat-label>
              <input matInput [ngxMatDatetimePicker]="pdt" formControlName="date_received" (dateChange)="dateChanged()">
              <mat-datepicker-toggle matSuffix [for]="pdt"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #pdt [showSpinners]="true" [showSeconds]="true" [enableMeridian]="false">
              </ngx-mat-datetime-picker>
            </mat-form-field>

            <mat-form-field fxFlex="33" class="lead-to-bulk-date-input">
              <mat-label>Contact Date</mat-label>
              <input matInput [ngxMatDatetimePicker]="cdt" formControlName="contact_date" [min]="minContactDate">
              <mat-datepicker-toggle matSuffix [for]="cdt"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #cdt [showSpinners]="true" [showSeconds]="true" [enableMeridian]="false">
              </ngx-mat-datetime-picker>
              <mat-error *ngIf="form.controls.contact_date.hasError('matDatepickerMin')">Date cannot be before Date Received</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="33" class="lead-to-bulk-comments-input">
              <mat-label>Comments</mat-label>
              <input matInput formControlName="comments">
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="33">
              <mat-label>Sales Rep</mat-label>
              <mat-select formControlName="client_contact" [compareWith]="compareFunction">
                <mat-option class="client-contact-opt" *ngFor="let contact of salesRepresentatives" [value]="contact">
                  {{ contact.name }} <span class="cc-geographic-zone">| {{ contact.geographicZone }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="33">
              <mat-label>Qualification</mat-label>
              <mat-select formControlName="source" [compareWith]="compareFunction" (selectionChange)="onChangeQualification()">
                <mat-option *ngFor="let qualification of selectsData.qualifications" [value]="qualification">
                  {{ qualification.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="33">
              <mat-label>ID Lead Forward</mat-label>
              <input matInput formControlName="id_lead_forward">
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="24">
              <mat-label>Matrix</mat-label>
              <mat-select formControlName="matrix" [compareWith]="compareFunction" (selectionChange)="getDefaultValues()">
                <mat-option *ngFor="let matrixOption of selectsData?.matrix" [value]="matrixOption">
                  {{ matrixOption.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="24">
              <mat-label>Country</mat-label>
              <mat-select formControlName="country" [compareWith]="compareFunction" (selectionChange)="calculateScore()">
                <mat-option *ngFor="let country of selectsData?.country" [value]="country">
                  {{ country.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="24">
              <mat-label>Delay Time</mat-label>
              <mat-select formControlName="delay" [compareWith]="compareFunction" (selectionChange)="calculateScore()">
                <mat-option *ngFor="let delay of selectsData?.delay" [value]="delay">
                  {{ delay.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="24">
              <mat-label>Industry</mat-label>
              <mat-select formControlName="industry" [compareWith]="compareFunction" (selectionChange)="calculateScore()">
                <mat-option *ngFor="let industry of selectsData?.industry" [value]="industry">
                  {{ industry.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="50">
              <mat-label>Score</mat-label>
              <input matInput formControlName="score">
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <mat-label>Msg ID</mat-label>
              <input matInput formControlName="message_id_received">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="33">
              <mat-label>Campaign Bulk ID</mat-label>
              <input matInput formControlName="lead_to_campaign_id">
            </mat-form-field>
            <mat-form-field fxFlex="33">
              <mat-label>Date Campaign Bulk</mat-label>
              <input matInput [matDatepicker]="dcb" formControlName="campaign_bulk_date" (dateChange)="dateChanged()">
              <mat-datepicker-toggle matSuffix [for]="dcb"></mat-datepicker-toggle>
              <mat-datepicker #dcb>
              </mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex="33">
              <mat-label>Job Posting ID</mat-label>
              <input matInput formControlName="job_linkedin_id">
            </mat-form-field>
          </div>
        </form>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="onClickCancel()" cdkFocusInitial>Cancel</button>
  <button mat-raised-button class="lead-save-btn" (click)="onClickSave()" [disabled]="!isSaveEnabled">Save</button>
</div>