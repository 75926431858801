<div class="form-row">
  <span *ngIf="isPartial" class="partial-tag">Partial</span>
  <mat-form-field class="new-lead-field" appearance="standard">
    <mat-label>{{ label }}</mat-label>
    <input matInput [value]="source" [type]="controlType" readonly>
  </mat-form-field>
  <div class="button-wrapper">
    <button mat-stroked-button (click)="onCopy()" #copyBtn type="button"><mat-icon>arrow_forward</mat-icon></button>
  </div>
  <ng-container [ngSwitch]="controlType">
    <ng-container *ngSwitchCase="'select'">
      <mat-form-field>
        <mat-label>{{ label }}</mat-label>
        <mat-select [formControl]="fieldControl" [compareWith]="compareFunction">
          <mat-option *ngFor="let item of selectData" [value]="label === 'Pattern Lead' ? item.id : item.name" >
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mat-form-field class="new-lead-field" appearance="standard">
        <mat-label>{{ label }}</mat-label>
        <input matInput [formControl]="fieldControl" [type]="controlType" (focus)="onFocusField()">
      </mat-form-field>
    </ng-container>
  </ng-container>
  
</div>