export interface ITemplate {
    id: number;
    date: string;
    title: string;
    attachment: boolean;
    sdr: string;
    clientContact: string;
    notes: string;
    isSelected: boolean;
    bcc: string;
    cc: string;
    clientContactEmail: string;
    clientContactId: number;
    clientId: number;
    tags: string;
    templateText: string;
    templateTypeId: number;
    isInternalUse: boolean;
    sortDate: Date;
    hasAttachments: boolean;
    client: string;
  }
export interface ITemplateDeleteResponse {
    data: number;
    message: string;
    code: number;
}

export class Template implements ITemplate {
  id: number;
  date: string;
  title: string;
  attachment: boolean;
  sdr: string;
  clientContact: string;
  notes: string;
  bcc: string;
  cc: string;
  clientContactEmail: string;
  clientContactId: number;
  clientId: number;
  tags: string;
  templateText: string;
  templateTypeId: number;
  isSelected: boolean;
  isInternalUse: boolean;
  sortDate: Date;
  hasAttachments: boolean;
  client: string;

  constructor(template) {
    this.id = template.TemplateId;
    this.date = this.formatDate(new Date(template.DateCreated));
    this.title = template.Title;
    this.attachment = template.Attachment;
    this.sdr = template.Sdr;
    this.clientContact = template.ClientContact;
    this.notes = template.Notes;
    this.bcc =  template.Bcc;
    this.cc = template.Cc;
    this.clientContactEmail = template.ClientContactEmail;
    this.clientContactId = template.ClientContactId
    this.clientId = template.ClientId;
    this.tags = template.Tags;
    this.templateText = template.TemplateText;
    this.templateTypeId = template.TemplateTypeId;
    this.isSelected = false;
    this.isInternalUse = template.InternalUseOnly;
    this.sortDate = new Date(template.DateCreated);
    this.hasAttachments = template.Attachment && template.Attachment.length;
    this.client = template.Client;
  }

  formatDate(date) {
    return [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
}