import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { IDiscrepancy } from 'src/app/model/Discrepancy';
import { IInteraction } from 'src/app/model/DiscrepancyLeadToBulk';
import { InquiryLead } from 'src/app/model/InquiryLead';
import { DiscrepanciesDataService } from 'src/app/services/discrepancies-data.service';
import { InquiryService } from 'src/app/services/inquiry.service';
import { SelectedDiscrepancyService } from 'src/app/services/selected-discrepancy.service';

@Component({
  selector: 'app-view-lead-toolbar',
  templateUrl: './view-lead-toolbar.component.html',
  styleUrls: ['./view-lead-toolbar.component.scss']
})
export class ViewLeadToolbarComponent implements OnInit, OnDestroy {
  lead: InquiryLead;
  subscriptions = new Subscription();
  selectedSdr = null;
  selectedDiscrepancy: IDiscrepancy = null;
  @Input() selectedHistoryRow: Set<IInteraction>;
  constructor(
    private inquiryService: InquiryService,
    private discrepancyDataService: DiscrepanciesDataService,
    private snackbar: MatSnackBar,
    private selectedDiscrepancyService: SelectedDiscrepancyService
  ) { }

  
  ngOnInit() {
    this.subscriptions.add(this.inquiryService.inquiryLeads.subscribe((inquiryLeads) => {
      if (inquiryLeads?.length) {
        this.lead = inquiryLeads[0];
      }
    }));

    this.subscriptions.add(this.discrepancyDataService.newSearchParams.subscribe(params => {
      this.selectedSdr = params?.sdr;
    }));

    this.subscriptions.add(this.selectedDiscrepancyService.selectedLog.subscribe(log => { 
      this.selectedDiscrepancy = log;
    }));
  }

  ngOnDestroy(){
    this.subscriptions.unsubscribe();
  }

  get isReassignEnabled(): boolean {
    return this.lead && this.selectedHistoryRow?.size !== 0 && !!this.selectedDiscrepancy?.sdr?.id;
  }

  get leadToCampaignValue(): number {
    return this.lead?.history[0]?.leadToCampaignId;
  }

  onClickReassign() {
    if(this.selectedHistoryRow.size === 0) return;
    this.discrepancyDataService.createInteraction({
      interactionSourceId: 2,
      contactId: this.lead.leadId,
      interactionUtcDatetime: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
      messageId: this.selectedDiscrepancy.message.message_id,
      sdrId: this.lead.history[0]?.userId,
      forwardedSdrId: this.selectedSdr || this.selectedDiscrepancy.sdr.id,
    }).subscribe((response: any) => {
      if(response.code === 200) {
        this.displaySnackbarMessage('Lead successfully reassigned.');
      } else {
        this.displaySnackbarMessage('An error occurred while reassigning Lead.');
      }
    }, () => {
      this.displaySnackbarMessage('An error occurred while reassigning Lead.');
    });
  }

  private displaySnackbarMessage(message: string) {
    if(!message) return;
    this.snackbar.open(message, null, { 
      duration: 3000,
    });
  }

  onClickAddToBulk() {}

}
