import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-classifier-log-folder',
  templateUrl: './classifier-log-folder.component.html',
  styleUrls: ['./classifier-log-folder.component.scss'],
})
export class ClassifierLogFolderComponent implements OnInit, OnChanges {
  @Input() folderType: string;
  constructor() {}

  ngOnInit(): void {
    this.formatName();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.folderType) {
      this.formatName();
    }
  }

  formatName() {
    if (!this.folderType) return;
    const splitName = this.folderType.split('/');
    if (splitName[1]) {
      const folderName = splitName[1].replace(/\d{1,2}_/g, '');
      // @ts-ignore
      this.folderType = folderName.replaceAll('_', ' ');
    } else {
      // @ts-ignore
      this.folderType = splitName[0].replaceAll('_', ' ');
    }
  }
}
