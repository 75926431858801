import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { DefaultAPIResponse, DefaultAPIResponsePaginated } from '../model/APIResponse';
import { IDetailedSdr, IReassignSdrPayload, ISdrFilters, ISdrFiltersResponse } from '../model/Sdr';

@Injectable({
  providedIn: 'root'
})
export class SdrService {
	private apiBackEnd!: string;

	constructor(private http: HttpClient) {
		this.apiBackEnd = environment.apiBaseURL;
	}

  public getSdrs(
    page: number,
    size: number,
    filters?: ISdrFilters,
  ): Observable<DefaultAPIResponsePaginated<IDetailedSdr[]>> {
    let params = new HttpParams()
    .append('page', page)
    .append('size', size);

    if(filters) {
      Object.keys(filters).forEach(key => {
        params = params.append(`${key.slice(0, -1)}_id`, filters[key])
      })
    }

    return this.http.get<DefaultAPIResponsePaginated<IDetailedSdr[]>>(`${this.apiBackEnd}/app/user_operator`, { params })
  }

  public getFilter(
    filterKey: string,
    userInput?: string,
    searchKey?: string,
  ): Observable<DefaultAPIResponsePaginated<ISdrFiltersResponse[]>> {

    let params = new HttpParams().append('resource_name', filterKey);

    if(userInput) {
      params = params.append(searchKey, userInput);
    }

    return this.http.get<DefaultAPIResponsePaginated<ISdrFiltersResponse[]>>(`${this.apiBackEnd}/app/user/management/filters`, { params })
  }

  public reassignSdrs(payload: IReassignSdrPayload[]): Observable<DefaultAPIResponse<[]>> {
    return this.http.post<DefaultAPIResponse<[]>>(`${this.apiBackEnd}/app/users_operators_customers_levels`, payload)
  }
}
