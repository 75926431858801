<div fxLayout="column" style="background: white; height: 60%;" id="emailColumn">
  <div>
    <app-discrepancy-details-tool-bar></app-discrepancy-details-tool-bar>
  </div>

  <div fxFlex="70%" fxLayout="row" fxLayoutAlign="center center" style="padding: 0 16px; height: 100%;">
    <iframe id="emailIframe" #iframe frameborder="0"></iframe>

    <div style="overflow-y: hidden; padding: 20px" *ngIf="!currentEmail">
      <span *ngIf="!loading">Message not found</span>
    </div>
  </div>
  <div fxLayout="row" fxFlex="25%" class="notification-container">
    <app-notification-list></app-notification-list>
  </div>
</div>