<div fxLayout="column" *ngIf="!loadingToken">
  <div style="height: 4px;">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  </div>
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="26" [fxFlex.lt-md]="100" [fxShow.lt-md]="showFeed" style="height: 100%">
      <app-mails-feed></app-mails-feed>
    </div>
    <div fxLayout="column" fxFlex="74" [fxFlex.lt-md]="100" [fxShow.lt-md]="!showFeed" style="border-left: 1px solid #c9c9c9">
      <app-mail-details></app-mail-details>
    </div>
  </div>
</div>

<div *ngIf="showMinComposer">
  <div style="position: fixed; bottom: 0; right: 20px">
    <app-mail-composer-min></app-mail-composer-min>
  </div>
</div>
