import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Amplify from 'aws-amplify';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const authConf = {
  Auth: {
      region: 'us-west-2',
      userPoolId: environment.authUserPoolId,
      userPoolWebClientId: environment.authUserPoolWebClientId,
      oauth: {
          domain : environment.authDomain,
          scope : ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn : 'http://localhost:4200',
          redirectSignOut : 'http://localhost:4200',
          responseType: 'token',
          options: {
              AdvancedSecurityDataCollectionFlag : false
          }
      }
  },
};
Amplify.configure(authConf);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
