import { Component, OnInit } from '@angular/core';
import {SDAuthService} from '../../services/sd-auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private authService: SDAuthService ) { }

  ngOnInit() {
    setInterval(async () => {
      await this.authService.refreshToken();
    }, 1000 * 60 * 30);
  }

}
