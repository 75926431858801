import { Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  attachmentParameters: any;
  constructor(
  ) {
  }

  downloadLocalFile(documentData: Blob, documentName: string) {
    this.downloadDocument(documentData, documentName);
  }

  validateFile(file: File) {
    if (file.size === 0) {
      return 'Attached file can not be empty';
    }

    const allowedExtensions = ['json'];
    const fileExtension = file.name.split('.').pop();
    if (!allowedExtensions.includes(fileExtension)) {
      return 'Invalid file type. Allowed types are ' + allowedExtensions.join();
    }
    return '';
  }

  downloadDocument(data: Blob, documentName: string): void {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(data);
    element.download = documentName;
    document.body.appendChild(element);
    element.click();
    element.remove();
  }

  getBase64(file) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded =  reader.result.toString().replace(/^data:(.*,)?/, '');
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  }

  b64toBlob(b64Data, contentType = '', sliceSize= 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }



}
