<div class="faq-operator-container">
  <div
    class="header-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <span class="faq-title">{{ title }}</span>
    <mat-icon
      mat-dialog-close
      class="close-btn"
      >close</mat-icon
    >
  </div>

  <iframe
    id="faqIframe"
    #faqIframe
    frameborder="0"
    sandbox="allow-same-origin"
  ></iframe>
</div>
