import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ILeadSearchParams } from 'src/app/model/Discrepancy';
import { ILeadInteractionsData, ILeadToBulk, ISelectsData, Interaction } from 'src/app/model/DiscrepancyLeadToBulk';
import { ContactsDataService } from 'src/app/services/contacts-data.service';
import { DiscrepanciesDataService } from 'src/app/services/discrepancies-data.service';
import dayjs from 'dayjs';
import { MatDialog } from '@angular/material/dialog';
import { NewLeadDialogComponent } from '../new-lead-dialog/new-lead-dialog.component';
import { SelectedDiscrepancyService } from 'src/app/services/selected-discrepancy.service';
import { InquiryComponent } from '../../home/inquiry/inquiry.component';

@Component({
  selector: 'app-search-leads',
  templateUrl: './search-leads.component.html',
  styleUrls: ['./search-leads.component.scss'],
})
export class SearchLeadsComponent implements OnInit, OnDestroy {
  isLoadingSearch = false;
  searchResult: ILeadInteractionsData;
  selectsData: ISelectsData;
  salesRepresentatives = [];
  searchCustomerId = null;
  selectedItem = null;
  searchParams = null;
  subscriptions = new Subscription();
  noResultsMessage = '';
  currentLog = null;

  constructor(
    private discrepanciesService: DiscrepanciesDataService,
    private clientsContactService: ContactsDataService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private selectedDiscrepancyService: SelectedDiscrepancyService,
  ) {}

  ngOnInit(): void {
    this.loadSelectsData();

    this.subscriptions.add(
      this.discrepanciesService.interactionsUpdated.subscribe((updated) => {
        if (updated) {
          this.refreshResults();
        }
      }),
    );

    this.subscriptions.add(
      this.selectedDiscrepancyService.selectedLog.subscribe((log) => {
        if (log) {
          this.currentLog = log;
        }
        this.searchResult = null;
        this.discrepanciesService.selectSearchItem(null);
        this.isLoadingSearch = false;
        this.selectedItem = null;
        this.noResultsMessage = '';
      }),
    );

    this.subscriptions.add(
      this.discrepanciesService.newSearchParams.subscribe((params) => {
        if (params?.email && params?.sdr) {
          this.onExecuteSearch(params);
        }
      }),
    );

    this.subscriptions.add(
      this.discrepanciesService.selectedInteraction.subscribe((interaction) => {
        this.selectedItem = interaction;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onExecuteSearch(searchParams: ILeadSearchParams) {
    this.isLoadingSearch = true;
    this.searchCustomerId = searchParams.client;
    this.setSelectedItem(null);
    this.searchParams = searchParams;
    this.subscriptions.add(
      this.discrepanciesService
        .searchLeadInteractions(searchParams.sdr, searchParams.email)
        .pipe(
          //@ts-ignore
          switchMap((searchResponse) => {
            this.searchResult = searchResponse;
            if (searchResponse) {
              this.noResultsMessage = '';
              return combineLatest([
                this.clientsContactService.loadContacts('', 'Active', `${searchParams.client}`, '', 1, 10),
                this.discrepanciesService.getQualifications(searchParams.client),
              ]);
            } else {
              this.noResultsMessage = "We couldn't find any results matching the search criteria";
              return of([{ items: [] }]);
            }
          }),
        )
        .subscribe(
          ([contactsResponse, qualificationsReponse]: any) => {
            this.salesRepresentatives = contactsResponse?.items?.filter(
              (clientContact) => clientContact.geographicZone && clientContact.geographicZone !== 'SUSPENDED',
            );
            this.selectsData.qualifications = qualificationsReponse || [];
            this.isLoadingSearch = false;
          },
          (error) => {
            if (error.status === 404) {
              this.noResultsMessage = "We couldn't find any results matching the search criteria";
            } else {
              this.noResultsMessage = 'An Error occurred while executing search';
            }
            this.salesRepresentatives = [];
            this.selectsData?.qualifications && (this.selectsData.qualifications = []);
            this.isLoadingSearch = false;
          },
        ),
    );
  }

  get isEditableCustomer(): boolean {
    return this.searchCustomerId && this.discrepanciesService.EDITABLE_CUSTOMERS_IDS.includes(this.searchCustomerId);
  }

  refreshResults() {
    this.discrepanciesService
      .searchLeadInteractions(this.searchParams.sdr, this.searchParams.email)
      .subscribe((response) => {
        this.searchResult = response;
        this.discrepanciesService.setInteractionsUpdated(false);
      });
  }

  onUpdateLead(leadToUpdate: ILeadToBulk) {
    // this.discrepanciesService.updateLeadToBulk(leadToUpdate.id, this.mapAttributesToUpdate(leadToUpdate)).subscribe(
    //   (response: any) => {
    //     if(response.code === 200) {
    //       this.snackbar.open('Lead to bulk was successfully updated', null, {
    //         duration: 3000,
    //       });
    //     }
    //   },
    //   error => {
    //     const errorDetails = error.error?.detail?.[0]?.msg || "";
    //     this.snackbar.open(`An error occurred while trying to update the Lead to bulk: ${errorDetails}`, null, { duration: 3000 });
    //   });
  }

  loadSelectsData() {
    this.discrepanciesService.getSelectsData().subscribe((response) => {
      this.selectsData = response;
    });
  }

  setSelectedItem(item) {
    this.selectedItem = item;
    this.discrepanciesService.selectInteractionItem(item);
  }

  onClickNewLead() {
    this.dialog.open(NewLeadDialogComponent, {
      width: '800px',
      panelClass: 'discrepancies-dialog',
      data: {
        country: this.selectsData.country,
        industry: this.selectsData.industry,
      },
    });
  }

  onClickViewLead() {
    this.dialog.open(InquiryComponent, {
      height: '85%',
      width: '80%',
      maxWidth: 'initial',
      panelClass: 'view-lead-dialog',
      data: {
        sdr: this.currentLog.sdr,
        email: this.currentLog?.sender_lead?.email,
        title: 'View Lead',
        context: 'discrepancies',
      },
    });
  }

  private mapAttributesToUpdate(lead: ILeadToBulk) {
    const attrs: any = {
      date_response: dayjs(lead.date_received).format('YYYY-MM-DDTHH:mm:ss'),
      comments: lead.comments,
      client_contact_id: lead.client_contact.id,
      id_lead_forward: lead.id_lead_forward,
      source_id: lead.source.id,
      message_id_received: lead.message_id_received,
      matrix_id: lead.matrix.id,
      delay_id: lead.delay.id,
      country_id: lead.country.id,
      industry_id: lead.industry.id,
      score: lead.score,
    };

    if (lead.contact_date) {
      attrs.contact_date = dayjs(lead.contact_date).format('YYYY-MM-DDTHH:mm:ss');
    }

    return attrs;
  }

  private sortResultsById(results) {
    const x = results.sort((a, b) => {
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;

      return 0;
    });
    return x;
  }
}
