<mat-form-field
  [appearance]="appearance"
  [id]="'multiselect-' + id + '-mat-form-field'"
>
  <div class="chip-list-wrapper hide">
    <mat-chip-listbox
      #chipList
      [id]="'multiselect-' + id + '-mat-chip-listbox'"
    >
      <ng-container *ngFor="let select of selectData; let i = index">
        <mat-chip-option
          class="cardinal-colors"
          [selectable]="false"
          (click)="removeChip(select)"
          [id]="'multiselect-' + id + '-mat-chip-option-' + i"
        >
          {{ select.item }}
          <mat-icon class="mat-chip-remove">cancel</mat-icon>
        </mat-chip-option>
      </ng-container>
    </mat-chip-listbox>
  </div>
  <input
    matInput
    [placeholder]="placeholder"
    [matAutocomplete]="auto"
    [formControl]="selectControl"
    [id]="'multiselect-' + id + '-mat-input'"
  />

  <mat-autocomplete
    #auto="matAutocomplete"
    hideSingleSelectionIndicator
    [displayWith]="displayFn"
    [id]="'multiselect-' + id + '-mat-autocomplete'"
  >
    <mat-option
      class="select-option"
      *ngFor="let data of filteredData | async; let i = index"
      style="font-size: 12px"
      [id]="'multiselect-' + id + '-mat-option-' + i"
    >
      <div
        class="option-container"
        (click)="optionClicked($event, data)"
      >
        <mat-checkbox
          [checked]="data.selected"
          (click)="onCheckboxClick($event, data)"
          color="primary"
          [id]="'multiselect-' + id + '-mat-checkbox-' + i"
        >
          {{ data.item }}
        </mat-checkbox>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
