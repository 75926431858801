import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SalesRepresentative } from "../model/SalesRepresentative";
import { Template } from "../model/TemplateData";


@Injectable({
    providedIn: 'root'
  })
export class TemplateService {

    apiBackEnd = '';
    constructor(private http: HttpClient) {
      this.apiBackEnd = environment.apiBaseURL;
      }

    public getUseTemplate(templateTypeId: number, sdr: string): Observable<Template[]> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("template_type_id", templateTypeId.toString());
        queryParams = queryParams.append("sdr", sdr);
        return this.http.get(`${this.apiBackEnd}/app/template/get_use_template`, { params: queryParams }).pipe(map((response: any) => {
          return response.data.map((template)=> new Template(template));
        }));
    }

    public getTemplateTypes(): Observable<any> {
        return this.http.get(`${this.apiBackEnd}/app/template_type/types`);
    }

    public getSalesRepresentativesByClient(sdrId: number): Observable<SalesRepresentative[]> {
      let queryParams = new HttpParams();
      queryParams = queryParams.append("sdr_id", sdrId.toString());
      return this.http.get(`${this.apiBackEnd}/app/client_contact/get_client_contacts_not_suspended`, { params: queryParams }).pipe(map((response: any) => {
        return response.data.map((salesRep)=> { 
          return new SalesRepresentative({ 
            name: salesRep.Name ? salesRep.Name : salesRep.email,
            profileCC: salesRep.ProfileCC,
            geographicZone: salesRep.GeographicZone
          }) 
        });
      }));
    }

    public getTemplates(templateTypeId: number, limit: number, offset: number,
                        searchText: string, orderBy: string, orderType: string): Observable<any> {
      const url = `${this.apiBackEnd}/app/template/search_template`;
      let queryParams = new HttpParams();
      queryParams = queryParams
                    .append("template_type_id", templateTypeId)
                    .append("limit", limit)
                    .append("offset", offset)
                    .append("search_text", searchText)
                    .append("order_by", orderBy)
                    .append("order_type", orderType);
      return this.http.get(url, { params: queryParams }).pipe(map((response: any) => {
        if(response.data) {
          response.data = response.data.map(template => new Template(template));
        }
        return response;
      }));
    }
}
