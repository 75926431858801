<div class="interaction-data-container">
  <mat-card [ngClass]="{ active: isSelected }">
    <div
      class="header-wrapper"
      fxLayout="row"
      fxLayoutGap="8px"
    >
      <mat-card-header fxFlex="80">
        <div
          mat-card-avatar
          class="interaction-header-image"
        ></div>
        <mat-card-subtitle [matTooltip]="interaction.prospectStatus">
          <div class="date-wrapper">
            <p class="status-chip">{{ interaction.prospectStatus }}</p>
            <p class="status-date">{{ interaction.utcDateTimeInteraction | date: 'YYYY-MM-dd h:mm a' }}</p>
          </div>
        </mat-card-subtitle>
      </mat-card-header>
      <!-- <div class="header-btns-wrapper" fxFlex="20">
        <button mat-raised-button class="interaction-save-btn" (click)="onClickSave($event)" *ngIf="isSelected" [disabled]="!isSaveEnabled">Save</button>
      </div> -->
    </div>
    <mat-card-content [ngClass]="detailsCollapsed ? 'collapsed' : 'expanded'">
      <div
        fxLayout="row"
        class="campaign-info"
      >
        <small *ngIf="interaction.interactionId"
          >Interaction ID: <strong>{{ interaction.interactionId }}</strong></small
        >
        <small *ngIf="interaction.campaignId"
          >Campaign ID: <strong>{{ interaction.campaignId }}</strong></small
        >
        <small *ngIf="interaction.templateId"
          >Template ID: <strong>{{ interaction.templateId }}</strong></small
        >
      </div>
      <div class="form-wrapper">
        <form [formGroup]="form">
          <div
            fxLayout="row"
            fxLayoutGap="8px"
          >
            <mat-form-field fxFlex="50">
              <mat-label>ID Lead Forward</mat-label>
              <input
                matInput
                formControlName="id_lead_forward"
              />
            </mat-form-field>

            <mat-form-field
              fxFlex="50"
              class="search-result-date-input"
            >
              <mat-label>To Contact Date</mat-label>
              <input
                matInput
                [ngxMatDatetimePicker]="cdt"
                formControlName="contact_date"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="cdt"
              ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #cdt
                [showSpinners]="true"
                [showSeconds]="true"
                [enableMeridian]="false"
              >
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>

          <div
            fxLayout="row"
            fxLayoutGap="8px"
          >
            <mat-form-field fxFlex="100">
              <mat-label>Msg ID</mat-label>
              <input
                matInput
                formControlName="message_id_received"
              />
            </mat-form-field>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>
