<div
  fxLayout="row"
  class="reset-password-container"
>
  <div
    fxLayout="row"
    fxFlex.lg="75"
    fxFlex.md="100"
    fxFlex.sm="100"
  >
    <div
      fxLayout="column"
      fxFlex="100"
    >
      <div
        fxLayout="column"
        class="logo-wrapper"
      >
        <img
          src="assets/images/logo_full.png"
          alt="WinDifferent"
        />
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="center"
        class="left-side"
      >
        <ng-container *ngIf="!recoveryCodeRequested; else UpdatePasswordForm">
          <div
            fxLayout="column"
            class="title"
          >
            <div
              fxLayout="row"
              class="back-btn-wrapper"
            >
              <button
                mat-icon-button
                class="back-btn"
                (click)="backToLogin()"
              >
                <mat-icon>arrow_back</mat-icon>
              </button>
              <span class="page-title">Reset Password</span>
            </div>
            <p class="module-name">Operations Module</p>
          </div>
          <form
            [formGroup]="sendCodeForm"
            (submit)="onSendCode()"
          >
            <p class="description">Enter your registered emails address. You will receive a code.</p>
            <mat-form-field appearance="outline">
              <mat-label><mat-icon>person</mat-icon>Email</mat-label>
              <input
                type="text"
                matInput
                formControlName="email"
                (click)="clearRecoveryError()"
              />
              <mat-error *ngIf="sendCodeForm.get('email').invalid">This field is required</mat-error>
            </mat-form-field>
            <p
              class="error-message"
              *ngIf="accountNotFound"
            >
              We couldn't find an account with that email address.
            </p>
            <p
              class="error-message"
              *ngIf="recoveryError"
            >
              {{ recoveryError }}
            </p>
            <div
              fxLayout="row"
              fxLayoutAlign="center"
            >
              <button
                *ngIf="!loading"
                mat-flat-button
                color="primary"
                class="login-btn"
                type="submit"
                [disabled]="sendCodeForm.get('email').invalid"
              >
                Send
              </button>
              <mat-spinner
                strokeWidth="5"
                diameter="35"
                *ngIf="loading"
              ></mat-spinner>
            </div>
          </form>
        </ng-container>

        <ng-template #UpdatePasswordForm>
          <div
            fxLayout="column"
            class="title"
          >
            <div
              fxLayout="row"
              class="back-btn-wrapper"
            >
              <button
                mat-icon-button
                class="back-btn"
                (click)="backToRecoveryCode()"
              >
                <mat-icon>arrow_back</mat-icon>
              </button>
              <span class="page-title">Change your Password</span>
            </div>
            <p class="module-name">Operations Module</p>
          </div>
          <form
            [formGroup]="updatePasswordForm"
            (submit)="onUpdatePassword()"
          >
            <div class="password-requirements">
              <p>New password must contain:</p>
              <ul>
                <li>At least 8 characters</li>
                <li>At least 1 lowercase character</li>
                <li>At least 1 uppercase character</li>
                <li>At least 1 number</li>
                <li>At least 1 special character</li>
                <li>Up to 15 characters</li>
              </ul>
            </div>
            <mat-form-field appearance="outline">
              <mat-label><mat-icon>lock</mat-icon>Password</mat-label>
              <input
                type="password"
                matInput
                formControlName="newPassword"
                (click)="clearRecoveryError()"
              />
              <mat-error *ngIf="updatePasswordForm.get('newPassword').errors?.required"
                >This field is required</mat-error
              >
              <mat-error *ngIf="updatePasswordForm.get('newPassword').errors?.pattern">
                Password does not meet the requirements
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label><mat-icon>person</mat-icon>Code</mat-label>
              <input
                type="text"
                matInput
                formControlName="code"
                (keydown.enter)="onUpdatePassword()"
                (click)="clearRecoveryError()"
              />
              <mat-error *ngIf="updatePasswordForm.get('code').invalid">This field is required</mat-error>
            </mat-form-field>
            <p
              class="error-message"
              *ngIf="recoveryError"
            >
              {{ recoveryError }}
            </p>
            <div
              fxLayout="row"
              fxLayoutAlign="center"
            >
              <button
                *ngIf="!loading"
                mat-flat-button
                color="primary"
                class="login-btn"
                type="submit"
                [disabled]="!updatePasswordForm.valid"
              >
                Update
              </button>
              <mat-spinner
                strokeWidth="5"
                diameter="35"
                *ngIf="loading"
              ></mat-spinner>
            </div>
          </form>
        </ng-template>
      </div>
    </div>
  </div>
  <div
    fxLayout="row"
    fxFlex="25"
    fxFlex.md="0"
    fxFlex.sm="0"
    class="right-side"
  >
    <img
      src="assets/images/login_image.svg"
      class="login-img"
      alt="Operations Module Login"
    />
  </div>
</div>
