<div
  class="contanct-list-container"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <div
    id="container"
    fxFill
  >
    <div
      id="titleDiv"
      fxLayout.gt-md="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-between center"
    >
      <span
        class="pageTitle"
        [ngClass.lt-md]="'mobile'"
        >Manage Contacts</span
      >
      <button
        (click)="createContact()"
        class="btnCreatecontact"
        mat-raised-button
        color="primary"
        id="client-contacts-create-contact-btn"
      >
        CREATE CONTACT
      </button>
    </div>

    <div class="manage-contacts-table">
      <div id="search-wrapper">
        <mat-form-field
          class="search-form-field form-field-5"
          appearance="outline"
          id="client-contacts-search-contact-mat-form-field"
        >
          <mat-label>Search</mat-label>
          <input
            matInput
            [(ngModel)]="nameFilter"
            autocomplete="off"
            (keyup)="applyContactFilter()"
            id="client-contacts-search-contact-input"
          />
          <mat-icon
            matSuffix
            *ngIf="nameFilter"
            (click)="onContactSearchClear()"
            id="client-contacts-search-contact-mat-icon-clear"
            >close</mat-icon
          >
        </mat-form-field>
        <button
          mat-stroked-button
          class="search-filters-btn"
          [ngClass.lt-md]="'mobile'"
          [matMenuTriggerFor]="menu"
          id="client-contacts-seach-filters-btn"
        >
          <mat-icon>filter_list</mat-icon>
          Filter
        </button>
        <mat-menu
          #menu="matMenu"
          class="contacts-search-panel"
        >
          <div (click)="$event.stopPropagation()">
            <div>Client</div>
            <mat-form-field
              appearance="fill"
              class="clients-dropdown"
              id="client-contacts-clients-mat-form-field"
            >
              <input
                type="text"
                aria-label="Number"
                matInput
                [formControl]="clientFilterCtrl"
                [matAutocomplete]="clientsAuto"
                id="client-contacts-clients-input"
              />
              <mat-autocomplete
                #clientsAuto="matAutocomplete"
                [displayWith]="displayFn"
                id="client-contacts-clients-mat-autocomplete"
              >
                <mat-option
                  *ngFor="let client of filteredClientOptions | async"
                  [value]="client"
                  id="client-contacts-clients-mat-option"
                >
                  {{ client }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="clientFilterCtrl.hasError('invalidClientId')">Client not found</mat-error>
            </mat-form-field>
            <div>Email</div>
            <mat-form-field
              class="email-input"
              appearance="fill"
              id="client-contacts-email-mat-form-field"
            >
              <input
                matInput
                [(ngModel)]="emailFilter"
                maxlength="100"
                id="client-contacts-email-input"
              />
            </mat-form-field>
            <div>Status</div>
            <div>
              <mat-form-field
                class="status-picker"
                appearance="outline"
                id="client-contacts-status-mat-form-field"
              >
                <mat-select
                  [(value)]="statusFilter"
                  id="client-contacts-email-mat-select"
                >
                  <mat-option
                    *ngFor="let status of statusOptions"
                    [value]="status"
                    id="client-contacts-email-mat-option"
                  >
                    {{ status }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="filters-btn-wrapper">
              <button
                (click)="clearSearch()"
                mat-raised-button
                id="client-contacts-clear-filters-btn"
              >
                Clear Filters
              </button>
              <button
                (click)="search()"
                class="btnSearch"
                mat-raised-button
                color="primary"
                [disabled]="!canExecuteSearch"
                id="client-contacts-search-btn"
              >
                Search
              </button>
            </div>
          </div>
        </mat-menu>
      </div>
      <div
        class="mat-elevation-z0 shrink"
        [ngClass.lt-md]="'mobile'"
        fxFill
      >
        <mat-table
          [dataSource]="contactListData"
          matSort
          matSortDisableClear
          #contactSort="matSort"
        >
          <ng-container matColumnDef="name">
            <mat-header-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="22"
              *matHeaderCellDef
              mat-sort-header
              >Name</mat-header-cell
            >
            <mat-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="22"
              *matCellDef="let element"
              >{{ element.name }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex.gt-md="22"
              fxFlex.lt-sm="15"
              *matHeaderCellDef
              mat-sort-header
              >Status</mat-header-cell
            >
            <mat-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex.gt-md="22"
              fxFlex.lt-sm="15"
              *matCellDef="let element"
              [ngClass]="{
                'status-active': element.status === 'Active',
                'status-inactive': element.status !== 'Active'
              }"
            >
              {{ element.status }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="clientName">
            <mat-header-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="22"
              *matHeaderCellDef
              mat-sort-header
              >Client</mat-header-cell
            >
            <mat-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="22"
              *matCellDef="let element"
              >{{ element.clientName }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="email">
            <mat-header-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="22"
              *matHeaderCellDef
              mat-sort-header
              >Email</mat-header-cell
            >
            <mat-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="22"
              *matCellDef="let element"
              >{{ element.email }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex.gt-md="12"
              fxFlex.lt-sm="19"
              *matHeaderCellDef
              >Actions</mat-header-cell
            >
            <mat-cell
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex.gt-md="12"
              fxFlex.lt-sm="19"
              *matCellDef="let element"
              class="center actionsAddress"
            >
              <button
                fxLayout="row"
                fxLayoutAlign="center center"
                class="edit-btn"
                mat-icon-button
                (click)="editContact(element.id)"
                id="client-contacts-edit-contact-btn"
              >
                <mat-icon class="edit-icon">edit</mat-icon>
              </button>
              <!-- <button fxLayout="row" fxLayoutAlign="center center" class="delete-btn" mat-icon-button (click)="removeContact(element.id)"><mat-icon class="delete-icon">delete</mat-icon></button> -->
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="loading">
            <mat-footer-cell
              *matFooterCellDef
              colspan="2"
            >
              Loading data...
            </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="noData">
            <mat-footer-cell
              *matFooterCellDef
              colspan="2"
            >
              No data.
            </mat-footer-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="contactDisplayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: contactDisplayedColumns"></mat-row>
          <mat-footer-row
            *matFooterRowDef="['loading']"
            [ngClass]="{ hide: contactListData !== null }"
          ></mat-footer-row>
          <mat-footer-row
            *matFooterRowDef="['noData']"
            [ngClass]="{ hide: !(contactListData !== null && contactListData.data.length === 0) }"
          ></mat-footer-row>
        </mat-table>
        <mat-paginator
          class="paginator-table"
          [length]="resultsLength"
          [pageSize]="10"
          showFirstLastButtons
          #contactPaginator="matPaginator"
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
