<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="compose-min-top-bar"
  [ngStyle]="{ width: minimized ? '400px ' : '700px' }"
>
  <div>Message</div>
  <div fxLayout="row">
    <mat-icon *ngIf="!minimized" (click)="onToggleMinimize()">{{ minimized ? 'maximize' : 'minimize' }}</mat-icon>
    <mat-icon
      mat-icon-button
      (click)="minimized ? onToggleMinimize() : onExpandDialog() "
      >open_in_full</mat-icon
    >
    <mat-icon (click)="onCloseDialog()">close</mat-icon>
  </div>
</div>
