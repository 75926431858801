export interface ClientContactApiResponse {
  items: ClientContact[];
  total_count: number;
}

export class ClientContact {
    id: number;
    name: string;
    status: string;
    clientName: string;
    clientId: number;
    email: string;
    geographicZone: string;

    constructor(data?: any) {
      if (!data)
        return;
      this.id = data.id;
      this.name = data.name;
      this.status = data.status;
      this.clientName = data.clientName;
      this.clientId = data.clientId;
      this.email = data.email;
      this.geographicZone = data.geographicZone;
    }
}
