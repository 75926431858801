<form [formGroup]="form">
  <app-form-row
    label="Full Name"
    [source]="selectedLead?.fullName"
    [fieldControl]="form.controls.full_name"
    (handleCopy)="onCopy('first_name', selectedLead?.fullName)"
  ></app-form-row>
  <app-form-row
    label="Title"
    [source]="selectedLead?.title"
    [fieldControl]="form.controls.title"
    (handleCopy)="onCopy('title', selectedLead?.title)"
  ></app-form-row>
<app-form-row
    label="Email Address"
    [source]="selectedLead?.emailAddress"
    [fieldControl]="form.controls.email_address"
    (handleCopy)="onCopy('email_address', selectedLead?.emailAddress)"
  ></app-form-row>
  <app-form-row
    label="LinkedInURLGen"
    [source]="selectedLead?.linkedInUrl"
    [fieldControl]="form.controls.linked_in_url_gen"
    (handleCopy)="onCopy('linked_in_url_gen', selectedLead?.linkedInUrl)"
  ></app-form-row>
  <app-form-row
    label="Country"
    [source]="selectedLead?.country"
    [fieldControl]="form.controls.country"
    controlType="select"
    [selectData]="selectsData?.countries"
    (handleCopy)="onCopy('country', selectedLead?.country)"
    [isPartial]="true"
  ></app-form-row>
  <app-form-row
    label="State"
    [isPartial]="true"
    [source]="selectedLead?.state"
    [fieldControl]="form.controls.state"
    controlType="select"
    [selectData]="selectsData?.states"
    (handleCopy)="onCopyState(selectedLead?.state)"
  ></app-form-row>
  <app-form-row
    label="City"
    [isPartial]="true"
    [source]="selectedLead?.city"
    [fieldControl]="form.controls.city"
    (handleCopy)="onCopy('city', selectedLead?.city)"
  ></app-form-row>
  <app-form-row
    label="Phone"
    [source]="selectedLead?.phone"
    [fieldControl]="form.controls.phone"
    (handleCopy)="onCopy('phone', selectedLead?.phone)"
  ></app-form-row>
  <app-form-row
    label="Source Email Address"
    [isPartial]="true"
    [source]="selectedLead?.companySource"
    [fieldControl]="form.controls.email_address_source"
    (handleCopy)="onCopy('email_address_source', selectedLead?.companySource)"
  ></app-form-row>
</form>