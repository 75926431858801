import {Permission} from './Permission';

export class User {
    email: string;
    permissions: Permission[];

    public constructor(email: string, permissions: Permission[]) {
        this.email = email;
        this.permissions = permissions;
    }
}