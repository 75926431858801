<div fxLayout="row" class="login-container">
  <div fxLayout="row" fxFlex.lg="75" fxFlex.md="100" fxFlex.sm="100">
    <div fxLayout="column" fxFlex="100">
      <div fxLayout="column" class="logo-wrapper">
        <img src="assets/images/logo_full.png" alt="WinDifferent" />
      </div>
      <div fxLayout="column" fxLayoutAlign="center" class="left-side">
        <div fxLayout="column" fxLayoutAlign="start">
          <div fxLayout="column" class="title">
            <p class="login">Sign In</p>
            <p class="module-name">
              Operations Module
            </p>
          </div>
        </div>
        <form [formGroup]="form" (submit)="login()">
          <mat-form-field appearance="outline">
            <mat-label><mat-icon>person</mat-icon>Email</mat-label>
            <input type="text" matInput formControlName="email" (click)="loginError=''" />
            <mat-error *ngIf="form.get('email').invalid">This field is required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label><mat-icon>lock</mat-icon>Password</mat-label>
            <input type="password" matInput formControlName="password" (click)="loginError=''" (keydown.enter)="login()" />
            <mat-error *ngIf="form.get('password').invalid">This field is required</mat-error>
          </mat-form-field>

          <div fxLayout="row" fxLayoutAlign="center">
            <span *ngIf="loginError" class="login-error"> {{ loginError }} </span>
          </div>

          <div fxLayout="row">
            <a class="forgot-password-btn" [routerLink]="['/reset-password']">Forgot your password?</a>
          </div>
          <div fxLayout="row" fxLayoutAlign="center">
            <button *ngIf="!loading" mat-flat-button color="primary" class="login-btn" type="submit">
              Sign In
            </button>
            <mat-spinner strokeWidth="5" diameter="35" *ngIf="loading"></mat-spinner>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxFlex="25" fxFlex.md="0" fxFlex.sm="0" class="right-side">
    <img src="assets/images/login_image.svg" class="login-img" alt="Operations Module Login" />
  </div>
</div>