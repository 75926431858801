<div
  class="message"
  *ngIf="messages.length"
>
  <div class="message-container">
    <div
      *ngFor="let message of messages"
      class="message-container__item"
      [ngClass]="{
        'message-container__item--outbound': !message.isInbound,
        'message-container__item--inbound': message.isInbound
      }"
    >
      <span
        class="message-plain"
        [ngClass]="{ 'message-plain--outbound': !message.isInbound, 'message-plain--inbound': message.isInbound }"
        [innerHTML]="message.messagePlain | newlineToBr"
      ></span>
    </div>
  </div>
</div>
