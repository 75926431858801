import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Sdr} from '../../model/Sdr';

@Injectable({
  providedIn: 'root'
})
export class LoadingStateService {
  private isLoadingState = new BehaviorSubject<boolean>(false);
  private loadingStates = [];
  private isFeedLoadingState = new BehaviorSubject<boolean>(false);
  private feedLoadingStates = [];

  constructor() {}

  setLoadingState(state: boolean) {
    if (state)
      this.loadingStates.push(true);
    else if (this.loadingStates.length > 0)
      this.loadingStates.pop();

    this.isLoadingState.next(this.loadingStates.length > 0);
  }

  clearLoadingState() {
    this.loadingStates = [];
    this.isLoadingState.next(this.loadingStates.length > 0);
  }

  get isLoading() {
    return this.isLoadingState.asObservable();
  }

  setFeedLoadingState(state: boolean) {
    if (state)
      this.feedLoadingStates.push(true);
    else if (this.feedLoadingStates.length > 0)
      this.feedLoadingStates.pop();

    this.isFeedLoadingState.next(this.feedLoadingStates.length > 0);
  }

  get isFeedLoading() {
    return this.isFeedLoadingState.asObservable();
  }

  clearFeedLoadingState() {
    this.feedLoadingStates = [];
    this.isFeedLoadingState.next(this.feedLoadingStates.length > 0);
  }
}
