import { Component } from '@angular/core';

@Component({
  selector: 'app-mail-details-container',
  templateUrl: './mail-details-container.component.html',
  styleUrls: ['./mail-details-container.component.scss'],
})
export class MailDetailsContainerComponent {
  constructor() {}
}
