
<div mat-dialog-title>
  <div fxLayout="row" class="top-bar" fxLayoutAlign="space-between center">
    <div class="dialog-name">Details</div>
    <div fxLayout="row">
      <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
  </div>
  <div fxLayout="row" class="actions-wrapper">
    <button mat-stroked-button class="copy-btn" (click)="copyPartial()">Copy Partial</button>
    <button mat-stroked-button class="copy-btn" (click)="copyAll()">Copy All</button>
  </div>
</div>
<mat-dialog-content>
<div class="lead-id-wrapper" *ngIf="selectedLead">Lead ID: <b>{{ selectedLead.contactId }}</b></div>
<app-new-lead-form 
  [selectedLead]="selectedLead" 
  [form]="newLeadForm"
  [selectsData]="selectsData"
  (copyProperty)="handleCopy($event)"
></app-new-lead-form>
</mat-dialog-content>
<mat-dialog-actions class="footer-actions-wrapper">
    <button mat-stroked-button class="cancel-btn" mat-dialog-close>Close</button>
    <button mat-stroked-button class="preview-btn" (click)="previewData()" cdkFocusInitial [disabled]="newLeadForm.invalid">Add to Leads</button>
</mat-dialog-actions>