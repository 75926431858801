<div class="layoutDiv">
  <div
    class="container"
    fxLayout="column"
  >
    <div
      class="titleDiv"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon>arrow_back_ios</mat-icon>
      <span>Create New Operator User</span>
    </div>
    <mat-divider></mat-divider>
    <app-operator-user-form [operatorUserForm]="operatorUserForm"></app-operator-user-form>
    <div class="footer">
      <button
        mat-button
        class="dismiss-button"
        (click)="cancel()"
        id="operator-user-cancel-btn"
      >
        CANCEL
      </button>
      <button
        class="confirm-button"
        mat-raised-button
        color="primary"
        (click)="save()"
        cdkFocusInitial
        [disabled]="!operatorUserForm.valid"
        id="operator-user-add-new-operator-btn"
      >
        ADD NEW OPERATOR
      </button>
    </div>
  </div>
</div>
