import { ElementRef, Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertDialogComponent } from "../components/alert-dialog/alert-dialog.component";
import { UIGuid } from "../helpers/ui-guid";
import { Attachment } from "../model/Attachment";
import { ComposeEmail } from "../model/ComposeEmail";
import { MAX_NUMBER_OF_ATTACHMENTS, SENDER_OUTLOOK } from "../utils/constants";
import { LoadingStateService } from "./base/loading-state.service";
import { SdApiService } from "./base/sd-api.service";
import { ParameterService } from "./parameter.service";

const MODAL_DURATION = 3000;

@Injectable({
  providedIn: 'root'
})
export class MessageAttachmentService {

  attachmentParameters: any;
  constructor(
    private sdApiService: SdApiService,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar,
    private parameterService: ParameterService
  ) {

    this.getAttachmentParameters();

  }

  downloadDraftFile(documentId: string, documentName: string, isSafe: boolean, documentData: string, isLocal: boolean) {
    if (isLocal) {
      this.downloadLocalFile(documentData, documentName);
    } else {
      this.downloadRemoteFile(documentId, documentName, isSafe);
    }
  }

  downloadRemoteFile(documentId: string, documentName: string, isSafe: boolean) {
    if (!isSafe) {
      return;
    }
    this.loadingStateService.setLoadingState(true);
    this.sdApiService.getDownloadUrl(documentId).subscribe(
      downloadUrl => {
        this.sdApiService.getAttachmentFromUrl(
          downloadUrl.url)
          .subscribe((data) => {
          this.downloadDocument(data, documentName);
          this.loadingStateService.setLoadingState(false);
        }, error => {
          console.log(error);
          this.loadingStateService.setLoadingState(false);
          this.snackBar.open('An error ocurred while downloading the file', null, { duration: MODAL_DURATION });
        });
      },
      error => {
        this.snackBar.open('An error ocurred while downloading the file', null, { duration: MODAL_DURATION });
        console.log(error);
        // dialog.open(AlertDialogComponent, uploadErrorConfirmDialogConfig);
      });
  }

  downloadLocalFile(documentData: string, documentName: string) {
    const data = this.b64toBlob(documentData);
    this.downloadDocument(data, documentName);
  }

  async attach(files: FileList, attachments: Attachment[], inputFileControl: ElementRef, dialog: MatDialog,
               sender?: string) {
    const attachmentErrors = [];
    Array.from(files).forEach(async file => {
      const attachmentError = this.validateAttachment(file, attachments, sender);
      if (attachmentError === '') {
        const uid = UIGuid.newGuid();

        const fileId = uid + file.name;
        const attachment = {
          filename: file.name,
          fileId,
          size: file.size,
          isUploading: true,
          content: '',
          isLocal: true,
          key: '',
          isSafe: true,
          contentType: file.type
        };
        attachments.push(attachment);
        await this.uploadAttachment(file, attachment, attachments, dialog);
        this.getBase64(file).then(
          data => {
            attachment.content = data;
          }
        );
      } else {
        attachmentErrors.push({ filename: file.name, error: attachmentError });
      }
    });
    if (attachmentErrors.length > 0) {
      let errorMessage = 'Unable to upload some files due to the following errors: <br><br><br>';
      attachmentErrors.forEach(ae => {
        errorMessage += ae.filename + '<br> ' + ae.error + ' <br><br>';
      });
      const errorConfirmDialogConfig = new MatDialogConfig();
      errorConfirmDialogConfig.data = {
          title: "Attachment Errors",
          message: errorMessage
        };
      dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
    }
    inputFileControl.nativeElement.value = '';
  }

  async uploadAttachment(file: File, attachment: Attachment, allAttachments: Attachment[], dialog: MatDialog) {
    const obs = await this.sdApiService.requestUploadSignature2(file);
    obs.subscribe(
        presignedPostData => {
          attachment.key = presignedPostData.fields.key;
          this.sdApiService.uploadAttachment(
            presignedPostData,
            file
            ).subscribe(
            response => {
              attachment.isUploading = false;
            },
            error => {
              console.log(error);
              // allAttachments = allAttachments.filter(attachment => attachment.filename !== file.name);
              this.filterAttachment(allAttachments, file.name);
              const uploadErrorConfirmDialogConfig = new MatDialogConfig();
              uploadErrorConfirmDialogConfig.data = {
                title: "Error",
                message: "An error happened while uploading the attachment"
              };
              dialog.open(AlertDialogComponent, uploadErrorConfirmDialogConfig);
            });

        },
        error => {
          console.log(error);
          // allAttachments = allAttachments.filter(attachment => attachment.filename !== file.name);
          this.filterAttachment(allAttachments, file.name);
          const uploadErrorConfirmDialogConfig = new MatDialogConfig();
          uploadErrorConfirmDialogConfig.data = {
            title: "Error",
            message: "An error happened while uploading the attachment"
          };
          dialog.open(AlertDialogComponent, uploadErrorConfirmDialogConfig);
        });
  }

  deleteAttachment(fileName: string, fileId: string, isLocal: boolean, attachments: Attachment[]) {
    // attachments = attachments.filter(attachment => attachment.filename !== fileName);
    this.filterAttachment(attachments, fileName);
    if (isLocal) {
      // this.sdApiService.deleteAttachment(fileId).subscribe();
    }
  }

  validateAttachment(file: File, attachments: Attachment[], sender?: string) {

    if (file.size === 0) {
      return 'Attached file can not be empty';
    }
    let sizeLimit = Math.min(this.attachmentParameters.OUTLOOK_ATTACHMENT_SIZE_LIMIT,
      this.attachmentParameters.GMAIL_ATTACHMENT_SIZE_LIMIT);
    let totalSizeLimit = Math.min(this.attachmentParameters.OUTLOOK_TOTAL_ATTACHMENTS_SIZE_LIMIT,
      this.attachmentParameters.GMAIL_TOTAL_ATTACHMENTS_SIZE_LIMIT);
    if (sender) {
      sizeLimit = sender === SENDER_OUTLOOK ? this.attachmentParameters.OUTLOOK_ATTACHMENT_SIZE_LIMIT :
    this.attachmentParameters.GMAIL_ATTACHMENT_SIZE_LIMIT;
    // Total size
      totalSizeLimit = sender === SENDER_OUTLOOK ? this.attachmentParameters.OUTLOOK_TOTAL_ATTACHMENTS_SIZE_LIMIT :
      this.attachmentParameters.GMAIL_TOTAL_ATTACHMENTS_SIZE_LIMIT;
    }
    const individualMbSize = sizeLimit * 0.00000095367432;

    // if (file.size * 0.00000095367432 > 4.4) {
    if (file.size * 0.00000095367432 > individualMbSize) {
      // return 'Size can not be greater than 4.4 MB';
      const roundedIndividualLimit = (Math.round(individualMbSize * 100) / 100).toFixed(2);
      return 'Size can not be greater than ' + roundedIndividualLimit + ' MB';
    }
    const totalMbSize = totalSizeLimit * 0.00000095367432;

    const currentSizes = attachments.map(attachment => attachment.size);

    currentSizes.push(file.size);
    const totalSize = currentSizes.reduce((prev, next) => prev + next);
    if (totalSize * 0.00000095367432 > totalMbSize) {
    // if (totalSize * 0.00000095367432 > 4.4) {
      // return 'Total size can not be greater than 4.4 MB';
      const roundedTotalLimit = (Math.round(totalMbSize * 100) / 100).toFixed(2);
      return 'Total size can not be greater than ' + roundedTotalLimit + ' MB';
    }
    // const forbiddenExtensions = ['exe','bat','dll','jar'];
    // const fileExtension = file.name.split('.').pop();
    // if(forbiddenExtensions.includes(fileExtension)) {
    //   return 'Invalid file type. Forbidden types are exe, bat, dll, jar.';
    // }
    // const allowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'png',
    // 'jpg', 'jpeg'];
    const allowedExtensions = this.attachmentParameters.ATTACHMENT_EXTENSIONS;
    const fileExtension = file.name.split('.').pop();
    if (!allowedExtensions.includes(fileExtension)) {
      // return 'Invalid file type. Allowed types are pdf, doc, docx, xls, xlsx, ppt, pptx, txt, png, jpg, jpeg.';
      return 'Invalid file type. Allowed types are ' + allowedExtensions.join();
    }
    if (attachments.some(attachment => attachment.filename === file.name)) {
      return 'There is already a file attached with that name';
    }

    if (attachments.length >= MAX_NUMBER_OF_ATTACHMENTS) {
      return `Can not attach more than ${MAX_NUMBER_OF_ATTACHMENTS} files`;
    }

    return '';
  }

  downloadDocument(data: Blob, documentName: string): void {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(data);
    element.download = this.checkExtension(data, documentName);
    document.body.appendChild(element);
    element.click();
    element.remove();
  }

  private checkExtension(data: Blob, documentName: string){
    const fileExt = documentName.split('.').pop();
    const emptyName = '.' + fileExt;
    if (documentName === emptyName){
      return ' .' + fileExt;
    }
    return documentName;
  }

  private getBase64(file) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded =  reader.result.toString().replace(/^data:(.*,)?/, '');
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  }

  private b64toBlob(b64Data, contentType = '', sliceSize= 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }


  getAttachmentParameters() {
    this.parameterService
      .getAttachmentParameters()
      .subscribe(response => {
        this.attachmentParameters = response.data;
        }
      );
  }


  checkAttachmentSize(attachments: Attachment[], sender: string) {
    const sizeLimit = sender === SENDER_OUTLOOK ? this.attachmentParameters.OUTLOOK_ATTACHMENT_SIZE_LIMIT :
    this.attachmentParameters.GMAIL_ATTACHMENT_SIZE_LIMIT;
    // Total size
    const totalSizeLimit = sender === SENDER_OUTLOOK ?
      this.attachmentParameters.OUTLOOK_TOTAL_ATTACHMENTS_SIZE_LIMIT :
    this.attachmentParameters.GMAIL_TOTAL_ATTACHMENTS_SIZE_LIMIT;
    let totalSize = 0;
    let message = '';
    attachments.forEach(item => {
      if (item.size > sizeLimit) {
        const roundedIndividualLimit = Math.round(sizeLimit / 1024).toFixed(2);
        return message = 'Size can not be greater than ' + roundedIndividualLimit + ' MB for the selected sdr';
      }
      totalSize += item.size;
    });
    if (totalSize > totalSizeLimit) {
      const roundedIndividualLimit = Math.round(totalSizeLimit / 1024).toFixed(2);
      message = 'Total size can not be greater than ' + roundedIndividualLimit + ' MB for the selected sdr';
    }
    return message;
  }


  getKbSize(bytesValue: number) {
    return (bytesValue / 1000).toFixed(2);
  }

  getFileName(fullFileName: string) {
    const extensionIndex = fullFileName?.lastIndexOf('.');
    if (extensionIndex !== -1) {
      return fullFileName?.substring(0, extensionIndex);
    }
    return fullFileName;
  }

  getExtension(fullFileName: string) {
    const extensionIndex = fullFileName?.lastIndexOf('.');
    if (extensionIndex !== -1) {
      return fullFileName?.substring(extensionIndex);
    }
    return '';
  }

  filterAttachment(attachments: Attachment[], fileName) {
    const targetAttachment = attachments.find(attachment => attachment.filename === fileName);
    const index = attachments.indexOf(targetAttachment);
    if (index > -1) {
      attachments.splice(index, 1);
    }
  }

}
