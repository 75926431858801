import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Clipboard} from '@angular/cdk/clipboard';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ILead, ILeadInteractionsData, IInteractionContact } from 'src/app/model/DiscrepancyLeadToBulk';
import { DiscrepanciesDataService } from 'src/app/services/discrepancies-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { InquiryService } from 'src/app/services/inquiry.service';
import { InquiryLead } from 'src/app/model/InquiryLead';

@Component({
  selector: 'app-lead-summary',
  templateUrl: './lead-summary.component.html',
  styleUrls: ['./lead-summary.component.scss']
})
export class LeadSummaryComponent implements OnInit {
  contact: IInteractionContact = null;
  // leadToBulk: ILeadToBulk = null;
  isLoading = false;
  constructor(
    private discrepancyDataService: DiscrepanciesDataService,
    private snackbar: MatSnackBar,
    private clipboard: Clipboard,
    private dialog: MatDialog,
    private inquiryService: InquiryService
  ) { }

  ngOnInit(): void {
    this.discrepancyDataService.selectedSearchResult.subscribe(response => {
      if(response) {
        this.contact = response?.contact;
        this.inquiryService.setInquiryLeads([{ ...new InquiryLead(response), selected: true }]);
      }
    })
  }

  get isAliasBtnDisabled(): boolean {
    return this.contact?.contactStatus === 'Forwarder' || this.isLoading;
  }

  updateLeadStatus() {
    this.isLoading = true;
    this.discrepancyDataService.updateLeadStatus(this.contact.contactId, { id_email_status: 29 }).subscribe(response => {
      if(response.code === 200) {
        this.snackbar.open('Lead status successfully updated to Alias', null, { duration: 3000 });
        // this.discrepancyDataService.getLeadsData(this.lead.contact.contactId).subscribe(response => this.lead = response);
      }
      this.isLoading = false;
    })
  }

  // copyInfo() {
  //   let textToCopy = `
  // *** INTERNAL USE ONLY ***
  //   FYI, Lead profile details below...
  //   Original Email: ${this.contact.emailAddress}
  //   Name: ${this.contact.fullName}
  //   Title: ${this.contact.title}
  //   LinkedIn URL: ${this.contact.linkedInUrl}
  //   Country: ${this.contact.country}
  //   State: ${this.contact.state}
  //   City: ${this.contact.city}
  //   Phone: ${this.contact.phone}
  //   Phone Extension: ${this.contact.phone_extension}
  //   Company: ${this.contact.companyName}
  //   Industry: ${this.contact.}
  //   Size: ${this.contact.kw_size}
  // `;

  //   if (this.leadToBulk.comments) {
  //     const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //       data: {
  //         message: `Is the lead the same as ${this.leadToBulk.comments}?`,
  //         confirmBtnText: 'Yes',
  //         cancelBtnText: 'No'
  //       }
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //       if(!result) {
  //         this.discrepancyDataService.getLeadsDataByEmail(this.leadToBulk.comments).subscribe((
  //         response: ILead) => {
  //           if(response) {
  //             textToCopy += '\t-------------------------------------------\n' +
  //             '\tOriginal Lead contacted\n' +
  //             `\temail: ${response.email_address}\n` +
  //             `\tName: ${response.first_name} ${response.last_name}\n` +
  //             `\tTitle: ${response.title}\n`
              
  //             this.copyToClipboard(textToCopy);
  //           }
  //         }, error => {
  //           this.snackbar.open('An error occurred while trying to get original Lead information', null, { duration: 3000 });
  //         })      
  //       } else {
  //         this.copyToClipboard(textToCopy);
  //       }
  //     })
  //   } else {
  //     this.copyToClipboard(textToCopy);
  //   }
  // }

  private copyToClipboard(textToCopy: string) {
    this.clipboard.copy(textToCopy);
    this.snackbar.open('Lead information copied to clipboard', null, { duration: 3000 });
  }
}
