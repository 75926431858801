import { Component, Input, OnInit } from '@angular/core';
import { SendStatusType } from 'src/app/model/SendStatusType';
import { FeedService } from 'src/app/services/feed.service';
import { Email } from '../../../../../model/Email';
import { SelectedMailService } from '../../../../../services/selected-mail.service';

@Component({
  selector: 'app-mail-feed-item',
  templateUrl: './mail-feed-item.component.html',
  styleUrls: ['./mail-feed-item.component.scss'],
})
export class MailFeedItemComponent implements OnInit {
  @Input() email: Email;
  @Input() selectedMailId = '';
  SendStatusType = SendStatusType;
  selectedFolder = '';

  constructor(private selectedMailService: SelectedMailService, private feedService: FeedService) { }

  ngOnInit() {
    this.feedService.selectedFolder.subscribe(folder => {
      this.selectedFolder = folder;
    });
  }

  get isSelected(): boolean {
    return this.email .storageId === this.selectedMailId || this.selectedMailService.isMailInBulkSelection(this.email);
  }

  select() {
    this.selectedMailService.selectMail(this.email);
    this.feedService.setShowFeed(false);
  }

  toggleSelect() {
    this.selectedMailService.toggleMailInBulkSelection(this.email);
  }

  getArgentinianDate() {
    return new Date(this.email.date).toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' });
  }
}
