<div class="layoutDiv">
  <div
    class="container"
    fxLayout="column"
  >
    <div
      class="titleDiv"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon>arrow_back_ios</mat-icon>
      <div fxLayout="column">
        <span class="user-name">{{ (curentOperator | async)?.name }} {{ (curentOperator | async)?.last_name }}</span>
        <span class="user-email">{{ (curentOperator | async)?.email }}</span>
      </div>
      <div
        class="summary"
        fxLayout="column"
      >
        <div class="summary-header">
          <span>Summary of SDRS assigned</span>
        </div>
        <mat-divider></mat-divider>
        <div
          class="summary-content"
          fxLayout="row"
        >
          <div fxLayout="column">
            <span>{{ (curentOperator | async)?.default_sdrs.length ?? '-' }}</span>
            <span>Inbox</span>
          </div>

          <div fxLayout="column">
            <span>{{ (curentOperator | async)?.manual_discrepancies.length ?? '-' }}</span>
            <span>Discrepancies Manual</span>
          </div>

          <div fxLayout="column">
            <span>{{ (curentOperator | async)?.review_discrepancies.length ?? '-' }}</span>
            <span>Discrepancies Review</span>
          </div>

          <div fxLayout="column">
            <span>-</span>
            <span>Double Validation</span>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <mat-tab-group (selectedTabChange)="handleTabChange($event.index)">
      <mat-tab label="Details">
        <app-operator-user-form
          [operatorUserForm]="operatorUserForm"
          [currentValues]="curentOperator | async"
          [isEdit]="true"
        ></app-operator-user-form>
        <br />
      </mat-tab>
      <mat-tab label="SDRS">
        <div class="table-padding">
          <app-table-actions
            [showSelectAll]="false"
            (filterUserInputEvent)="handleFilterUserInputEvent($event)"
          ></app-table-actions>

          <div class="mat-elevation-z1 shrink">
            <mat-table
              [dataSource]="sdrDataSource"
              matSort
              #sdrSort="matSort"
              fxLayout="column"
            >
              <ng-container matColumnDef="email">
                <mat-header-cell
                  fxLayoutAlign="start center"
                  fxFlex="40"
                  *matHeaderCellDef
                  mat-sort-header
                  >SDR Name</mat-header-cell
                >
                <mat-cell
                  fxLayoutAlign="start center"
                  fxFlex="40"
                  *matCellDef="let element"
                  >{{ element.email }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="customer_name">
                <mat-header-cell
                  fxLayoutAlign="start center"
                  fxFlex="25"
                  *matHeaderCellDef
                  mat-sort-header
                  >Client</mat-header-cell
                >
                <mat-cell
                  fxLayoutAlign="start center"
                  fxFlex="25"
                  *matCellDef="let element"
                >
                  <span class="rounded-background">
                    {{ element.customer_name }}
                  </span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="level_name">
                <mat-header-cell
                  fxLayoutAlign="start center"
                  fxFlex="25"
                  *matHeaderCellDef
                  mat-sort-header
                  >SDR Permission</mat-header-cell
                >
                <mat-cell
                  fxLayoutAlign="start center"
                  fxFlex="25"
                  *matCellDef="let element"
                >
                  <span
                    *ngIf="element.level_name"
                    class="rounded-background"
                  >
                    {{ element.level_name }}
                  </span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell
                  fxLayoutAlign="center center"
                  fxFlex="10"
                  *matHeaderCellDef
                  >Actions</mat-header-cell
                >
                <mat-cell
                  fxLayoutAlign="center center"
                  fxFlex="10"
                  *matCellDef="let element"
                  class="center"
                >
                  <button
                    fxLayoutAlign="center center"
                    class="action-btn"
                    mat-icon-button
                    (click)="({})"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="noData">
                <tr
                  class="mat-row"
                  *matNoDataRow
                >
                  <td
                    class="mat-cell no-data"
                    colspan="2"
                    fxFlex="100"
                  >
                    {{ sdrDataSource.data || sdrHasError ? 'No data.' : 'Loading SDRs.' }}
                  </td>
                </tr>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedSdrColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedSdrColumns"></mat-row>
            </mat-table>
          </div>
          <mat-paginator
            [pageSizeOptions]="[10, 50, 100]"
            showFirstLastButtons
            #sdrPaginator
          ></mat-paginator>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="footer">
      <ng-container *ngIf="(currentTab$ | async) === OperatorUserManagementEditTabs.DETAILS">
        <button
          mat-button
          class="dismiss-button"
          (click)="toggleUser()"
        >
          {{ (curentOperator | async)?.enabled ? 'DEACTIVATE' : 'ACTIVATE' }} USER
        </button>
        <button
          class="confirm-button"
          mat-raised-button
          color="primary"
          (click)="save()"
          cdkFocusInitial
          [disabled]="!operatorUserForm.valid"
        >
          SAVE CHANGES
        </button>
      </ng-container>
    </div>
  </div>
</div>
