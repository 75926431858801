import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Message } from '../../../model/sdr-conversations/sdrs-conversations.model';
import { SdrConversationsService } from '../../../services/sdr-conversations.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit, OnDestroy {
  messages: Message[];
  private destroy$: Subject<boolean> = new Subject();

  constructor(private sdrConversationsService: SdrConversationsService) {}

  ngOnInit(): void {
    this.sdrConversationsService.selectedConversation.pipe(takeUntil(this.destroy$)).subscribe({
      next: (selectedConversation) => {
        this.messages = selectedConversation?.messages || [];
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
