import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SdApiService } from '../../../../services/base/sd-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { MatTableDataSource } from '@angular/material/table';

const MODAL_DURATION = 3000;

@Component({
  selector: 'app-address-suggestion-management',
  templateUrl: './address-suggestion-management.component.html',
  styleUrls: ['./address-suggestion-management.component.scss'],
})
export class AddressSuggestionManagementComponent implements OnInit, AfterViewInit {
  constructor(
    private service: SdApiService,
    private router: Router,
    private dialog: MatDialog,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar,
  ) {}

  listData = new MatTableDataSource<any>();
  displayedColumns: string[] = ['client', 'email', 'actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  searchKey: string;
  clients = [];
  selectedClient: string;
  emailAddress: string;
  emailAddressPreviousValues: { [id: string]: string } = {};
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  loading = false;

  ngOnInit() {
    this.service.getAllClients().subscribe((response) => {
      const clients = response.clients;

      this.clients = clients
        .filter((client) => client.sdrs.some((sdr) => sdr.email !== null))
        .map((c) => c.name)
        .sort();
    });

    this.loadingStateService.isLoading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  ngAfterViewInit() {
    this.service.getAddressSuggestions().subscribe((list) => {
      if (Array.isArray(list)) {
        const sortedList =
          list.sort((i1, i2) => {
            if (i1.client > i2.client) {
              return 1;
            }

            if (i1.client < i2.client) {
              return -1;
            }

            if (i1.contactEmail > i2.contactEmail) {
              return 1;
            }

            if (i1.contactEmail < i2.contactEmail) {
              return -1;
            }
            return 0;
          }) || [];

        const array = sortedList?.map((item) => {
          return {
            $key: item.id,
            id: item.id,
            client: item.client,
            email: item.contactEmail,
            isEditing: false,
          };
        });
        this.listData = new MatTableDataSource(array);
        this.listData.sort = this.sort;
        this.listData.paginator = this.paginator;
        this.listData.filterPredicate = (data, filter) => {
          return this.displayedColumns.some((ele) => {
            return ele != 'actions' && data[ele].toLowerCase().indexOf(filter) != -1;
          });
        };
      }
    });
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyFilter();
  }

  onEmailClear() {
    this.emailAddress = '';
  }

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  addAddress() {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Are you sure you want to add this address suggestion?',
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.loadingStateService.setLoadingState(true);
        this.service.addAddressSuggestion(this.selectedClient, this.emailAddress).subscribe(
          (response) => {
            this.loadingStateService.setLoadingState(false);
            if (response.success) {
              this.listData.data.push({
                $key: response.id,
                id: response.id,
                client: this.selectedClient,
                email: this.emailAddress,
                isEditing: false,
              });
              this.listData._updateChangeSubscription();
              this.snackBar.open('Address suggestion added successfully', null, { duration: MODAL_DURATION });
            } else {
              this.handleOperationError(response.error);
            }
            this.selectedClient = '';
            this.emailAddress = '';
          },
          (error) => {
            console.log(error);
            this.handleOperationError('An error ocurred while adding the address suggestion');
            this.selectedClient = '';
            this.emailAddress = '';
          },
        );
      }
    });
  }

  editAddress(id: string) {
    const changeIndex = this.listData.data.findIndex((item) => item.id === id);
    this.listData.data[changeIndex].isEditing = true;
    this.emailAddressPreviousValues[id] = this.listData.data[changeIndex].email;
    // this.listData._updateChangeSubscription();
  }

  updateAddress(id: string, client: string, email: string) {
    const validationResult = email.match(this.emailPattern) != null;
    if (validationResult) {
      const confirmDialogConfig = new MatDialogConfig();
      confirmDialogConfig.data = {
        title: 'Confirm',
        message: 'Are you sure you want to update this address suggestion?',
      };
      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
      confirmDialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.loadingStateService.setLoadingState(true);
          this.service.updateAddressSuggestion(id, client, email).subscribe(
            (response) => {
              this.loadingStateService.setLoadingState(false);
              if (response.error == '') {
                const changeIndex = this.listData.data.findIndex((item) => item.id === id);
                this.listData.data[changeIndex].client = client;
                this.listData.data[changeIndex].email = email;
                this.listData.data[changeIndex].isEditing = false;
                this.listData._updateChangeSubscription();
                this.snackBar.open('Address suggestion updated successfully', null, { duration: MODAL_DURATION });
              } else {
                this.handleOperationError(response.error);
              }
            },
            (error) => {
              console.log(error);
              this.handleOperationError('An error ocurred while updating the address suggestion');
            },
          );
        }
      });
    }
  }

  cancelEdit(id: string) {
    const changeIndex = this.listData.data.findIndex((item) => item.id === id);
    this.listData.data[changeIndex].isEditing = false;
    this.listData.data[changeIndex].email = this.emailAddressPreviousValues[id];
    // this.listData._updateChangeSubscription();
  }

  removeAddress(id: string) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Are you sure you want to remove this address suggestion?',
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.loadingStateService.setLoadingState(true);
        this.service.removeAddressSuggestion(id).subscribe(
          (response) => {
            this.loadingStateService.setLoadingState(false);
            if (response.error == '') {
              this.listData.data.splice(
                this.listData.data.findIndex((item) => item.id === id),
                1,
              );
              this.listData._updateChangeSubscription();
              this.snackBar.open('Address suggestion removed successfully', null, { duration: MODAL_DURATION });
            } else {
              this.handleOperationError(response.error);
            }
          },
          (error) => {
            console.log(error);
            this.handleOperationError('An error ocurred while removing the address suggestion');
          },
        );
      }
    });
  }

  handleOperationError(message: string) {
    this.loadingStateService.setLoadingState(false);
    const errorConfirmDialogConfig = new MatDialogConfig();
    errorConfirmDialogConfig.data = {
      title: 'Error',
      message,
    };
    this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
  }
}
