import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SdApiService } from '../../../../services/base/sd-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { TemplateService } from 'src/app/services/template.service';
import { merge, of } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { INTRODUCTION_TEMPLATE } from 'src/app/utils/constants';

const MODAL_DURATION = 3000;

@Component({
  selector: 'app-email-template-management',
  templateUrl: './email-template-management.component.html',
  styleUrls: ['./email-template-management.component.scss']
})
export class EmailTemplateManagementComponent implements OnInit, AfterViewInit {

  constructor(
    private service: SdApiService,
    private router: Router,
    private dialog: MatDialog,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar,
    private templateService: TemplateService
  ) {}

  replyListData: MatTableDataSource<any> = new MatTableDataSource([]);
  replyDisplayedColumns: string[] = ['sortDate', 'title', 'hasAttachments', 'client', 'actions'];
  @ViewChild('replySort') replySort: MatSort;
  @ViewChild('replyPaginator') replyPaginator: MatPaginator;
  replySearchKey: string;
  resultsLength = 0;

  introductionListData: MatTableDataSource<any> = new MatTableDataSource([]);
  introductionDisplayedColumns: string[] = [
    'sortDate',
    'title',
    'hasAttachments',
    'client',
    'sdr',
    'clientContact',
    'actions',
  ];
  @ViewChild('introductionSort') introductionSort: MatSort;
  @ViewChild('introductionPaginator') introductionPaginator: MatPaginator;
  introductionSearchKey: string;

  loading = false;
  templateManagementView = 'Reply';

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe(loading => {
      this.loading = loading;
    });
  }

  ngAfterViewInit() {
    this.loadingStateService.setLoadingState(true);
    this.service.getReplyTemplates().subscribe(list => {
      this.loadingStateService.setLoadingState(false);
      const sortedReplyList = list.sort((i1, i2) => {
        if (i1.sortDate > i2.sortDate) {
          return 1;
        }

        if (i1.sortDate < i2.sortDate) {
          return -1;
        }

        if (i1.title > i2.title) {
          return 1;
        }

        if (i1.title < i2.title) {
          return -1;
        }

        return 0;
    });
      const array = sortedReplyList.map(item => {
        return {
          $key: item.id,
          id: item.id,
          date: item.date,
          sortDate: item.sortDate,
          title: item.title,
          hasAttachments: item.hasAttachments,
          client: item.client,
          tags: item.tags,
        };
      });
      this.replyListData = new MatTableDataSource(array);
      this.replyListData.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'title':
            return this.normalizeData(item.title);
          case 'client':
            return this.normalizeData(item.client);
          default:
            return item[property] || '';
        }
      };
      this.replyListData.sort = this.replySort;
      this.replyListData.paginator = this.replyPaginator;
      this.replyListData.filterPredicate = (data, filter) => {
        return this.replyDisplayedColumns.some(ele => {
          return ele != 'actions' && ele != 'sortDate' && ele != 'date' && ele != 'hasAttachments' && data[ele].toLowerCase().indexOf(filter) != -1;
        }) || data.tags.toLowerCase().indexOf(filter) != -1;
      };
    });
    this.introductionSort.sortChange.subscribe(() => (this.introductionPaginator.pageIndex = 0));

    merge(this.introductionSort.sortChange, this.introductionPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {

      return this.templateService.getTemplates(INTRODUCTION_TEMPLATE,
        this.introductionPaginator.pageSize,
        this.introductionPaginator.pageIndex * this.introductionPaginator.pageSize,
        this.introductionSearchKey ? this.introductionSearchKey?.trim().toLowerCase() : this.introductionSearchKey,
        this.introductionSort.active,
        this.introductionSort.direction);
      }),
      map((response) => {
        this.loadingStateService.setLoadingState(false);

        if (response.data === null) {
          return [];
        }

        this.resultsLength = response.total;
        return response.data;
      })
    )
        .subscribe(templates => {
          this.introductionListData = new MatTableDataSource(templates);
        });

  }

  refreshData() {
    this.loadingStateService.setLoadingState(true);
    return this.templateService.getTemplates(INTRODUCTION_TEMPLATE,
        this.introductionPaginator.pageSize,
        this.introductionPaginator.pageIndex * this.introductionPaginator.pageSize,
        this.introductionSearchKey ? this.introductionSearchKey?.trim().toLowerCase() : this.introductionSearchKey,
        this.introductionSort.active,
        this.introductionSort.direction)

    .pipe(catchError(() => of(null)))
    .map((response) => {
      this.loadingStateService.setLoadingState(false);

      if (response.data === null) {
        return [];
      }
      this.resultsLength = response.total;
      return response.data;
    }).subscribe(templates => {
      this.introductionListData = new MatTableDataSource(templates);
    });
  }

  onReplySearchClear() {
    this.replySearchKey = '';
    this.applyReplyFilter();
  }

  onIntroductionSearchClear() {
    this.introductionSearchKey = '';
    this.applyIntroductionFilter();
  }

  applyReplyFilter() {
    this.replyListData.filter = this.replySearchKey?.trim().toLowerCase();
  }

  applyIntroductionFilter() {
    this.introductionPaginator.pageIndex = 0;
    this.refreshData();
  }

  editTemplate(id: string) {
    this.router.navigate(['/home/management/templates/edit'], { queryParams: { id } });
  }

  removeReplyTemplate(id: string) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Are you sure you want to remove this reply template?',
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loadingStateService.setLoadingState(true);
        this.service.removeTemplate(id).subscribe(
          response => {
            this.loadingStateService.setLoadingState(false);
            if (response.code === 200) {
              this.replyListData.data.splice(this.replyListData.data.findIndex(item => item.id === id), 1);
              this.replyListData._updateChangeSubscription();
              this.snackBar.open('Reply template removed successfully', null, { duration: MODAL_DURATION });
            } else {
              this.handleOperationError(response.message);
            }
          },
          error => {
            console.log(error);
            this.handleOperationError('An error ocurred while removing the reply template');
          });
      }
    });
  }

  removeIntroductionTemplate(id: string) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Are you sure you want to remove this introduction template?',
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loadingStateService.setLoadingState(true);
        this.service.removeTemplate(id).subscribe(
          response => {
            this.loadingStateService.setLoadingState(false);
            if (response.code === 200) {
              this.introductionListData.data.splice(this.introductionListData.data.findIndex(item => item.id === id), 1);
              this.introductionListData._updateChangeSubscription();
              this.snackBar.open('Introduction template removed successfully', null, { duration: MODAL_DURATION });
            } else {
              this.handleOperationError(response.message);
            }
          },
          error => {
            console.log(error);
            this.handleOperationError('An error ocurred while removing the introduction template');
          });
      }
    });
  }

  handleOperationError(message: string) {
    this.loadingStateService.setLoadingState(false);
    const errorConfirmDialogConfig = new MatDialogConfig();
    errorConfirmDialogConfig.data = {
      title: 'Error',
      message,
    };
    this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
  }

  viewReplyTemplates() {
    this.templateManagementView = 'Reply';
  }

  viewIntroductionTemplates() {
    this.templateManagementView = 'Introduction';
  }

  createReplyTemplate() {
    this.router.navigate(['/home/management/templates/edit'], { queryParams: { type: 'reply', id: 0 } });
  }

  createIntroductionTemplate() {
    this.router.navigate(['/home/management/templates/edit'], { queryParams: { type: 'introduction', id: 0 } });
  }

  private normalizeData(sortString: string): string {
    let cleanString = '';
    if (sortString) {
      cleanString = sortString.trim();
    }
    return cleanString.toLowerCase();
  }
}
