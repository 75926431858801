<div id="layoutDiv">
  <div id="container">
    <div id="titleDiv"><span class="pageTitle">User Details</span></div>
    <mat-card class="card-container">
      <mat-card-content>
        <div class="title-wrapper">
          <span class="credentialsTitle">Credentials</span>
          <button
            *ngIf="showDisableBtn"
            mat-raised-button
            color="warn"
            (click)="onDisableUser()"
            [disabled]="!isUserEnabled"
            id="user-details-disable-btn"
          >
            {{ isUserEnabled ? 'DISABLE USER' : 'DISABLED' }}
          </button>
        </div>

        <div id="userEmailDiv">
          <span class="userEmail"><b>Email:</b> {{ userEmail }}</span>
        </div>

        <div id="userEmailDiv">
          <mat-form-field
            class="pass-set-form-field form-field-5"
            appearance="outline"
            subscriptSizing="dynamic"
            id="user-details-new-password-mat-form-field"
          >
            <mat-label>New Password</mat-label>
            <input
              matInput
              [(ngModel)]="newPassword"
              type="password"
              [pattern]="passwordPattern"
              #usrPassword="ngModel"
              maxlength="15"
              id="user-details-new-password-input"
            />

            <mat-icon
              matSuffix
              *ngIf="newPassword"
              (click)="onPasswordClear()"
              id="user-details-new-password-mat-icon-clear"
              >close</mat-icon
            >
          </mat-form-field>
          <button
            mat-raised-button
            color="primary"
            (click)="setPassword()"
            [disabled]="newPassword === '' || usrPassword.errors?.pattern"
            id="user-details-set-password-btn"
          >
            <mat-icon style="margin-right: 5px">vpn_key</mat-icon>SET PASSWORD
          </button>
        </div>
        <div
          style="color: red"
          *ngIf="usrPassword.errors?.pattern"
        >
          Password does not meet strength conditions (Minimum eight and maximum 15 characters, at least one uppercase
          letter, one lowercase letter, one number and one special character).
        </div>
      </mat-card-content>
    </mat-card>
    <ng-container *ngIf="clientSdrs?.length && allSdrs?.length">
      <mat-card class="card-container">
        <mat-card-content>
          <div class="sdrs-section-title"><span>SDRs</span></div>
          <app-folders-sdrs-list
            [clientsInfo]="clientSdrs"
            [sdrsInfo]="allSdrs"
            [assignedSdrsList]="sdrsListData"
            (assignSdr)="addFolderSdr($event)"
            (removeSdr)="removeFolderSdr($event)"
          ></app-folders-sdrs-list>
        </mat-card-content>
      </mat-card>
      <mat-card class="card-container">
        <mat-card-content>
          <span class="sdrs-section-title">SDRs Discrepancies</span>
          <mat-tab-group>
            <mat-tab label="Discrepancies - Manual">
              <app-discrepancies-sdrs-list
                [clientsInfo]="clientSdrs"
                [sdrsInfo]="allSdrs"
                [assignedSdrsList]="discrepancySdrsDataSource"
                (assignSdr)="onAssignDiscrepancySdr($event)"
                (removeSdr)="onRemoveDiscrepancySdr($event)"
              >
              </app-discrepancies-sdrs-list>
            </mat-tab>
            <mat-tab label="Discrepancies - Review">
              <app-discrepancies-sdrs-list
                [clientsInfo]="clientSdrs"
                [sdrsInfo]="allSdrs"
                [assignedSdrsList]="reviewDiscrepancySdrsDataSource"
                (assignSdr)="onAssignReviewDiscrepancySdr($event)"
                (removeSdr)="onRemoveReviewDiscrepancySdr($event)"
              >
              </app-discrepancies-sdrs-list>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <mat-card class="card-container">
      <mat-card-content>
        <div id="permissionsTitleDiv"><span class="permissionsTitle">Permissions</span></div>
        <div class="permissions-container">
          <div class="add-permission-container">
            <mat-form-field
              appearance="outline"
              class="form-field-5 field"
              subscriptSizing="dynamic"
              id="user-details-add-permission-mat-form-field"
            >
              <mat-label>Add Permission</mat-label>
              <input
                [attr.type]="'text'"
                name="permission-filter-options"
                id="permission-filter-options"
                matInput
                [formControl]="permissionFilterControl"
                [matAutocomplete]="permissionsAuto"
                [(ngModel)]="selectedPermission"
                id="user-details-add-permission-input"
              />
              <mat-autocomplete
                #permissionsAuto="matAutocomplete"
                [displayWith]="displayFn"
                id="user-details-add-permission-mat-autocomplete"
              >
                <mat-option
                  *ngFor="let permission of filteredPermissionOptions | async"
                  [value]="permission.name"
                  id="user-details-add-permission-mat-option"
                >
                  {{ permission.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <button
              mat-raised-button
              color="primary"
              class="field__button"
              (click)="addPermission()"
              [disabled]="!isPermissionValid()"
              id="user-details-add-permission-btn"
            >
              <mat-icon style="margin-right: 5px">add_circle</mat-icon>ADD
            </button>
          </div>

          <div class="mat-elevation-z8 shrink">
            <mat-form-field
              appearance="outline"
              class="form-field-5 search-permission-field"
              subscriptSizing="dynamic"
              id="user-details-search-permission-mat-form-field"
            >
              <mat-label>Search Permission</mat-label>
              <input
                matInput
                [(ngModel)]="permissionSearchKey"
                autocomplete="off"
                (keyup)="applyPermissionFilter()"
                id="user-details-search-permission-input"
              />
              <mat-icon
                matSuffix
                *ngIf="permissionSearchKey"
                (click)="onPermissionSearchClear()"
                id="user-details-search-permission-mat-icon-clear"
                >close</mat-icon
              >
            </mat-form-field>
            <mat-table
              [dataSource]="permissionListData"
              matSort
              matSortActive="permission"
              matSortDirection="asc"
              matSortDisableClear
              #permissionSort="matSort"
            >
              <ng-container matColumnDef="permission">
                <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  >Permission</mat-header-cell
                >
                <mat-cell *matCellDef="let element">{{ element.permission.name }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell
                  *matHeaderCellDef
                  class="center"
                  >Actions</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="center"
                >
                  <button
                    mat-icon-button
                    (click)="removePermission(row.permission)"
                    id="user-details-remove-permission-btn"
                  >
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="loading">
                <mat-footer-cell
                  *matFooterCellDef
                  colspan="2"
                >
                  Loading data...
                </mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="noData">
                <mat-footer-cell
                  *matFooterCellDef
                  colspan="2"
                >
                  No data.
                </mat-footer-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="permissionColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: permissionColumns"></mat-row>
              <mat-footer-row
                *matFooterRowDef="['loading']"
                [ngClass]="{ hide: permissionListData !== null }"
              ></mat-footer-row>
              <mat-footer-row
                *matFooterRowDef="['noData']"
                [ngClass]="{ hide: !(permissionListData !== null && permissionListData.data?.length === 0) }"
              ></mat-footer-row>
            </mat-table>
            <mat-paginator
              [pageSize]="10"
              showFirstLastButtons
              #permissionsPaginator="matPaginator"
            ></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
