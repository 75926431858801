import { Component } from '@angular/core';

@Component({
  selector: 'app-mail-feed-skeleton-loader',
  templateUrl: './mail-feed-skeleton-loader.component.html',
  styleUrls: ['./mail-feed-skeleton-loader.component.scss'],
})
export class MailFeedSkeletonLoaderComponent {
  sharedTheme = {
    'border-radius': '8px',
    height: '12px',
    display: 'block',
  };

  getThemeConfig(additionalOptions?: { [key: string]: any }) {
    return {
      ...this.sharedTheme,
      ...additionalOptions,
    };
  }
}
