<a
  style="margin: 0 10px 0 0"
  (click)="onClickAttachment()"
  [style.cursor]="attachment.isSafe ? 'pointer' : 'initial'"
>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="attachment-box"
    matTooltip="{{ attachment.filename }}"
  >
    <div fxLayout="column">
      <span class="attachment-name">
        {{ messageAttachmentService.getFileName(attachment.filename) | truncate: 15 : false : '..'
        }}{{ messageAttachmentService.getExtension(attachment.filename) }}
      </span>
      <span class="attachment-size"> {{ messageAttachmentService.getKbSize(attachment.size) }} kb </span>
    </div>
    <div
      *ngIf="attachment.isUploading"
      fxLayout="row"
    >
      <mat-spinner
        [diameter]="20"
        style="margin-left: 10px; margin-right: 5px"
      ></mat-spinner>
    </div>
    <div
      fxLayout="row"
      *ngIf="!attachment.isUploading"
    >
      <mat-icon
        *ngIf="isRemovable && !attachment.isUploading"
        (click)="onRemoveAttachment($event)"
        >cancel</mat-icon
      >
    </div>
  </div>
</a>
