<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
      <mat-panel-title fxFlex="15">
        <div fxLayout="row" fxLayoutGap="10px">
          <div fxFlexAlign="center">
            <mat-icon class="material-symbols-outlined" style="color: #515151;">account_circle</mat-icon>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-around">
            <!-- <span class="color-gray">{{operatorDetails.client_level}}</span> -->
            <span class="color-purple">{{operatorDetails.name}}</span>
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description fxFlex="85">
        <div fxFlex="14" fxLayout="column" fxLayoutAlign="space-around center">
          <span>Quantity</span>
          <span class="color-black">{{operatorDetails.clientsCount}}</span>
        </div>
      
        <div fxFlex="14" fxLayout="column" fxLayoutAlign="space-around center">
          <span>Quantity</span>
          <span class="color-black">{{operatorDetails.sdrCount}}</span>
        </div>
      
        <div fxFlex="15" fxLayout="column" fxLayoutAlign="space-around center">
          <span>Folder</span>
          <span class="color-black">{{operatorDetails.folders}}</span>
        </div>
      
        <div fxFlex="15" fxLayout="column" fxLayoutAlign="space-around center">
          <span>Discrepancies</span>
          <span class="color-black">{{operatorDetails.discrepancies}}</span>
        </div>
      
        <div fxFlex="14" fxLayout="column" fxLayoutAlign="space-around center">
          <span>E-mail/day</span>
          <span class="color-black" >{{operatorDetails.emailCount}}</span>
        </div>
      
        <div fxFlex="14" fxLayout="column" fxLayoutAlign="space-around center">
          <span>TC Average</span>
          <span class="color-black" [class.warn]="operatorDetails.timeToReply > maxTimeToReply">{{operatorDetails.timeToReply}} minutes</span>
        </div>

        <div fxFlex="14" fxLayoutAlign="end center" class="hover">
          <span class="color-purple"> see the detail</span>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-accordion displayMode="flat" togglePosition="before">
      <mat-expansion-panel 
        class="mat-elevation-z0" 
        *ngFor="let company of operatorDetails.companies"
        (opened)="handleCompanyDetailsOpen()"
        (afterExpand)="handleCompanyDetailsOpened(company.sdrs)"
      >
        <mat-expansion-panel-header >
          <mat-panel-title fxFlex="15">
            {{company.companyName}}
          </mat-panel-title>
          <mat-panel-description fxFlex="85">
            {{company.sdrCount}} SDRs
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="table-container" fxFlex="100" fxLayoutAlign="space-around center">
          <mat-table [dataSource]="sdrDataSource" fxFlex="90">
            <ng-container matColumnDef="sdrName">
              <mat-header-cell fxLayoutAlign="start center" fxFlex="18" *matHeaderCellDef>SDR Name</mat-header-cell>
              <mat-cell  fxLayoutAlign="start center" fxFlex="18" *matCellDef="let element">
                <mat-checkbox [checked]="checkIfRowIsSelected(element.id)" (change)="handleSelect($event.checked, element.id)">
                  {{element.sdrName}}
                </mat-checkbox>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="folders">
              <mat-header-cell fxLayoutAlign="start center" fxFlex="16" *matHeaderCellDef>Folder</mat-header-cell>
              <mat-cell  fxLayoutAlign="start center" fxFlex="16" *matCellDef="let element">
                {{element.folders}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="discrepancies">
              <mat-header-cell fxLayoutAlign="start center" fxFlex="16" *matHeaderCellDef>Discrepancies</mat-header-cell>
              <mat-cell  fxLayoutAlign="start center" fxFlex="16" *matCellDef="let element">
                {{element.discrepancies}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="emailCount">
              <mat-header-cell fxLayoutAlign="start center" fxFlex="16" *matHeaderCellDef>Email/day</mat-header-cell>
              <mat-cell  fxLayoutAlign="start center" fxFlex="16" *matCellDef="let element">
                {{element.emailCount}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="timeToReply">
              <mat-header-cell fxLayoutAlign="start center" fxFlex="16" *matHeaderCellDef>Av.time to reply</mat-header-cell>
              <mat-cell  fxLayoutAlign="space-evenly center" fxFlex="16" *matCellDef="let element">
                <span [class.warn]="element.timeToReply > maxTimeToReply">{{element.timeToReply}} minutes</span>
                <mat-icon *ngIf="element.timeToReply > maxTimeToReply" class="warn">warning_outlined</mat-icon>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell fxLayoutAlign="start center" fxFlex="18" *matHeaderCellDef>Select operator</mat-header-cell>
              <mat-cell  fxLayoutAlign="start center" fxFlex="18" *matCellDef="let element">
                  <mat-select 
                    fxLayoutAlign="start center"
                    placeholder="Select Operator"
                    [value]="element.client_level"
                    #selectedOperator
                  >
                    <mat-select-trigger fxLayout="row" fxLayoutAlign="space-evenly center">
                      <mat-icon>person_outlined</mat-icon>
                      <span>{{selectedOperator.value}}</span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let operator of [{name: 'test 1'}, {name: 'test 2'}]" [value]="operator.name">
                      <mat-icon>person_outlined</mat-icon>
                      <span>{{operator.name}}</span>
                    </mat-option>
                  </mat-select>
              </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="noData">
              <mat-footer-cell *matNoDataRow>
                No data.
              </mat-footer-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedSdrColumns"></mat-header-row>
            <mat-row [class.selected-row]="checkIfRowIsSelected(row.id)" *matRowDef="let row; columns: displayedSdrColumns"></mat-row>
          </mat-table>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
    
  </mat-expansion-panel>
</mat-accordion>