import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IInteraction } from 'src/app/model/DiscrepancyLeadToBulk';

@Component({
  selector: 'app-lead-interaction-item',
  templateUrl: './lead-interaction-item.component.html',
  styleUrls: ['./lead-interaction-item.component.scss']
})
export class LeadInteractionItemComponent implements OnInit {
  @Input() interaction: IInteraction;
  @Input() isEditableCustomer;
  @Input() isSelected = false;
  @Output() updateLead: EventEmitter<any> = new EventEmitter();
  form: FormGroup = new FormGroup({});
  detailsCollapsed = true;
  
  constructor() { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.form = new FormGroup({
      contact_date: new FormControl(this.interaction.toContact),
      client_contact: new FormControl(this.interaction.salesRepId),
      id_lead_forward: new FormControl({ value: this.interaction.idLeadForward, disabled: true }),
      message_id_received: new FormControl({ value: this.interaction.messageId, disabled: true}),
      campaign_bulk_date: new FormControl(this.interaction.campaignId),
    })
  }

  get isSaveEnabled(): boolean {
    return this.form.dirty && this.form.valid && this.form.touched;
  }

  compareFunction(option, value): boolean {
    return option.id === value?.id;
  }

  onClickSave(event: any) {
    event.stopPropagation();
    this.emitSave();
  }

  emitSave() {
    this.updateLead.emit({id: this.interaction.customerId, ...this.form.getRawValue()});
    this.form.markAsUntouched();
  }
}

