<div
  fxLayout="column"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane.mail-composer-container"
>
  <div
    cdkDragHandle
    #modalBar
    class="mail-composer-header-container"
  >
    <app-composer-window-bar
      (closeDialog)="close()"
      (minimizeDialog)="handleMinimizedDialog($event)"
    ></app-composer-window-bar>
  </div>
  <mat-dialog-content class="mail-composer-content">
    <app-recipient-fields></app-recipient-fields>
    <div style="padding: 5px">
      <div id="myId"></div>
    </div>
    <iframe
      id="composerPreviewIframe"
      #composerPreviewIframe
      frameborder="0"
      style="
        overflow-y: scroll;
        padding: 20px;
        max-height: calc(70vh - 130px);
        width: 98%;
        max-width: 98%;
        vertical-align: middle;
        min-height: 250px;
      "
    ></iframe>

    <div class="attachments-wrapper">
      <div *ngFor="let attachment of currentComposedEmail.attachments">
        <app-attachment
          [attachment]="attachment"
          [isRemovable]="true"
          (clickEvt)="
            messageAttachmentService.downloadDraftFile(
              attachment.key,
              attachment.filename,
              attachment.isSafe,
              attachment.content,
              attachment.isLocal
            )
          "
          (removeEvt)="
            messageAttachmentService.deleteAttachment(
              attachment.filename,
              attachment.fileId,
              attachment.isLocal,
              currentComposedEmail.attachments
            )
          "
        >
        </app-attachment>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="start">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        mat-flat-button
        color="primary"
        (click)="send()"
        class="send-btn"
        [ngClass]="{ disabledSend: isSdrBlocked || !isSendConfigured }"
        [disabled]="invalidForm || isSdrBlocked || !isSendConfigured"
        id="mail-composer-send-btn"
      >
        {{ sendButtonText }}
      </button>
      <div class="attachment-btn">
        <label
          for="inputFile"
          matTooltip="Add Attachment"
        >
          <span><mat-icon>attach_file</mat-icon></span>
        </label>
        <input
          type="file"
          id="inputFile"
          (change)="addAttachment($event.target.files)"
          multiple
          #inputFileControl
        />
      </div>
      <button
        mat-button
        *ngIf="switchAllowed && !selectedTemplate && currentComposedEmail.category !== categoryType.Forward"
        (click)="selectTemplate()"
        [disableRipple]="true"
        class="select-template-btn"
        matTooltip="Select Template"
        id="mail-composer-select-template-btn"
      >
        <mat-icon class="iconSelectTemplate">sticky_note_2</mat-icon>
      </button>
      <p
        *ngIf="selectedTemplate"
        class="templateLabel"
      >
        Template Used:
      </p>
      <p
        *ngIf="selectedTemplate"
        class="templateTitle"
      >
        {{ selectedTemplate.title }}
      </p>
      <button
        mat-icon-button
        *ngIf="switchAllowed && currentComposedEmail.category === categoryType.Forward"
        (click)="selectSalesRep()"
        [disableRipple]="true"
        class="select-template-btn"
        matTooltip="Select Sales Representative"
        id="mail-composer-select-sales-representative-btn"
      >
        <mat-icon class="iconSelectTemplate">face</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="displayCopyLeadBtn"
        (click)="onCopyLeadProfile()"
        [disableRipple]="true"
        class="select-template-btn"
        matTooltip="Copy Lead Profile"
        [disabled]="leadCopied || isLoadingLead"
        id="mail-composer-copy-lead-profile-btn"
      >
        <mat-icon
          *ngIf="!isLoadingLead"
          class="iconSelectTemplate"
          >group</mat-icon
        >
        <mat-spinner
          class="search-btn-spinner"
          *ngIf="isLoadingLead"
          diameter="20"
        ></mat-spinner>
      </button>
    </div>
  </mat-dialog-actions>
</div>
