import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IInteraction } from 'src/app/model/DiscrepancyLeadToBulk';
import { Sdr } from 'src/app/model/Sdr';
import { InquiryService } from 'src/app/services/inquiry.service';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit {
  sdr: Sdr;
  client: string;
  email: string;
  date: Date;
  title: string;
  context: string = 'feed'
  selectedHistoryRow = new Set<IInteraction>();
  constructor(@Inject(MAT_DIALOG_DATA) public parentData: any, private inquiryService: InquiryService) { }

  ngOnInit() {
    this.sdr = this.parentData.sdr;
    this.client = this.parentData.client;
    this.email = this.parentData.email;
    this.title = this.parentData.title || 'Open Inquiry'
    this.context = this.parentData.context || 'feed'
    const today = new Date();
    this.inquiryService.setInquiryLeads([]);
    // If email is selected
    if (this.parentData.sdr) {
      this.inquiryService.searchInquiryLead(this.parentData.sdr.email, "", this.parentData.email, "", "");
    }
  }

  get isFeed(): boolean {
    return this.context === 'feed'
  }

  getFormattedDefaultDate(date: Date) {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  }

  onSelectHistoryRow(row) {
    this.selectedHistoryRow = row;
  }

}
