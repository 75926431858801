<div class="inquiry-lead-info">
	<div fxLayout="row" fxLayoutGap="10">
		<div class="section" fxLayout="column" fxFlex="50">
			<div class="section-title">Details</div>
			<div>
				<p>
					<span class="label-font">ID Lead</span>
					<span class="value-font">{{lead?.leadId}}</span>
				</p>
				<p>
					<span class="label-font">First Name</span>
					<span class="value-font">{{lead?.firstName}}</span>
				</p>
				<p>
					<span class="label-font">Middle Name</span>
					<span class="value-font">{{lead?.middleName}}</span>
				</p>
				<p>
					<span class="label-font">Last Name</span>
					<span class="value-font">{{lead?.lastName}}</span>
				</p>
				<p>
					<span class="label-font">Title</span>
					<span class="value-font">{{lead?.title}}</span>
				</p>
				<p>
					<span class="label-font">Job Responsability</span>
					<span class="value-font">{{lead?.jobResponsibility}}</span>
				</p>
				<p>
					<span class="label-font">Job Department</span>
					<span class="value-font">{{lead?.jobDepartment}}</span>
				</p>
				<p>
					<span class="label-font">Country</span>
					<span class="value-font">{{lead?.country}}</span>
				</p>
				<p>
					<span class="label-font">State</span>
					<span class="value-font">{{lead?.state}}</span>
				</p>
				<p>
					<span class="label-font">City</span>
					<span class="value-font">{{lead?.city}}</span>
				</p>
				<p>
					<span class="label-font">ID Company</span>
					<span class="value-font">{{lead?.company?.idCompany}}</span>
				</p>
				<p>
					<span class="label-font">Company Name</span>
					<span class="value-font">{{lead?.company?.name}}</span>
				</p>
				<p>
					<span class="label-font">LinkedIn URL</span>
					<span class="value-font">{{lead?.company?.linkedInUrl}}</span>
				</p>
				<p>
					<span class="label-font">LinkedIn Company ID</span>
					<span class="value-font">{{lead?.company?.linkedinCompanyId}}</span>
				</p>
				<p>
					<span class="label-font">Technologies</span>
					<span class="value-font">{{lead?.company?.technologies}}</span>
				</p>
				<p>
					<span class="label-font">Industry</span>
					<span class="value-font">{{lead?.company?.industry}}</span>
				</p>
				<p>
					<span class="label-font">Size</span>
					<span class="value-font">{{lead?.company?.size}}</span>
				</p>
			</div>
		</div>
		<div fxLayout="column" fxFlex="50">
			<div class="section">
				<div class="section-title"></div>
				<div>
					<p>
						<span class="label-font">Email Address</span>
						<span class="value-font">{{lead?.emailAddress}}</span>
					</p>
					<p>
						<span class="label-font">LinkedIn URL Gen</span>
						<span class="value-font">{{lead?.linkedInUrlGen}}</span>
					</p>
					<p>
						<span class="label-font">Email Strategy</span>
						<span class="value-font">{{lead?.emailStrategy}}</span>
					</p>
					<p>
						<span class="label-font">Date Created</span>
						<span class="value-font">{{lead?.dateCreated}}</span>
					</p>
					<p>
						<span class="label-font">Date Updated</span>
						<span class="value-font">{{lead?.dateUpdated}}</span>
					</p>
					<p>
						<span class="label-font">Pattern Id</span>
						<span class="value-font">{{lead?.patternId}}</span>
					</p>
					<p>
						<span class="label-font">Email Status ID</span>
						<span class="value-font">{{lead?.emailStatus?.id}}</span>
					</p>
					<p>
						<span class="label-font">Contact Type ID</span>
						<span class="value-font">{{lead?.contactTypeId}}</span>
					</p>
					<p>
						<span class="label-font">Phone</span>
						<span class="value-font">{{lead?.phone}}</span>
					</p>
				</div>
			</div>
			<div class="section">
				<div class="section-title"></div>
				<div>
					<p>
						<span class="label-font">Quantity Employees on LinkedIn</span>
						<span class="value-font">{{lead?.company?.employeeCount}}</span>
					</p>
					<p>
						<span class="label-font">Domain</span>
						<span class="value-font">{{lead?.company?.domain}}</span>
					</p>
					<p>
						<span class="label-font">Type</span>
						<span class="value-font">{{lead?.company?.type}}</span>
					</p>
					<p>
						<span class="label-font">Website</span>
						<span class="value-font">{{lead?.company?.website}}</span>
					</p>
					<p>
						<span class="label-font">Specialties</span>
						<span class="value-font">{{lead?.company?.specialties}}</span>
					</p>
					<p>
						<span class="label-font">Year Founded</span>
						<span class="value-font">{{lead?.company?.yearFounded}}</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</div>