<div class="discrepancies-sdrs-list-container">
  <mat-form-field
    appearance="outline"
    class="form-field-5 field"
    subscriptSizing="dynamic"
    id="discrepancies-sdrs-clients-mat-form-field"
  >
    <mat-label>Clients</mat-label>
    <input
      matInput
      type="text"
      [formControl]="clientCtrl"
      [matAutocomplete]="clientsAuto"
      id="discrepancies-sdrs-clients-input"
    />
    <mat-autocomplete
      #clientsAuto="matAutocomplete"
      (optionSelected)="onChangeClient($event.option.value)"
      id="discrepancies-sdrs-clients-mat-autocomplete"
    >
      <mat-option
        *ngFor="let client of filteredClients | async"
        [value]="client"
        id="discrepancies-sdrs-clients-mat-option"
      >
        {{ client }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div class="add-sdrs-container">
    <mat-form-field
      appearance="outline"
      class="form-field-5 field"
      subscriptSizing="dynamic"
      id="discrepancies-sdrs-add-sdr-mat-form-field"
    >
      <mat-label>Add SDRs</mat-label>
      <input
        type="text"
        aria-label="Number"
        matInput
        [formControl]="sdrCtrl"
        [matAutocomplete]="sdrsAuto"
        id="discrepancies-sdrs-add-sdr-input"
      />
      <mat-autocomplete
        #sdrsAuto="matAutocomplete"
        id="discrepancies-sdrs-add-sdr-mat-autocomplete"
      >
        <mat-option
          *ngFor="let sdr of filteredSdrs | async"
          [value]="sdr.email"
          id="discrepancies-sdrs-add-sdr-mat-option"
        >
          {{ sdr.email }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      class="field__button"
      (click)="onAssignSdr()"
      [disabled]="!isSdrValid()"
      id="discrepancies-sdrs-add-sdr-btn"
    >
      <mat-icon>add_circle</mat-icon>ADD
    </button>
  </div>

  <div class="discrepancies-sdrs-table mat-elevation-z8">
    <mat-form-field
      appearance="outline"
      class="discrepancies-sdrs-table__search-sdr-field form-field-5"
      subscriptSizing="dynamic"
      id="discrepancies-sdrs-search-sdr-mat-form-field"
    >
      <mat-label>Search SDR</mat-label>
      <input
        matInput
        [(ngModel)]="searchText"
        autocomplete="off"
        (keyup)="applySearch()"
        id="discrepancies-sdrs-search-sdr-input"
      />
      <mat-icon
        *ngIf="searchText"
        matSuffix
        (click)="onClearSearch()"
        id="discrepancies-sdrs-search-sdr-mat-icon-clear"
        >close</mat-icon
      >
    </mat-form-field>
    <mat-table
      [dataSource]="assignedSdrsList"
      matSort
      matSortActive="sdr"
      matSortDirection="asc"
      matSortDisableClear
      #sdrsSort="matSort"
    >
      <ng-container matColumnDef="sdr">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          >SDR</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.sdr.email }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell
          *matHeaderCellDef
          class="center"
          >Actions</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="center"
        >
          <button
            mat-icon-button
            (click)="onRemoveSdr(row.sdr)"
            id="discrepancies-sdrs-remove-sdr-btn"
          >
            <mat-icon>remove_circle</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="loading">
        <mat-footer-cell
          *matFooterCellDef
          colspan="2"
        >
          Loading data...
        </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="noData">
        <mat-footer-cell
          *matFooterCellDef
          colspan="2"
        >
          No data.
        </mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        *matFooterRowDef="['loading']"
        [ngClass]="{ hide: assignedSdrsList && assignedSdrsList.data }"
      ></mat-footer-row>
      <mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{ hide: !(assignedSdrsList && assignedSdrsList.data.length === 0) }"
      ></mat-footer-row>
    </mat-table>
    <mat-paginator
      [pageSize]="10"
      showFirstLastButtons
      #sdrsPaginator="matPaginator"
    ></mat-paginator>
  </div>
</div>
