import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ILeadToBulk, ISelectsData } from 'src/app/model/DiscrepancyLeadToBulk';
import { DiscrepanciesDataService } from 'src/app/services/discrepancies-data.service';

interface IDialogData {
  lead: ILeadToBulk;
  detailsForm: FormGroup;
  selectsData: ISelectsData;
  salesRepresentatives: any[];
  isQualificationsEditable: boolean;
}

const SNACKBAR_DURATION = 3000;

@Component({
  selector: 'app-lead-to-bulk-dialog',
  templateUrl: './lead-to-bulk-dialog.component.html',
  styleUrls: ['./lead-to-bulk-dialog.component.scss', '../discrepancies.component.scss']
})
export class LeadToBulkDialogComponent implements OnInit {
  lead: ILeadToBulk;
  selectsData: ISelectsData = null;
  salesRepresentatives = [];
  form: FormGroup;
  initialValues;

  constructor(
    public dialogRef: MatDialogRef<LeadToBulkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    private discrepanciesDataService: DiscrepanciesDataService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.lead = this.data.lead;
    this.selectsData = this.data.selectsData;
    this.salesRepresentatives = this.data.salesRepresentatives;
    this.initialValues = this.data.detailsForm.value;
    this.form = this.data.detailsForm;
  }

  get isSaveEnabled(): boolean {
    return this.form.dirty && this.form.valid && this.form.touched;
  }

  get minContactDate(): Date | null {
    return this.form.get('date_received').value ? new Date(this.form.get('date_received').value) : null;
  }

  compareFunction(option, value): boolean {
    return option.id === value?.id;
  }

  onClickSave() {
    this.dialogRef.close({
      save: true,
      form: this.form
    });
  }

  onClickCancel() {
    this.form.reset(this.initialValues);
    this.dialogRef.close();
  }

  getDefaultValues() {
    const form = this.form.getRawValue();
    if(form.date_received && form.source.id && form.campaign_bulk_date) {
      const inputs = {
        source_id: form.source.id,
        date_response: new Date(form.date_received).toJSON(),
        campaign_bulk_date: new Date(form.campaign_bulk_date)?.toJSON()?.split('T')[0],
        industry_lead: this.lead.industry_lead,
        country_lead: this.lead.country_lead
      }
      if(!form.matrix.value) {
        this.form.get('matrix').setValue(this.selectsData.matrix.find(opt => opt.id === form.matrix.id));
        this.form.get('matrix').enable();
      }

      if(!form.source.factor) {
        this.form.get('source').setValue(this.selectsData.qualifications.find(opt => opt.id === form.source.id));
        this.form.get('source').enable();
      }

      this.discrepanciesDataService.getLeadDefaultValues(inputs).subscribe(
        response => {
          this.form.get('country').setValue(this.selectsData.country.find(opt => opt.id === response.country_id));
          this.form.get('country').enable();
          if(!this.discrepanciesDataService.CONDITIONAL_QUALIFICATION_IDS.includes(form.source.id)) {
            this.form.get('delay').setValue(this.selectsData.delay.find(opt => opt.id === response.delay_id))
            this.form.get('delay').enable();
          } else {
            this.form.get('delay').setValue(null);
            this.form.get('delay').disable();
          }
          this.form.get('industry').setValue(this.selectsData.industry.find(opt => opt.id === response.industry_id));
          this.form.get('industry').enable();
          
          this.calculateScore();
        }, 
        error => {
          this.snackbar.open(`An error occurred: ${error.error.detail}`, null, { duration: SNACKBAR_DURATION });
        }
      )

    }
  }

  onChangeQualification() {
    this.form.get('matrix').enable();
    if(this.form.get('matrix').value) {
      this.getDefaultValues();
    }
  }

  calculateScore() {
    const form = this.form.getRawValue();
    if(form.source?.factor && form.industry?.value && form.matrix?.value && form.country?.value && form.delay?.value) {
      const inputs: any = {
        source_value: this.form.get('source').value.factor,
        industry_value: this.form.get('industry').value.value,
        matrix_value: this.form.get('matrix').value.value,
        country_value: this.form.get('country').value.value
      }

      if (form.delay?.value) {
        inputs.delay_value = form.get('delay').value.value
      }

      this.discrepanciesDataService.getLeadScore(inputs).subscribe(
        response => {
          this.form.get('score').setValue(response.value);
        },
        error => {
          this.snackbar.open(`An error occurred: ${error.error.detail}`, null, { duration: SNACKBAR_DURATION });
          this.form.get('score').setValue(0);
        }
      )
    }
  }

  dateChanged() {
    if(this.data.isQualificationsEditable) {
      this.getDefaultValues();
    }
  }

}
