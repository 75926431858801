import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IInteractionContact } from 'src/app/model/DiscrepancyLeadToBulk';

@Component({
  selector: 'app-new-lead-form',
  templateUrl: './new-lead-form.component.html',
  styleUrls: ['./new-lead-form.component.scss']
})
export class NewLeadFormComponent implements OnInit {
  @Input() selectedLead: IInteractionContact;
  @Input() form: FormGroup;
  @Input() selectsData: any;
  @Output() copyProperty = new EventEmitter<{property: string, value: any}>(null);
  constructor() { }

  ngOnInit(): void {}

  onCopy(property: string, value) {
    this.copyProperty.emit({property, value});
  }

  onCopyState(value) {
    const countryVal = this.form.get('country').value;
    if(countryVal && ['United States', 'Canada'].includes(countryVal)) {
      this.copyProperty.emit({property: 'state', value});
    }
  }
}
