import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SdApiService } from '../../../services/base/sd-api.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { FileUploadService } from 'src/app/services/file-upload-service';

const MODAL_DURATION = 3000;

@Component({
  selector: 'app-sdr-management',
  templateUrl: './sdr-management.component.html',
  styleUrls: ['./sdr-management.component.scss']
})
export class SdrManagementComponent implements OnInit  {

  constructor(private service: SdApiService,
              private router: Router,
              private dialog: MatDialog,
              private loadingStateService: LoadingStateService,
              public fileUploadService: FileUploadService,
              private snackBar: MatSnackBar) { }

  providers = ['gmail', 'outlook'];
  selectedProvider: string;
  domain: string;
  attachedFile: any;
  loading = false;
  @ViewChild('inputFileControl', { read: ElementRef }) inputFileControl: ElementRef;

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  onDomainClear() {
    this.domain = "";
  }

  uploadConfiguration() {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
        title: "Confirm",
        message: "Are you sure you want to upload this configuration?"
      };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.loadingStateService.setLoadingState(true);
          this.service.uploadSdrCongiruation(this.selectedProvider, this.domain, this.attachedFile.content).subscribe(
            response => {
              this.loadingStateService.setLoadingState(false);
              if (response.success) {
                this.snackBar.open('Configuration uploaded successfully', null, { duration: MODAL_DURATION });
              } else {
                this.handleOperationError(response.error);
              }
              this.selectedProvider = "";
              this.domain = "";
              this.attachedFile = null;
            },
            error => {
              console.log(error);
              this.handleOperationError("An error ocurred while adding the address suggestion");
              this.selectedProvider = "";
              this.domain = "";
              this.attachedFile = null;
            });
        }
      });
  }

  handleOperationError(message: string) {
    this.loadingStateService.setLoadingState(false);
    const errorConfirmDialogConfig = new MatDialogConfig();
    errorConfirmDialogConfig.data = {
      title: "Error",
      message
    };
    this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
  }

  navigateToMain() {
    this.router.navigate(['/home']);
  }


  attach(files: FileList) {
    const attachmentErrors = [];
    Array.from(files).forEach(file => {
      const attachmentError = this.fileUploadService.validateFile(file);
      if (attachmentError === '') {
        const fileId = file.name;
        const attachment = {
          filename: file.name,
          fileId,
          size: file.size,
          isUploading: true,
          content: '',
          physicalFile: file
        };

        const reader = new FileReader();
        const comp = this;
        reader.onload = function(event) {
            try {
              let resTarget: any;
              resTarget = event.target;
              attachment.content = JSON.parse(resTarget.result.toString());
              comp.attachedFile = attachment;
              comp.attachedFile.isUploading = false;
            } catch {
              const errorConfirmDialogConfig = new MatDialogConfig();
              errorConfirmDialogConfig.data = {
                title: "Attachment Errors",
                message: "File is not a valid JSON"
              };
              comp.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
            }

        };
        reader.readAsText(file);
      } else {
        attachmentErrors.push({ filename: file.name, error: attachmentError });
      }
    });
    if (attachmentErrors.length > 0) {
      let errorMessage = 'Unable to upload the file due to the following errors: <br><br><br>';
      attachmentErrors.forEach(ae => {
        errorMessage += ae.filename + '<br> ' + ae.error + ' <br><br>';
      });
      const errorConfirmDialogConfig = new MatDialogConfig();
      errorConfirmDialogConfig.data = {
          title: "Attachment Errors",
          message: errorMessage
        };
      this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
    }
    this.inputFileControl.nativeElement.value = '';
  }

  deleteAttachment() {
    this.attachedFile = null;
  }

}
