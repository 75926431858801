<div class="modal-header">
  <h2>Turn user {{ actionToDisplay }}</h2>
</div>
<div class="modal-content">
  <div class="toggle-container">
    <div class="toggle-content">
      <h4>Are you sure you want to turn this user {{ actionToDisplay }}?</h4>
      <div class="operator-info">
        <div class="operator-user">
          <div class="operator-info-header">
            <span>USER SELECTED</span>
          </div>
          <div class="operator-info-data">
            <div
              *ngFor="let operator of data.operatorsUsers"
              class="user-data"
            >
              <span
                ><strong>{{ operator.name }}</strong></span
              >
              <span>{{ operator.email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-actions">
  <button
    mat-button
    class="dismiss-button"
    (click)="onDismiss()"
    id="operator-user-active-toggle-cancel-btn"
  >
    CANCEL
  </button>
  <button
    class="confirm-button"
    mat-raised-button
    color="primary"
    (click)="onConfirm()"
    cdkFocusInitial
    id="operator-user-active-toggle-confirm-btn"
  >
    CONFIRM
  </button>
</div>
