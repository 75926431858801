import { Injectable } from '@angular/core';
import {SdApiService} from './base/sd-api.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private sdApiService: SdApiService) {}

  sendLog(payload: any) {
    this.sdApiService.sendLog(payload).subscribe((_) => {
      // console.log('log sent');
    }, error => {
      console.log(error);
    });
  }

  submitLog(payload: any) {
    this.sdApiService.submitLog(payload).subscribe((_) => {
    }, error => {
      console.log(error);
    });
  }

}
