interface IContact {
  id: number;
  email: string;
  name: string;
}

interface IStatus {
  id: number;
  name: string;
}

interface IDiscrepancyStats {
  total_pending: number;
  total_day: number;
}


interface IDiscrepancyMessage {
  uid: string;
  message_id: string;
  date: string;
}

export interface IDiscrepancy {
  id: number;
  sdr: IContact;
  message: IDiscrepancyMessage,
  input_folder: string;
  output_folder: string;
  sender: IContact;
  sender_lead: IContact;
  original_recipient: IContact;
  status: IStatus;
  pending: boolean;
  created_at: Date;
}

export interface ILoadDiscrepanciesResponse {
  data: IDiscrepancy[];
  stats: IDiscrepancyStats;
}

export interface ILeadsToBulkSearchParams {
  client?: number;
  sdr: number;
  email: string;
  firstName?: string;
  lastName?: string;
  fromDate?: string;
  toDate?: string;
  searchDate?: string;
  isPartialEmail: boolean;
  matchAnyCondition?: boolean;
}

export interface ILeadSearchParams {
  client?: number;
  sdr: number;
  email: string;
}

export class Discrepancy implements IDiscrepancy {
  id: number;
  sdr: IContact;
  message: IDiscrepancyMessage;
  input_folder: string;
  output_folder: string;
  sender: IContact;
  sender_lead: IContact;
  original_recipient: IContact;
  status: IStatus;
  pending: boolean;
  created_at: Date;

  constructor(log) {
    this.id = log.id;
    this.sdr = log.sdr;
    this.message = log.message;
    this.input_folder = log.input_folder;
    this.output_folder = log.output_folder;
    this.sender = log.sender;
    this.sender_lead = log.lead_match;
    this.original_recipient = log.original_recipient;
    this.status = log.status;
    this.pending = log.pending;
    this.created_at = log.created_at;
  }
}
