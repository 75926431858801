import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OperatorsLevels, UpdateOperatorLevelRequest, IOperator } from '../../../../model/Operator';
import { ClientManagementModalData } from '../../../../model/Sdr';

@Component({
  selector: 'app-set-operator-level',
  templateUrl: './set-level.component.html',
  styleUrls: ['./set-level.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class SetOperatorLevelComponent {
  public operatorLevelsDataSource: MatTableDataSource<OperatorsLevels> = new MatTableDataSource(null);
  public displayedOperatorLevelsColumns: string[] = ['level','select'];
  public selectedLevel: number;

  constructor(
    public dialog: MatDialogRef<SetOperatorLevelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClientManagementModalData
  ) {
    this.createOperatorLevelsDataSource(data.levels);
  }

  public onDismiss(): void {
    this.dialog.close([]);
  }

  public onConfirm(): void {
    this.dialog.close(this.mapOperatorToAssignOperatorRequest([]));
  }

  public handleLevelChangeEvent(levelId: number): void {
    this.selectedLevel = levelId;
  }

  private mapOperatorToAssignOperatorRequest(operators: IOperator[]): UpdateOperatorLevelRequest[] {
    return operators.map(operator => ({
      level_id: this.selectedLevel,
      operators_customers_level_id: operator.operator_id,
    }))
  }

  private createOperatorLevelsDataSource(operatorList: OperatorsLevels[]): void {
    this.operatorLevelsDataSource = new MatTableDataSource(operatorList);
  }
}