import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDiscrepancy } from 'src/app/model/Discrepancy';
import { DiscrepanciesService } from 'src/app/services/discrepancies.service';
import { SelectedDiscrepancyService } from 'src/app/services/selected-discrepancy.service';

@Component({
  selector: 'app-classifier-log',
  templateUrl: './classifier-log.component.html',
  styleUrls: ['./classifier-log.component.scss'],
})
export class ClassifierLogComponent implements OnInit {
  @Input() log: IDiscrepancy;
  @Output() completeDiscrepancy: EventEmitter<null> = new EventEmitter();
  @Output() emailClicked: EventEmitter<string> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  get isCompleted(): boolean {
    return this.log.status.name === 'completed';
  }

  onComplete() {
    this.completeDiscrepancy.emit();
  }

  onClickEmail(email: string) {
    this.emailClicked.emit(email);
  }
}
