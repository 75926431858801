<div
  fxLayout="column"
  class="mail-clasifier"
  *ngIf="classifications.length"
>
  <div
    *ngIf="selectedEmails >= 2"
    class="bulk-text"
  >
    Classify {{ selectedEmails }} emails as:
  </div>
  <div *ngFor="let section of classifications">
    <div
      fxLayout="row"
      class="category-title-wrapper"
    >
      {{ section.sectionName }}
    </div>
    <div class="category-options-wrapper">
      <button
        *ngFor="let classification of section.classifications"
        class="classification-btn"
        mat-stroked-button
        [disabled]="isClassificationDisabled || !classification.name"
        (click)="classify(classification)"
        [id]="classification.htmlId"
      >
        <span
          *ngIf="section.sectionName === 'Direct access'"
          class="classification-shortcut"
        >
          {{ classification.shortcut }}
        </span>
        <span class="classification-name">
          {{ classification.name | titlecase }}
        </span>
      </button>
    </div>
  </div>
</div>
