import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SdApiService } from '../../../../services/base/sd-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { ContactsDataService } from 'src/app/services/contacts-data.service';
import { merge, Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { CustomerService } from 'src/app/services/customer.service';

const MODAL_DURATION = 3000;

@Component({
  selector: 'app-client-contacts-list',
  templateUrl: './client-contacts-list.component.html',
  styleUrls: ['./client-contacts-list.component.scss']
})
export class ClientContactsListComponent implements OnInit, AfterViewInit {

  constructor(
    private service: SdApiService,
    private contactsDataService: ContactsDataService,
    private router: Router,
    private dialog: MatDialog,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar,
    private customerService: CustomerService
  ) {}

  contactListData: MatTableDataSource<any> = new MatTableDataSource([]);
  contactDisplayedColumns: string[] = ['name', 'status', 'clientName', 'email', 'actions'];
  @ViewChild('contactSort') contactSort: MatSort;
  @ViewChild('contactPaginator') contactPaginator: MatPaginator;
  emailFilter = '';
  nameFilter = '';
  statusFilter = '';
  statusOptions = ['','Active', 'Suspended'];

  loading = false;
  resultsLength = 0;
  removable = true;

  clientFilterCtrl = new FormControl();
  availableClients: string[] = [];
  filteredClientOptions: Observable<any[]>;
  allClientsObj = [];
  firstLoad = true;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe(loading => {
      this.loading = loading;
    });

    this.customerService.getCustomers().subscribe(response => {
      const clients = response.data;
      this.availableClients = clients.map(c => c.customer_name).sort();
      this.allClientsObj = clients;
      this.availableClients.splice(0, 0, '');
      this.filteredClientOptions = this.clientFilterCtrl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.customer_name),
          map(customerName => customerName ? this._filterClient(customerName) : this.availableClients.slice())
        )

      this.clientFilterCtrl.valueChanges.subscribe(() => this.setClientCtrlError());
    });
  }

  ngAfterViewInit() {
    this.contactSort.sortChange.subscribe(() => (this.contactPaginator.pageIndex = 0));

    merge(this.contactSort.sortChange, this.contactPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingStateService.setLoadingState(true);
          var clientId = this.getClientId() || '';
          return this.contactsDataService
            .loadContacts(
              this.nameFilter,
              this.statusFilter,
              clientId,
              this.emailFilter,
              this.contactPaginator.pageIndex + 1,
              this.contactPaginator.pageSize,
              this.contactSort.active ? this.contactSort.active : 'date_created',
              this.contactSort.direction ? this.contactSort.direction : 'desc'
            )
            .pipe(catchError(() => of(null)));
        }),
        map((data) => {
          this.loadingStateService.setLoadingState(false);

          if (data === null) {
            return [];
          }

          this.firstLoad = false;
          this.resultsLength = data.total_count;
          return data.items;
        })
      )
      .subscribe((data) => {
        this.contactListData = new MatTableDataSource(data);
      });
  }

  get canExecuteSearch(): boolean {
    if(this.clientFilterCtrl.value) {
      return !!this.getClientId();
    } 

    return true;
  }

  getClientId() {
    if(this.clientFilterCtrl.value) {
      return this.allClientsObj.find(c => c.customer_name === this.clientFilterCtrl.value)?.customer_id;
    }
  }

  refreshData() {
    const clientId = this.getClientId() || '';
    this.loadingStateService.setLoadingState(true);
      return this.contactsDataService
        .loadContacts(
          this.nameFilter,
          this.statusFilter,
          clientId,
          this.emailFilter,
          this.contactPaginator.pageIndex + 1,
          this.contactPaginator.pageSize,
          this.contactSort.active,
          this.contactSort.direction || undefined
        )
        .pipe(catchError(() => of(null)))
        .map((data) => {
          this.loadingStateService.setLoadingState(false);

          if (data === null) {
            return [];
          }

          this.resultsLength = data.total_count;
          return data.items;
    }).subscribe((data) => (this.contactListData = new MatTableDataSource(data)));
  }

  setClientCtrlError() {
    if(!this.canExecuteSearch) {
      this.clientFilterCtrl.setErrors({ invalidClientId: true });
    }
  }

  onContactSearchClear() {
    this.nameFilter = '';
    this.refreshData();
  }

  applyContactFilter() {
    this.refreshData();
  }

  search() {
    this.contactPaginator.pageIndex = 0;
    this.refreshData();
    this.trigger.closeMenu();
  }

  clearSearch() {
    if(!this.clientFilterCtrl.value &&  !this.emailFilter && !this.statusFilter) return;
    this.clientFilterCtrl.setValue('');
    this.emailFilter = null;
    this.statusFilter = null;
    this.search();
  }

  editContact(id: string) {
    this.router.navigate(['/home/management/contacts/contact-edit'], { queryParams: { id } });
  }

  removeContact(id: number) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Are you sure you want to remove this client contact?',
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loadingStateService.setLoadingState(true);
        this.contactsDataService.removeContact(id).subscribe(
          response => {
            this.loadingStateService.setLoadingState(false);
            if (response.data == 1) {
              this.contactListData.data.splice(this.contactListData.data.findIndex(item => item.id === id), 1);
              this.contactListData._updateChangeSubscription();
              this.snackBar.open('Client contact removed successfully', null, { duration: MODAL_DURATION });
            } else {
              this.handleOperationError(response.message);
            }
          },
          error => {
            console.log(error);
            this.handleOperationError('An error ocurred while removing the contact template');
          });
      }
    });
  }

  handleOperationError(message: string) {
    this.loadingStateService.setLoadingState(false);
    const errorConfirmDialogConfig = new MatDialogConfig();
    errorConfirmDialogConfig.data = {
      title: 'Error',
      message,
    };
    this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
  }

  createContact() {
    this.router.navigate(['/home/management/contacts/contact-edit'], { queryParams: { id: 0 } });
  }

  private _filterClient(client: string): string[] {
    const filterValue = client.toLowerCase();

    return this.availableClients.filter(e => e.toLowerCase().includes(filterValue));
  }

  displayFn(input: string): string {
    return input ? input : '';
  }

}
