<div class="layoutDiv">
  <div class="container">
    <div
      class="titleDiv"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon>arrow_back_ios</mat-icon>
      <span>Operator Users</span>
    </div>
    <mat-divider></mat-divider>
    <div
      class="actions"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div class="action-buttons">
        <button
          mat-raised-button
          color="primary"
          (click)="handleCreateUser()"
          id="operator-user-new-user-btn"
        >
          <mat-icon>add</mat-icon>
          NEW USER
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <mat-tab-group (selectedTabChange)="handleTabChange($event.index)">
      <mat-tab
        label="ACTIVE"
        id="operator-user-active-mat-tab"
      >
        <div class="table-padding">
          <app-table-actions
            [showSelectAllAction]="true"
            [selectAllActionLabel]="
              (currentTab$ | async) === OperatorUserManagementTabs.ACTIVE ? 'Set Inactive' : 'Set Active'
            "
            [selectAllActionDisabled]="!selectedUserIds.length || (disableSelectAllAction$ | async)"
            [allItensSelected]="selectedAllUsers$ | async"
            (selectAllEvent)="handleSelectAllEvent($event)"
            (selectAllActionEvent)="openOperatorUserActiveTogleModal()"
            (filterUserInputEvent)="handleFilterUserInputEvent($event)"
            #activeTableActions
          ></app-table-actions>
          <div class="mat-elevation-z8 shrink">
            <ng-container *ngTemplateOutlet="table"></ng-container>
            <mat-paginator
              [pageSizeOptions]="[10, 50, 100]"
              showFirstLastButtons
              (page)="handleSelectAllEvent(false)"
              #activePaginator
            ></mat-paginator>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        label="INACTIVE"
        id="operator-user-inactive-mat-tab"
      >
        <div class="table-padding">
          <app-table-actions
            [showSelectAllAction]="true"
            [selectAllActionLabel]="
              (currentTab$ | async) === OperatorUserManagementTabs.ACTIVE ? 'Set Inactive' : 'Set Active'
            "
            [selectAllActionDisabled]="!selectedUserIds.length || (disableSelectAllAction$ | async)"
            [allItensSelected]="selectedAllUsers$ | async"
            (selectAllEvent)="handleSelectAllEvent($event)"
            (selectAllActionEvent)="openOperatorUserActiveTogleModal()"
            (filterUserInputEvent)="handleFilterUserInputEvent($event)"
            #inactiveTableActions
          ></app-table-actions>
          <div class="mat-elevation-z8 shrink">
            <ng-container *ngTemplateOutlet="table"></ng-container>
            <mat-paginator
              [pageSizeOptions]="[10, 50, 100]"
              showFirstLastButtons
              (page)="handleSelectAllEvent(false)"
              #inactivePaginator
            ></mat-paginator>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #table>
  <!-- <div class="mat-elevation-z1 shrink"> -->
  <mat-table
    [dataSource]="userDataSource"
    matSort
  >
    <ng-container matColumnDef="select">
      <mat-header-cell
        fxLayoutAlign="start center"
        fxFlex="7"
        *matHeaderCellDef
      ></mat-header-cell>
      <mat-cell
        fxLayoutAlign="start center"
        fxFlex="7"
        *matCellDef="let element"
      >
        <mat-checkbox
          color="primary"
          [checked]="checkIfRowIsSelected(element.id)"
          (change)="handleSelect($event.checked, element.id)"
          id="operator-user-select-mat-checkbox"
        ></mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell
        fxLayoutAlign="start center"
        fxFlex="30"
        *matHeaderCellDef
        mat-sort-header
        >Name</mat-header-cell
      >
      <mat-cell
        fxLayoutAlign="start center"
        fxFlex="30"
        *matCellDef="let element"
        >{{ element.name }}</mat-cell
      >
    </ng-container>
    <ng-container matColumnDef="email">
      <mat-header-cell
        fxLayoutAlign="start center"
        fxFlex="25"
        *matHeaderCellDef
        >Email</mat-header-cell
      >
      <mat-cell
        fxLayoutAlign="start center"
        fxFlex="25"
        *matCellDef="let element"
        >{{ element.email }}</mat-cell
      >
    </ng-container>
    <ng-container matColumnDef="last_activity">
      <mat-header-cell
        fxLayoutAlign="start center"
        fxFlex="23"
        *matHeaderCellDef
        >Last Activity</mat-header-cell
      >
      <mat-cell
        fxLayoutAlign="start center"
        fxFlex="23"
        *matCellDef="let element"
      >
        {{ element.last_activity | date: 'yyyy/MM/dd hh:mm:ss' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell
        fxLayoutAlign="center center"
        fxFlex="15"
        *matHeaderCellDef
        >Actions</mat-header-cell
      >
      <mat-cell
        fxLayoutAlign="center center"
        fxFlex="15"
        *matCellDef="let element"
        class="center"
      >
        <button
          fxLayoutAlign="center center"
          class="action-btn"
          mat-icon-button
          (click)="handleEditClick(element)"
          id="operator-user-edit-btn"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          fxLayoutAlign="center center"
          class="action-btn"
          mat-icon-button
          (click)="openOperatorUserActiveTogleModal(element)"
          id="operator-user-open-user-modal-btn"
        >
          <mat-icon>{{
            (currentTab$ | async) === OperatorUserManagementTabs.ACTIVE ? 'do_not_disturb_on' : 'sync'
          }}</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <tr
        class="mat-row"
        *matNoDataRow
      >
        <td
          class="mat-cell no-data"
          colspan="2"
          fxFlex="100"
        >
          {{ userDataSource.data || userHasError ? 'No data.' : 'Loading users.' }}
        </td>
      </tr>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedUserColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedUserColumns"></mat-row>
  </mat-table>
  <!-- </div> -->
</ng-template>
