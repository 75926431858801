import { Component, OnInit } from '@angular/core';
import { SdrService } from '../../../services/sdr.service';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MODAL_DURATION } from '../../../model/Utils';
import { HttpStatusCode } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  IDetailedSdr,
  IReassignSdrPayload,
  ISdrFilters,
  ISdrFiltersResponse,
  ISdrForm,
  ISdrFormValue,
  SdrFiltersEnum,
  SdrFiltersSearchKeyEnum,
} from '../../../model/Sdr';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ReassignSdrComponent } from './reassign-sdr/reassign-sdr.component';

@Component({
  selector: 'app-sdrs-management-management',
  templateUrl: './sdrs-management-container.component.html',
  styleUrls: ['./sdrs-management-container.component.scss'],
})
export class SdrsManagementContainerComponent implements OnInit {
  public currentPage: number;
  public pageSize: number;
  public pageCount: number;
  public sdrCount: number;
  public displayedColumns: string[] = ['company', 'sdr', 'sdr_permission', 'operator'];
  public sdrFormArray: FormArray = new FormArray([]);
  public sdrDataSource: MatTableDataSource<IDetailedSdr> = new MatTableDataSource(null);
  public sdrHasError = false;
  public activeFilters: ISdrFilters = {};
  public SdrFiltersEnum = SdrFiltersEnum;
  public avaliableCompanies$: BehaviorSubject<ISdrFiltersResponse[]> = new BehaviorSubject<ISdrFiltersResponse[]>([]);
  public avaliableSdrs$: BehaviorSubject<ISdrFiltersResponse[]> = new BehaviorSubject<ISdrFiltersResponse[]>([]);
  public avaliablePermissions$: BehaviorSubject<ISdrFiltersResponse[]> = new BehaviorSubject<ISdrFiltersResponse[]>([]);
  public avaliableOperators$: BehaviorSubject<ISdrFiltersResponse[]> = new BehaviorSubject<ISdrFiltersResponse[]>([]);

  public avaliableOperatorsForTable$: BehaviorSubject<Record<number, ISdrFiltersResponse[]>> = new BehaviorSubject<
    Record<number, ISdrFiltersResponse[]>
  >({});
  public avaliablePermissionsForTable$: BehaviorSubject<Record<number, ISdrFiltersResponse[]>> = new BehaviorSubject<
    Record<number, ISdrFiltersResponse[]>
  >({});

  public selectedSdrIds: number[] = [];
  public selectedAllSdrs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public disableSelectAllAction$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly sdrService: SdrService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.loadSdrs();
    this.handleCompanyFilter();
    this.handleSdrFilter();
    this.handlePermissionFilter();
    this.handleOperatorFilter();
  }

  public handlePageEvent(event: PageEvent): void {
    this.loadSdrs(event.pageIndex + 1, event.pageSize, this.activeFilters);
  }

  public handleUserInput(filterKey: SdrFiltersEnum, userInput?: any, sdrId?: number): void {
    switch (filterKey) {
      case SdrFiltersEnum.COMPANY:
        this.handleCompanyFilter(userInput);
        break;
      case SdrFiltersEnum.SDR:
        this.handleSdrFilter(userInput);
        break;
      case SdrFiltersEnum.PERMISSION:
        this.handlePermissionFilter(userInput, sdrId, !sdrId);
        break;
      case SdrFiltersEnum.OPERATOR:
        this.handleOperatorFilter(userInput, sdrId, !sdrId);
        break;
      default:
        break;
    }

    if (!sdrId) {
      this.handleFilterSelectionChange(filterKey, userInput, !userInput);
    }
  }

  public handleFilterSelectionChange(filterKey: SdrFiltersEnum, selectedItem?: string, forceReload = true): void {
    if (!selectedItem) {
      this.resetFilter(filterKey);
    } else {
      this.activeFilters[filterKey] = selectedItem;
    }

    if (forceReload) {
      this.loadSdrs(1, this.pageSize, this.activeFilters);
    }
  }

  public formatFilterPlaceholderName(filter: ISdrFiltersResponse): string {
    return filter?.name;
  }

  public formatFilterPlaceholderEmail(filter: ISdrFiltersResponse): string {
    return filter?.email;
  }

  public handleSelect(selected: boolean, entityId: number): void {
    if (selected) {
      this.selectedSdrIds.push(entityId);
    } else {
      this.selectedSdrIds = this.selectedSdrIds.filter((sdrId) => sdrId !== entityId);
    }

    if (!this.selectedSdrIds.length) {
      this.selectedAllSdrs$.next(false);
      return;
    }

    this.selectedAllSdrs$.next(
      this.sdrDataSource.data.every((sdr) => this.selectedSdrIds.includes(sdr.user_operator_id)),
    );
  }

  public handleSelectAllEvent(selectAll: boolean): void {
    if (selectAll) {
      const usersToAdd = this.sdrDataSource.data
        .filter((sdr) => !this.selectedSdrIds.includes(sdr.user_operator_id))
        .map((sdr) => sdr.user_operator_id);
      this.selectedSdrIds.push(...usersToAdd);
    } else {
      this.selectedSdrIds.splice(0);
    }

    this.selectedAllSdrs$.next(selectAll);
  }

  public checkIfRowIsSelected(sdrId: number): boolean {
    return this.selectedSdrIds.includes(sdrId);
  }

  public openReassignModal(): void {
    if (!this.validadeSdrs()) {
      this.showToast(`Please check selected SDR's`);
      return;
    }

    const selectedSdrs = this.sdrDataSource.data.filter((sdr) => this.selectedSdrIds.includes(sdr.user_operator_id));

    const dialogRef = this.dialog.open(ReassignSdrComponent, {
      width: '25%',
      data: {
        sdrs: selectedSdrs,
      },
      panelClass: 'wd-modal',
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: IReassignSdrPayload[]) => {
        if (!!res) {
          this.reassignSdrs(res);
        }
      });
  }

  public setFormValue(control: AbstractControl, value: ISdrFiltersResponse): void {
    control.setValue(value);
    const targetSdr = control.parent.value as ISdrFormValue;

    this.sdrDataSource.data.forEach((data) => {
      if (data.user_operator_id === targetSdr.user_operator_id) {
        data.operator = targetSdr.operator;
        data.level = targetSdr.level;
      }
    });
  }

  public handleSave(): void {
    if (!this.validadeSdrs()) {
      this.showToast(`Please check selected SDR's`);
      return;
    }

    const selectedSdrs = this.sdrDataSource.data.filter((sdr) => this.selectedSdrIds.includes(sdr.user_operator_id));

    const updatedPayload = selectedSdrs.map((sdr) => ({
      user_operator_id: sdr.user_operator_id,
      user_id: sdr.user_id,
      operator_id: sdr.operator.id,
      level_id: sdr.level.id,
    }));

    this.reassignSdrs(updatedPayload, true);
  }

  private validadeSdrs(): boolean {
    const selectedControls = this.sdrFormArray.controls.filter((control) =>
      this.selectedSdrIds.includes(control.get('user_operator_id').value),
    );
    return selectedControls.every((control) => {
      control.get('level').markAsTouched();
      control.get('operator').markAsTouched();
      return control.valid;
    });
  }

  private validateSelectedOption = (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;
    const invalidSdr = typeof controlValue === 'string' || controlValue instanceof String;

    if (control?.value && invalidSdr) {
      return { invalidOption: true };
    }
    return null;
  };

  private reassignSdrs(payload: IReassignSdrPayload[], isUpdate = false): void {
    const successAction = isUpdate ? 'updated' : 'reassigned';
    const errorAction = isUpdate ? 'update' : 'reassign';
    this.disableSelectAllAction$.next(true);
    this.sdrService
      .reassignSdrs(payload)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res.code === HttpStatusCode.Created) {
            this.loadSdrs(1, this.pageSize, this.activeFilters);
            this.showToast(`SDR's were ${successAction} successfully`);
          } else {
            this.showToast(`Failed to ${errorAction} SDR's`);
          }
        },
        () => {
          this.showToast(`Failed to ${errorAction} SDR's`);
        },
      );
  }

  private resetFilter(filterKey: SdrFiltersEnum): void {
    delete this.activeFilters[filterKey];
  }

  private handleCompanyFilter(userInput?: string): void {
    this.sdrService.getFilter(SdrFiltersEnum.COMPANY, userInput, SdrFiltersSearchKeyEnum.NAME).subscribe(
      (res) => {
        if (res.code === HttpStatusCode.Ok) {
          this.avaliableCompanies$.next(res.data);
        } else {
          this.showToast('Failed to load companies');
        }
      },
      () => {
        this.showToast('Failed to load companies');
      },
    );
  }

  private handleSdrFilter(userInput?: string): void {
    this.sdrService.getFilter(SdrFiltersEnum.SDR, userInput, SdrFiltersSearchKeyEnum.NAME).subscribe(
      (res) => {
        if (res.code === HttpStatusCode.Ok) {
          this.avaliableSdrs$.next(res.data);
        } else {
          this.showToast("Failed to load SDR's");
        }
      },
      () => {
        this.showToast("Failed to load SDR's");
      },
    );
  }

  private handlePermissionFilter(userInput?: string, sdrId?: number, setFilterDropdown = true): void {
    this.disableSelectAllAction$.next(true);
    this.sdrService.getFilter(SdrFiltersEnum.PERMISSION, userInput, SdrFiltersSearchKeyEnum.NAME).subscribe(
      (res) => {
        if (res.code === HttpStatusCode.Ok) {
          if (setFilterDropdown) {
            this.avaliablePermissions$.next(res.data);
          }
          if (sdrId) {
            const updatedFilters = this.updateTableDataForTargetSdr(
              res.data,
              this.avaliablePermissionsForTable$.value,
              sdrId,
            );
            this.avaliablePermissionsForTable$.next(updatedFilters);
          }
        } else {
          this.showToast('Failed to load permissions');
        }
      },
      () => {
        this.showToast('Failed to load permissions');
      },
    );
  }

  private handleOperatorFilter(userInput?: string, sdrId?: number, setFilterDropdown = true): void {
    this.sdrService.getFilter(SdrFiltersEnum.OPERATOR, userInput, SdrFiltersSearchKeyEnum.EMAIL).subscribe(
      (res) => {
        if (res.code === HttpStatusCode.Ok) {
          if (setFilterDropdown) {
            this.avaliableOperators$.next(res.data);
          }

          if (sdrId) {
            const updatedFilters = this.updateTableDataForTargetSdr(
              res.data,
              this.avaliableOperatorsForTable$.value,
              sdrId,
            );
            this.avaliableOperatorsForTable$.next(updatedFilters);
          }
        } else {
          this.showToast('Failed to load operators');
        }
      },
      () => {
        this.showToast('Failed to load operators');
      },
    );
  }

  private updateTableDataForTargetSdr(
    newFilters: ISdrFiltersResponse[],
    currentFilters: Record<number, ISdrFiltersResponse[]>,
    targetSdr: number,
  ): Record<number, ISdrFiltersResponse[]> {
    currentFilters[targetSdr] = newFilters;
    return currentFilters;
  }

  private loadSdrs(page: number = DEFAULT_PAGE_NUMBER, size: number = DEFAULT_PAGE_SIZE, filters?: ISdrFilters): void {
    this.selectedAllSdrs$.next(false);
    this.selectedSdrIds = [];
    this.sdrService
      .getSdrs(page, size, filters)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.disableSelectAllAction$.next(false);
          if (res.code === HttpStatusCode.Ok) {
            this.sdrHasError = false;
            this.createSdrDataSource(res.data);
            this.currentPage = res.page;
            this.pageSize = res.size;
            this.pageCount = res.pages;
            this.sdrCount = res.total;
          } else {
            this.sdrHasError = true;
            this.showToast("Failed to load SDR's");
          }
        },
        () => {
          this.disableSelectAllAction$.next(false);
          this.sdrHasError = true;
          this.showToast("Failed to load SDR's");
        },
      );
  }

  private showToast(message: string): void {
    this.snackBar.open(message, null, {
      duration: MODAL_DURATION,
    });
  }

  private async createSdrDataSource(sdrList: IDetailedSdr[]): Promise<void> {
    this.sdrDataSource = new MatTableDataSource(sdrList);

    const formGroups = sdrList.map((data) =>
      this.formBuilder.group({
        user_operator_id: new FormControl(data.user_operator_id),
        operator: new FormControl(data.operator.id ? data.operator : null, [
          Validators.required,
          this.validateSelectedOption,
        ]),
        level: new FormControl(data.level.id ? data.level : null, [Validators.required, this.validateSelectedOption]),
      }),
    );

    this.sdrFormArray = new FormArray(formGroups);

    const defaultOperators = await this.sdrService.getFilter(SdrFiltersEnum.OPERATOR).toPromise();
    const defaultLevels = await this.sdrService.getFilter(SdrFiltersEnum.PERMISSION).toPromise();
    const operatorFilters: Record<number, ISdrFiltersResponse[]> = {};
    const levelsFilters: Record<number, ISdrFiltersResponse[]> = {};

    for (const sdr of sdrList) {
      operatorFilters[sdr.user_operator_id] = sdr.operator.id ? [sdr.operator] : defaultOperators.data;
      levelsFilters[sdr.user_operator_id] = sdr.level.id ? [sdr.level] : defaultLevels.data;
    }

    this.avaliableOperatorsForTable$.next(operatorFilters);
    this.avaliablePermissionsForTable$.next(levelsFilters);
  }
}
