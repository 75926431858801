<div
  class="classifier-log-container"
  *ngIf="log && log.id"
>
  <div class="log-heading">
    <div class="log-heading__information">
      <div class="log-id">
        <span
          >ID &nbsp;<strong>{{ log.id }}</strong></span
        >
      </div>
      <div class="sdr-id">
        <span>
          SDR &nbsp;<strong>{{ log.sdr.id }}</strong>
        </span>
      </div>
      <div class="sdr-email">
        <span>
          <strong>{{ log.sdr.email }}</strong>
        </span>
      </div>
    </div>
    <div class="log-heading__folders">
      <div>
        <span>Input Folder:</span>
        <app-classifier-log-folder [folderType]="log.input_folder"></app-classifier-log-folder>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div>
        <span>Output Folder:</span>
        <app-classifier-log-folder [folderType]="log.output_folder"></app-classifier-log-folder>
      </div>
    </div>
  </div>
  <div class="complete-btn-container">
    <button
      class="complete-btn"
      mat-raised-button
      color="primary"
      (click)="onComplete()"
      [disabled]="isCompleted"
    >
      {{ isCompleted ? 'Completed' : 'Complete Case' }}
    </button>
  </div>

  <div class="log-body">
    <ng-container *ngIf="log.sender && log.sender.email; else noRegister">
      <div
        class="sender-info"
        fxLayout.gt-lg="row"
        fxLayout.lt-lg="column"
      >
        <span fxFlex.gt-md="15"></span>
        <span
          fxFlex.gt-md="10"
          (click)="onClickEmail(log.sender.email)"
          >{{ log.sender.id }}</span
        >
        <span fxFlex.gt-md="27">{{ log.sender.name }}</span>
        <span
          fxFlex.gt-md="28"
          class="clickable"
          (click)="onClickEmail(log.sender.email)"
          >{{ log.sender.email }}</span
        >
        <div fxFlex.gt-md="20">
          <span class="tag info-tag">To Contact</span>
        </div>
      </div>
      <div
        class="sender-lead-info"
        fxLayout.gt-md="row"
        fxLayout.lt-md="column"
      >
        <div
          fxFlex.gt-md="15"
          *ngIf="log.sender_lead"
        >
          <span class="tag found-tag">Found</span>
        </div>
        <div
          fxFlex.gt-md="15"
          *ngIf="!log.sender_lead"
        >
          <span class="tag not-found-tag">Not Found</span>
        </div>
        <span
          fxFlex.gt-md="10"
          *ngIf="log.sender_lead"
          (click)="onClickEmail(log.sender_lead.email)"
          >{{ log.sender_lead.id }}</span
        >
        <span
          fxFlex.gt-md="27"
          *ngIf="log.sender_lead"
          >{{ log.sender_lead.name }}</span
        >
        <span
          fxFlex.gt-md="28"
          *ngIf="log.sender_lead"
          (click)="onClickEmail(log.sender_lead.email)"
          >{{ log.sender_lead.email }}</span
        >
        <div fxFlex.gt-md="20">
          <span class="tag info-tag">In Leads</span>
        </div>
      </div>
      <div
        class="original-contact-info"
        fxLayout.gt-md="row"
        fxLayout.lt-md="column"
      >
        <div
          fxFlex.gt-md="15"
          *ngIf="log.original_recipient"
        >
          <span class="tag found-tag">Found</span>
        </div>
        <div
          fxFlex.gt-md="15"
          *ngIf="!log.original_recipient"
        >
          <span class="tag not-found-tag">Not Found</span>
        </div>
        <span
          fxFlex.gt-md="10"
          *ngIf="log.original_recipient"
          (click)="onClickEmail(log.original_recipient.email)"
          >{{ log.original_recipient.id }}</span
        >
        <span
          fxFlex.gt-md="27"
          *ngIf="log.original_recipient"
          >{{ log.original_recipient.name }}</span
        >
        <span
          fxFlex.gt-md="28"
          *ngIf="log.original_recipient"
          (click)="onClickEmail(log.original_recipient.email)"
          >{{ log.original_recipient.email }}</span
        >
        <div fxFlex.gt-md="20">
          <span class="tag info-tag">Original Contact Sent</span>
        </div>
      </div>
    </ng-container>
    <ng-template #noRegister>
      <div class="tag not-found-tag">Not Registers Found</div>
    </ng-template>
  </div>
</div>
