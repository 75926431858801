
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AssignOperatorRequest, IOperator, OperatorManagementTables, OperatorTableFiltersLabels } from '../../../model/Operator';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CustomerService } from '../../../services/customer.service';
import { IClients } from '../../../model/Customer';
import { OperatorService } from '../../../services/operator.service.';
import { AssignOperatorComponent } from './assign-operator/assign-operator.component';
import { TableFilter, TableFilterKeyChangedEvent } from '../../../model/TableFilter';
import { HttpStatusCode } from '@angular/common/http';
import { ClientManagementModalCloseData, ICustomerSdr } from 'src/app/model/Sdr';
import { Router } from '@angular/router';
import { Language, SuportedLanguages } from '../../../model/Flags';
import { TableActionsComponent } from '../../../components/table-actions/table-actions.component';

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management-container.component.html',
  styleUrls: ['./client-management-container.component.scss']
})
export class ClientManagementContainerComponent implements OnInit{
  @ViewChild('operatorSort', { static: false  }) public operatorSort: MatSort;
  @ViewChild('operatorPaginator', { static: false  }) public operatorPaginator: MatPaginator;
  @ViewChild('sdrSort', { static: false  }) public sdrSort: MatSort;
  @ViewChild('sdrPaginator', { static: false  }) public sdrPaginator: MatPaginator;
  @ViewChild('operatorTableActions', {static: false}) public operatorTableActions: TableActionsComponent;
  @ViewChild('sdrTableActions', {static: false}) public sdrTableActions: TableActionsComponent;

  public OperatorManagementTables = OperatorManagementTables;
  public customers$: Observable<IClients[]>;
  public currentTab$: BehaviorSubject<OperatorManagementTables> = new BehaviorSubject<OperatorManagementTables>(OperatorManagementTables.OPERATOR);
  public operatorDataSource: MatTableDataSource<IOperator> = new MatTableDataSource(null);
  public sdrDataSource: MatTableDataSource<ICustomerSdr> = new MatTableDataSource(null);
  public displayedOperatorColumns: string[] = ['name', 'email', 'status', 'languages', 'actions'];
  public displayedSdrColumns: string[] = ['user_name', 'actions'];
  public selectedCustomer: IClients;
  public operatorSearchKey: string;
  public sdrSearchKey: string;
  public operatorHasError = false;
  public sdrHasError = false;
  public operatorsFilters$:BehaviorSubject<TableFilter[]> = new BehaviorSubject<TableFilter[]>([]);

  constructor(
    private readonly customerService: CustomerService,
    private readonly operatorService: OperatorService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.loadFilters();
    this.customers$ = this.getCustomerClients();
  }

  public formatLanguages(languages: string): Language[] {
    return languages.split(',').map(language => ({
        shortName: language as SuportedLanguages
    }));
  }

  public getOperatorsList(customer: IClients): void{
    if(!customer){
      return
    }

    this.selectedCustomer = customer;

    this.customerService.getAllOperatorsForCustomer(customer.clientId).pipe(take(1)).subscribe(res => {
      if(res.code === HttpStatusCode.Ok && res.data){
        this.operatorHasError = false;
        this.createOperatorDataSource(res.data)
      } else {
        this.operatorHasError = true;
        this.createOperatorDataSource([]);
      }
    }, (() => {
      this.operatorHasError = true;
      this.createOperatorDataSource([]);
    }))

    this.customerService.getAllSdrForCustomer(customer.clientId).subscribe(res => {
      if(res.code === HttpStatusCode.Ok && res.data){
        this.sdrHasError = false;
        this.createSdrDataSource(res.data)
      } else {
        this.sdrHasError = true;
        this.createSdrDataSource([]);
      }
    }, (() => {
      this.sdrHasError = true;
      this.createSdrDataSource([]);
    }))
  }

  public openAssignOperatorModal(sdrId?: ICustomerSdr): void {

    this.operatorService.getAllOperatorLevels().pipe(take(1)).subscribe(levels => {
      if(levels.data.length){
        const dialogRef = this.dialog.open(AssignOperatorComponent, {
          width: '50%',
          data: {
            selectedSdr: sdrId,
            sdrs: this.sdrDataSource.data,
            levels: levels.data,
            client: this.selectedCustomer,
          },
          panelClass: 'wd-modal'
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((res: ClientManagementModalCloseData) => {
          if(res){
            this.assignOperator(res.operators);
          }
        })
      }
    })
  }

  public handleOperatorFilterKeyChangedEvent(event: TableFilterKeyChangedEvent): void {
    this.operatorSearchKey = event.newKey;
    this.applyOperatorFilter(event.currentFilter, this.operatorSearchKey);
  }

  public handleFilterUserInputEvent(filter: string): void {
    if(this.currentTab$.value === OperatorManagementTables.OPERATOR){
      this.applyOperatorFilter(filter, this.operatorSearchKey);
    } else {
      this.applySdrFilter(filter);
    }
  }

  public handleTabChange(selectedTabIndex: number): void {
    this.currentTab$.next(selectedTabIndex === 0 ? OperatorManagementTables.OPERATOR : OperatorManagementTables.SDR);
    this.resetFilters();
  }

  public handleOperatorEditClick(targetOperator: IOperator): void {
    this.router.navigate([`home/management/users/${targetOperator.email}`])
  }

  public handleSDREditClick(targetSDR: ICustomerSdr): void {
    console.log('targetSDR', targetSDR)
    console.log('url', `home/management/contacts/contact-edit?id=${targetSDR.user_id}`)
    this.router.navigate([`home/management/contacts/contact-edit?id=${targetSDR.user_id}`])
  }

  private resetFilters(): void {
    this.operatorTableActions.resetFilterField();
    this.sdrTableActions.resetFilterField();
    this.operatorDataSource.filter = null;
    this.sdrDataSource.filter = null;
  }

  private assignOperator(operatorsRequest: AssignOperatorRequest[]): void {
    this.customerService.assignOperator(operatorsRequest).pipe(take(1)).subscribe(res => {
      if(Number(res.code) === HttpStatusCode.Created){
        this.getOperatorsList(this.selectedCustomer);
      }
    })
  }

  private applyOperatorFilter(filterInput: string, filterKey?: string): void {
    if(!this.operatorDataSource.data){
      return
    }

    this.operatorDataSource.filterPredicate = (data, filter) => {

      if(filterKey){
        return data[filterKey].toLowerCase().indexOf(filter) !== -1
      }

      return this.displayedOperatorColumns.some(ele => {
        if(ele === 'actions' || !data[ele]){
          return false
        }

        return data[ele].toLowerCase().indexOf(filter) !== -1;
      });

    };
    this.operatorDataSource.filter = filterInput.trim().toLowerCase();
  }

  private applySdrFilter(filterInput: string): void {
    this.sdrDataSource.filter = filterInput.trim().toLowerCase();
  }

  private loadFilters(): void {
    const operatorFilters: TableFilter[] = [];
    Object.keys(OperatorTableFiltersLabels).forEach(key => {
      if(this.displayedOperatorColumns.includes(key)){
        operatorFilters.push({
          key: key,
          label: OperatorTableFiltersLabels[key]
        })
      }
    })

    this.operatorsFilters$.next(operatorFilters);
  }

  private getCustomerClients(): Observable<IClients[]> {
    return this.customerService.getAllCustomers().pipe(
      filter(response => !!response.data.clients),
      map(response => response.data.clients)
    );
  }

  private createOperatorDataSource(operatorList: IOperator[]): void {
    this.operatorDataSource = new MatTableDataSource(operatorList);
    this.operatorDataSource.sort = this.operatorSort;
    this.operatorDataSource.paginator = this.operatorPaginator;
  }

  private createSdrDataSource(sdrList: ICustomerSdr[]): void {
    this.sdrDataSource = new MatTableDataSource(sdrList);
    this.sdrDataSource.sort = this.sdrSort;
    this.sdrDataSource.paginator = this.sdrPaginator;

    this.sdrDataSource.filterPredicate = (data, filter) => {
      return data.user_name.toLowerCase().indexOf(filter) !== -1;
    }
  }
}
