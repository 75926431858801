import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Conversation, SdrConversation } from '../../model/sdr-conversations/sdrs-conversations.model';
import { SdrConversationsService } from '../../services/sdr-conversations.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sdr-conversations',
  templateUrl: './sdr-conversations.component.html',
  styleUrls: ['./sdr-conversations.component.scss'],
})
export class SdrConversationsComponent implements OnInit, OnDestroy {
  sdrConversations: SdrConversation[] = [];
  selectedConversation: Conversation;

  private destroy$: Subject<boolean> = new Subject();
  constructor(
    private sdrConversationsService: SdrConversationsService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.loadSdrsConversations();

    this.sdrConversationsService.selectedConversation.pipe(takeUntil(this.destroy$)).subscribe({
      next: (selectedConversation) => {
        this.selectedConversation = selectedConversation || null;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadSdrsConversations() {
    this.sdrConversationsService
      .getSdrConversations()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: SdrConversation[]) => {
          this.sdrConversations = response;
        },
        error: () => {
          this.snackBar.open('An error occurred while retrieving conversations', null, {
            duration: 3000,
          });
        },
      });
  }
}
