<div class="search-results-container">
  <div class="new-lead-btn-wrapper">
    <button class="new-lead-btn" mat-stroked-button (click)="onClickViewLead()" [disabled]="!currentLog?.sender_lead?.email">
      <span>View Lead</span>
    </button>
    <!-- <button class="new-lead-btn" mat-stroked-button (click)="onClickNewLead()" [disabled]="!searchResult?.contact">
      <span>New Lead</span>
    </button> -->
  </div>
  <app-search-bar 
    [isLoading]="isLoadingSearch"
    [collapseSearch]="isLoadingSearch ? false : !!searchResult"
  ></app-search-bar>
  <ng-container *ngIf="searchResult; else EmptyResults">
    <div class="search-interactions-wrapper">
      <div class="section-title">Lead Interactions</div>
      <mat-radio-group class="items-list-wrapper" fxFill="100" [(ngModel)]="selectedItem">
        <mat-radio-button *ngFor="let item of searchResult?.interactions" [value]="item" (change)="setSelectedItem($event.value)">
          <app-lead-interaction-item
          [interaction]="item"
          [isEditableCustomer]="isEditableCustomer"
          [isSelected]="selectedItem?.interactionId === item.interactionId"
          (updateLead)="onUpdateLead($event)"
          ></app-lead-interaction-item>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </ng-container>
  <ng-template #EmptyResults>
    <div class="empty-results-container">
      <ng-container *ngIf="noResultsMessage; else InitialEmptyState"><small>{{noResultsMessage}}</small></ng-container>
      <ng-template #InitialEmptyState>
        <img src="/assets/images/empty_discrepancies_search_results.svg" alt="Search for Discrepancies">
      </ng-template>
    </div>
  </ng-template>
</div>
