import { Component, OnInit } from '@angular/core';

import { SDAuthService } from 'src/app/services/sd-auth.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { FeedService } from 'src/app/services/feed.service';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
})
export class TopNavigationComponent implements OnInit {
  options = ['1', '2', '3', '4'];
  color = '#15fa86';
  userEmail = '';
  isAdmin = false;
  showFeed = true;
  hasDiscrepanciesPermission = false;

  constructor(
    private authService: SDAuthService,
    private userService: UserService,
    private router: Router,
    private feedService: FeedService,
  ) {}

  ngOnInit() {
    this.authService.getCurrentUserEmail().then(email => {
      this.userEmail = email;
    });
    this.userService.isUserAdmin().subscribe(isAdmin => {
      this.isAdmin = isAdmin;
    });
    this.feedService.showFeed.subscribe(showFeed => {
      this.showFeed = showFeed;
    });
    this.userService
      .hasPermission('discrepancies')
      .subscribe(hasPermission => (this.hasDiscrepanciesPermission = hasPermission));
  }

  logOut() {
    this.authService.logOut();
  }

  toggleFeed() {
    this.showFeed = !this.showFeed;
    this.feedService.setShowFeed(this.showFeed);
  }

  navigateToHome() {
    this.router.navigate(['/home']);
  }

  navigateToManagement() {
    this.router.navigate(['/home/management']);
  }

  navigateToDiscrepancies() {
    window.open(`${window.location.origin}/home/discrepancies`, '_blank');
  }
}
