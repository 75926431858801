<div class="view-lead-toolbar">
  <div class="details-wrapper">
    <span *ngIf="leadToCampaignValue"><strong>Lead to Campaign</strong> {{ leadToCampaignValue }}</span>
  </div>
  <div class="actions-wrapper">
    <!-- <mat-checkbox color=""primary>Client Filter On</mat-checkbox> -->
    <button class="new-lead-btn" mat-stroked-button (click)="onClickReassign()" [disabled]="!isReassignEnabled">
      <span>Reassign</span>
    </button>
    <!-- <button class="new-lead-btn" mat-stroked-button (click)="onClickAddToBulk()" disabled>
      <span>Add to Bulk</span>
    </button> -->
  </div>
</div>