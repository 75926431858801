import { Component, Input, OnInit } from '@angular/core';
import { Discrepancy, IDiscrepancy } from 'src/app/model/Discrepancy';
import { DiscrepanciesDataService } from 'src/app/services/discrepancies-data.service';
import { AppConstants } from '../../../resources/app-constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SdApiService } from 'src/app/services/base/sd-api.service';
import { SelectedDiscrepancyService } from 'src/app/services/selected-discrepancy.service';
import { DiscrepanciesService } from 'src/app/services/discrepancies.service';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';

@Component({
  selector: 'app-classifier',
  templateUrl: './classifier.component.html',
  styleUrls: ['./classifier.component.scss'],
})
export class ClassifierComponent implements OnInit {
  @Input() currentSdr = -7;
  discrepancies: IDiscrepancy[] = [];
  currentLogIdx = 0;
  totalCount = 0;
  pendingCount = 0;
  currentLog: IDiscrepancy = new Discrepancy({});
  isLoading = true;
  isEmailLoading = false;
  page = 0;
  isReview = false;
  completedCases = 0;

  constructor(private discrepanciesService: DiscrepanciesDataService,
    private _snackBar: MatSnackBar,
    private sdApiService: SdApiService,
    private selectedDiscrepancyService: SelectedDiscrepancyService,
    private discrepanciesApiService : DiscrepanciesService,
    private loadingStateService : LoadingStateService) {}

  async ngOnInit(): Promise<void> {
    await this.loadDiscrepancies();
    this.sdApiService.loadUserClientsSDRs();
    this.loadingStateService.isLoading.subscribe(isLoading => {
      this.isEmailLoading = isLoading;
    });
  }

  get isPrevBtnDisabled(): boolean {
    return this.isLoading || this.isEmailLoading || this.currentLogIdx === 0 || !this.discrepancies.length;
  }

  get isNextBtnDisabled(): boolean {
    return this.isLoading || this.isEmailLoading || this.pendingCount === (this.currentLogIdx + 1) || !this.discrepancies.length;
  }

  get logExits(): boolean {
    return this.currentLogIdx < this.pendingCount - 1 && this.currentLogIdx < this.discrepancies.length - 1
  }

  async navigateNextLog() {
    if (this.logExits) {
      this.currentLogIdx += 1;
      this.currentLog = this.discrepancies[this.currentLogIdx];
      this.selectDiscrepancy(this.currentLog);
    } else {
      this.page += 1;
      await this.loadDiscrepancies();
    }
  }

  navigatePreviousLog() {
    if (this.currentLogIdx > 0) {
      this.currentLogIdx -= 1;
      this.currentLog = this.discrepancies[this.currentLogIdx];
      this.selectDiscrepancy(this.currentLog);
    }
  }

  loadDiscrepancies() {
    this.isLoading = true;
    this.discrepanciesService.loadDiscrepancies(this.page, AppConstants.DISCREPANCIES_LIMIT, this.isReview).subscribe(
      response => {
        this.discrepancies = this.page === 0 ? response.data : this.discrepancies.concat(...response.data);
        this.completedCases = this.page === 0 ? 0 : this.completedCases;
        this.setData(response);
      }, 
      error => {
        this.isLoading = false;
      }
    );
  }

  async onRefreshDiscrepancies() {
    this.page = 0;
    await this.loadDiscrepancies();
  }

  loadDiscrepanciesByStatus(isReview: boolean) {
    this.page = 0;
    this.isLoading = true;
    this.isReview = isReview;
    this.discrepanciesService
      .loadDiscrepancies(this.page, AppConstants.DISCREPANCIES_LIMIT, isReview)
      .subscribe(
        response => {
        this.discrepancies = response.data;
        this.setData(response);
        }, 
        error => {
          this.discrepancies = [];
          this.setData({
            stats: {
              total_day: 0,
              total_pending: 0,
            }
          })
        }
      );
  }

  onCompleteDiscrepancy() {
    this.isLoading = true;
    this.discrepanciesService.completeDiscrepancy(this.currentLog.id).subscribe(
      response => {
        if (response && response.code === 200) {
          this.completedCases++;
          this.currentLog.status = {
            id: -1,
            name: 'completed'
          }
          this._snackBar.open('Log was successfully marked as completed', null, {
            duration: 3000
          });
        }
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
      }
    );
  }

  private setData(response) {
    this.totalCount = response.stats.total_day;
    this.pendingCount = response.stats.total_pending;
    this.currentLogIdx = this.page * AppConstants.DISCREPANCIES_LIMIT;
    this.currentLog = this.discrepancies[this.currentLogIdx];
    this.selectDiscrepancy(this.currentLog);
    this.isLoading = false;
  }

  selectDiscrepancy(discrepancy: IDiscrepancy) {
    if(discrepancy && discrepancy.id) {
      this.selectedDiscrepancyService.selectDiscrepancy(discrepancy);
      this.loadingStateService.setLoadingState(true);
      this.discrepanciesApiService.getMessageFromDiscrepancy(discrepancy.message.uid).subscribe(message => {
        this.loadingStateService.setLoadingState(false);
        this.selectedDiscrepancyService.selectMail(message);
      }, error => {
        console.log(error);
        this.loadingStateService.setLoadingState(false);
        this.selectedDiscrepancyService.selectMail(null);
      });
    }
  }

  onClickEmail(email: string) {
   this.selectedDiscrepancyService.setClickedEmail(email);
  } 
}
