<div id="layoutDiv">
  <div id="container">
    <div class="titleDiv">User Management</div>

    <div class="actions-container">
      <mat-form-field
        class="search-form-field form-field-5"
        floatLabel="never"
        appearance="outline"
        subscriptSizing="dynamic"
        id="user-list-search-user-mat-form-field"
      >
        <mat-label>Search Users</mat-label>
        <input
          matInput
          [(ngModel)]="searchKey"
          autocomplete="off"
          (keyup)="applyFilter()"
          id="user-list-search-user-input"
        />
        <mat-icon
          matSuffix
          *ngIf="searchKey"
          (click)="onSearchClear()"
          id="user-list-search-user-mat-icon-clear"
          >close</mat-icon
        >
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        (click)="navigateToNew()"
        class="fixed-width"
        id="user-list-add-user-btn"
      >
        <mat-icon>group_add</mat-icon>ADD USERS
      </button>
    </div>

    <div class="mat-elevation-z8 table-container">
      <mat-table
        [dataSource]="listData"
        matSort
        matSortActive="email"
        matSortDirection="asc"
        matSortDisableClear
      >
        <ng-container matColumnDef="email">
          <mat-header-cell
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="50"
            *matHeaderCellDef
            mat-sort-header
            >Email</mat-header-cell
          >
          <mat-cell
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="50"
            *matCellDef="let element"
            >{{ element.email }}</mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="50"
            *matHeaderCellDef
            >Actions</mat-header-cell
          >
          <mat-cell
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="50"
            *matCellDef="let row"
            class="center"
          >
            <button
              fxLayout="row"
              fxLayoutAlign="center center"
              class="action-btn"
              mat-icon-button
              (click)="navigateToDetails(row.email)"
              id="user-list-edit-user-btn"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="loading">
          <mat-footer-cell
            *matFooterCellDef
            colspan="2"
          >
            Loading data...
          </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="noData">
          <mat-footer-cell
            *matFooterCellDef
            colspan="2"
          >
            No data.
          </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
          *matFooterRowDef="['loading']"
          [ngClass]="{ hide: listData !== null }"
        ></mat-footer-row>
        <mat-footer-row
          *matFooterRowDef="['noData']"
          [ngClass]="{ hide: !(listData !== null && listData.data.length === 0) }"
        ></mat-footer-row>
      </mat-table>
      <mat-paginator
        [pageSize]="10"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
