<div class="toolbar-container" fxLayout.gt-md="row" fxLayout.lt-md="column">
  <div fxLayout="row">
    <div class="total-count">
      <span>Total {{ totalCount }}</span>
    </div>
    <div class="pending-count">
      <span>Pending {{ pendingCount }}</span>
    </div>
    <button mat-stroked-button (click)="onRefresh()">
      Refresh
    </button>
  </div>
  <div fxLayout="row" class="navitation-wrapper">
    <div class="actions-wrapper">
      <button mat-stroked-button (click)="onManual()">
        Manual
      </button>
      <button mat-stroked-button (click)="onReview()">
        Review
      </button>
    </div>
    <div class="navigation-btns">
      <button class="navigation-btn" mat-stroked-button (click)="onMovePrevious()" [disabled]="isPrevDisabled">
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button class="navigation-btn" mat-stroked-button (click)="onMoveNext()" [disabled]="isNextDisabled">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>
</div>
