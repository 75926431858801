<div class="lead-data-container">
  <mat-card [ngClass]="{'active': isActive}">
    <div class="header-wrapper" fxLayout="row" fxLayoutGap="8px">
      <mat-card-header fxFlex="80">
        <div mat-card-avatar class="lead-header-image" (click)="onClickExpand()"></div>
        <mat-card-title [matTooltip]="fullName">{{ fullName }}</mat-card-title>
        <mat-card-subtitle [matTooltip]="lead.email">
          <p>{{ lead.email }}</p>
          <div class="date-wrapper">
            <span class="status-chip">{{ lead.status.name }}</span>
            <span class="status-date">{{ lead.date_status | date: 'YYYY-MM-dd h:mm a' }}</span>
          </div>
        </mat-card-subtitle>

      </mat-card-header>
      <div class="header-btns-wrapper" fxFlex="20">
        <img *ngIf="lead.job_linkedin_id" src="/assets/images/linkedIn_icon.svg" alt="LinkedIn Job Posting">
        <button mat-raised-button class="lead-save-btn" (click)="onClickSave($event)" *ngIf="isActive"
          [disabled]="!isSaveEnabled">Save</button>
      </div>
    </div>
    <mat-card-content [ngClass]="detailsCollapsed ? 'collapsed' : 'expanded'">
      <div fxLayout="row" class="campaign-info">
        <small *ngIf="lead.campaign_id">Campaign ID: <strong>{{ lead.campaign_id }}</strong></small>
        <small *ngIf="lead.template_id">Template ID: <strong>{{ lead.template_id }}</strong></small>
      </div>
      <div class="form-wrapper">
        <form [formGroup]="form">
          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="33" class="search-result-date-input">
              <mat-label>Date Received</mat-label>
              <input matInput [ngxMatDatetimePicker]="pdt" formControlName="date_received" (dateChange)="dateChanged()">
              <mat-datepicker-toggle matSuffix [for]="pdt"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #pdt [showSpinners]="true" [showSeconds]="true" [enableMeridian]="false">
              </ngx-mat-datetime-picker>
            </mat-form-field>

            <mat-form-field fxFlex="33" class="search-result-date-input">
              <mat-label>Contact Date</mat-label>
              <input matInput [ngxMatDatetimePicker]="cdt" formControlName="contact_date" [min]="minContactDate">
              <mat-datepicker-toggle matSuffix [for]="cdt"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #cdt [showSpinners]="true" [showSeconds]="true" [enableMeridian]="false">
              </ngx-mat-datetime-picker>
              <mat-error *ngIf="form.controls.contact_date.hasError('matDatepickerMin')">Date cannot be before Date Received</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="33" class="search-result-comments-input">
              <mat-label>Comments</mat-label>
              <input matInput formControlName="comments">
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="33">
              <mat-label>Sales Rep</mat-label>
              <mat-select formControlName="client_contact" [compareWith]="compareFunction">
                <mat-option class="client-contact-opt" *ngFor="let contact of salesRepresentatives" [value]="contact">
                  {{ contact.name }} <span class="cc-geographic-zone">| {{ contact.geographicZone }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="33">
              <mat-label>Msg ID</mat-label>
              <input matInput formControlName="message_id_received">
            </mat-form-field>

            <mat-form-field fxFlex="33">
              <mat-label>ID Lead Forward</mat-label>
              <input matInput formControlName="id_lead_forward">
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="33">
              <mat-label>Qualification</mat-label>
              <mat-select formControlName="source" [compareWith]="compareFunction" (selectionChange)="onChangeQualification()">
                <mat-option *ngFor="let qualification of selectsData.qualifications" [value]="qualification">
                  {{ qualification.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="33">
              <mat-label>Matrix</mat-label>
              <mat-select formControlName="matrix" [compareWith]="compareFunction" (selectionChange)="getDefaultValues()">
                <mat-option *ngFor="let matrix of selectsData.matrix" [value]="matrix">
                  {{ matrix.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="33">
              <mat-label>Country</mat-label>
              <mat-select formControlName="country" [compareWith]="compareFunction" (selectionChange)="calculateScore()">
                <mat-option *ngFor="let country of selectsData.country" [value]="country">
                  {{ country.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex="33">
              <mat-label>Delay Time</mat-label>
              <mat-select formControlName="delay" [compareWith]="compareFunction" (selectionChange)="calculateScore()">
                <mat-option *ngFor="let delay of selectsData.delay" [value]="delay">
                  {{ delay.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="33">
              <mat-label>Industry</mat-label>
              <mat-select formControlName="industry" [compareWith]="compareFunction" (selectionChange)="calculateScore()">
                <mat-option *ngFor="let industry of selectsData.industry" [value]="industry">
                  {{ industry.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="33">
              <mat-label>Score</mat-label>
              <input matInput formControlName="score">
            </mat-form-field>
          </div>
        </form>
      </div>
    </mat-card-content>

    <mat-card-actions align="center">
      <button mat-button (click)="toggleDetails($event)" class="details-btn" disableRipple>
        {{ detailsCollapsed ? 'More Details' : 'Hide Details' }}
        <mat-icon>
          {{ detailsCollapsed ? 'arrow_drop_down' : 'arrow_drop_up' }}
        </mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>