<div
  class="extra-info"
  *ngIf="selectedConversation"
>
  <div class="extra-info__container">
    <p class="extra-info__item"><mat-icon color="primary">info</mat-icon></p>
    <p class="extra-info__item"><span class="title">SDR ID:</span>{{ selectedConversation.sdrId }}</p>
    <p class="extra-info__item"><span class="title">SDR Name:</span>{{ selectedConversation.sdrName }}</p>
    <p class="extra-info__item">
      <span class="title">Conversation Id: </span>{{ selectedConversation.conversationId }}
    </p>
  </div>
</div>
