import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Sdr } from 'src/app/model/Sdr';
import { SdApiService } from 'src/app/services/base/sd-api.service';
import { InquiryService } from 'src/app/services/inquiry.service';

@Component({
  selector: 'app-inquiry-search',
  templateUrl: './inquiry-search.component.html',
  styleUrls: ['./inquiry-search.component.scss']
})
export class InquirySearchComponent implements OnInit {
  selectedClient: string;
  selectedSdr: Sdr;
  clients = [];
  sdrs: Sdr[] = [];
  clientSdrs: Sdr[] = [];
  searchDate: Date;
  email: string;
  firstName = "";
  lastName = "";
  error = "";
  subscriptions = new Subscription();
  isSearchLoading = false;
  @Input() initialSelectedClient: string;
  @Input() initialSelectedSdr: Sdr;
  @Input() initialEmail: string;
  @Input() initialDate: Date;
  emailPattern = new RegExp(/^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
  constructor(private sdApiService: SdApiService, private inquiryService: InquiryService) { }

  ngOnInit() {
    this.subscriptions.add(this.sdApiService.userSdrsSent.subscribe((sdrs) => {
      this.orderSdr(sdrs);
      this.sdrs = sdrs;
      this.clientSdrs = this.sdrs.filter(sdr => sdr.client == this.initialSelectedClient).sort((a, b) => {
        if (a.email > b.email) {
          return 1;
        }
        if (a.email < b.email) {
          return -1;
        }
        return 0;
      });
      this.selectedSdr = this.initialSelectedSdr;
      this.searchDate = this.initialDate;
    }));
    this.subscriptions.add(this.sdApiService.userClientsSent.subscribe((clients: any[]) => {
      this.clients = clients.map(c => c.name).sort();
      this.selectedClient = this.initialSelectedClient;
    }));

    this.subscriptions.add(this.inquiryService.isLoading.subscribe((isLoading) => this.isSearchLoading = isLoading));
    
    this.email = this.initialEmail;
  }

  search() {
    this.error = '';
    let sdr = this.selectedSdr ? this.selectedSdr.email : "";
    let searchDate = this.searchDate ? this.formatDate(this.searchDate) : "";

    this.email = this.email.trim();
    this.firstName = this.firstName.trim();
    this.lastName = this.lastName.trim();

    if(this.email) {
      if(!this.emailPattern.test(this.email) && !this.searchDate) {
        this.error = 'Date is required when executing a search with a partial email.';
        return;
      }
    } else {
      this.error = 'Email is required to perform the search.';
      return;
    }
    this.inquiryService.searchInquiryLead(sdr, searchDate, this.email, this.firstName, this.lastName);
    this.inquiryService.setInquirySearch({
      client: this.selectedClient,
      sdr,
      date: searchDate,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName
    });
  }

  get isValidSearch(): boolean {
    return !!this.selectedClient && !!this.selectedSdr?.email && !!this.email;
  }

  updateClients() {
    this.clientSdrs = this.sdrs.filter(sdr => sdr.client == this.selectedClient).sort((a, b) => {
      if (a.email > b.email) {
        return 1;
      }
      if (a.email < b.email) {
        return -1;
      }
      return 0;
    });
    this.selectedSdr = this.clientSdrs[0];
  }

  orderSdr(sdrs: Sdr[]) {
    const ordered = sdrs;
    ordered.sort((a, b) => {
      if (a.email > b.email)
        return 1;
      else if (a.email === b.email) {
        return 0;
      } else {
        return -1;
      }
    });
    this.sdrs = ordered;
  }

  onEmailClear() {
    this.email = "";
  }

  onFirstNameClear() {
    this.firstName = "";
  }
  onLastNameClear() {
    this.lastName = "";
  }

  formatDate(date: Date) {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  }

  validateEmail() {
    if(this.emailPattern.test(this.email)) {
      this.firstName = '';
      this.lastName = '';
    }
  }
}
