import { FormControl } from '@angular/forms';
import { IClients } from './Customer';
import { AssignOperatorRequest, IOperator, OperatorsLevels } from './Operator';

// TODO: Move to Customer.ts when is merged into develop.
export enum ClientsDataContext {
  HOMEPAGE = 'homepage',
  DISCREPANCIES = 'discrepancies',
}
export class Sdr implements ISdr {
  email: string;
  client: string;
  signature: string;
  imap: string;
  isSendConfigured: boolean;
  id: string;
  clientId: number;

  constructor(raw?: ISdr) {
    this.email = raw.email;
    this.client = raw.client;
    this.signature = raw.signature;
    this.imap = raw.imap;
    this.id = raw.id;
    // TODO:Get from parameters when api provides
    // this.signature=`<p>
    //   <span style="font-size:12px;"><span style="font-family: arial, helvetica, sans-serif; color: rgb(0, 112, 192); font-weight: bold;">Dr. Mart&iacute;n Ymaz - ` + this.email + `</span><br style="color: rgb(0, 0, 0); font-family: Arial; font-size: 13px;" />
    //   <span style="font-family: arial, helvetica, sans-serif; font-weight: bold;">abogado (CPACF T&deg; 41, F&deg; 319 &ndash; DNI 16.345.645)</span><br style="color: rgb(0, 0, 0); font-family: Arial; font-size: 13px;" />
    //   <span style="font-size:16px;"><span style="font-family: arial, helvetica, sans-serif; color: rgb(50, 94, 158); font-weight: bold;">Ymaz Abogados &amp; Asociados</span></span></span><br style="color: rgb(0, 0, 0); font-family: Arial; font-size: 13px;" />
    //   <br style="color: rgb(0, 0, 0); font-family: Arial; font-size: 13px;" />
    //   <span style="font-size:12px;"><span style="font-family: arial, helvetica, sans-serif;"><strong>Direct:&nbsp;</strong></span><span style="font-size:11px;">+54 911 3682 8730 | Maipu 1210 piso 8, Buenos Aires Argentina</span><br style="color: rgb(0, 0, 0); font-family: Arial; font-size: 13px;" />
    //   <span style="font-family: arial, helvetica, sans-serif;"><strong>Email:</strong>&nbsp;<a href="mailto:martin.ymaz@ymazabogados.com" style="color: rgb(0, 112, 192);" target="_blank">martin.ymaz@ymazabogados.com</a>&nbsp;</span></span></p>`;
    // Add some line changes for every signature
    this.signature = '<br><br>' + this.signature + '';
    this.isSendConfigured = raw.isSendConfigured;
    this.clientId = raw.clientId;
  }

  static checkIntegrity(sdrs: Sdr[]) {
    let isOkay = true;
    sdrs.forEach((sdr) => {
      if (sdr.email == null) isOkay = false;
    });
    return isOkay;
  }
}

export interface ISdr {
  email: string;
  client: string;
  signature: string;
  imap: string;
  isSendConfigured: boolean;
  id: string;
  clientId: number;
}

export interface ICustomerSdr {
  user_id: number;
  user_name: string;
  user_email: string | null;
  operators: IOperator[];
}

export interface ClientManagementModalData {
  selectedSdr: ICustomerSdr;
  sdrs: ICustomerSdr[];
  levels: OperatorsLevels[];
  client: IClients;
}

export interface ClientManagementModalCloseData {
  sdrId: number;
  operators: AssignOperatorRequest[];
}

export interface IDetailedSdr {
  user_operator_id: number;
  user_id: number;
  user_email: string | null;
  operator: ISdrFiltersResponse;
  customer_id: number;
  customer_name: string;
  level: ISdrFiltersResponse;
}

export enum SdrFiltersEnum {
  COMPANY = 'customers',
  SDR = 'users',
  PERMISSION = 'levels',
  OPERATOR = 'operators',
}

export enum SdrFiltersSearchKeyEnum {
  NAME = 'name',
  EMAIL = 'email',
}
export interface ISdrFilters {
  customers?: string;
  users?: string;
  levels?: string;
  operators?: string;
}

export interface ISdrFiltersResponse {
  id: number;
  name: string;
  email?: string;
}

export interface ISdrForm {
  user_operator_id: FormControl<number | null>;
  operator: FormControl<ISdrFiltersResponse | null>;
  level: FormControl<ISdrFiltersResponse | null>;
}

export interface ISdrFormValue {
  user_operator_id: number;
  operator: ISdrFiltersResponse | null;
  level: ISdrFiltersResponse | null;
}

export interface ReassignSdrModalData {
  sdrs: IDetailedSdr[];
}

export interface IReassignSdrPayload {
  user_operator_id: number;
  user_id: number;
  operator_id: number;
  level_id: number;
}

export const DEFAULT_PAGE_SIZE = 100;
export const DEFAULT_PAGE_NUMBER = 1;
