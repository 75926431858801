import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {


  transform(value: string, limit = 20, completeWords = false, ellipsis = '...') {
    if (!value) {
      return '';
    }
    if (value.length <= limit) {
      return value;
    }
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    return value.length > limit && limit !== -1 ? value.substr(0, limit) + ellipsis : value;
  }
}
