<div
  mat-dialog-title
  fxLayout="row"
  class="advance-search-header"
  fxLayoutAlign="space-between center"
>
  Advanced Search
  <mat-icon mat-dialog-close>close</mat-icon>
</div>
<div class="advanced-search-container">
  <form
    (submit)="onSubmitSearch()"
    [formGroup]="form"
  >
    <div fxLayout="row">
      <small class="advanced-filters-hint"
        >A search with a partial (or no) email requires at least the First or Last Name and a Date to be
        executed.</small
      >
    </div>
    <div fxLayout="row">
      <mat-form-field
        fxFlex="100"
        class="form-field-5"
        appearance="outline"
      >
        <mat-label>Email</mat-label>
        <input
          matInput
          formControlName="email"
        />
        <mat-error *ngIf="form?.get('email')?.invalid && form?.get('email')?.errors?.['minlength']">
          Email must be at least 3 characters long.
        </mat-error>
      </mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="10"
    >
      <mat-form-field
        fxFlex="50"
        class="form-field-5"
        appearance="outline"
      >
        <mat-label>First Name</mat-label>
        <input
          matInput
          formControlName="firstName"
        />
      </mat-form-field>
      <mat-form-field
        fxFlex="50"
        class="form-field-5"
        appearance="outline"
      >
        <mat-label>Last Name</mat-label>
        <input
          matInput
          formControlName="lastName"
        />
      </mat-form-field>
    </div>
    <div
      class="row"
      fxLayoutGap="10"
    >
      <div
        *ngIf="form?.get('bySingleDate')?.value"
        fxFlex="78"
      >
        <mat-form-field
          fxFlex="100"
          class="search-date-picker"
        >
          <mat-label>Enter a date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="searchDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div
        *ngIf="!form?.get('bySingleDate')?.value"
        fxFlex="78"
      >
        <mat-form-field
          fxFlex="100"
          class="search-date-picker"
        >
          <mat-label>Enter a seven-day date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              placeholder="From"
              formControlName="fromDate"
            />
            <input
              matEndDate
              placeholder="To"
              formControlName="toDate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error
            *ngIf="
              form?.controls?.fromDate?.hasError('invalidDateRange') ||
              form?.controls?.toDate?.hasError('invalidDateRange')
            "
          >
            <span class="invalid-date-range">Range must be of 7 days</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="checkbox-wrapper"
        fxFlex="22"
      >
        <mat-checkbox
          formControlName="bySingleDate"
          color="primary"
          >Search specific date</mat-checkbox
        >
      </div>
    </div>

    <div class="search-buttons-wrapper">
      <div
        fxLayout="row"
        class="checkbox-wrapper"
      >
        <mat-checkbox
          formControlName="matchAnyCondition"
          color="primary"
          >Return records matching <strong>any</strong> search condition</mat-checkbox
        >
      </div>

      <button
        class="search-btn"
        mat-stroked-button
        color="primary"
        [disabled]="!isValid || isLoading"
      >
        <span *ngIf="!isLoading">Find</span>
        <mat-spinner
          class="search-btn-spinner"
          *ngIf="isLoading"
          diameter="20"
        ></mat-spinner>
      </button>
    </div>
  </form>
  <div class="advance-search-results">
    <mat-table [dataSource]="searchResultsData">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID Lead </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="first_name">
        <mat-header-cell *matHeaderCellDef> First Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.first_name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="last_name">
        <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.last_name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Email Address </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="loading">
        <mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          Loading data...
        </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="noData">
        <mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          {{ noResultsMessage }}
        </mat-footer-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="table-header"
      ></mat-header-row>
      <mat-row
        (click)="onRowClicked(row)"
        [class.row-is-clicked]="selectedRow.has(row)"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
      <mat-footer-row
        *matFooterRowDef="['loading']"
        [ngClass]="{ hide: !isLoading }"
      ></mat-footer-row>
      <mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{ hide: isLoading || !(searchResultsData !== null && !searchResultsData.data.length) }"
      ></mat-footer-row>
    </mat-table>
    <mat-paginator
      [pageSize]="15"
      showFirstLastButtons
      #historyPaginator="matPaginator"
    ></mat-paginator>
  </div>
</div>
