import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SdApiService } from '../../../../../../services/base/sd-api.service';
import { SelectedMailService } from '../../../../../../services/selected-mail.service';
import { MailCategory, IMailClassification, ClassificationEmailFeedEnum } from '../../../../../../model/MailCategory';
import { FeedService } from '../../../../../../services/feed.service';
import { AppConstants } from '../../../../../../resources/app-constants';
import { Email } from '../../../../../../model/Email';
import { ConfirmDialogComponent } from '../../../../../../components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-mail-details-categorizer',
  templateUrl: './mail-details-categorizer.component.html',
  styleUrls: ['./mail-details-categorizer.component.scss'],
})
export class MailDetailsCategorizerComponent implements OnInit, OnDestroy {
  classifications: IMailClassification[] = [];
  bulkLength = 0;
  currentEmail: Email;
  subscriptions = new Subscription();
  directAccessClassifications: MailCategory[] = [];
  private folder = 'inbox';

  constructor(
    private sdApiService: SdApiService,
    public selectedMailService: SelectedMailService,
    private feedService: FeedService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.feedService.selectedFolder.subscribe((folder) => {
      if (folder) {
        this.sdApiService.loadSdrClassifications(folder);
        this.folder = folder;
      }
    });

    this.sdApiService.classifications
      .pipe(
        map((data) => {
          const options = [];
          ['Direct access', 'Uncommon', 'Blacklist'].forEach((section) => {
            const elem = data.find((el) => el.sectionName === section);
            if (elem) {
              if (section === 'Direct access') {
                elem.classifications = this.mapDirectAccesses(elem.classifications);
                this.directAccessClassifications = elem.classifications;
              }
              options.push(elem);
            }
          });
          return options.length ? options : data;
        }),
      )
      .subscribe((data) => {
        const convertNameToHtmlId = (name) => name?.toLowerCase().replace(/ /g, '-') + '-btn';

        const transformClassifications = (classifications) => {
          return classifications.map((item) => {
            return {
              ...item,
              htmlId: item.name ? convertNameToHtmlId(item.name) : item.name, // Evitar transformar nombres vacíos
            };
          });
        };

        const transformedData = data.map((section) => {
          return {
            ...section,
            classifications: transformClassifications(section.classifications),
          };
        });
        this.classifications = transformedData;
      });

    this.selectedMailService.emailBulkSelection.subscribe((bulk) => {
      this.bulkLength = bulk.length;
    });

    this.subscriptions.add(
      this.selectedMailService.selectedEmail.subscribe((currentEmail) => {
        this.currentEmail = currentEmail;
      }),
    );
  }

  get isClassificationDisabled(): boolean {
    return !this.currentEmail && !this.bulkLength;
  }

  get selectedEmails(): number {
    return this.currentEmail ? this.bulkLength + 1 : this.bulkLength;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:keydown.control.0', ['$event'])
  hotkeyClassify_0(event: KeyboardEvent) {
    event.preventDefault();
    this.selectedMailService.triggerClassifyMail(
      this.directAccessClassifications.find((opt) => opt.id === AppConstants.CATEGORY_NOT_NOW_ID),
    );
  }

  @HostListener('window:keydown.control.1', ['$event'])
  hotkeyClassify_1(event: KeyboardEvent) {
    event.preventDefault();
    this.selectedMailService.triggerClassifyMail(
      this.directAccessClassifications.find((opt) => opt.id === AppConstants.CATEGORY_OUT_OF_OFFICE_ID),
    );
  }

  @HostListener('window:keydown.control.2', ['$event'])
  hotkeyClassify_2(event: KeyboardEvent) {
    event.preventDefault();
    this.selectedMailService.triggerClassifyMail(
      this.directAccessClassifications.find((opt) => opt.id === AppConstants.CATEGORY_OUT_OF_OFFICE_LONG_TERM_ID),
    );
  }

  @HostListener('window:keydown.control.3', ['$event'])
  hotkeyClassify_3(event: KeyboardEvent) {
    event.preventDefault();
    this.selectedMailService.triggerClassifyMail(
      this.directAccessClassifications.find((opt) => opt.id === AppConstants.CATEGORY_OUT_OF_COMPANY_ID),
    );
  }

  @HostListener('window:keydown.control.4', ['$event'])
  hotkeyClassify_4(event: KeyboardEvent) {
    event.preventDefault();
    this.selectedMailService.triggerClassifyMail(
      this.directAccessClassifications.find((opt) => opt.id === AppConstants.CATEGORY_BAD_FIT_ID),
    );
  }

  @HostListener('window:keydown.control.5', ['$event'])
  hotkeyClassify_5(event: KeyboardEvent) {
    event.preventDefault();
    this.selectedMailService.triggerClassifyMail(
      this.directAccessClassifications.find((opt) => opt.id === AppConstants.CATEGORY_NOT_INTERESTED_ID),
    );
  }

  @HostListener('window:keydown.control.6', ['$event'])
  hotkeyClassify_6(event: KeyboardEvent) {
    event.preventDefault();
    this.selectedMailService.triggerClassifyMail(
      this.directAccessClassifications.find((opt) => opt.id === AppConstants.CATEGORY_UNSUBSCRIBE_MANUAL_ID),
    );
  }

  @HostListener('window:keydown.control.7', ['$event'])
  hotkeyClassify_7(event: KeyboardEvent) {
    event.preventDefault();
    this.selectedMailService.triggerClassifyMail(
      this.directAccessClassifications.find((opt) => opt.id === AppConstants.CATEGORY_REVIEW_ID),
    );
  }

  @HostListener('window:keydown.control.8', ['$event'])
  hotkeyClassify_8(event: KeyboardEvent) {
    event.preventDefault();
    this.selectedMailService.triggerClassifyMail(
      this.directAccessClassifications.find((opt) => opt.id === AppConstants.CATEGORY_TO_CONTACT_ID),
    );
  }

  @HostListener('window:keydown.control.9', ['$event'])
  hotkeyClassify_9(event: KeyboardEvent) {
    event.preventDefault();
    this.selectedMailService.triggerClassifyMail(
      this.directAccessClassifications.find((opt) => opt.id === AppConstants.CATEGORY_CALL_U_BACK_ID),
    );
  }

  classify(classification: MailCategory) {
    if (classification.id === ClassificationEmailFeedEnum.DeleteFrom) {
      const confirmDialogConfig = new MatDialogConfig();
      confirmDialogConfig.data = {
        title: 'Confirm',
        message: 'Are you sure you want to classify this email as DeleteFrom?',
      };
      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
      confirmDialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.selectedMailService.triggerClassifyMail(classification);
        }
      });
    } else {
      this.selectedMailService.triggerClassifyMail(classification);
    }
  }

  private mapDirectAccesses(directAccesses) {
    const mappedOptions = [];
    [
      { name: 'REVIEW', shortcut: 7 },
      { name: 'To Contact', shortcut: 8 },
      { name: 'Call U Back', shortcut: 9 },
      { name: 'Bad Fit', shortcut: 4 },
      { name: 'Not Interested', shortcut: 5 },
      { name: 'Unsubscribe', shortcut: 6 },
      { name: 'Out Of Office', shortcut: 1 },
      { name: 'Out of Office Long Term', shortcut: 2 },
      { name: 'Out Of Company', shortcut: 3 },
      { name: '', shortcut: null },
      { name: 'Not Now', shortcut: 0 },
      { name: '', shortcut: null },
    ].forEach((option) => {
      if (!!option.name) {
        const classification = directAccesses.find((el) => el.name === option.name);
        classification.shortcut = option.shortcut;
        mappedOptions.push(classification);
      } else {
        mappedOptions.push({ name: '', id: '' });
      }
    });

    return mappedOptions;
  }
}
