<div class="client-contacts-edit">
  <div class="client-contacts-edit__header">
    <span class="client-contacts-edit__header__title">{{ pageTitle }}</span>
    <button
      mat-raised-button
      (click)="cancel()"
    >
      CANCEL
    </button>
  </div>
  <mat-divider></mat-divider>

  <div class="client-contacts-edit__form">
    <mat-form-field
      appearance="outline"
      class="form-field-5"
      subscriptSizing="dynamic"
    >
      <mat-label>Client</mat-label>
      <input
        type="text"
        aria-label="Number"
        matInput
        required
        [formControl]="clientFilterControl"
        [matAutocomplete]="clientsAuto"
        [(ngModel)]="selectedClient"
      />
      <mat-autocomplete
        #clientsAuto="matAutocomplete"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let client of filteredClientOptions | async"
          [value]="client"
        >
          {{ client }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="form-field-5"
      subscriptSizing="dynamic"
    >
      <mat-label>Name</mat-label>
      <input
        matInput
        [(ngModel)]="name"
        required
        maxlength="50"
      />
    </mat-form-field>
    <mat-form-field
      class="form-field-5"
      appearance="outline"
      subscriptSizing="dynamic"
    >
      <mat-label>Comments</mat-label>
      <textarea
        matInput
        [(ngModel)]="comments"
        style="resize: none"
        maxlength="1000"
      ></textarea>
    </mat-form-field>
    <mat-form-field
      class="form-field-5"
      appearance="outline"
      subscriptSizing="dynamic"
    >
      <mat-label>Geographic Zone</mat-label>
      <input
        matInput
        [(ngModel)]="geographicZone"
        maxlength="100"
      />
    </mat-form-field>
    <mat-form-field
      class="form-field-5"
      appearance="outline"
      subscriptSizing="dynamic"
    >
      <mat-label>Email</mat-label>
      <input
        matInput
        [(ngModel)]="email"
        required
        [pattern]="emailPatternExpression"
        maxlength="100"
      />
    </mat-form-field>
    <mat-form-field
      class="form-field-5"
      appearance="outline"
      subscriptSizing="dynamic"
    >
      <mat-label>Profile CC</mat-label>
      <mat-chip-grid #profileCcChipList>
        <mat-chip-row
          [color]="item.invalid ? 'warn' : 'primary'"
          selected
          highlighted
          *ngFor="let item of profileCcList; let i = index"
          [removable]="removable"
          (removed)="removeProfileCc(item)"
          name="profileCcChips"
          >{{ item.value }}
          <mat-icon
            matChipRemove
            *ngIf="removable"
            >cancel</mat-icon
          >
        </mat-chip-row>
        <input
          matInput
          id="profileCc"
          [matChipInputFor]="profileCcChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addProfileCc($event)"
          [formControl]="profileCcControl"
          #ccInput
        />
      </mat-chip-grid>
    </mat-form-field>
    <mat-form-field
      class="form-field-5"
      appearance="outline"
      subscriptSizing="dynamic"
    >
      <mat-label>Job Position</mat-label>
      <input
        matInput
        [(ngModel)]="jobPosition"
        maxlength="100"
      />
    </mat-form-field>
    <mat-form-field
      class="form-field-5"
      appearance="outline"
      subscriptSizing="dynamic"
    >
      <mat-label>Email Template CC</mat-label>
      <mat-chip-grid #emailTemplateCcChipList>
        <mat-chip-row
          [color]="item.invalid ? 'warn' : ''"
          selected
          highlighted
          *ngFor="let item of emailTemplateCcList; let i = index"
          [removable]="removable"
          (removed)="removeEmailTemplateCc(item)"
          name="emailTemplateCcChips"
        >
          {{ item.value }}
          <mat-icon
            matChipRemove
            *ngIf="removable"
            >cancel</mat-icon
          >
        </mat-chip-row>
        <input
          matInput
          id="emailTemplateCc"
          [matChipInputFor]="emailTemplateCcChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addEmailTemplateCc($event)"
          [formControl]="emailTemplateCcControl"
          #emailTemplateCcInput
        />
      </mat-chip-grid>
    </mat-form-field>
    <mat-form-field
      class="form-field-5"
      appearance="outline"
      subscriptSizing="dynamic"
    >
      <mat-label>Email Template BCC</mat-label>
      <mat-chip-grid #emailTemplateBccChipList>
        <mat-chip-row
          [color]="item.invalid ? 'warn' : ''"
          selected
          highlighted
          *ngFor="let item of emailTemplateBccList; let i = index"
          [removable]="removable"
          (removed)="removeEmailTemplateBcc(item)"
          name="emailTemplateBccChips"
          >{{ item.value }}
          <mat-icon
            matChipRemove
            *ngIf="removable"
            >cancel</mat-icon
          >
        </mat-chip-row>
        <input
          matInput
          id="emailTemplateBcc"
          [matChipInputFor]="emailTemplateBccChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addEmailTemplateBcc($event)"
          [formControl]="emailTemplateBccControl"
          #emailTemplateBccInput
        />
      </mat-chip-grid>
    </mat-form-field>
    <mat-form-field
      class="form-field-5 client-contacts-edit__form__status"
      appearance="outline"
      subscriptSizing="dynamic"
    >
      <mat-label>Status</mat-label>
      <mat-select [(value)]="selectedStatus">
        <mat-option
          *ngFor="let status of statusOptions"
          [value]="status"
        >
          {{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <div class="client-contacts-edit__footer">
    <button
      mat-raised-button
      color="primary"
      (click)="createContact()"
      [disabled]="invalidForm || isSubmitting"
    >
      {{ submitContactLabel }}
    </button>
  </div>
</div>
