import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TOKEN_KEY } from '../services/sd-auth.service';
import 'rxjs-compat/add/operator/map';
import { catchError, switchMap, filter, take, timeout } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { SDAuthService } from '../services/sd-auth.service';
import { environment } from 'src/environments/environment';

const DEFAULT_TIMEOUT = 5 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: SDAuthService) {}

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('password-public')) {
      return next
        .handle(request)
        .pipe(timeout(DEFAULT_TIMEOUT))
        .pipe(
          catchError((error) => {
            return this.errorHandling(error, request, next);
          }),
        );
    }

    if (request.url.includes(environment.apiBaseURL) || request.url.includes(environment.newApiBaseUrl)) {
      request = this.addToken(request);
      return next
        .handle(request)
        .pipe(timeout(DEFAULT_TIMEOUT))
        .pipe(
          catchError((error) => {
            return this.errorHandling(error, request, next);
          }),
        );
    } else {
      return next
        .handle(request)
        .pipe(timeout(DEFAULT_TIMEOUT))
        .pipe(
          catchError((error) => {
            return throwError(error);
          }),
        );
    }
  }

  errorHandling(error, request: HttpRequest<any>, next: HttpHandler) {
    console.log('Http error, code: ' + error.status + ' ,url: ' + request.url);
    if (error instanceof HttpErrorResponse && error.status === 401) {
      console.log('Invalid token');
      return this.handle401Error(request, next);
    } else {
      return throwError(error);
    }
  }

  private addToken(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: request.url.includes(environment.apiBaseURL)
          ? localStorage.getItem(TOKEN_KEY)
          : request.url.includes(environment.newApiBaseUrl)
            ? `Bearer ${localStorage.getItem(TOKEN_KEY)}`
            : '',
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    console.log('Silent refresh');
    if (!this.isRefreshing) {
      console.log('Silent refresh route 1');
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshSessionToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          console.log('Auth service subscription');
          return next.handle(this.addToken(request)).pipe(timeout(DEFAULT_TIMEOUT));
        }),
      );
    } else {
      console.log('Silent refresh route 2');
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          console.log('Refresh token subject');
          return next.handle(this.addToken(request)).pipe(timeout(DEFAULT_TIMEOUT));
        }),
      );
    }
  }
}
