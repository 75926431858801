import { FormControl } from "@angular/forms";
import { IClients } from "./Customer";

export class Operator implements IOperator {
    operator_id: number;
    name: string;
    email: string;
    status: string;
    languages: string;

    constructor(data?: IOperator) {
        if (!data)
        return;
        this.operator_id = data.operator_id;
        this.name = data.name;
        this.email = data.email;
        this.status = data.status;
        this.languages = data.languages;
    }
}

export interface IOperator {
    operator_id: number;
    name: string;
    email: string;
    status: string;
    languages: string;
}

export interface IOperatorSDR {
    operator_id: string;
    operator_name: string;
    operator_email: string;
    assigned: string;
    level_id?: string;
    level_name?: string;
}

export interface IOperatorDetails extends IOperator {
    clientsCount: number;
    sdrCount: number;
    folders: string[];
    discrepancies: string[];
    emailCount: number;
    timeToReply: number;
    companies: IOperatorDetailsCompany[];
}

export interface IOperatorDetailsCompany {
    companyName: string;
    sdrCount: number;
    sdrs: IOperatorDetailsCompanySdr[];
}

export interface IOperatorDetailsCompanySdr {
    id: number;
    sdrName: string;
    folders: string[];
    discrepancies: string[];
    emailCount: number;
    timeToReply: number;
}

export enum OperatorManagementTables {
    OPERATOR = 'OPERATOR',
    SDR = 'SDR'
}

export interface OperatorsLevels {
    id: number;
    name: string;
    description: string;
}

export interface UnsetOperatorModalData {
    operator: IOperator;
    client: IClients;
}

export interface AssignOperatorRequest {
    level_id: number;
    operator_id: number;
    customer_id: number;
    user_id: number;
}

export interface UpdateOperatorLevelRequest {
    level_id: number;
    operators_customers_level_id: number;
}

export enum OperatorTableFiltersLabels {
    name = 'Filter Operator Names',
    email = 'Filter User Email',
    status = 'Filter Operator Status',
    languages = 'Filter Languages'
}

export interface IOperatorUser {
    id: number;
    name: string;
    last_name: string;
    email: string;
    language: string,
    last_activity: string;
}

export interface IOperatorUserDetails {
    id: number;
    name: string;
    email?: string;
    last_name: string;
    language: string;
    enabled: boolean;
    roles: string;
    permissions: IOperatorUserDetailsEntityRef[];
    default_sdrs: IOperatorUserDetailsSdr[];
    manual_discrepancies: string[];
    review_discrepancies: string[];
    clients: IOperatorUserDetailsEntityRef[];
}

export interface IOperatorUserDetailsSdr {
    id: number;
    email: string;
    customer_name: string;
    level_name: string;
}

export interface IOperatorUserDetailsEntityRef {
    id: number;
    name: string
}

export interface IOperatorUserCommonPayload {
  name: string;
  last_name: string;
  language: string,
}

export interface IOperatorUserCreatePayload extends IOperatorUserCommonPayload{
    email: string;
    password: string;
}

export interface IOperatorUserUpdatePayload extends IOperatorUserCommonPayload{
    password?: string;
}

export interface IOperatorUserForm {
  name: FormControl<string | null>;
  last_name: FormControl<string | null>;
  email: FormControl<string | null>;
  password: FormControl<string | null>;
  language: FormControl<string | null>;
}

export enum OperatorUserManagementTabs {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export enum OperatorUserManagementEditTabs {
    DETAILS = 'DETAILS',
    SDRS = 'SDRS'
}

export interface ToggleOperatorUserModalData {
  operatorsUsers: IOperatorUser[];
  action: ToggleOperatorUserActions;
}

export enum ToggleOperatorUserActions {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export interface IOperatorUserToggleActivePayload {
  action: ToggleOperatorUserActions;
  usernames: string[];
}
