import { Component, OnInit } from '@angular/core';
import { SdApiService } from 'src/app/services/base/sd-api.service';
import { ClientsDataContext } from 'src/app/model/Sdr';

@Component({
  selector: 'app-discrepancies',
  templateUrl: './discrepancies.component.html',
  styleUrls: ['./discrepancies.component.scss']
})
export class DiscrepanciesComponent implements OnInit {

  constructor(private sdApiService: SdApiService) { }

  ngOnInit() {
    this.sdApiService.setClientsContext(ClientsDataContext.DISCREPANCIES);
  }
}
