import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

interface ICovertedList {
  date: string;
  fullName: string;
  additionalInfo: string;
}

@Component({
  selector: 'app-converted-clients-list',
  templateUrl: './converted-clients-list.component.html',
  styleUrls: ['./converted-clients-list.component.scss'],
})
export class ConvertedClientsListComponent implements OnInit, AfterViewInit{
  displayedColumns: string[] = ['date', 'fullName', 'additionalInfo'];
  dataSource: MatTableDataSource<ICovertedList>;

  @ViewChild(MatSort) sort: MatSort;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
}
