import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ILeadToBulk, ISelectsData } from 'src/app/model/DiscrepancyLeadToBulk';
import { DiscrepanciesDataService } from 'src/app/services/discrepancies-data.service';

import { LeadToBulkDialogComponent } from '../lead-to-bulk-dialog/lead-to-bulk-dialog.component';

const SNACKBAR_DURATION = 3000;
@Component({
  selector: 'app-search-result-item',
  templateUrl: './search-result-item.component.html',
  styleUrls: ['./search-result-item.component.scss'],
})
export class SearchResultItemComponent implements OnInit {
  @Input() lead: ILeadToBulk;
  @Input() selectsData: ISelectsData;
  @Input() salesRepresentatives = [];
  @Input() isEditableCustomer;
  @Input() isActive = false;
  @Output() updateLead: EventEmitter<any> = new EventEmitter();
  @Output() detailsToggled: EventEmitter<any> = new EventEmitter();
  // maxDate = new Date();

  form: FormGroup = new FormGroup({});
  detailsCollapsed = true;
  
  constructor(
    private dialog: MatDialog,
    private discrepanciesDataService: DiscrepanciesDataService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.form = new FormGroup({
      date_received: new FormControl(this.lead.date_received, Validators.required),
      contact_date: new FormControl(this.lead.contact_date, Validators.required),
      comments: new FormControl(this.lead.comments),
      client_contact: new FormControl(this.lead.client_contact),
      id_lead_forward: new FormControl(this.lead.id_lead_forward),
      country: new FormControl({ value: this.lead.country, disabled: true }),
      delay: new FormControl({ value: this.lead.delay, disabled: true }),
      industry: new FormControl({ value: this.lead.industry, disabled: true }),
      matrix: new FormControl({ value: this.lead.matrix, disabled: true }),
      source: new FormControl({ value: this.lead.source, disabled: !this.isQualificationsEditable }),
      message_id_received: new FormControl(this.lead.message_id_received),
      campaign_bulk_date: new FormControl(this.lead.campaign_bulk_date),
      job_linkedin_id: new FormControl({ value: this.lead.job_linkedin_id, disabled: true }),
      lead_to_campaign_id:  new FormControl(this.lead.id),
      score: new FormControl({ value: this.lead.score, disabled: true })
    })
  }

  get isSaveEnabled(): boolean {
    return this.form.dirty && this.form.valid && this.form.touched;
  }

  get isQualificationsEditable(): boolean {
    return this.isEditableCustomer && this.lead.status.name === 'Converted - Lead';
  }

  get fullName(): string {
    return `${this.lead.first_name} ${this.lead.last_name}`
  }

  get minContactDate(): Date | null {
    return this.form.get('date_received').value ? new Date(this.form.get('date_received').value) : null;
  }

  toggleDetails(event) {
    event.stopPropagation();
    this.detailsCollapsed = !this.detailsCollapsed;
    this.detailsToggled.emit();
  }

  onClickExpand() {
    const dialog = this.dialog.open(LeadToBulkDialogComponent, {
      width: '650px',
      maxHeight: '700',
      panelClass: 'lead-to-bulk-details',
      data: {
        lead: this.lead,
        detailsForm: this.form,
        selectsData: this.selectsData,
        salesRepresentatives: this.salesRepresentatives,
        isQualificationsEditable: this.isQualificationsEditable
      }
    })

    dialog.afterClosed().subscribe(result => {
      if(result && result.save) {
        this.form.patchValue(result.form.value);
        this.emitSave();
      } else {
        this.form.markAsUntouched();
      }
    });
  }

  compareFunction(option, value): boolean {
    return option.id === value?.id;
  }

  onClickSave(event: any) {
    event.stopPropagation();
    this.emitSave();
  }

  emitSave() {
    this.updateLead.emit({id: this.lead.id, ...this.form.getRawValue()});
    this.form.markAsUntouched();
  }

  getDefaultValues() {
    const form = this.form.getRawValue();
    if(form.date_received && form.source.id && form.campaign_bulk_date) {
      const inputs = {
        source_id: form.source.id,
        date_response: new Date(form.date_received).toJSON(),
        campaign_bulk_date: new Date(form.campaign_bulk_date)?.toJSON()?.split('T')[0],
        industry_lead: this.lead.industry_lead,
        country_lead: this.lead.country_lead
      }
      if(!form.matrix.value) {
        this.form.get('matrix').setValue(this.selectsData.matrix.find(opt => opt.id === form.matrix.id));
        this.form.get('matrix').enable();
      }

      if(!form.source.factor) {
        this.form.get('source').setValue(this.selectsData.qualifications.find(opt => opt.id === form.source.id));
        this.form.get('source').enable();
      }

      this.discrepanciesDataService.getLeadDefaultValues(inputs).subscribe(
        response => {
          this.form.get('country').setValue(this.selectsData.country.find(opt => opt.id === response.country_id));
          this.form.get('country').enable();
          this.form.get('industry').setValue(this.selectsData.industry.find(opt => opt.id === response.industry_id));
          this.form.get('industry').enable();

          if(!this.discrepanciesDataService.CONDITIONAL_QUALIFICATION_IDS.includes(form.source.id)) {
            this.form.get('delay').setValue(this.selectsData.delay.find(opt => opt.id === response.delay_id))
            this.form.get('delay').enable();
          } else {
            this.form.get('delay').setValue(null);
            this.form.get('delay').disable();
          }
          
          this.calculateScore();
        }, 
        error => {
          this.snackbar.open(`An error occurred: ${error.error.detail}`, null, { duration: SNACKBAR_DURATION });
        }
      )

    }
  }

  onChangeQualification() {
    this.form.get('matrix').enable();
    if(this.form.get('matrix').value) {
      this.getDefaultValues();
    }
  }

  calculateScore() {
    const form = this.form.getRawValue();
    if(form.source?.factor && form.industry?.value && form.matrix?.value && form.country?.value) {
      const inputs: any = {
        source_value: this.form.get('source').value.factor,
        industry_value: this.form.get('industry').value.value,
        matrix_value: this.form.get('matrix').value.value,
        country_value: this.form.get('country').value.value
      }

      if (form.delay?.value) {
        inputs.delay_value = this.form.get('delay').value.value
      }
      this.discrepanciesDataService.getLeadScore(inputs).subscribe(
        response => {
          this.form.get('score').setValue(response.value);
        },
        error => {
          this.snackbar.open(`An error occurred: ${error.error.detail}`, null, { duration: SNACKBAR_DURATION });
          this.form.get('score').setValue(0);
        }
      )
    }
  }

  dateChanged() {
    if(this.isQualificationsEditable) {
      this.getDefaultValues();
    }
  }
}
