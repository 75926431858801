import { Attachment } from './Attachment';
import { Reference } from './Reference';
import { SendStatusType } from './SendStatusType';

export enum FOLDERS {
  REVIEW = 'REVIEW',
  SENT = 'Sent',
  INBOX = 'Inbox',
  CONVERTED = 'Converted',
  OTHER = 'Other',
  SPAM = 'Spam',
  SALE_OPERATORS = 'Sale Operations',
  SPAM_WD = 'Spam WD',
}

export class Email {
  sdr: string;
  uid: string;
  subject: string;
  from: string;
  to: string;
  cc: string;
  messageId: string;
  date: string;
  dateMillis: number;
  bodyHtml: string;
  folderId: string;
  toAddresses: string[];
  downloadTimestamp: number;
  hasError: boolean;
  storageId: string;
  from_: Reference;
  // from_: any
  to_: Reference[];
  cc_: Reference[];
  imap: string;
  sdrName: string;
  references: any;
  in_reply_to: any;
  sendStatus: string;
  errorMessage: string;
  folder: string;
  hasAttachments: boolean;
  attachments: Attachment[];

  constructor(data?: any) {
    if (!data) return;
    this.sdr = data.sdr || '';
    this.uid = data.uid || '';
    this.subject = data.subject || '';
    this.from = data.from || '';
    this.to = data.to || '';
    this.cc = data.cc || '';
    this.folderId = data.folder_id;
    this.messageId = data.message_id || '';
    this.date = data.date || '';
    this.dateMillis = data.date ? new Date(data.date).getTime() : -1;
    this.bodyHtml = data.body_html || data.message_html;
    this.toAddresses = data.to_addresses || '';
    this.hasError = false;
    this.downloadTimestamp = Date.now();
    this.storageId = data.storage_id || '';
    this.from_ = data.from_ || '';
    this.to_ = data.to_ || '';
    this.cc_ = data.cc_ || '';
    this.imap = data.imap || '';
    this.sdrName = data.sdrName || '';
    this.references = data.references || '';
    this.in_reply_to = data.in_reply_to || '';
    this.sendStatus = data.sendStatus;
    this.errorMessage = data.errorMessage;
    this.folder = data.folder;
    this.hasAttachments = data.has_attachments;
    this.attachments = data.attachments;
  }

  static sortByDAte(emails: Email[]) {
    if (!emails) return [];

    return emails.sort((a: Email, b: Email) => {
      return a.dateMillis - b.dateMillis;
    });
  }
}
