
import { Component, OnInit } from '@angular/core';
import { SdApiService } from '../../../services/base/sd-api.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';

const MODAL_DURATION = 3000;

@Component({
  selector: 'app-bot-setup',
  templateUrl: './bot-setup.component.html',
  styleUrls: ['./bot-setup.component.scss']
})
export class BotSetupComponent implements OnInit  {

  constructor(private service: SdApiService,
              private loadingStateService: LoadingStateService,
              private snackBar: MatSnackBar) { }

  loading = false;
  fromDate: Date;
  toDate: Date;

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe((loading) => {
      this.loading = loading;
    });
    this.fromDate = new Date();
    this.toDate = new Date();
  }

  downloadSimultionReport() {
    let fromDate = this.fromDate ? this.formatDate(this.fromDate, false) : "";
    let toDate = this.toDate ? this.formatDate(this.toDate, true) : "";
    this.loadingStateService.setLoadingState(true);
    this.service.downloadSimulationReport(fromDate,toDate).subscribe(
      downloadUrl => {
        if(downloadUrl) {
          this.service.getAttachmentFromUrl(
            downloadUrl)
            .subscribe((data) => {
            this.downloadDocument(data, 'SimulationReport' + fromDate + '_' + toDate);
            this.loadingStateService.setLoadingState(false);
          }, error => {
            console.log(error);
            this.loadingStateService.setLoadingState(false);
            this.snackBar.open('An error ocurred while downloading the simulation report', null, { duration: MODAL_DURATION });
          });
        } else {
          this.snackBar.open('No data was found for the selected date range', null, { duration: MODAL_DURATION });
          this.loadingStateService.setLoadingState(false);
        }
        
      },
      error => {
        this.snackBar.open('An error ocurred while downloading the simulation report', null, { duration: MODAL_DURATION });
        console.log(error);
        this.loadingStateService.setLoadingState(false);
      });
  }

  downloadClassificationReport() {
    let fromDate = this.fromDate ? this.formatDate(this.fromDate, false) : "";
    let toDate = this.toDate ? this.formatDate(this.toDate, true) : "";
    this.loadingStateService.setLoadingState(true);
    this.service.downloadClassificationReport(fromDate,toDate).subscribe(
      downloadUrl => {
        if(downloadUrl) {
          this.service.getAttachmentFromUrl(
            downloadUrl)
            .subscribe((data) => {
            this.downloadDocument(data, 'ClassificationReport_' + fromDate + '_' + toDate);
            this.loadingStateService.setLoadingState(false);
          }, error => {
            console.log(error);
            this.loadingStateService.setLoadingState(false);
            this.snackBar.open('An error ocurred while downloading the classification report', null, { duration: MODAL_DURATION });
          });
        } else {
          this.snackBar.open('No data was found for the selected date range', null, { duration: MODAL_DURATION });
          this.loadingStateService.setLoadingState(false);
        }
      },
      error => {
        this.snackBar.open('An error ocurred while downloading the classification report', null, { duration: MODAL_DURATION });
        console.log(error);
        this.loadingStateService.setLoadingState(false);
      });
  }

  formatDate(date: Date, includeSelf: boolean) {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + (!includeSelf ? "T00:00:00" : "T23:59:59");
  }

  downloadDocument(data: Blob, documentName: string): void {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(data);
    element.download = documentName;
    document.body.appendChild(element);
    element.click();
    element.remove();
  }


}
