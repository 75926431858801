export enum IconNames {
    usa = 'usa_flag',
    spain = 'spain_flag',
    brazil = 'brazil_flag',
}

export enum SuportedLanguages {
    english = 'en',
    spanish = 'es',
    portuguese = 'pt',
}

export interface Language {
  shortName: SuportedLanguages,
  fullName?: string
}
