
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOperatorUserDetails, IOperatorUserForm } from '../../../../model/Operator';
import { Language, SuportedLanguages } from '../../../../model/Flags';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-operator-user-form',
  templateUrl: './operator-user-form.component.html',
  styleUrls: ['./operator-user-form.component.scss']
})
export class OperatorUserFormComponent implements OnInit, OnChanges{

  @Input() public operatorUserForm: FormGroup<IOperatorUserForm>;
  @Input() public currentValues?: IOperatorUserDetails;
  @Input() public isEdit: boolean = false;

  public suportedLanguages$: BehaviorSubject<Language[]> = new BehaviorSubject<Language[]>([]);
  public passwordValidator: RegExp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}/;

  constructor() { }

  public ngOnInit(): void {
    this.generateLanguagesArray();
    this.createOperatorUserForm();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if(changes.currentValues?.currentValue) {
      this.patchValuesOnForm(changes.currentValues.currentValue);
    }
  }

  public generateLanguagesArray(): void {
    const languages: Language[] = [];

    Object.keys(SuportedLanguages).forEach(key => {
      languages.push({
        shortName:SuportedLanguages[key],
        fullName: key
      });
    })

    this.suportedLanguages$.next(languages);
  }

  public handleLanguageCheck(checked: boolean, language: string): void {
    const languageForm = this.operatorUserForm?.get('language');
    const currentLanguages = !!languageForm?.value ? languageForm.value.split(',') : [];
    if(checked){
      languageForm?.setValue([ ...currentLanguages, language].toString());
      return
    }

    languageForm?.setValue(currentLanguages.filter(currentLanguage => currentLanguage !== language).toString());
  }

  private patchValuesOnForm(currentOperator: IOperatorUserDetails): void {
    this.operatorUserForm.patchValue({...currentOperator});
  }

  private createOperatorUserForm(): void {
    this.operatorUserForm.addControl('name', new FormControl(null, [Validators.required]));
    this.operatorUserForm.addControl('last_name', new FormControl(null, [Validators.required]));
    this.operatorUserForm.addControl('language', new FormControl(null, [Validators.required]));

    if(this.isEdit){
      this.operatorUserForm.addControl('email', new FormControl({ value: null, disabled: true}, [Validators.required, Validators.email]));
      this.operatorUserForm.addControl('password', new FormControl(null, [Validators.minLength(8), Validators.maxLength(10)]));
    } else {
      this.operatorUserForm.addControl('email', new FormControl(null, [Validators.required, Validators.email]));
      this.operatorUserForm.addControl('password', new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(10)]));
    }
  }
}
